import React from "react";

export function AlarmSectionView() {
  return (
    <div className="inner-page-row">
        <div className="grid md:grid-cols-3 gap-28 pt-3">
        <h2>AlarmSectionView</h2>
        </div>
    </div>
  );
}
