import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IContractResponse } from "../contract.interface";

interface IContractDetails {
  contractDetails: IContractResponse;
}

const initialState: IContractDetails = {
  contractDetails: {
    customer: {
      address: "",
      gstNumber: "",
      id: 0,
      mobileNumber: "",
      name: "",
    },

    duration: 0,
    durationPeriod: "year",
    endDate: null,
    equipments: [],
    id: 0,
    name: "",
    operatorIds: [],
    site: {
      area: "",
      id: 0,
      images: [],
      lattitude: "",
      location: "",
      longitude: "",
      status: false,
    },

    startDate: "",
  },
};
const contractSlice = createSlice({
  name: "contractReducer",
  initialState,
  reducers: {
    setContractdetails(state, actions: PayloadAction<IContractResponse>) {
      state.contractDetails = actions.payload;
      return state;
    },
  },
});

export const { setContractdetails } = contractSlice.actions;
export default contractSlice.reducer;
