import React from "react";
import {
  DEVICE_OPERATIONS,
  IAddDeviceRequest,
  IDeviceListRequest,
  IDeviceTypeRequest,
  IUpdateDeviceRequest,
} from "../device.interface";
import { deviceService } from "./device.services";
import { AppThunk } from "../../../config/store.hooks";
import { setDevice, setDeviceType } from "../store/device.reducer";
import { DEVICE_API_ENDPOINTS } from "../../../config/default.config";
import { processFiles } from "../../../utils/file-processor";

export const addDevice = (
  addDeviceRequest: IAddDeviceRequest,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    const formData = new FormData();
    formData.append("id", String(addDeviceRequest.id));
    formData.append("name", addDeviceRequest.name);
    formData.append("imei", String(addDeviceRequest.imei));
    formData.append("devicetype", addDeviceRequest.deviceType);
    formData.append("sensorProtocol", addDeviceRequest.sensorProtocol);
    formData.append("sensorIP", addDeviceRequest.sensorIP);
    formData.append("sensorPort", addDeviceRequest.sensorPort);
    formData.append("cameraProtocol", addDeviceRequest.cameraProtocol);
    formData.append("cameraIP", addDeviceRequest.cameraIP);
    formData.append("cameraPort", addDeviceRequest.cameraPort);
    formData.append("cameraFrameRate", addDeviceRequest.cameraFrameRate);
    formData.append("cameraResolution", addDeviceRequest.cameraResolution);
    formData.append("isCameraAdded", String(addDeviceRequest.isCameraAdded));
    formData.append("isDeleted", String(addDeviceRequest.isDeleted));

    addDeviceRequest.images.forEach((item, index) => {
      for (let key in item) {
        formData.append(`images[${index}].${key}`, item[key]);
      }
    });

    try {
      const response = await deviceService.addDevice(formData);
      if (response?.status === 200 || response?.status === 201) {
        setSuccess(true, DEVICE_OPERATIONS.CREATE, null);
      } else {
        setSuccess(false, null);
      }
    } catch (err: any) {
      let errorCode = err?.response?.data?.errorCode;
      setSuccess(false, DEVICE_OPERATIONS.CREATE, errorCode);
      return null;
    }
  };
};

export const getDeviceTypes = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await deviceService.getDeviceTypes();
      if (response?.status === 200 || response?.status === 201) {
        dispatch(setDeviceType(response.data.data.data));
      }
    } catch (err) {
      return null;
    }
  };
};

export const getDeviceById = (deviceId: number, setSuccess: any): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await deviceService.getDeviceById(deviceId);

      if (response?.status === 200 || response?.status === 201) {
        const processedFiles = processFiles(response?.data?.data?.images);

        dispatch(
          setDevice({ ...response?.data?.data, images: processedFiles })
        );
        setSuccess(true);
        return response;
      } else {
        setSuccess(false);
        //hanlde error case herw
      }
    } catch (err) {
      setSuccess(false);
    }
  };
};

export const updateDevice = (
  updateDeviceRequest: IUpdateDeviceRequest,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    const formData = new FormData();
    formData.append("id", String(updateDeviceRequest.id));
    formData.append("name", updateDeviceRequest.name);
    formData.append("imei", String(updateDeviceRequest.imei));
    formData.append("devicetype", String(updateDeviceRequest.deviceType));
    formData.append("sensorProtocol", updateDeviceRequest.sensorProtocol);
    formData.append("sensorIP", updateDeviceRequest.sensorIP);
    formData.append("sensorPort", updateDeviceRequest.sensorPort);
    formData.append("cameraProtocol", updateDeviceRequest.cameraProtocol);
    formData.append("cameraIP", updateDeviceRequest.cameraIP);
    formData.append("cameraPort", updateDeviceRequest.cameraPort);
    formData.append("cameraFrameRate", updateDeviceRequest.cameraFrameRate);
    formData.append("cameraResolution", updateDeviceRequest.cameraResolution);
    formData.append("isCameraAdded", String(updateDeviceRequest.isCameraAdded));
    formData.append("storageType", String(updateDeviceRequest.storageType));
    formData.append("isDeleted", String(updateDeviceRequest.isDeleted));
    updateDeviceRequest.images.forEach((item, index) => {
      for (let key in item) {
        formData.append(`images[${index}].${key}`, item[key]);
      }
    });

    try {
      const response = await deviceService.updateDevice(formData);
      if (response?.status === 200 || response?.status === 201) {
        setSuccess(true, DEVICE_OPERATIONS.UPDATE, null);
      } else {
        setSuccess(false, DEVICE_OPERATIONS.UPDATE, null);
      }
    } catch (err: any) {
      let errorCode = err?.response?.data?.errorCode;
      setSuccess(false, DEVICE_OPERATIONS.UPDATE, errorCode);
      return null;
    }
  };
};

export const getDeviceList = async (IDeviceListRequest: IDeviceListRequest) => {
  try {
    const response = await deviceService.getDeviceList(IDeviceListRequest);

    if (response?.success) {
      return response?.data;
    } else {
      //hanlde error case herw
    }
  } catch (err) {
    //handle error herw
  }
};

export const deleteDeviceById = async (deviceId: number, setSuccess: any) => {
  try {
    const response = await deviceService.deleteDeviceById(deviceId);

    if (response?.success) {
      setSuccess(true,null);
    } else {
      setSuccess(false,null);
    }
  } catch (err: any) {
    setSuccess(false, err?.response?.data?.errorCode);
  }
};

export const deviceActions = {
  addDevice,
  getDeviceTypes,
  getDeviceById,
  updateDevice,
  getDeviceList,
  deleteDeviceById,
};
