import { DEVICE_API_ENDPOINTS, OPERATOR_API_ENDPOINTS } from "../../../config/default.config";
import axiosInstance from "../../../utils/intercepter.util";
import { IAddOrUpdateOperatorRequest, IOperatorListRequest } from "../operator.interface";

const addOperator = async (formData: FormData) => {
    const response = await axiosInstance.post(
      OPERATOR_API_ENDPOINTS.ADD_OPERATOR,
      formData
    );
    return response;
  };

  const updateOperator = async (formData: FormData) => {
    const response = await axiosInstance.put(
      OPERATOR_API_ENDPOINTS.UPDATE_OPERATOR,
      formData
    );
    return response;
  };

  const getOperatorList = async (operatorAddRequest: IOperatorListRequest) => {
    const response = await axiosInstance.get(
      OPERATOR_API_ENDPOINTS.GET_OPERATOR_LIST.replace(
        ":page",
        `${operatorAddRequest.Page}`
      )
        .replace(":pageSize", `${operatorAddRequest.PageSize}`)
        .replace(":orderByDesc", `${operatorAddRequest.OrderByDesc}`)
        .replace(":orderBy", operatorAddRequest.OrderBy)
        .replace(":searchKey", operatorAddRequest.SearchKey)
    );
    return response;
  };

  const getOperatorById = async (operatorId: number) => {
    const response = await axiosInstance.get(OPERATOR_API_ENDPOINTS.GET_OPERATOR_BY_ID.replace(':id', `${operatorId}`));
    return response;
  };

  const deleteOperatorById = async (operatorId: number) => {
    const response = await axiosInstance.delete(
      OPERATOR_API_ENDPOINTS.DELETE_OPERATOR.replace(":id", `${operatorId}`)
    );
    return response?.data;
  };
  

  export const operartorService = {
    addOperator,
    getOperatorList,
    getOperatorById,
    updateOperator,
    deleteOperatorById,
  };
  