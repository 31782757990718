import { IOperator, IOperatorReduxState } from "../operator.interface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: IOperatorReduxState = {
  operator: {
    id: 0,
    name: "",
    mobile: "",
    location: "",
    address: "",
    images: [],
  },
};

const OperatorSlice = createSlice({
  name: "operatorReducer",
  initialState,
  reducers: {
    setOperator(state, actions: PayloadAction<IOperator>) {
      state.operator = actions.payload;
      return state;
    },
  },
});

export const { setOperator } = OperatorSlice.actions;
export default OperatorSlice.reducer;
