import React, { useEffect, useState } from "react";
import { RecentAlarmLayout } from "./alarmSection/alarmSection";
import StatusTile from "./status.tile";
import StatusTable from "../components/status.component/status.table";
import { useNavigate } from "react-router-dom";
import { NAVIGATIONS } from "../../config/default.config";
import { useAppDispatch } from "../../config/store.hooks";
import { dashboardAction } from "./service/dashbord.action";

export interface ISiteStatus {
  activeSites: number;
  inActiveSites: number;
  standBySites: number;
}

export interface IEquipmentStatus {
  activeCount: number;
  inActiveCount: number;
  standByCount: number;
}

function DashboardComponent() {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(NAVIGATIONS.OPERATOR_STATUS_VIEW);
  };

  const [siteStatus, setSiteStatus] = useState<ISiteStatus>({
    activeSites: 0,
    inActiveSites: 0,
    standBySites: 0,
  });

  const [equipmentStatus,setEquipmentStatus]=useState<IEquipmentStatus>({
    activeCount: 0,
    inActiveCount: 0,
    standByCount: 0,
  })

  const dispatch = useAppDispatch();

  useEffect(() => {
    getSiteStatus();
    getEquipmentStatus();
  }, []);

  const getSiteStatus = async () => {
    const response: any = await dispatch(dashboardAction.getSiteStatus());
    setSiteStatus(response?.data);
  };

  const getEquipmentStatus =async()=>{
    const response: any = await dispatch(dashboardAction.getEquipmentStatus());
    setEquipmentStatus(response?.data);
  }
  return (
    <div className="pl-24 pt-10 pb-2 h-full flex ">
      <div className=" w-2/3">
      <div className=" px-8 pt-7">
          <div className="md:flex justify-between">
            <StatusTile
              title={"Equipment Usage Summary"}
              active={equipmentStatus?.activeCount}
              standby={equipmentStatus?.standByCount}
              finished={equipmentStatus?.inActiveCount}
            />
            <StatusTile
              title={"Site Status"}
              active={siteStatus?.activeSites}
              standby={siteStatus?.standBySites}
              finished={siteStatus?.inActiveSites}
            />
          </div>
          <div className="equipmentStatusDesign rounded-lg  shadow-[0px_0px_11px_0px_#00000024] bg-white py-5 dark:status-tile-container">
            <div className="flex  justify-between px-8">
              <div className=" flex text-lg title-tab">
                <div className="font-[550]  text-gray-500 dark:head-text-color ">
                  Equipment
                </div>
                <div className=" text-gray-400 pl-1 dark:head-text-color">
                  Status
                </div>
              </div>
              <button type="button" className=" text-blue-600 text-sm cursor-pointer" onClick={handleNavigation}>View more</button>
            </div>
            <StatusTable />
          </div> 
        </div>
      </div>
      <div className="alarmSection w-1/3">
        <RecentAlarmLayout />
      </div>
    </div>
  );
}
export default DashboardComponent;
