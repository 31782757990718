import { config } from "process";
import { EQUIPMENT_API, SEQROPS_INSTANCE_API, SITE_API } from "../../../config/default.config";
import axiosInstance from "../../../utils/intercepter.util";
import { IInstanceProperties, IOperatorStatusRequest, IStatusEquipmentReq,  } from "../../components/status.component/status.table.interface";
import { IOperatorStatusUpdateRequest, IRecentMediaRequest } from "../alarmSection/alarmSection.interface";

const getEquipmentDetails = async (OperatorStatusReq: any) => {
  try {
    const response = await axiosInstance.post(
      SEQROPS_INSTANCE_API.GET_ALL_EQUIPMENT_DETAILS,
      OperatorStatusReq,
    );

    if(response.status===200)
    {
      return response.data;
    }
    else{
      return null
    }
  } catch (error) {
  }
};


const getOperatorLog=async(IOperatorLogsReq:any)=>{
  try {
    const response = await axiosInstance.post(
      SEQROPS_INSTANCE_API.GET_ALL_OPERATOR_LOGS,
      IOperatorLogsReq,
    );
    if(response.status===200)
    {
      return response.data;
    }
    else{
      return null
    }
  } catch (error) {
    
  }

}

const getMediaAlarm=async(recentMediaAlarmRequest:IRecentMediaRequest)=>{
  
    const response = await axiosInstance.post(SEQROPS_INSTANCE_API.GET_MEDIA_ALARM,recentMediaAlarmRequest)
    return response

}

const getSiteStatus=async()=>{
  const response =await axiosInstance.get(SITE_API.SITE_STATUS);
  return response
}


const getRecentVideoAlarms=async(recentMediaAlarmRequest:IRecentMediaRequest)=>{
  const response=await axiosInstance.post(SEQROPS_INSTANCE_API.GET_RECENT_VIDEO,recentMediaAlarmRequest)
  return response
}

const updateOperatorStatus=async(operatorStatusUpdateRequest:IOperatorStatusUpdateRequest)=>{

  const response=await axiosInstance.put(SEQROPS_INSTANCE_API.UPDATE_OPERATOR_STATUS,operatorStatusUpdateRequest);
  return response
  
}

const getEquipmentStatus=async()=>{
  const response = await axiosInstance.get(EQUIPMENT_API.GET_EQUIPMENT_STATUS) 
  return response
}

const getAllInstanceProperties=async(instancePropertiesRequest:IInstanceProperties)=>{

  const response= await axiosInstance.post(SEQROPS_INSTANCE_API.GET_ALL_INSTANCE_PROPERTIES,instancePropertiesRequest)
  return response
}

const getOperatorDetails=async(operatorDetails:IStatusEquipmentReq[])=>{
  const response =await axiosInstance.post(SEQROPS_INSTANCE_API.GET_OPERATOR_DETAILS,operatorDetails)
  return response
}


export const dashboardService = {
  getEquipmentDetails,
  getOperatorLog,
  getMediaAlarm,
  getSiteStatus,
  getRecentVideoAlarms,
  updateOperatorStatus,
  getEquipmentStatus,
  getAllInstanceProperties,
  getOperatorDetails
};
