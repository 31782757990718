import React, { useEffect, useState } from "react";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/Delete_icon.svg";
import noImageIcon from "../../assets/images/No-image.jpg";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_DIALOUGES,
  CONFIRMATION_TITLES,
  EQUIPMENT_MESSAGE,
  ERROR,
  NAVIGATIONS,
  OPERATOR_ERROR_MESSAGE,
  OPERATOR_MESSAGES,
  SESSION_DETAILS,
  SUBMIT_BUTTON_TEXT,
} from "../../config/default.config";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";
import { siteActions } from "../site/service/site.action";
import { stat } from "fs/promises";
import {
  setIsError,
  setIsLoading,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import { Tooltip } from "@mui/material";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { operatorActions } from "./services/operator.actions";
import { IOperator } from "./operator.interface";
import { setOperator } from "./store/operator.reducer";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import { encryptUtils } from "../../utils/encryption.utils";

export function OperatorView() {
  const [ready, setReady] = useState<boolean>(false);
  const navigate = useNavigate();
  const { open, close } = useConfirmationPopup();
  const dispatch = useAppDispatch();
  const operatorFromStore = useAppSelector(
    (state) => state.operatorReducer.operator
  );
  const { id } = useParams();
  const handleNavigateBack = () => {
    let emptyOperator: IOperator = {
      id: 0,
      name: "",
      mobile: "",
      location: "",
      address: "",
      images: [],
    };

    dispatch(setOperator(emptyOperator));
    navigate(NAVIGATIONS.OPERATOR_LIST);
  };

  const handleNavigateHistory = () => {
    navigate(
      NAVIGATIONS.OPERATOR_HISTORY.replace(
        ":id",
        encryptUtils.encryptURL(encryptUtils.decryptURL(id))
      )
    );
    let emptyOperator: IOperator = {
      id: 0,
      name: "",
      mobile: "",
      location: "",
      address: "",
      images: [],
    };

    dispatch(setOperator(emptyOperator));
  };
  const [operatorDetails, setOperatorDetails] = useState<IOperator>();

  const setSuccessForOperatorFetching = (state: boolean) => {
    dispatch(setIsLoading(false));
    if (state) {
      setReady(true);
      dispatch(setIsLoading(false));
    } else {
      dispatch(setIsLoading(false));
    }
  };

  const setSuccessForOperatorDelete = (state: boolean, errorCode: any) => {
    dispatch(setIsLoading(false));
    if (state && errorCode === null) {
      dispatch(setToastMsg(OPERATOR_MESSAGES.OPERATOR_DELETE_SUCCESS));
      dispatch(setToast(true));
      handleNavigateBack();
      handleClosePopup();
    } else {
      if (
        !state &&
        errorCode != null &&
        errorCode == OPERATOR_ERROR_MESSAGE.OPERATOR_DELETE_ERROR_CODE
      ) {
        dispatch(setToastMsg(OPERATOR_MESSAGES.OPERATOR_UNABLE_DELETE));
        dispatch(setIsError(true));
        dispatch(setToast(true));
      } else {
        dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
        dispatch(setIsError(true));
        dispatch(setToast(true));
      }

      handleClosePopup();
    }
  };

  const getOperatorDetails = async () => {
    dispatch(setIsLoading(true));
    dispatch(
      operatorActions.getOperatorById(
        Number(encryptUtils.decryptURL(id)),
        setSuccessForOperatorFetching
      )
    );
  };

  const handleEditClick = () => {
    navigate(
      NAVIGATIONS.OPERATOR_UPDATE.replace(
        ":id",
        encryptUtils.encryptURL(encryptUtils.decryptURL(id))
      )
    );
  };

  const handleDeleteConfirmation = (confirm: boolean) => {
    if (confirm) {
      dispatch(setIsLoading(true));
      operatorActions.deleteOperatorById(
        operatorFromStore?.id,
        setSuccessForOperatorDelete
      );
    } else {
      handleClosePopup();
    }
  };

  const handleClosePopup = () => {
    close();
  };

  const handleOpenPopup = (confirmation: any) => {
    open(
      CONFIRMATION_TITLES.CONFIRM_DELETE,
      CONFIRMATION_DIALOUGES.OPERATOR_DELETE_CONFIRMATION.replace(
        ":operatorName",
        operatorFromStore?.name
      ),
      SUBMIT_BUTTON_TEXT.YES,
      CANCEL_BUTTON_TEXT.NO,
      () => {
        confirmation(true);
      },
      () => {
        confirmation(false);
      }
    );
  };

  useEffect(() => {
    getOperatorDetails();
  }, []);

  useEffect(() => {
    setOperatorDetails(operatorFromStore);
  }, [operatorFromStore]);

  if (!ready) {
    return null;
  }

  return (
    <div className="inner-page-row">
      <div className="grid md:grid-cols-3 gap-28 pt-3">
        <div className="col-span-2 box-border">
          <div className="flex justify-between">
            <div className=" flex text-lg">
              <button
                type="button"
                className="mr-3"
                onClick={handleNavigateBack}
              >
                <img src={backBtnIcon} alt="" className="p-1" />
              </button>
              <div className=" font-bold p-1 text-gray-600 mt-1 dark:head-text-color">
                Operator
              </div>
              <div className="p-1 text-gray-500 mt-1 dark:head-text-color">
                Status
              </div>
            </div>
            <div
              className=" text-blue-600 p-2 text-sm mt-1 cursor-pointer"
              onClick={() => handleNavigateHistory()}
            >
              History
            </div>
          </div>

          <div className=" bg-white rounded-3xl shadow-md mt-4 dark:tile-bg-color">
            <div className="p-10 ">
              <div className=" flex justify-between ">
                <div className="flex">
                  <div className=" inline p-2 text-gray-500">
                    <b className="dark:head-text-color">Operator</b>
                  </div>

                  <Tooltip title="Edit" placement="top">
                    <div
                      className=" inline p-2 cursor-pointer"
                      onClick={() => {
                        handleEditClick();
                      }}
                    >
                      <img className="w-7" src={editIcon} alt="edit" />
                    </div>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <div
                      className=" inline p-2 cursor-pointer"
                      onClick={() => {
                        handleOpenPopup(handleDeleteConfirmation);
                      }}
                    >
                      <img className="w-7" src={deleteIcon} alt="delete" />
                    </div>
                  </Tooltip>
                </div>

                <div className=" text-gray-400 text-md dark:normal-text-color">
                  Location
                  <span className="text-gray-400 text-md">
                    <b className="dark:head-text-color">
                      {" "}
                      {operatorDetails?.location}
                    </b>
                  </span>
                </div>
              </div>
              <div className="sm:block md:flex py-3">
                <div>
                  <img
                    style={{ width: 100, height: 100 }}
                    src={
                      operatorDetails?.images[0]
                        ? `${operatorDetails?.images[0]?.resource}`
                        : noImageIcon
                    }
                    className="border border-gray h-32 rounded-2xl"
                  />
                </div>
                <div className="px-8">
                  <div className="font-bold dark:head-text-color">
                    {operatorDetails?.name}{" "}
                  </div>
                </div>
              </div>
              <hr className="flex-grow" />
              <div className="text-slate-500 font-bold mt-2 text-[18px] my-3 dark:head-text-color">
                More about <span> {operatorDetails?.name}</span>
              </div>
              <div>
                <h2 className="text-slate-500 font-normal  mt-2 text-[15px] dark:normal-text-color">
                  Address
                </h2>
                <h2 className="text-slate-400 font-bold text-[15px] dark:head-text-color">
                  {operatorDetails?.address}
                </h2>
              </div>
              <div className="flex pt-3">
                <div>
                  <h2 className="text-slate-500 font-normal  mt-2 text-[15px] dark:normal-text-color">
                    Mobile
                  </h2>
                  <h2 className="text-slate-400 font-bold text-[15px] dark:head-text-color">
                    {operatorDetails?.mobile}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
          Operation Hours
          <div className=" bg-white rounded-3xl shadow-md mt-8 h-24"></div>
        </div> */}
        </div>
      </div>
    </div>
  );
}
