import closeIcon from "../../../assets/images/close_icon.svg";

export interface IPreviewBox {
  showImage: boolean;
  operatorImage: string;
  onClose:()=>void
}

function ImagePreviewBox({ showImage, operatorImage,onClose }: IPreviewBox) {
  return showImage ? (
    <div className="fixed inset-0 flex items-center justify-center z-50  popup-overlay ">
      <div className="relative z-10 bg-white p-4 rounded-lg shadow-md dark:panel-bg-color">
        <div className="flex justify-end mb-4">
          <button className="" onClick={onClose}>
            <img className="w-6" src={closeIcon} alt="close" />
          </button>
        </div>
        <img src={operatorImage} className=" h-[60vh] w-[90vh]" />
      </div>
    </div>
  ) : null;
}
export default ImagePreviewBox;
