import { AUTH_CREDENTIALS } from "../config/default.config";
import CryptoJS from "crypto-js";

const KEY = CryptoJS.enc.Utf8.parse(AUTH_CREDENTIALS.KEY); // 256-bit key
const IV = CryptoJS.enc.Utf8.parse(AUTH_CREDENTIALS.IV); // 128-bit IV

export const AESUtils = {
  encrypt: (text: string) => {
    try {
      const encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(text),
        KEY,
        {
          iv: IV,
          mode: CryptoJS.mode.CBC, // Use CFB mode
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return encrypted.toString();
    } catch (error) {
      return null;
    }
  },

  decrypt: (text: string) => {
    try {
      const decrypted = CryptoJS.AES.decrypt(text, KEY, {
        iv: IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      return null;
    }
  },
};
