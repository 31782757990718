import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICustomer } from "../customer.interface";
import { stat } from "fs";

interface ICustomerDetails {
  customerDetails: ICustomer;
}
const initialState: ICustomerDetails = {
  customerDetails: {
    id: 0,
    name: "",
    mobileNumber: "",
    gstNumber: "",
    address: "",
    images:[]
  },
};

const customerSlice = createSlice({
    name : "customerReducer",
    initialState,
    reducers:{
        setCustomerDetails(state,actions:PayloadAction<any>){
            state.customerDetails = actions.payload;
            return state;
        }
    }
});

export const {setCustomerDetails}= customerSlice.actions;
export default customerSlice.reducer