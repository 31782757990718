import React, { useEffect, useState } from "react";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";
import { Field, reduxForm, change } from "redux-form";
import {
  IMEIValidation,
  alphaNumericValidation,
  deviceTypeValidation,
  frameRateValidation,
  isNumberValidation,
  isPortNumberValidation,
  minMaxValidation,
  nameValidation,
  protocolValidation,
  requiredValidation,
  resolutionValidation,
  validateIpAddressOrHostname,
} from "../../validations/validations";
import {
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_DIALOUGES,
  CONFIRMATION_TITLES,
  DEVICE_ERROR_CODES,
  DEVICE_MESSAGES,
  ERROR,
  NAVIGATIONS,
  SESSION_DETAILS,
  SUBMIT_BUTTON_TEXT,
} from "../../config/default.config";
import {
  DEVICE_OPERATIONS,
  IAddDeviceRequest,
  IDevice,
  IDeviceErrors,
  IDeviceType,
  IUpdateDeviceRequest,
} from "./device.interface";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import { deviceActions } from "./services/device.actions";
import { setDevice } from "./store/device.reducer";
import ImageUploadComponent, {
  IImageInfo,
} from "../components/image-upload/image-upload.component";
import {
  setIsError,
  setIsLoading,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { encryptUtils } from "../../utils/encryption.utils";

const renderInputName = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="name"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Name<span className="text-red-500">*</span>
      </label>
      <div className="">
        <input
          {...props?.input}
          type="text"
          name="name"
          id="name"
          className="block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
        {props?.error?.name}
      </div>
    </div>
  );
};

const renderInputDevicetype = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="deviceType"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Device Type<span className="text-red-500">*</span>
      </label>
      <div className="">
        <select
          {...props?.input}
          id="deviceType"
          name="deviceType"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select Device Type</option>
          {props.deviceTypes.map((type: IDeviceType, index: number) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderInputIMEI = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="imei"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        IMEI<span className="text-red-500">*</span>
      </label>
      <div className="">
        <input
          {...props?.input}
          type="text"
          name="imei"
          id="imei"
          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
        {props?.error?.imei}
      </div>
    </div>
  );
};

const renderInputProtocol = (props: any) => {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="sensorProtocol"
        className="block text-sm font-medium text-gray-900"
      >
        Protocol<span className="text-red-500">*</span>
      </label>
      <div className="">
        <select
          {...props?.input}
          id="sensorProtocol"
          name="sensorProtocol"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select Protocol</option>
          <option value={1}>TCP</option>
          <option value={2}>UDP</option>
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderInputIPAddress = (props: any) => {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="sensorIP"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        IP Address / Hostname<span className="text-red-500">*</span>
      </label>
      <div className="">
        <input
          {...props?.input}
          type="text"
          name="sensorIP"
          id="sensorIP"
          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderInputPort = (props: any) => {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="sensorPort"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Port<span className="text-red-500">*</span>
      </label>
      <div className="">
        <input
          {...props?.input}
          type="text"
          name="sensorPort"
          id="sensorPort"
          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderInputCameraProtocol = (props: any) => {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="cameraProtocol"
        className="block text-sm font-medium text-gray-900"
      >
        Protocol<span className="text-red-500">*</span>
      </label>
      <div className="">
        <select
          {...props?.input}
          id="cameraProtocol"
          name="cameraProtocol"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select Protocol</option>
          <option value={1}>TCP</option>
          <option value={2}>UDP</option>
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderInputCameraIPAddress = (props: any) => {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="cameraIP"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        IP Address / Hostname<span className="text-red-500">*</span>
      </label>
      <div className="">
        <input
          {...props?.input}
          type="text"
          name="cameraIP"
          id="cameraIP"
          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderInputCameraPort = (props: any) => {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="cameraPort"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Port<span className="text-red-500">*</span>
      </label>
      <div className="">
        <input
          {...props?.input}
          type="text"
          name="cameraPort"
          id="cameraPort"
          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderInputFrameRate = (props: any) => {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="cameraFrameRate"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Frame Rate<span className="text-red-500">*</span>
      </label>
      <div className="">
        <select
          {...props?.input}
          id="cameraFrameRate"
          name="cameraFrameRate"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select Frame Rate</option>
          <option value="25">25 FPS</option>
          <option value="30">30 FPS</option>
          <option value="35">35 FPS</option>
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderInputResolution = (props: any) => {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="cameraResolution"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Resolution<span className="text-red-500">*</span>
      </label>
      <div className="">
        <select
          {...props?.input}
          id="cameraResolution"
          name="cameraResolution"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select Resolution</option>
          <option>160 * 120</option>
          <option>320 * 240</option>
          <option>640 * 480</option>
          <option>1280 * 720</option>
          <option>1927 * 1080</option>
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

export let DeviceCreate: any = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [addCamera, setAddCamera] = useState<boolean>(false);
  const { id } = useParams();
  const { handleSubmit, valid, reset, dirty } = props;
  const { open, close } = useConfirmationPopup();
  const [deletedImageCount, setDeletedImageCount] = useState<number>(0);
  const [error, setError] = useState<IDeviceErrors>({ name: "", imei: "" });
  const [ready, setReady] = useState<boolean>(false);
  const [deviceDetails, setDeviceDetails] = useState<IDevice>();
  const deviceFromStore = useAppSelector(
    (state) => state.deviceReducer.deviceDetails
  );
  const [images, setImages] = useState<IImageInfo[]>([]);
  const deviceTypes = useAppSelector(
    (state) => state.deviceReducer.deviceTypes
  );

  const handleSave = async (values: any) => {
    dispatch(setIsLoading(true));
    if (deviceFromStore.name.length !== 0) {
      let IUpdateDeviceRequest: IUpdateDeviceRequest = {
        id: deviceFromStore.id,
        name: values.name,
        deviceType: deviceFromStore.deviceType.id || 1,
        imei: values.imei,
        sensorProtocol: values.sensorProtocol || 0,
        sensorIP: values.sensorIP,
        sensorPort: values.sensorPort,
        cameraProtocol: values.cameraProtocol || 0,
        cameraIP: values.cameraIP,
        cameraPort: values.cameraPort,
        cameraFrameRate: values.cameraFrameRate || 25,
        cameraResolution: values.cameraResolution || 160 * 120,
        isCameraAdded: addCamera,
        storageType: 0,
        isDeleted: false,
        images: images,
      };
      dispatch(
        await deviceActions.updateDevice(IUpdateDeviceRequest, setSuccess)
      );
    } else {
      let IAddDeviceRequest: IAddDeviceRequest = {
        id: 0,
        name: values.name,
        deviceType: values.deviceType || 1,
        imei: values.imei,
        sensorProtocol: values.sensorProtocol || 0,
        sensorIP: values.sensorIP,
        sensorPort: values.sensorPort,
        cameraProtocol: values.cameraProtocol || 0,
        cameraIP: values.cameraIP,
        cameraPort: values.cameraPort,
        cameraFrameRate: values.cameraFrameRate || 25,
        cameraResolution: values.cameraResolution || 160 * 120,
        isCameraAdded: addCamera,
        isDeleted: false,
        images: images,
      };

      dispatch(deviceActions.addDevice(IAddDeviceRequest, setSuccess));
    }
  };

  const handleValidateCamera = (isCamera: boolean) => {
    let formName: string = "create-device-form";
    if (!isCamera) {
      dispatch(change(formName, "cameraProtocol", "0"));
      dispatch(change(formName, "cameraIP", "111.111.111.111"));
      dispatch(change(formName, "cameraPort", "1234"));
      dispatch(change(formName, "cameraFrameRate", "30"));
      dispatch(change(formName, "cameraResolution", "160 * 120"));
    } else if (isCamera) {
      dispatch(change(formName, "cameraProtocol", ""));
      dispatch(change(formName, "cameraIP", ""));
      dispatch(change(formName, "cameraPort", ""));
      dispatch(change(formName, "cameraFrameRate", ""));
      dispatch(change(formName, "cameraResolution", ""));
    }
  };

  const setSuccess = (
    state: boolean,
    type: string,
    errorCode: string | null
  ) => {
    dispatch(setIsLoading(false));
    if (state) {
      if (type === DEVICE_OPERATIONS.CREATE) {
        dispatch(setIsError(false));
        dispatch(setToastMsg(DEVICE_MESSAGES.DEVICE_ADD_SUCCESS));
        dispatch(setToast(true));
        reset();
        navigate(NAVIGATIONS.DEVICE_LIST);
      } else if (type === DEVICE_OPERATIONS.UPDATE) {
        dispatch(setIsError(false));
        dispatch(setToastMsg(DEVICE_MESSAGES.DEVICE_UPDATE_SUCCESS));
        dispatch(setToast(true));
        navigate(
          NAVIGATIONS.DEVICE_VIEW.replace(
            ":id",
            `${encryptUtils.encryptURL(deviceFromStore.id)}`
          )
        );
      }
    } else {
      if (errorCode === DEVICE_ERROR_CODES.DEVICE_NAME_ALREADY_EXIST) {
        setError({
          ...error,
          name: DEVICE_MESSAGES.DEVICE_NAME_ALREADY_EXIST,
          imei: "",
        });
      } else if (errorCode === DEVICE_ERROR_CODES.DEVICE_IMEI_ALREADY_EXIST) {
        setError({
          ...error,
          name: "",
          imei: DEVICE_MESSAGES.DEVICE_IMEI_ALREADY_EXIST,
        });
      }
      if (type === DEVICE_OPERATIONS.CREATE) {
        if (errorCode === DEVICE_ERROR_CODES.DEVICE_NAME_ALREADY_EXIST) {
          dispatch(setIsError(true));
          dispatch(setToastMsg(DEVICE_MESSAGES.DEVICE_NAME_ALREADY_EXIST));
          dispatch(setToast(true));
        } else if (
          errorCode === DEVICE_ERROR_CODES.IMEI_ALREADY_EXISTS_ERROR_CODE
        ) {
          dispatch(setIsError(true));
          dispatch(setToastMsg(DEVICE_MESSAGES.DEVICE_IMEI_ALREADY_EXIST));
          dispatch(setToast(true));
        } else {
          dispatch(setIsError(true));
          dispatch(setToastMsg(DEVICE_MESSAGES.DEVICE_ADD_FAILED));
          dispatch(setToast(true));
        }
      } else if (type === DEVICE_OPERATIONS.UPDATE) {
        if (errorCode === DEVICE_ERROR_CODES.DEVICE_NAME_ALREADY_EXIST) {
          dispatch(setIsError(true));
          dispatch(setToastMsg(DEVICE_MESSAGES.DEVICE_NAME_ALREADY_EXIST));
          dispatch(setToast(true));
        } else if (
          errorCode === DEVICE_ERROR_CODES.IMEI_ALREADY_EXISTS_ERROR_CODE
        ) {
          dispatch(setIsError(true));
          dispatch(setToastMsg(DEVICE_MESSAGES.DEVICE_IMEI_ALREADY_EXIST));
          dispatch(setToast(true));
        } else {
          dispatch(setIsError(true));
          dispatch(setToastMsg(DEVICE_MESSAGES.DEVICE_UPDATE_FAILED));
          dispatch(setToast(true));
        }
      }
    }
  };

  const handleOpenPopup = (confirmation: any) => {
    if (dirty || images.length > 0) {
      open(
        CONFIRMATION_TITLES.CONFIRM_EXIT,
        CONFIRMATION_DIALOUGES.UNSAVED_CHANGES,
        SUBMIT_BUTTON_TEXT.YES,
        CANCEL_BUTTON_TEXT.NO,
        () => {
          confirmation(true);
        },
        () => {
          confirmation(false);
        }
      );
    } else {
      confirmation(true);
    }
  };

  const handleClosePopup = () => {
    close();
  };

  const updateDeletedImageCount = (images: IImageInfo[]) => {
    const count = images.filter((image) => !image.deleted).length;
    setDeletedImageCount(count);
  };

  const handleNavigateBack = (confirm: boolean) => {
    handleClosePopup();
    if (confirm) {
      if (deviceFromStore.name.length > 0) {
        navigate(
          NAVIGATIONS.DEVICE_VIEW.replace(
            ":id",
            `${encryptUtils.encryptURL(deviceFromStore.id)}`
          )
        );
      } else {
        navigate(NAVIGATIONS.DEVICE_LIST);
      }
    }
  };

  const handleAddCameraClick = (isCamera: boolean) => {
    setAddCamera(!addCamera);
    handleValidateCamera(isCamera);
  };

  const setSuccessForDeviceFetching = (state: boolean) => {
    dispatch(setIsLoading(false));
    if (state) {
      setReady(true);
      dispatch(setIsLoading(false));
    } else {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setIsLoading(true));
      dispatch(deviceActions.getDeviceTypes());
      let deviceIdForUpdate = encryptUtils.decryptURL(id);
      if (deviceIdForUpdate !== null) {
        dispatch(
          deviceActions.getDeviceById(
            deviceIdForUpdate,
            setSuccessForDeviceFetching
          )
        );
      } else if (deviceIdForUpdate === null) {
        dispatch(setIsLoading(false));
        setReady(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setImages(deviceFromStore.images);
    if (deviceFromStore.cameraIP !== "") {
      setAddCamera(true);
    }
    if (deviceFromStore.id !== 0) {
      props.initialize({
        name: deviceFromStore.name,
        imei: deviceFromStore.imei,
        deviceType: deviceFromStore.deviceType.id,
        sensorProtocol: deviceFromStore.sensorProtocol,
        sensorIP: deviceFromStore.sensorIP,
        sensorPort: deviceFromStore.sensorPort,
        cameraIP: deviceFromStore.cameraIP,
        cameraPort: deviceFromStore.cameraPort,
        cameraProtocol: deviceFromStore.cameraProtocol,
        cameraFrameRate: deviceFromStore.cameraFrameRate,
        cameraResolution: deviceFromStore.cameraResolution,
        images: deviceFromStore.images,
      });
    }
  }, [deviceFromStore]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="inner-page-row">
          <div className="inline-block w-[100%] top-bar-sticky justify-between">
            <div className="float-left flex items-center">
              <button
                type="button"
                className="mr-3"
                onClick={() => {
                  handleOpenPopup(handleNavigateBack);
                }}
              >
                <img src={backBtnIcon} alt="" className="p-1" />
              </button>
              <h2 className="text-xl">
                <b>
                  {deviceFromStore.name.length > 0
                    ? "Update Device"
                    : "Add Device"}
                </b>
              </h2>
            </div>
            <div className="float-right">
              <button
                className={`text-[14px] text-white rounded-md p-2 px-8 py-2 ${
                  !valid || images.length === 0 || deletedImageCount === 0
                    ? "btn bg-slate-300"
                    : "btn btn-primary"
                } `}
                disabled={
                  !valid || images.length === 0 || deletedImageCount === 0
                }
              >
                <div>
                  {deviceFromStore?.name?.length > 0 ? "Update" : "Save"}
                </div>
              </button>
            </div>
          </div>
          <div className=" bg-white mt-6 rounded-md flex p-10 flex-wrap">
            <div className="w-1/2 pr-4">
              <h3 className="text-blue-500 font-bold inline-block">
                <span className="underline mb-3 px-2 py-1">Basic</span>
              </h3>

              <div className="pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  <Field
                    type="text"
                    name="name"
                    component={renderInputName}
                    validate={[requiredValidation,nameValidation]}
                    error={error}
                    onChange={() => {
                      setError({ ...error, name: "" });
                    }}
                  />
                  <Field
                    name="deviceType"
                    component={renderInputDevicetype}
                    deviceTypes={deviceTypes}
                    validate={deviceTypeValidation}
                  />
                  <Field
                    type="text"
                    name="imei"
                    component={renderInputIMEI}
                    validate={IMEIValidation}
                    error={error}
                    onChange={() => {
                      setError({ ...error, imei: "" });
                    }}
                  />
                </div>
              </div>
              <div className="w-[100%] bg-gray-100 rounded-md p-8 ">
                <div className="grid grid-cols-12 gap-x-6 gap-y-2 sm:grid-cols-12">
                  <div className="sm:col-span-12">
                    <h3 className="block text-lg font-bold leading-6 text-gray-900 mb-2">
                      Sensor
                    </h3>
                  </div>
                  <Field
                    name="sensorProtocol"
                    component={renderInputProtocol}
                    validate={protocolValidation}
                  />
                  <Field
                    type="text"
                    name="sensorIP"
                    component={renderInputIPAddress}
                    validate={validateIpAddressOrHostname}
                  />
                  <Field
                    type="text"
                    name="sensorPort"
                    component={renderInputPort}
                    validate={[isNumberValidation, isPortNumberValidation]}
                  />
                </div>
              </div>
              <div className="float-right p-2">
                <button
                  type="button"
                  onClick={() => {
                    handleAddCameraClick(!addCamera);
                  }}
                >
                  <p className="text-[blue] bg-white">
                    {addCamera ? "- Remove Camera " : "+ Add Camera "}
                  </p>
                </button>
              </div>
              {addCamera && (
                <>
                  <div className="w-[100%] pt-10 bg-white">
                    <div className="grid grid-cols-12 gap-x-4 sm:grid-cols-12">
                      <Field
                        name="cameraProtocol"
                        component={renderInputCameraProtocol}
                        validate={protocolValidation}
                      />
                      <Field
                        type="text"
                        name="cameraIP"
                        component={renderInputCameraIPAddress}
                        validate={addCamera && validateIpAddressOrHostname}
                      />
                      <Field
                        type="text"
                        name="cameraPort"
                        component={renderInputCameraPort}
                        validate={
                          addCamera && [
                            isNumberValidation,
                            isPortNumberValidation,
                          ]
                        }
                      />
                      <Field
                        name="cameraFrameRate"
                        component={renderInputFrameRate}
                        validate={frameRateValidation}
                      />
                      <Field
                        name="cameraResolution"
                        component={renderInputResolution}
                        validate={resolutionValidation}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="w-1/2 pl-4 p-20">
              <label
                htmlFor="grossPower"
                className="block text-sm font-medium leading-6 text-gray-900 ml-9"
              >
                Images <span className="text-red-500">*</span>
              </label>
              <ImageUploadComponent
                images={images}
                onImageUpload={(_images) => {
                  setImages(_images);
                  updateDeletedImageCount(_images);
                }}
              />
              {valid && deletedImageCount === 0 ? (
                <span className=" ml-10 text-red-400 ">Image is required</span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

DeviceCreate = reduxForm({
  form: "create-device-form",
})(DeviceCreate);

export default DeviceCreate;
