import { useNavigate } from "react-router-dom";
import SortingIcon from "../../assets/images/sorting_icon.svg";
import SortingIconDesc from "../../assets/images/sorting_icon_down.svg";
import { useEffect, useState } from "react";
import PaginatedItems from "../components/pagination.component";
import { NAVIGATIONS } from "../../config/default.config";
import noImageIcon from "../../assets/images/No-image.jpg";
import { Tooltip } from "@mui/material";
import { IPaginationPayload } from "../site/site.interface";
import { IOperatorItem, IOperatorListRequest } from "./operator.interface";
import { operatorActions } from "./services/operator.actions";
import { encryptUtils } from "../../utils/encryption.utils";
import NoDataFound from "../components/noDataFound";
import { useAppDispatch } from "../../config/store.hooks";
import { setIsLoading } from "../../common/common.reducer";

export function OperatorList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleAddOperatorItemClick = (id: number) => {
    navigate(
      NAVIGATIONS.OPERATOR_VIEW.replace(":id", `${encryptUtils.encryptURL(id)}`)
    );
  };
  const [formattedOperatorList, setFormattedOperatorList] = useState<
    IOperatorItem[]
  >([]);
  const [totalRecords, setTotalRecords] = useState();
  const [paginationPayload, setPaginationPayload] =
    useState<IPaginationPayload>({
      page: 1,
      pageSize: 16,
      orderByDesc: true,
      orderBy: "Name",
      searchKey: "",
    });

  const onPaginationChange = (
    curretdata: any,
    itemOffset: any,
    endOffset: any
  ) => {
    setFormattedOperatorList(curretdata);
  };

  const handleAddOperatorClick = () => {
    navigate(NAVIGATIONS.OPERATOR_ADD);
  };

  const setSuccess = (state: boolean) => {
    dispatch(setIsLoading(false));
  };

  const getOperatorList = async (operatorListRequest: IOperatorListRequest) => {
    dispatch(setIsLoading(true));
    const response = await operatorActions.getOperatorList(
      operatorListRequest,
      setSuccess
    );
    setTotalRecords(response?.totalItems);
    let tempOperatorItemList: IOperatorItem[] = [];
    response?.data.map((operator: any) => {
      let operatorItem: IOperatorItem = {
        id: operator?.id,
        name: operator?.name,
        mobile: operator?.mobile,
        location: operator?.location,
        image: operator?.images[0]?.url,
      };
      tempOperatorItemList.push(operatorItem);
    });
    setFormattedOperatorList(tempOperatorItemList);
  };

  const reRenderOperators = (page: number) => {
    setPaginationPayload({
      ...paginationPayload,
      page: page,
    });

    let operatorListRequest: IOperatorListRequest = {
      Page: page,
      PageSize: paginationPayload.pageSize,
      OrderByDesc: paginationPayload.orderByDesc,
      OrderBy: paginationPayload.orderBy,
      SearchKey: paginationPayload.searchKey,
    };
    getOperatorList(operatorListRequest);
  };

  useEffect(() => {
    const operatorListRequest: IOperatorListRequest = {
      Page: paginationPayload.page,
      PageSize: paginationPayload.pageSize,
      OrderByDesc: paginationPayload.orderByDesc,
      OrderBy: paginationPayload.orderBy,
      SearchKey: paginationPayload.searchKey,
    };
    getOperatorList(operatorListRequest);
  }, [
    paginationPayload.page,
    paginationPayload.pageSize,
    paginationPayload.orderByDesc,
    paginationPayload.orderBy,
    paginationPayload.searchKey,
  ]);

  return (
    <div className="inner-page-row">
      <div className="inline-block w-[100%] mt-5">
        <div className="float-left">
          <h2 className="text-xl">
            <b className="dark:head-text-color">Operators</b>
          </h2>
        </div>
      </div>
      <div className="top-bar-sticky dark:search-bar-bg-color">
        <div className="flex flex-wrap items-center">
          <div className="flex-1 w-100">
            <input
              type="text"
              name="searchKey"
              id="searchKey"
              className="border rounded-lg border-gray-300 p-2 placeholder-gray-300  text-slate-500  w-[100%] cursor-pointer  dark:panel-bg-color dark:normal-text-color"
              placeholder="Search Operators"
              onChange={(e) => {
                setPaginationPayload({
                  ...paginationPayload,
                  searchKey: e?.target?.value,
                });
              }}
            />
          </div>
          <div className="flex-2 w-100">
            <Tooltip title="filter" placement="top">
              <select
                id="sortKey"
                name="sortKey"
                className=" dark:panel-bg-color dark:normal-text-color border rounded-lg border-gray-300 p-2 placeholder-gray-200  text-slate-500 font-thin  cursor-pointer h-10 w-40 mx-6 bg-white pl-4 pr-4 text-sm  focus:outline-none focus:border-red-500 "
                onChange={(e) => {
                  setPaginationPayload({
                    ...paginationPayload,
                    orderBy: e?.target?.value,
                  });
                }}
              >
                <option value="Name">Name</option>
                <option value="Location">Location</option>
              </select>
            </Tooltip>
          </div>
          <div className="flex-none ml-2 cursor-pointer">
            <Tooltip
              title={
                paginationPayload.orderByDesc ? "sort by asc" : "sort by desc"
              }
              placement="top"
            >
              <img
                width={26}
                height={26}
                style={{ width: 26, height: 26 }}
                src={
                  paginationPayload.orderByDesc ? SortingIcon : SortingIconDesc
                }
                alt="logo"
                onClick={() => {
                  setPaginationPayload({
                    ...paginationPayload,
                    orderByDesc: !paginationPayload.orderByDesc,
                  });
                }}
              ></img>
            </Tooltip>
          </div>
          <div className="flex-none ml-2 cursor-pointer">
            <button
              className=" py-2 btn btn-primary"
              onClick={() => handleAddOperatorClick()}
            >
              <div>Add</div>
            </button>
          </div>
        </div>
      </div>
      {formattedOperatorList && formattedOperatorList.length > 0 ? (
        <div>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 content-start mb-5 inner-page-list">
            {formattedOperatorList?.map((item: IOperatorItem, index) => {
              return (
                <div
                  key={item?.id}
                  className="transform cursor-pointer hover:scale-105 transition duration-75 active:bg-blue-100 rounded-xl place-content-center bg-white xs:w-full shadow-1xl mt-5 mr-5 border-dashed border-1 border-gray-500 dark:tile-bg-color"
                  onClick={() => {
                    handleAddOperatorItemClick(item?.id);
                  }}
                >
                  <div className=" flex justify-center flex-col p-[10px] sm:p-[20px] md:p-[30px]">
                    <div className="flex flex-wrap content-start  ">
                      <img
                        style={{ width: 60, height: 60, objectFit: "cover" }}
                        src={item.image ? item.image : noImageIcon}
                        alt="logo"
                        className="border border-gray h-32 rounded mr-5 dark:border-color-type"
                      ></img>
                      <div className="cursor-pointer box-row">
                        <p>
                          <b className="dark:head-text-color">{item?.name}</b>
                        </p>
                        <p className="text-xs cursor-pointer">{item?.mobile}</p>
                        <p className="text-xs cursor-pointer">
                          {item?.location}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <PaginatedItems
            itemsPerPage={paginationPayload.pageSize}
            totalRecords={totalRecords}
            onPagination={onPaginationChange}
            reRenderDevices={reRenderOperators}
          />
        </div>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
}
