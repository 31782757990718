import { IImageInfo } from "../components/image-upload/image-upload.component";

export interface IAddEquipmentRequest {
  id: number;
  name: string;
  vehicleNumber: string;
  description: string;
  operatingWeight: number;
  grossPower: number;
  bucketCapacity: number;
  maxDiggingDepth: number;
  images: any;
  deviceId: number;
  status: number;
}
export interface IUpdateEquipment {
  id: number;
  name: string;
  vehicleNumber: string;
  description: string;
  operatingWeight: number;
  grossPower: number;
  bucketCapacity: number;
  maxDiggingDepth: number;
  images: any;
  deviceId: number;
  status: number;
}
export interface IPaginationPayload {
  page: number;
  pageSize: number;
  orderByDesc: boolean;
  orderBy: string;
  searchKey: string;
}
export interface IEquipmentListRequest {
  Page: number;
  PageSize: number;
  OrderByDesc: boolean;
  OrderBy: string;
  SearchKey: string;
}

export interface IGetAllInstanceListRequest {
  getAll: boolean;
  modelName: string|undefined;
  pageNo: number;
  pageSize: number;
  searchKey:string;
  sortKey:string;
  sortOrder: string;
}
export type IEquipmentItem = {
  id: number;
  name: string;
  vehicleNumber: string;
  image: string;
  status: number;
};

export interface IEquipment {
  id: number;
  name: string;
  vehicleNumber: string;
  description: string;
  operatingWeight: number;
  grossPower: number;
  bucketCapacity: number;
  maxDiggingDepth: number;
  images: IImageInfo[];
  deviceId: number;
  status: number;
}

export interface IFileInfo {
  id: number;
  deviceId: number;
  storageType: number;
  reference: string;
  url: string;
  extention: string;
}

export const EQUIPMENT_OPERATIONS = {
  UPDATE: "UPDATE",
  CREATE: "CREATE",
};
