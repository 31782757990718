import moment from "moment";
import { VALIDATION_MESSAGES } from "../config/default.config";

// name required validation
export const requiredValidation = (value: any) =>
  value || typeof value === "number"
    ? undefined
    : VALIDATION_MESSAGES.NAME_REQUIRED;

// alphanumeric validation
export const alphaNumericValidation = (value: any) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? VALIDATION_MESSAGES.ALPHANUMERIC_VALIDATION
    : undefined;

// min max validation
export const minMaxValidation = (value: any) => {
  if (value) {
    if (/[^a-zA-Z0-9 ]/i.test(value)) {
      return VALIDATION_MESSAGES.ALPHANUMERIC_VALIDATION;
    } else if (value.length < 3 || value.length > 20) {
      return "Value must be between 3 and 20 characters long";
    }
  }
  return undefined;
};

// min max validation for address
export const minMaxValidationForAddress = (value: any) => {
  if (value) {
    if (value.length < 3 || value.length > 100) {
      return "Value must be between 3 and 100 characters long";
    }
  } else {
    return VALIDATION_MESSAGES.ADDRESS_REQUIRED;
  }
  // return undefined;
};

//imei validation
export const IMEIValidation = (value: any) => {
  if (!value) {
    return VALIDATION_MESSAGES.IMEI_REQUIRED;
  } else if (!/^[1-9]\d{14}$/.test(value)) {
    return VALIDATION_MESSAGES.INVALID_IMEI;
  }
  return undefined;
};

//ipaddress or hostname validation
export const validateIpAddressOrHostname = (value: any) => {
  if (!value) {
    return VALIDATION_MESSAGES.INVALID_IPADDRESS_OR_HOSTNAME;
  }
  if (/^\d/.test(value)) {
    const ipAddressPattern =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (ipAddressPattern.test(value)) {
      return undefined;
    } else {
      return VALIDATION_MESSAGES.INVALID_IPADDRESS_OR_HOSTNAME;
    }
  } else {
    return undefined;
  }
};

//number validation
export const isNumberValidation = (value: any) => {
  if (!value) {
    return VALIDATION_MESSAGES.PORT_REQUIRED;
  } else if (!/^\d+$/.test(value)) {
    return VALIDATION_MESSAGES.ONLY_NUMBER_ALLOWED;
  }
  return undefined;
};

//port number validation
export const isPortNumberValidation = (value: any) => {
  if (value.length > 6) {
    return VALIDATION_MESSAGES.PORT_NUMBER_MAX_LIMIT;
  } else if (value.length < 3) {
    return VALIDATION_MESSAGES.PORT_NUMBER_MIN_LIMIT;
  }
  return undefined;
};

//location validation

export const locationValidation = (value: any) => {
  if (!value) {
    return VALIDATION_MESSAGES.LOCATION_REQUIRED;
  } else if (value.length < 3) {
    return VALIDATION_MESSAGES.NAME_MIN_LENGTH;
  }
};

export const addressValidation = (value: any) => {
  if (!value) {
    return VALIDATION_MESSAGES.ADDRESS_REQUIRED;
  } else if(!/^[a-zA-Z0-9.,\-_*&\s]+$/.test(value)) {
    return VALIDATION_MESSAGES.INVALID_INPUT.replace(":expression","alphanumeric,-_*&.");
  }
};

export const areaValidation = (value: any) => {
  if (!value) {
    return VALIDATION_MESSAGES.AREA_REQUIRED;
  } else if (value && /[^a-zA-Z0-9 ]/i.test(value)) {
    return VALIDATION_MESSAGES.ALPHANUMERIC_VALIDATION;
  }
};

export const latitudeValidation = (value: any) => {
  const latRegex = /^([-+]?([1-8]?\d(\.\d+)?|90(\.0+)?))$/;
  if (!value) {
    return VALIDATION_MESSAGES.LATITUDE_REQUIRED;
  } else if (!latRegex.test(value)) {
    return VALIDATION_MESSAGES.VALID_LATITUDE;
  }
};

export const longitudeValidation = (value: any) => {
  const longRegex = /^(-?((1[0-7]|[0-9])?\d(\.\d+)?|180(\.0+)?))$/;

  if (!value) {
    return VALIDATION_MESSAGES.LONGITUDE_REQUIRED;
  } else if (!longRegex.test(value)) {
    return VALIDATION_MESSAGES.VALID_LONGITUDE;
  }
};

export const statusValidation = (value: string) => {
  if (value === undefined) {
    return VALIDATION_MESSAGES.STATUS_REQUIRED;
  } else if (value?.length === 0) {
    return VALIDATION_MESSAGES.STATUS_REQUIRED;
  }
};

export const deviceValidation = (value: string) => {
  if (value === undefined) {
    return VALIDATION_MESSAGES.DEVICE_REQUIRED;
  } else if (value?.length === 0) {
    return VALIDATION_MESSAGES.DEVICE_REQUIRED;
  } else if (value === "") {
    return VALIDATION_MESSAGES.DEVICE_REQUIRED;
  }
};
export const deviceTypeValidation = (value: string) => {
  if (value === undefined) {
    return VALIDATION_MESSAGES.DEVICE_TYPE_REQUIRED;
  } else if (value?.length === 0) {
    return VALIDATION_MESSAGES.DEVICE_TYPE_REQUIRED;
  } else if (value === "") {
    return VALIDATION_MESSAGES.DEVICE_TYPE_REQUIRED;
  }
};

export const protocolValidation = (value: string) => {
  if (value === undefined) {
    return VALIDATION_MESSAGES.PORT_REQUIRED;
  } else if (value?.length === 0) {
    return VALIDATION_MESSAGES.PORT_REQUIRED;
  } else if (value === "") {
    return VALIDATION_MESSAGES.PORT_REQUIRED;
  }
};

export const frameRateValidation = (value: string) => {
  if (value === undefined) {
    return VALIDATION_MESSAGES.FRAME_RATE_REQUIRED;
  } else if (value?.length === 0) {
    return VALIDATION_MESSAGES.FRAME_RATE_REQUIRED;
  } else if (value === "") {
    return VALIDATION_MESSAGES.FRAME_RATE_REQUIRED;
  }
};

export const resolutionValidation = (value: string) => {
  if (value === undefined) {
    return VALIDATION_MESSAGES.RESOLUTION_REQUIRED;
  } else if (value?.length === 0) {
    return VALIDATION_MESSAGES.RESOLUTION_REQUIRED;
  } else if (value === "") {
    return VALIDATION_MESSAGES.RESOLUTION_REQUIRED;
  }
};

export const descriptionValidation = (value: any) => {
  if (value) {
    if (value.length < 3 || value.length > 250) {
      return "Value must be between 3 and 20 characters long";
    }
  } else if (!value) {
    return VALIDATION_MESSAGES.DESCRIPTION_REQUIRED;
  }
  return undefined;
};

export const vehicleNumberValidation = (value: any) => {
  if (!value) {
    return VALIDATION_MESSAGES.VEHICLE_NUMBER_REQUIRED;
  }
  return undefined;
};

export const operatingWeightValidation = (value: any) => {
  if (!value) {
    return VALIDATION_MESSAGES.OPERATING_WEIGHT_REQUIRED;
  }
  else if(!/^[\w\d\-^./ ]+$/.test(value))
  {
    return VALIDATION_MESSAGES.INVALID_INPUT.replace(":expression","alphanumeric,-_^/")
  }
  return undefined;
};

export const grossPowerValidation = (value: any) => {
  if (!value) {
    return VALIDATION_MESSAGES.GROSS_POWER_REQUIRED;
  }
  else if(!/^[\w\d\-^./ ]+$/.test(value))
  {
    return VALIDATION_MESSAGES.INVALID_INPUT.replace(":expression","alphanumeric,-_^/")
  }
  return undefined;
};

export const bucketCapacityValidation = (value: any) => {
  if (!value) {
    return VALIDATION_MESSAGES.BUCKET_CAPACITY_REQUIRED;
  }
  else if(!/^[\w\d\-^./ ]+$/.test(value))
  {
    return VALIDATION_MESSAGES.INVALID_INPUT.replace(":expression","alphanumeric,-_^/")
  }
  return undefined;
};

export const maxDiggingDepthValidation = (value: any) => {
  if (!value) {
    return VALIDATION_MESSAGES.MAX_DIGGING_DEPTH_REQUIRED;
  }
  else if(!/^[\w\d\-^./ ]+$/.test(value))
  {
    return VALIDATION_MESSAGES.INVALID_INPUT.replace(":expression","alphanumeric,-_^/")
  }
  return undefined;
};

export const GSTNumberValidation = (value: any) => {
  if (value) {
    if (/[^a-zA-Z0-9 ]/i.test(value)) {
      return VALIDATION_MESSAGES.INVALID_GST_NUMBER;
    } else if (value.length < 15 || value.length > 15) {
      return VALIDATION_MESSAGES.INVALID_GST_NUMBER;
    }
  } else {
    return VALIDATION_MESSAGES.GST_NUMBER_REQUIRED;
  }
  return undefined;
};

export const customerListValidation=(value:any)=>{
  if(value===undefined)
  {
    return VALIDATION_MESSAGES.CUSTOMER_LIST
  }
}
export const siteListValidation=(value:any)=>{
  if(value===undefined)
  {
    return VALIDATION_MESSAGES.SITE_LIST
  }
}
export const dateValidation=(value:any)=>{

  if(value===undefined)
  {
    return VALIDATION_MESSAGES.DATE_REQUIRED
  }

}

export const startDateValidation=(value:string,allValues:any)=>{
  if(value>allValues.endDate&& allValues.endDate!=="0"){
    let endDate= moment(allValues.endDate) ;
    let formattedEndDate=endDate.format("DD/MM/YYYY");
    return VALIDATION_MESSAGES.STARTDATE_LIMIT.replace(":endDate",formattedEndDate)
  }
}
export const endDateValidation=(value:string,allValues:any)=>{  
  if(allValues.endDate<allValues.startDate){
    let startDate= moment(allValues.startDate) ;
    let formattedStartDate=startDate.format("DD/MM/YYYY");
    return VALIDATION_MESSAGES.ENDATE_LIMIT.replace(":startDate",formattedStartDate)
  }
}
export const durationValidation=(value:any,props:any)=>{  
 
  if(value===undefined)
  {
    return VALIDATION_MESSAGES.DURATION_REQUIRED
  }
  else if(!/^\d+$/.test(value))
  {
    return VALIDATION_MESSAGES.ONLY_NUMBER_ALLOWED 
  }  
}

export const nameValidation=(value:any)=>{
  if(!/^[a-zA-Z0-9\s\-_]+$/.test(value))
  {
    return  VALIDATION_MESSAGES.INVALID_INPUT.replace(":expression","alphanumeric,-_")
  }
  else if(value.length<3){
return VALIDATION_MESSAGES.NAME_MIN_LENGTH
  }
}

// const map {
//   "DEVICE" : {
//     "IMEIREQ": "TRUE"
//   },
// "EQUIP" :{
//   IMEI:
// }

// }
