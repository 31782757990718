import { Table } from "@mui/material";
import React, { useEffect, useState } from "react";
import PaginatedItems from "../pagination.component";
import { useLocation, useParams } from "react-router-dom";
import {
  ICustomerHistoryRequest,
  IHistoryItem,
} from "../../customers/customer.interface";
import { customerAction } from "../../customers/services/customer.action";
import { customerServices } from "../../customers/services/customer.services";
import {
  IPaginationPayload,
  ISiteItem,
  ISiteListRequest,
} from "../../site/site.interface";
import { encryptUtils } from "../../../utils/encryption.utils";
import NoDataFound from "../noDataFound";
import { HISTORY_TYPE } from "../../../config/default.config";
import { siteActions } from "../../site/service/site.action";
import { IHistoryRequest } from "./history.component.interface";
import { equipmentAction } from "../../equipment/services/equipment.actions";

export type Paginationn = {
  id: number;
  name: string;
  serialNumber: string;
};

function HistoryTable({ historyType }: { historyType: string }) {
  const { id } = useParams();
  const [paginationPayload, setPaginationPayload] =
    useState<IPaginationPayload>({
      page: 1,
      pageSize: 10,
      orderByDesc: true,
      orderBy: "startDate",
      searchKey: "",
    });
  const [totalRecords, setTotalRecords] = useState();
  const [datas, setDatas] = useState<IHistoryItem[]>([]);

  const getCustomerHistory = async (historyRequest: IHistoryRequest) => {
    const response = await customerAction.customerHistory(historyRequest);
    setTotalRecords(response?.totalItems);
    let tempCustomerHistoryItem: IHistoryItem[] = [];

    response?.data?.map((customerHistory: any, index: any) => {
      let IHistoryItem: IHistoryItem = {
        col1: index + 1,
        col2: customerHistory?.name,
        col3: customerHistory?.startDate,
        col4: customerHistory?.endDate,
        col5: "",
      };
      tempCustomerHistoryItem.push(IHistoryItem);
    });
    setDatas(tempCustomerHistoryItem);
  };

  const getCustomerHistoryList = (pagenumber: number) => {
    let historyRequest: IHistoryRequest = {
      page: pagenumber,
      pageSize: paginationPayload.pageSize,
      orderByDesc: paginationPayload.orderByDesc,
      orderBy: paginationPayload.orderBy,
      searchKey: paginationPayload.searchKey,
      id: encryptUtils.decryptURL(id),
    };
    getCustomerHistory(historyRequest);
  };

  const getSitesHistory = async (historyRequest: IHistoryRequest) => {
    const response = await siteActions.getSitesHistory(historyRequest);
    setTotalRecords(response?.totalItems);
    let tempSiteItemList: IHistoryItem[] = [];
    response?.data?.map((site: any, index: any) => {
      let IHistoryItem: IHistoryItem = {
        col1 : index+1,
        col2 : site.location,
        col3 : site.startDate,
        col4 : site.endDate,
        col5 : ""
      };
      tempSiteItemList.push(IHistoryItem);
    });
    setDatas(tempSiteItemList);
  };

  const getEquipmentHistory = async (historyRequest: IHistoryRequest) => {
    const response = await equipmentAction.getEquipmentHistory(historyRequest);
    setTotalRecords(response?.totalItems);
    let tempSiteItemList: IHistoryItem[] = [];
    response?.data?.map((equipment: any, index: any) => {
      let historyItem: IHistoryItem = {
        col1 : index+1,
        col2 : equipment?.site?.location,
        col3 : equipment?.name,
        col4 : equipment?.startDate,
        col5 : equipment?.endDate,
      };
      tempSiteItemList.push(historyItem);
    });
    setDatas(tempSiteItemList);
  };

  const onPaginationChange = (
    curretdata: any,
    itemOffset: any,
    endOffset: any
  ) => {
    setDatas(curretdata);
  };

  let th1, th2, th3, th4, th5;

  if (historyType === HISTORY_TYPE.EQUIPMENTS) {
    th1 = "Serial No";
    th2 = "Site";
    th3 = "Contract";
    th4 = "Start Date";
    th5 = "End Date";
  } else if (historyType === HISTORY_TYPE.CONTRACTS) {
    th1 = "List of contract ";
    th2 = "Site";
    th3 = "Start Date";
    th4 = "End Date";
  } else if (historyType === HISTORY_TYPE.OPERATORS) {
    th1 = "Operator Name";
    th2 = "Operator Site";
    th3 = "Start Date";
    th4 = "End Date";
  } else if (historyType === HISTORY_TYPE.CUSTOMERS) {
    th1 = "Serial No";
    th2 = "Contract";
    th3 = "Start Date";
    th4 = "End Date";
  } else if (historyType === HISTORY_TYPE.SITES) {
    th1 = "Serial No";
    th2 = "Location";
    th3 = "Start Date";
    th4 = "End Date";
  }

  useEffect(() => {
    if (historyType === HISTORY_TYPE.CUSTOMERS) {
      let historyRequest: IHistoryRequest = {
        page: paginationPayload.page,
        pageSize: paginationPayload.pageSize,
        orderByDesc: paginationPayload.orderByDesc,
        orderBy: paginationPayload.orderBy,
        searchKey: paginationPayload.searchKey,
        id: encryptUtils.decryptURL(id),
      };
      getCustomerHistory(historyRequest);
    } else if (historyType === HISTORY_TYPE.EQUIPMENTS) {
      let historyRequest: IHistoryRequest = {
        page: paginationPayload.page,
        pageSize: paginationPayload.pageSize,
        orderByDesc: paginationPayload.orderByDesc,
        orderBy: paginationPayload.orderBy,
        searchKey: paginationPayload.searchKey,
        id: encryptUtils.decryptURL(id),
      };
      getEquipmentHistory(historyRequest);
    } else if (historyType === HISTORY_TYPE.SITES) {
      let historyRequest: IHistoryRequest = {
        page: paginationPayload.page,
        pageSize: paginationPayload.pageSize,
        orderByDesc: paginationPayload.orderByDesc,
        orderBy: paginationPayload.orderBy,
        searchKey: paginationPayload.searchKey,
        id: encryptUtils.decryptURL(id),
      };
      getSitesHistory(historyRequest);
    }
  }, [historyType, paginationPayload.page]);

  return (
    <div className="col-span-2 box-border m-3 p-10  bg-white rounded-3xl shadow-md mt-4 dark:tile-bg-color">
      {datas && datas.length > 0 ? (
          <div>
          <div className="h-[65vh]">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    {th1}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {th2}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {th3}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {th4}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {th5}
                  </th>
                </tr>
              </thead>
              <tbody>
               
                  <>
                    {datas?.map((item: IHistoryItem, index) => {
                      return (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={item.col1}>
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.col1}
                          </th>
                          <td className="px-6 py-4"> {item.col2}</td>
                          <td className="px-6 py-4"> {item.col3}</td>
                          <td className="px-6 py-4"> {item.col4}</td>
                          <td className="px-6 py-4"> {item.col5}</td>
                        </tr>
                      );
                    })}
                  </>
               
              </tbody>
            </table>
          </div>
        </div>
  
        <div className="mt-4 ">
          <PaginatedItems
            itemsPerPage={paginationPayload.pageSize}
            totalRecords={totalRecords}
            onPagination={onPaginationChange}
            reRenderDevices={getCustomerHistoryList}
          />
        </div>
        </div>
        ): <div> <NoDataFound /></div>
        
      }
    

    </div>
  );
}

export default HistoryTable;
