import { AppThunk } from "../../../config/store.hooks";
import { processFiles } from "../../../utils/file-processor";
import { IHistoryRequest } from "../../components/history.component/history.component.interface";
import {
  CUSTOMER_OPERATIONS,
  IAddCustomerRequest,
  ICustomerHistoryRequest,
  ICustomerListRequest,
  IUpdateCustomerRequest,
} from "../customer.interface";
import { setCustomerDetails } from "../store/customer.reducer";
import { customerServices } from "./customer.services";

const addCustomer = (
  IAddCustomerRequest: IAddCustomerRequest,
  setSuccess: any
): AppThunk => {
  return async (dispatch: any, getState) => {
    const formData = new FormData();
    formData.append("id", String(IAddCustomerRequest.id));
    formData.append("name", IAddCustomerRequest.name);
    formData.append("mobileNumber", IAddCustomerRequest.mobileNumber);
    formData.append("gstNumber", IAddCustomerRequest.gstNumber);
    formData.append("address", IAddCustomerRequest.address);

    IAddCustomerRequest.images.forEach((item: any, index: any) => {
      for (let key in item) {
        formData.append(`images[${index}].${key}`, item[key]);
      }
    });
    try {
      const response = await customerServices.addCustomer(formData);
      if (response?.status === 200 || response?.status === 201) {
        setSuccess(true, CUSTOMER_OPERATIONS.CREATE);
      } else {
        setSuccess(false, CUSTOMER_OPERATIONS.CREATE);
      }
    } catch (err: any) {
      setSuccess(false, CUSTOMER_OPERATIONS.CREATE, err?.response?.data?.errorCode);
      return null;
    }
  };
};

const updateCustomer = (
  IUpdateCustomerRequest: IUpdateCustomerRequest,
  setSuccess: any
): AppThunk => {
  return async (dispatch: any, getState) => {
    const formData = new FormData();
    formData.append("id", String(IUpdateCustomerRequest.id));
    formData.append("name", IUpdateCustomerRequest.name);
    formData.append("mobileNumber", IUpdateCustomerRequest.mobileNumber);
    formData.append("gstNumber", IUpdateCustomerRequest.gstNumber);
    formData.append("address", IUpdateCustomerRequest.address);
    IUpdateCustomerRequest.images.forEach((item: any, index: any) => {
      for (let key in item) {
        formData.append(`images[${index}].${key}`, item[key]);
      }
    });
    try {
      const response = await customerServices.updateCustomer(formData);
      if (response?.status === 200 || response?.status === 201) {
        setSuccess(true, CUSTOMER_OPERATIONS.UPDATE);
      } else {
        setSuccess(false, CUSTOMER_OPERATIONS.UPDATE);
      }
    } catch (err:any) {    
      setSuccess(false, CUSTOMER_OPERATIONS.UPDATE,err?.response?.data?.errorCode);
      return null;
    }
  };
};

const deletecustomer = async (id: number, setSuccess: any) => {
  try {
    const response = await customerServices.deleteCustomer(id);
    if (response?.data?.success) {
      setSuccess(true,null);
    } else {
      setSuccess(false,null);
    }
  } catch (error: any) {
    setSuccess(false, error?.response?.data?.errorCode);
  }
};

const listAllCustomer = async (
  CustomerListAllRequest: ICustomerListRequest
) => {
  try {
    const response = await customerServices.listAllCustomer(
      CustomerListAllRequest
    );
    return response?.data;
  } catch (error: any) {
    console.log("ERROR_IN_FETCHING_CUSTOMER_DATA_IN_CUSTOMER_ACTION", error);
    return 0;
  }
};

const customerHistory = async (
  historyRequest: IHistoryRequest
) => {
  try {
    const response = await customerServices.customerHistory(
      historyRequest
    );
    return response?.data;
  } catch (error: any) {
    return 0;
  }
};

const listCustomerById = (id: number, setSuccess: any): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await customerServices.listCustomerById(id);
      const processedFiles = processFiles(response?.data?.images);
      
      dispatch(setCustomerDetails({ ...response?.data ,images:processedFiles}));
      setSuccess(true);
      return response?.data;
    } catch (error) {
      setSuccess(false);
      return null;
    }
  };
};

export const customerAction = {
  addCustomer,
  updateCustomer,
  deletecustomer,
  listAllCustomer,
  listCustomerById,
  customerHistory,
};
