import { Tooltip } from "@mui/material";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/Delete_icon.svg";
import noImageIcon from "../../assets/images/No-image.jpg";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_DIALOUGES,
  CONFIRMATION_TITLES,
  CONTRACT_MESSAGE,
  ERROR,
  NAVIGATIONS,
  SESSION_DETAILS,
  SUBMIT_BUTTON_TEXT,
} from "../../config/default.config";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import { contractAction } from "./service/contract.action";
import { customerAction } from "../customers/services/customer.action";
import { siteActions } from "../site/service/site.action";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import {
  IContractResponse,
  IEquipmentDetails,
  IOperator,
} from "./contract.interface";
import { setContractdetails } from "./store/contract.reducer";
import {
  setIsError,
  setIsLoading,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";

export function ContractView() {
  const navigate = useNavigate();
  const [ready, setReady] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { open, close } = useConfirmationPopup();
  const { id } = useParams();

  const contractDetailsResponse = useAppSelector(
    (state) => state.contractReducer.contractDetails
  );
  const handleNavigateBack = () => {
    let emptyContractDetails: IContractResponse = {
      customer: {
        address: "",
        gstNumber: "",
        id: 0,
        mobileNumber: "",
        name: "",
      },
      duration: 0,
      durationPeriod: "",
      endDate: null,
      equipments: [],
      id: 0,
      name: "",
      operatorIds: [],
      site: {
        area: "",
        id: 0,
        images: [],
        lattitude: "",
        location: "",
        longitude: "",
        status: false,
      },
      startDate: "",
    };
    dispatch(setContractdetails(emptyContractDetails));
    navigate(NAVIGATIONS.CONTRACT_LIST);
  };

  const setSuccessForContractFetching = (state: boolean) => {
    if (state) {
      setReady(true);
      dispatch(setIsLoading(false));
    } else {
      dispatch(setIsError(true));
      dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
      dispatch(setToast(true));
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    dispatch(setIsLoading(true));
    dispatch(
      contractAction.getContractByid(
        Number(encryptUtils.decryptURL(id)),
        setSuccessForContractFetching
      )
    );
  };

  const setSuccessForContractDelete = (state: boolean, errorCode: any) => {
    if (state && errorCode === null) {
      dispatch(setToastMsg(CONTRACT_MESSAGE.CONTRACT_DELETE_SUCCESS));
      dispatch(setToast(true));
      handleNavigateBack();
      handleClosePopup();
    } else {
      if (!state && errorCode != null) {
        dispatch(setIsError(true));
        dispatch(setToastMsg(errorCode));
        dispatch(setToast(true));
      } else {
        dispatch(setIsError(true));
        dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
        dispatch(setToast(true));
      }

      handleClosePopup();
    }
  };

  const handleOpenPopup = (confirmation: any) => {
    open(
      CONFIRMATION_TITLES.CONFIRM_DELETE,
      CONFIRMATION_DIALOUGES.CONTRACT_DELETE_CONFIRMATION.replace(
        ":contractName",
        contractDetailsResponse?.name
      ),
      SUBMIT_BUTTON_TEXT.YES,
      CANCEL_BUTTON_TEXT.NO,
      () => {
        confirmation(true);
      },
      () => {
        confirmation(false);
      }
    );
  };
  const handleDeleteConfirmation = (confirm: boolean) => {
    if (confirm) {
      contractAction.deleteContractById(
        contractDetailsResponse?.id,
        setSuccessForContractDelete
      );
    } else {
      handleClosePopup();
    }
  };

  const handleClosePopup = () => {
    close();
  };

  const handleEditClick = () => {
    navigate(
      NAVIGATIONS.CONTRACT_UPDATE.replace(
        ":id",
        encryptUtils.encryptURL(encryptUtils.decryptURL(id))
      )
    );
  };

  if (!ready) {
    return null;
  }

  return (
    <div className="inner-page-row grid md:grid-cols-3 gap-28 mt-6">
      <div className="col-span-2 box-border">
        <div className="flex justify-between">
          <div className=" flex text-lg">
            <button type="button" className="mr-3" onClick={handleNavigateBack}>
              <img src={backBtnIcon} alt="" className="p-1" />
            </button>
            <div className=" font-bold p-1 text-gray-600 mt-1 dark:head-text-color">Contract</div>
            <div className="p-1 text-gray-500 mt-1 dark:head-text-color">Status:</div>
          </div>
          {/* <a
            onClick={() => {
              handleNavigateBack();
            }}
            className=" text-blue-600 p-2 text-sm cursor-pointer mt-1"
          >
            View More
          </a> */}
        </div>

        <div className=" bg-white rounded-3xl shadow-md mt-4 dark:tile-bg-color">
          <div className="p-10 ">
            <div className=" flex justify-between">
              <div>
                <div className=" inline p-2 text-gray-500 text-xl">
                  <b className="dark:head-text-color">Contract</b>
                  <Tooltip title="Update" placement="top">
                    <img
                      className=" inline p-2 ml-5 cursor-pointer"
                      src={editIcon}
                      alt="edit"
                      onClick={handleEditClick}
                    />
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <img
                      className=" inline p-2 cursor-pointer"
                      src={deleteIcon}
                      alt="delete"
                      onClick={() => {
                        handleOpenPopup(handleDeleteConfirmation);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="sm:block md:flex  py-5">
              <div className="rounded-xl">
                <img
                  style={{ width: 100, height: 100 }}
                  src={noImageIcon}
                  className="border border-gray h-32 rounded-2xl"
                />
              </div>
              <div className="px-8">
                <div className="font-bold dark:head-text-color">{contractDetailsResponse?.name}</div>
                <div className="flex pt-2">
                  <div className=" pr-3 font-normal text-[15px]">
                    Customer :
                  </div>
                  <div className=" font-bold text-gray-500 dark:head-text-color">
                    {contractDetailsResponse.customer?.name}
                  </div>
                </div>
                <div className="flex">
                  <div className="pr-3 font-normal text-[15px]">Site :</div>
                  <div className=" font-bold text-gray-500 dark:head-text-color">
                    {contractDetailsResponse.site.location}
                  </div>
                </div>
              </div>
            </div>
            <hr className="flex-grow" />
            <div className=" py-5">
              <div className="flex mt-2">
                <div className=" pr-3 font-normal text-[15px]">Start Date</div>
                <div>:</div>
                <div className=" font-bold text-gray-500 pl-1 dark:head-text-color">
                  {contractDetailsResponse.startDate}
                </div>
              </div>
              {contractDetailsResponse.endDate !== null ? (
                <div className="flex mt-2">
                  <div className=" pr-3 font-normal text-[15px]">End Date</div>
                  <div>:</div>
                  <div className=" font-bold text-gray-500 pl-1 dark:head-text-color">
                    {contractDetailsResponse.endDate}
                  </div>
                </div>
              ) : (
                <div className="flex mt-2">
                  <div className=" pr-3 font-normal text-[15px]">Duration </div>
                  <div>:</div>
                  <div className=" font-bold text-gray-500 pl-1 dark:head-text-color">
                    {contractDetailsResponse.duration}{" "}
                    {contractDetailsResponse.durationPeriod}
                  </div>
                </div>
              )}
            </div>
            <hr className="flex-grow" />
            <div className="mt-2 py-5">
              <div className=" font-bold text-gray-500 pb-5 dark:head-text-color">Equipments</div>
              {contractDetailsResponse?.equipments?.map(
                (item: any, index) => {
                  return (
                    <div key={item.equipmentId}>
                      <div className=" pr-3 font-normal text-[15px]">
                        {item.equipmentId}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <hr className="flex-grow" />
            <div className="mt-2 py-5">
              <div className=" font-bold text-gray-500 pb-5 dark:head-text-color">Operators</div>
              {contractDetailsResponse?.operatorIds?.map(
                (item: IOperator, index) => {
                  return (
                    <div key={item.id}>
                      <div className=" pr-3 font-normal text-[15px]">
                        {item.name}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        Operation Hours
        <div className=" bg-white rounded-3xl shadow-md mt-8 h-24"></div>
      </div> */}
    </div>
  );
}
