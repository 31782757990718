import { useEffect, useState } from "react";
import {
  IEquipmentDetailsResponse,
  IEquipmentRecentAlarmStatus,
  IFaceRecognition,
  IFormattedRecognitionResult,
  IInstanceProperties,
  IInstancePropertiesResponst,
  IInstanceResponse,
  IOperatorDataItem,
  IOperatorDetailedInstance,
  IOperatorDetailedStatus,
  IOperatorLogsRequest,
  IOperatorStatusRequest,
} from "./status.table.interface";
import noImageIcon from "../../../assets/images/No-image.jpg";
import { useLocation } from "react-router-dom";
import PaginatedItems from "../pagination.component";
import { dashboardAction } from "../../dashboard/service/dashbord.action";
import { useAppDispatch, useAppSelector } from "../../../config/store.hooks";
import {
  OPERATOR_STATUS,
  SEQROPS_INSTANCE_API,
} from "../../../config/default.config";
import moment from "moment";
import ImagePreviewBox from "../preview-box/image.preview.box";
import VideoPreviewBox from "../preview-box/video.preview.box";
import { operatorActions } from "../../operator/services/operator.actions";
import { IOperator } from "../../operator/operator.interface";
import NoDataFound from "../noDataFound";

function StatusTable() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [equipmentDetails, setEquipmentDetails] = useState<
    IOperatorStatusRequest[]
  >([]);
  const [equipmentDetailsData, setEquipmentDetailsData] = useState<
    IEquipmentDetailsResponse[]
  >([]);

  const [faceRecognitionResult, setFaceRecognitionResult] = useState<
    IFaceRecognition[]
  >([]);

  const [getAllInstanceData, setGetInstanceData] = useState<
    IInstancePropertiesResponst[]
  >([]);
  const [paginationPayload, setPaginationPayload] =
    useState<IOperatorLogsRequest>({
      sortTerm: "",
      sortOrder: "ASC",
      page: 0,
      pageSize: 12,
    });

  const [instancePaginationPayload, setInstancePaginationPayload] =
    useState<IInstanceProperties>({
      pageNo: 0,
      pageSize: 11,
      sortKey: "",
      sortOrder: "asc",
      searchKey: "",
      modelName: "EXCAVATOR",
      getAll: true,
      properties: ["Vin"],
    });

  const [totalRecords, setTotalRecords] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [operatorImage, setOperatorImage] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [operatorVideo, setOperatorVideo] = useState("");

  const [operatorDetailedInformation, setOperatorDetailedInformation] =
    useState<{ [operatorId: string]: IOperatorDetailedStatus }>({});

  const [formattedFaceRecognitionResult, setFormattedFaceRecognitionResult] =
    useState<{ [equipmentId: string]: IFormattedRecognitionResult }>({});
  const recentEquipmentStatus = useAppSelector(
    (state) => state.commonReducer.recentEquipmentAlarmStatus
  );

  const [operatorDeatilsByInstance, setOperatorDeatilsByInstance] = useState<{
    [equipmentName: string]: IOperatorDetailedInstance;
  }>({});

  useEffect(() => {
    let getRecentEquipmentStatus: IEquipmentRecentAlarmStatus = {
      ...recentEquipmentStatus,
    };
    let recentStatusObjectModel: any = {};
    let recentEquipment: IFormattedRecognitionResult = {
      dateOfIdentification: getRecentEquipmentStatus?.dateOfIdentification,
      operatorImage: getRecentEquipmentStatus?.dashboard_image,
      recognitionStatus: getRecentEquipmentStatus?.operator_recognition,
      recognition_status: getRecentEquipmentStatus?.operator_recognition,
      videoName: getRecentEquipmentStatus?.videoName,
      equipmentId: getRecentEquipmentStatus?.equipmentId,
      operatorId: "",
    };

    recentStatusObjectModel[getRecentEquipmentStatus?.equipmentId] =
      recentEquipment;

    setFormattedFaceRecognitionResult({
      ...formattedFaceRecognitionResult,
      ...recentStatusObjectModel,
    });
  }, [recentEquipmentStatus]);

  useEffect(() => {
    if (location.pathname !== "/operatorstatusview") {
      let operatorLogPaginationReq: IOperatorLogsRequest = {
        sortTerm: "",
        sortOrder: "DESC",
        page: 0,
        pageSize: 9,
      };
      getOperatorLogs(operatorLogPaginationReq);
      setPaginationPayload(operatorLogPaginationReq);
    } else {
      let operatorLogPaginationReq: IOperatorLogsRequest = {
        sortTerm: "",
        sortOrder: "DESC",
        page: 0,
        pageSize: 11,
      };
      getOperatorLogs(operatorLogPaginationReq);
      setPaginationPayload(operatorLogPaginationReq);
    }
  }, []);

  const getOperatorLogs = async (
    operatorLogPaginationReq: IOperatorLogsRequest
  ) => {
    const response: any = await dispatch(
      dashboardAction.getOperatorLogs(operatorLogPaginationReq)
    );

    let tempResultData: { [equipmentId: string]: IFormattedRecognitionResult } =
      {};
    response?.EquipmentDetails.map(
      (item: IOperatorStatusRequest, index: number) => {
        let formattedResult: IFormattedRecognitionResult = {
          dateOfIdentification: null,
          operatorImage: response?.Result[index]?.operatorImage,
          recognitionStatus: response?.Result[index]?.recognitionStatus,
          recognition_status: response?.Result[index]?.recognition_status,
          videoName: response?.Result[index]?.videoName,
          equipmentId: item?.equipmentId,
          operatorId: response?.Result[index]?.operatorId,
        };
        tempResultData[item?.equipmentId] = formattedResult;

        // getOperatorById(response?.Result[index]?.operatorId, item?.equipmentId);
      }
    );
    setFormattedFaceRecognitionResult({ ...tempResultData });
  };

  const getOperatorById = async (operatorId: number, equipmentId: string) => {
    const response: any = await dispatch(
      operatorActions.getOperatorById(105, setSuccessForOperatorFetching)
    );
    if (response?.status === 200) {
      let operatorData: { [operatorId: string]: IOperatorDetailedStatus } = {};
      operatorData[operatorId] = response?.data;
      setOperatorDetailedInformation({
        ...operatorDetailedInformation,
        ...operatorData,
      });
    }
  };
  const setSuccessForOperatorFetching = (state: boolean) => {};

  const handleOperatorStatus = (message: string, equipmentId: string) => {
    let status = "";
    if (message === OPERATOR_STATUS.OPERATOR_RECOGNISED) {
      status = OPERATOR_STATUS.OPERATOR_PRESENT_STATUS;
    } else if (message == OPERATOR_STATUS.NO_FACE) {
      status = OPERATOR_STATUS.OPERATOR_NOT_PRESENT;
    } else if (message === OPERATOR_STATUS.FACE_NOT_RECOGNISED) {
      status = OPERATOR_STATUS.UNKNOWN_OPERATOR_STATUS;
    } else if (equipmentId === "#1185:4" || equipmentId === "#1188:4") {
      status = OPERATOR_STATUS.OPERATOR_STANDBY;
    } else {
      status = OPERATOR_STATUS.OPERATOR_NOT_ACTIVE_STATUS;
    }
    return status;
  };

  const handlePreviewImage = (showStatus: boolean, image: string) => {
    setShowImage(showStatus);
    setOperatorImage(image);
  };

  useEffect(() => {
    if (location.pathname !== "/operatorstatusview") {
      let instancePropertiesRequest: IInstanceProperties = {
        pageNo: 0,
        pageSize: 9,
        sortKey: "",
        sortOrder: "asc",
        searchKey: "",
        modelName: "EXCAVATOR",
        getAll: true,
        properties: ["VIN"],
      };
      getAllInstanceProperties(instancePropertiesRequest);
    } else {
      let instancePropertiesRequest: IInstanceProperties = {
        pageNo: 0,
        pageSize: 11,
        sortKey: "",
        sortOrder: "asc",
        searchKey: "",
        modelName: "EXCAVATOR",
        getAll: true,
        properties: ["VIN"],
      };
      getAllInstanceProperties(instancePropertiesRequest);
    }
  }, []);

  const getAllInstanceProperties = async (
    instancePropertiesRequest: IInstanceProperties
  ) => {
    const response: any = await dispatch(
      dashboardAction.getAllInstanceProperties(instancePropertiesRequest)
    );
    setTotalRecords(response?.data?.totalRecords);
    setGetInstanceData(response?.data?.data);

    let responseInstanceName: any = [];
    response?.data?.data?.map((item: IInstanceResponse) => {
      responseInstanceName.push(item?.instanceName);
    });

    getOperatorDetails(responseInstanceName);
  };

  const onPaginateInstance = (pagenumber: number) => {
    setInstancePaginationPayload({
      ...instancePaginationPayload,
      pageNo: pagenumber,
    });
    let instancePropertiesRequest: IInstanceProperties = {
      pageNo: pagenumber - 1,
      pageSize: instancePaginationPayload.pageSize,
      sortKey: instancePaginationPayload.sortKey,
      sortOrder: instancePaginationPayload.sortOrder,
      searchKey: instancePaginationPayload.searchKey,
      modelName: instancePaginationPayload.modelName,
      getAll: instancePaginationPayload.getAll,
      properties: ["VIN"],
    };
    getAllInstanceProperties(instancePropertiesRequest);
  };

  const handleVideoPreview = (showStatus: boolean, videourl: string) => {
    setShowVideo(showStatus);
    setOperatorVideo(videourl);
  };

  const getOperatorDetails = async (responseInstanceName: any) => {
    const response: any = await dispatch(
      dashboardAction.getOperatorDetails(responseInstanceName)
    );

    let operatorDetailsTempData: {
      [equipmentName: string]: IOperatorDetailedInstance;
    } = {};

    response?.Result?.map((item: IOperatorDetailedInstance) => {
      operatorDetailsTempData[item?.equipmentName] = item;
    });
    setOperatorDeatilsByInstance(operatorDetailsTempData);
  };

  return (
    <div className=" px-8">
      <ImagePreviewBox
        showImage={showImage}
        operatorImage={operatorImage}
        onClose={() => {
          handlePreviewImage(false, "");
        }}
      />
      <VideoPreviewBox
        showVideo={showVideo}
        operatorVideo={operatorVideo}
        onClose={() => {
          handleVideoPreview(false, "");
        }}
      />
      <div>
        {getAllInstanceData && getAllInstanceData.length > 0 ? (
          <div>
            <div>
              <div className="relative overflow-x-auto  sm:rounded-lg mt-4  dashboardTable ">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 table-font status-table">
                  <thead className="text-sm text-gray-400  bg-gray-100  dark:table-head-bg-color ">
                    <tr>
                      <th scope="col" className="px-3 py-3">
                        Vehicle Type
                      </th>
                      <th scope="col" className=" py-3">
                        Vehicle Number
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Operator
                      </th>

                      {/* <th scope="col" className="px-6 py-3">
                        Operator Image
                      </th>
                       */}
                      <th scope="col" className=" py-3">
                        Status
                      </th>
                      <th scope="col" className="pl-6 pr-2 py-3">
                        Date of Identification
                      </th>
                      <th scope="col" className="py-3">
                        Image
                      </th>
                      <th scope="col" className="pl-4 py-3">
                        Video
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getAllInstanceData?.map(
                      (item: IInstancePropertiesResponst, index) => {
                        let imageUrl = "";
                        let videoUrl =
                          "https://seqrops-assets.ap-south-1.linodeobjects.com/dashboard/1.mp4";
                        let presentStatus = false;
                        if (
                          formattedFaceRecognitionResult[item.id]
                            ?.recognitionStatus === OPERATOR_STATUS.NO_FACE
                        ) {
                          presentStatus = true;
                        }
                        if (
                          formattedFaceRecognitionResult[item.id]?.operatorImage
                        ) {
                          imageUrl =
                            SEQROPS_INSTANCE_API.GET_OPERATORIMAGE.replace(
                              ":image",
                              formattedFaceRecognitionResult[item.id]
                                ?.operatorImage
                            );
                        } else {
                          imageUrl = noImageIcon;
                        }

                        return (
                          <tr
                            className="bg-white dark:table-bg-color dark:border-gray-700"
                            key={item.id}
                          >
                            <td
                              className={`px-3 py-2 font-medium tableTd ${
                                formattedFaceRecognitionResult[item.id]
                                  ?.recognitionStatus === "OPERATOR_RECOGNISED"
                                  ? `text-green-600  dark:text-color`
                                  : `text-orange-500`
                              }`}
                            >
                              {item?.instanceName}
                            </td>
                            <td
                              className={` py-2 font-medium tableTd ${
                                formattedFaceRecognitionResult[item.id]
                                  ?.recognitionStatus === "OPERATOR_RECOGNISED"
                                  ? `text-green-600  dark:text-color`
                                  : `text-orange-500`
                              }`}
                            >
                              {item?.properties["VIN"]}
                            </td>
                            {/*  */}
                            <td
                              className={` px-6 py-2 font-medium tableTd flex items-center ${
                                formattedFaceRecognitionResult[item.id]
                                  ?.recognitionStatus === "OPERATOR_RECOGNISED"
                                  ? `text-green-600  dark:text-color`
                                  : `text-orange-500`
                              }`}
                              // style={{width:"200px"}}
                            >
                              {operatorDeatilsByInstance[item.instanceName]
                                ?.operatorImage ? (
                                <div
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <img
                                    src={
                                      operatorDeatilsByInstance[
                                        item.instanceName
                                      ]?.operatorImage
                                        ? operatorDeatilsByInstance[
                                            item.instanceName
                                          ]?.operatorImage
                                        : noImageIcon
                                    }
                                    className=" rounded-full mr-3"
                                    style={{
                                      objectFit: "cover",
                                      height: "30px",
                                      width: "30px",
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {operatorDeatilsByInstance[item.instanceName]
                                ?.operatorName ? (
                                operatorDeatilsByInstance[item.instanceName]
                                  ?.operatorName
                              ) : (
                                <div>
                                  <div>No</div> Operator
                                </div>
                              )}
                            </td>
                            {/* <td
                              className={"px-6 py-2 tableTd" }
                            >
                            <img
                            src={formattedFaceRecognitionResult[item.id]?.operatorId?
                              operatorDetailedInformation[formattedFaceRecognitionResult[item.id]?.operatorId]?.images[0]?.url:noImageIcon}
                            className="h-9 w-9 rounded-full"
                             />
                            </td>
                            */}
                            {/*  */}
                            <td
                              className={` py-2 font-medium tableTd ${
                                formattedFaceRecognitionResult[item.id]
                                  ?.recognitionStatus === "OPERATOR_RECOGNISED"
                                  ? `text-green-600  dark:text-color`
                                  : `text-orange-500`
                              }`}
                            >
                              {handleOperatorStatus(
                                formattedFaceRecognitionResult[item.id]
                                  ?.recognitionStatus,
                                item.id
                              )}
                            </td>
                            <td
                              className={`px-6 py-2 font-medium tableTd ${
                                formattedFaceRecognitionResult[item.id]
                                  ?.recognitionStatus === "OPERATOR_RECOGNISED"
                                  ? `text-green-600  dark:text-color`
                                  : `text-orange-500`
                              }`}
                            >
                              {formattedFaceRecognitionResult[item.id]
                                ?.dateOfIdentification
                                ? moment
                                    .utc(
                                      formattedFaceRecognitionResult[item.id]
                                        ?.dateOfIdentification
                                    )
                                    .local()
                                    .format("DD-MM-YYYY HH:mm:ss")
                                : "Date Not Available"}
                            </td>
                            <td
                              className={` py-2 font-medium tableTd ${
                                formattedFaceRecognitionResult[item.id]
                                  ?.recognitionStatus === "OPERATOR_RECOGNISED"
                                  ? `text-green-600  dark:text-color`
                                  : `text-orange-500`
                              }`}
                            >
                              <div
                                onClick={() =>
                                  handlePreviewImage(true, imageUrl)
                                }
                                className=" cursor-pointer rounded-full"
                              >
                                <img
                                  src={imageUrl}
                                  className="rounded-full"
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                            </td>
                            <td
                              className={`pl-4 cursor-pointer ${
                                formattedFaceRecognitionResult[item.id]
                                  ?.recognitionStatus === "OPERATOR_RECOGNISED"
                                  ? `text-green-600  dark:text-color`
                                  : `text-orange-500`
                              }`}
                              onClick={() => {
                                !presentStatus &&
                                  handleVideoPreview(true, videoUrl);
                              }}
                            >
                              {presentStatus ? (
                                " Not accessible"
                              ) : (
                                <video width="50" height="50">
                                  <source src={videoUrl} type="video/mp4" />
                                  Your browser does not support Video.
                                </video>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className=" mt-6">
              {location.pathname === "/operatorstatusview" ? (
                <PaginatedItems
                  itemsPerPage={instancePaginationPayload?.pageSize}
                  totalRecords={totalRecords}
                  onPagination={() => {}}
                  reRenderDevices={onPaginateInstance}
                />
              ) : null}
            </div>
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
    </div>
  );
}
export default StatusTable;
