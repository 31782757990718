import { useEffect, useState } from "react";
import PaginatedItems from "../components/pagination.component";
import { useNavigate } from "react-router-dom";
import SortingIcon from "../../assets/images/sorting_icon.svg";
import { siteActions } from "./service/site.action";
import noImageIcon from "../../assets/images/No-image.jpg";
import { NAVIGATIONS } from "../../config/default.config";
import SortingIconDesc from "../../assets/images/sorting_icon_down.svg";
import {
  IPaginationPayload,
  ISiteItem,
  ISiteListRequest,
} from "./site.interface";
import { Tooltip } from "@mui/material";
import { encryptUtils } from "../../utils/encryption.utils";
import NoDataFound from "../components/noDataFound";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../config/store.hooks";
import { setIsLoading } from "../../common/common.reducer";

export function SiteList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formattedSiteList, setFormattedSiteList] = useState<ISiteItem[]>([]);
  const [paginationPayload, setPaginationPayload] =
    useState<IPaginationPayload>({
      page: 1,
      pageSize: 16,
      orderByDesc: true,
      orderBy: "Location",
      searchKey: "",
    });
  const [totalRecords, setTotalRecords] = useState();

  const setSuccess = (state: boolean) => {
    dispatch(setIsLoading(false));
  };

  const getSites = async (SiteListAllRequest: ISiteListRequest) => {
    dispatch(setIsLoading(true));
    const response = await siteActions.getAllSites(
      SiteListAllRequest,
      setSuccess
    );
    setTotalRecords(response?.totalItems);
    let tempSiteItemList: ISiteItem[] = [];
    response?.data.map((sites: any) => {
      let ISiteItem: ISiteItem = {
        id: sites?.id,
        area: sites?.area,
        location: sites?.location,
        lattitude: sites?.lattitude,
        longitude: sites?.longitude,
        status: sites?.status,
        image: sites?.images[0]?.url,
      };

      tempSiteItemList.push(ISiteItem);
    });
    setFormattedSiteList(tempSiteItemList);
  };

  const getSiteList = (pagenumber: number) => {
    setPaginationPayload({
      ...paginationPayload,
      page: pagenumber,
    });
    let siteListRequest: ISiteListRequest = {
      Page: pagenumber,
      PageSize: paginationPayload.pageSize,
      OrderByDesc: paginationPayload.orderByDesc,
      OrderBy: paginationPayload.orderBy,
      SearchKey: paginationPayload.searchKey,
    };
    getSites(siteListRequest);
  };

  useEffect(() => {
    let siteListRequest: ISiteListRequest = {
      Page: paginationPayload.page,
      PageSize: paginationPayload.pageSize,
      OrderByDesc: paginationPayload.orderByDesc,
      OrderBy: paginationPayload.orderBy,
      SearchKey: paginationPayload.searchKey,
    };
    getSites(siteListRequest);
  }, [
    paginationPayload.page,
    paginationPayload.pageSize,
    paginationPayload.orderByDesc,
    paginationPayload.orderBy,
    paginationPayload.searchKey,
  ]);

  const onPaginationChange = (
    curretdata: any,
    itemOffset: any,
    endOffset: any
  ) => {
    setFormattedSiteList(curretdata);
  };

  /* useEffect(() => {
    let deviceListRequest: ISiteListRequest = {
      Page: paginationPayload.page,
      PageSize: paginationPayload.pageSize,
      OrderByDesc: paginationPayload.orderByDesc,
      OrderBy: paginationPayload.orderBy,
      SearchKey: paginationPayload.searchKey,
    };
    getSites(deviceListRequest);
  }, [paginationPayload]);*/

  const handleSortKeyChange = (orderBy: string) => {
    setPaginationPayload({
      ...paginationPayload,
      orderBy: orderBy,
    });
  };

  const handleOrderBy = () => {
    setPaginationPayload({
      ...paginationPayload,
      orderByDesc: !paginationPayload.orderByDesc,
    });
  };

  const handleAddSiteClick = () => {
    navigate(NAVIGATIONS.SITE_ADD);
  };

  const navigateToSiteById = (id: any) => {
    navigate(NAVIGATIONS.SITE_VIEW.replace(":id", encryptUtils.encryptURL(id)));
  };

  const handleSearchKey = (event: any) => {
    setPaginationPayload({
      ...paginationPayload,
      searchKey: event.target.value,
    });
  };
  return (
    <div className="p-10 inner-page-row">
      <div className="inline-block w-[100%] mt-5">
        <div className="float-left">
          <h2 className="text-xl">
            <b className="dark:head-text-color">Sites</b>
          </h2>
        </div>

        {/* <div className="float-right">
          <button
            className="text-[14px] bg-blue-800 text-white rounded-md p-2 px-8 py-2"
            onClick={() => handleAddSiteClick()}
          >
            <div>Add</div>
          </button>
        </div> */}
      </div>
      <div className="top-bar-sticky dark:search-bar-bg-color">
        <div className="flex flex-wrap items-center">
          <div className="flex-1 w-100">
            <input
              type="text"
              name="searchKey"
              id="searchKey"
              className="border rounded-lg border-gray-300 p-2 placeholder-gray-300  text-slate-500 w-[100%] cursor-pointer dark:normal-text-color"
              placeholder="Search Sites"
              onChange={handleSearchKey}
            />
          </div>
          {/* <SearchComponent list={countIndex}/> */}
          <div className="flex-2 w-100">
            <Tooltip title="filter" placement="top">
              <select
                id="sortKey"
                name="sortKey"
                className=" dark:panel-bg-color dark:normal-text-color border rounded-lg border-gray-300 p-2 placeholder-gray-200  text-slate-500  h-10 w-40 mx-6 bg-white pl-4 pr-4 text-sm font-thin focus:outline-none focus:border-red-500 cursor-pointer"
                onChange={(e) => {
                  handleSortKeyChange(e?.target?.value);
                }}
              >
                <option value="Location">Location</option>
                <option value="Area">Area</option>
                <option value="Status">Status</option>
              </select>
            </Tooltip>
          </div>
          <div className="flex-none  cursor-pointer">
            <Tooltip
              title={
                paginationPayload.orderByDesc ? "Sort by asc" : "Sort by desc"
              }
              placement="top"
            >
              <img
                src={
                  paginationPayload.orderByDesc ? SortingIcon : SortingIconDesc
                }
                alt="logo"
                onClick={handleOrderBy}
              ></img>
            </Tooltip>
          </div>
          <div className="flex-none ml-2 cursor-pointer">
            <button
              className=" py-2 btn btn-primary"
              onClick={() => handleAddSiteClick()}
            >
              <div>Add</div>
            </button>
          </div>
        </div>
      </div>
      {formattedSiteList && formattedSiteList.length > 0 ? (
        <div>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 content-start mb-5 inner-page-list">
            {formattedSiteList?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="transform cursor-pointer hover:scale-105 transition duration-75 active:bg-blue-100 rounded-xl place-content-center bg-white xs:w-full shadow-1xl mt-5 mr-5 border-dashed border-1 border-gray-500 dark:tile-bg-color"
                  onClick={() => {
                    navigateToSiteById(item.id);
                  }}
                >
                  <div className=" flex justify-center flex-col p-[10px] sm:p-[20px] md:p-[30px]">
                    <div className="flex flex-wrap content-start ">
                      <img
                        style={{ width: 60, height: 60 }}
                        src={item.image ? item.image : noImageIcon}
                        alt="logo"
                      ></img>
                      <div className="ml-5 cursor-pointer">
                        <p>
                          <b className="dark:head-text-color">
                            {item?.location}
                          </b>
                        </p>
                        <p className="text-xs cursor-pointer pt-1">
                          {item?.area}
                        </p>
                        <p className="text-xs pt-1">
                          Status:
                          <span
                            className={`${
                              item?.status === 0
                                ? "text-green-600"
                                : item?.status === 1
                                ? "text-red-400"
                                : "text-yellow-400"
                            } text-md`}
                          >
                            {item?.status === 0
                              ? " Active"
                              : item?.status === 1
                              ? " Inactive"
                              : " StandBy"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <PaginatedItems
            itemsPerPage={paginationPayload.pageSize}
            totalRecords={totalRecords}
            onPagination={onPaginationChange}
            reRenderDevices={getSiteList}
          />
        </div>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
}
