import axios from "axios";
import { AUTH_API } from "../../../config/default.config";
import { IApiResponse } from "../../../config/commons.interface";
import { ITokenResponse } from "../types/login.types";
import { commonUtils } from "../../../utils/common.utils";

const getToken = async (params: any): Promise<IApiResponse<ITokenResponse>> => {
  try {
    const response = await axios.post<IApiResponse<ITokenResponse>>(
      AUTH_API.TOKEN,
      params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const verifyToken = async (
  sessionId: string
): Promise<IApiResponse<ITokenResponse>> => {
  try {
    const response = await axios.post<IApiResponse<ITokenResponse>>(
      AUTH_API.INTROSPECT,
      { sessionId }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const logout = async (params: any): Promise<IApiResponse<null>> => {
  try {
    const response = await axios.post<IApiResponse<null>>(
      AUTH_API.LOGOUT,
      params
    );
    return {
      success: true,
      statusCode: response.status,
      message: "",
      data: null,
      errorCode: "",
    };
  } catch (error: any) {
    return (
      error?.response?.data || {
        success: false,
        statusCode: 500,
        message: "",
        data: null,
        errorCode: "",
      }
    );
  }
};

const forgotPassword = async (
  email: string
): Promise<IApiResponse<ITokenResponse | null>> => {
  try {
    const response = await axios.put<IApiResponse<ITokenResponse>>(
      AUTH_API.FORGOT_PASSWORD,
      { email: email }
    );
    return response.data;
  } catch (error:any) {
    return {
      success: false,
      statusCode: 417,
      message: "",
      data: null,
      errorCode: error?.code,
    };
  }
};

const resetPassword = async (
  code: string,
  newPassword: string,
  confirmPassword: string
): Promise<IApiResponse<ITokenResponse | null>> => {
  try {
    const response = await axios.put<IApiResponse<ITokenResponse>>(
      AUTH_API.RESET_PASSWORD,
      { code, newPassword, confirmPassword }
    );
    return response.data;
  } catch (error:any) {
    return {
      success: false,
      statusCode: 417,
      message: "",
      data: null,
      errorCode: error?.code,
    };
  }
};

const verifyCode = async (
  code: string
): Promise<IApiResponse<ITokenResponse | null>> => {
  try {
    const response = await axios.get<IApiResponse<ITokenResponse>>(
      commonUtils.formatString(
        AUTH_API.VERIFY_RESET_CODE,
        encodeURIComponent(code)
      )
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const authService = {
  getToken,
  logout,
  verifyToken,
  forgotPassword,
  resetPassword,
  verifyCode,
};
