import React, { useEffect, useRef, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import volvoImage from "../../assets/images/volvo-logo.svg";
import seqropsLogo from "../../assets/images/equserv_conworx_logo.svg";
import DashboaardIcon from "../../assets/images/Dashboard_icon.svg";
import ContractIcon from "../../assets/images/Contract_icon.svg";
import CustomersIcon from "../../assets/images/Customers_icon.svg";
import DeviceIcon from "../../assets/images/Devices_icon.svg";
import EquipmentIcon from "../../assets/images/Equipment_icon.svg";
import OperatorIcon from "../../assets/images/Operators_icon.svg";
import OperatorHistoryIcon from "../../assets/images/Operator_history_icon.svg";
import DashboaardIconActive from "../../assets/images/Dashboard_icon_active.svg";
import ReportsIcon from "../../assets/images/Reports_icon.svg";
import SitesIcon from "../../assets/images/Sites_icon.svg";
import ReportsIconActive from "../../assets/images/Reports_icon_active.svg";

import ContractIconActive from "../../assets/images/Contract_icon_active.svg";
import CustomersIconActive from "../../assets/images/Customers_icon_active.svg";
import DeviceIconActive from "../../assets/images/Devices_icon_active.svg";
import EquipmentIconActive from "../../assets/images/Equipment_icon_active.svg";
import OperatorIconActive from "../../assets/images/Operators_icon_active.svg";
import serverRoomMonitoringDashboardIcon from "../../assets/images/Server-Room-Monitoring-Dashboard-icon.svg";
import OperatorHistoryIconActive from "../../assets/images/Operator_history_icon_active.svg";
import SitesIconActive from "../../assets/images/Sites_icon_active.svg";
import MenuIcon from "../../assets/images/seqrops_menu_icon.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import { DeviceList } from "../devices/device.list";
import {
  ASSET_REDIRECT_SCOPES,
  ASSET_SUITE_LOGIN_URL,
  ASSET_URLS,
  AUTH_MESSAGES,
  COOKIE_DETAILS,
  CURRENT_POSITIONS,
  DASHBOARD_TYPE,
  KEYCLOAK_CLIENTS,
  NAVIGATIONS,
  SESSION_DETAILS,
  SESSION_KEY,
  USER_DETAILS,
} from "../../config/default.config";
import Popper from "popper.js";
import { authActions } from "../login/service/login.action";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import {
  setCurrentPos,
  setIsError,
  setIsLoading,
  setTheme,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import { IDevice } from "../devices/device.interface";
import { setDevice } from "../devices/store/device.reducer";
import { useConfirmationPopup } from "./confirmation.component/confirmation-popup-context";
import { ISiteRespone } from "../site/site.interface";
import { setSiteDetails } from "../site/store/site.reducer";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import Dropdownopen from "../../assets/images/collapse-opened.png";
import { setEquipmentDetails } from "../equipment/store/equipment.reducer";
import { IEquipment } from "../equipment/equipment.interface";
import { IOperator } from "../operator/operator.interface";
import { setOperator } from "../operator/store/operator.reducer";
import { ICustomer } from "../customers/customer.interface";
import { setCustomerDetails } from "../customers/store/customer.reducer";
import { IContractResponse } from "../contract/contract.interface";
import { setContractdetails } from "../contract/store/contract.reducer";
import { assetKeycloakActions } from "../../config/keycloak/asset-keycloak.action";
import {
  ILogoutTokenPayload,
  IUserAuthorizations,
  IUserTokenDetails,
} from "../../config/keycloak/asset-keycloak.interface";
import { encryptUtils } from "../../utils/encryption.utils";
import { cookieUtils } from "../../utils/cookie.utils";
import { Tooltip } from "@mui/material";
import seqropsLogoutBtn from "../../assets/images/seqrops-exit-btn.svg";
import seqropsModelsIcon from "../../assets/images/asset_model_icon.svg";
import seqropsDeviceManagementIcon from "../../assets/images/device_management_icon.svg";
import seqropsAssetsIcon from "../../assets/images/asset_management_icon.svg";
import managementDashboardIcon from "../../assets/images/management_dashboard_icon.svg";
import operationalDashboardIcon from "../../assets/images/operational_dashboard.svg";
import seqropsFsmIcon from "../../assets/images/fsm_icon.svg";
import siteManagement from "../../assets/images/site-management-icon.svg";
import ReactSwitch from "react-switch";
import HomeIcon from "../../assets/images/seqrops_home_btn.svg";
import { AltRoute } from "@mui/icons-material";

function LayoutComponent({ children }: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const linkClick = (path: string, currentPos: string) => {
    clearStore();
    dispatch(setCurrentPos(currentPos));
    storeCurrentPosinLocalStorage(currentPos);
    navigate(path);
  };

  let userName: string = sessionStorageUtils.getLocalStorage(
    SESSION_KEY.LOCAL_STORAGE_KEY
  )?.token?.username;
  /* let userName = sessionStorageUtils.getLocalStorage(
    USER_DETAILS.USER_KEY
  ).name;*/

  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef: any = React.createRef();
  const popoverDropdownRef: any = React.createRef();
  const { open, close } = useConfirmationPopup();
  const currentPos = useAppSelector((state) => state.commonReducer.currentPos);
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
  const [homeUrl, setHomesmUrl] = useState<string | undefined>(undefined);
  const openDropdownPopover = () => {
    new Popper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-end",
    });
    setDropdownPopoverShow(true);
  };
  const theme = useAppSelector((state) => state.commonReducer.theme);
  const [session_id, setSession_id] = useState("");

  const op = useRef<OverlayPanel>(null);
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const handleLogout = () => {
    // dispatch(authActions.logout(logoutCallback));

    handleLogoutPopup(handleLogoutConfirmation);
  };
  const handleLogoutPopup = (confirmation: any) => {
    open(
      AUTH_MESSAGES.LOGOUT_TITLE,
      AUTH_MESSAGES.LOGOUT_CONFIRMATION,
      "Logout",
      "Cancel",
      () => {
        confirmation(true);
      },
      () => {
        confirmation(false);
      }
    );
  };

  const handleAppClick = (domain: string, url: string) => {
    let redirectUrl = url;

    const newArray = [];
    let userTokenDetails: IUserTokenDetails =
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY)?.token;
    let data: any = userTokenDetails?.userAuthorizations;
    const trueKeys = Object.keys(data).filter((key) => data[key]);
    trueKeys.forEach((key) => {
      newArray.push(key);
    });
    if (
      (trueKeys.length === 3 ||
        trueKeys.length === 4 ||
        trueKeys.length === 5) &&
      domain === ASSET_REDIRECT_SCOPES.ASSET_SUITE
    ) {
      const queryParams = new URLSearchParams({
        redirectFrom: ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD,
      });
      const urlWithParams = `${redirectUrl}?${queryParams.toString()}`;
      window.open(urlWithParams, "_self");
      return;
    }

    if (
      [
        ASSET_REDIRECT_SCOPES.MANAGEMENT_DASHBOARD,
        ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD,
        ASSET_REDIRECT_SCOPES.MONITORING_DASHBOARD,
      ].includes(domain)
    ) {
      const queryParams = new URLSearchParams({ dashboardType: domain });
      const urlWithParams = `${redirectUrl}?${queryParams.toString()}`;
      window.open(urlWithParams, "_self");
    } else {
      window.open(redirectUrl, "_self");
    }
  };

  const AppClick = ({ dashboardType, icon, label, url }: any) => {
    return (
      <div
        className="col target-app-container"
        onClick={() => handleAppClick(dashboardType, url)}
      >
        <div className="">
          <div className="div">
            <img
              src={icon}
              alt={dashboardType}
              className="img-fluid asset-images"
            />
            <span className="operationalDashboardDiv seqrops-options-text dark:head-text-color">
              {label}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const handleLogoutConfirmation = async (confirm: boolean) => {
    if (confirm) {
      dispatch(setIsLoading(true));
      // dispatch(authActions.logout(logoutCallback));

      let ILogoutTokenPayload: ILogoutTokenPayload = {
        refresh_token: authorozedScopes.refresh_token,
        session_id: encryptUtils.encryptAuthKey(authorozedScopes.session_id),
      };
      await assetKeycloakActions.logoutToken(
        ILogoutTokenPayload,
        logoutSeqrops
      );
      close();
    } else {
      close();
    }
  };

  const logoutSeqrops = (status: boolean) => {
    if (status) {
      let cookieName = COOKIE_DETAILS.COOKIE_NAME;
      dispatch(setIsLoading(false));
      localStorage.clear();
      cookieUtils.deleteCookie(cookieName);
      window.location.replace(ASSET_SUITE_LOGIN_URL);
    } else {
      dispatch(setIsLoading(false));
      dispatch(setIsError(true));
      dispatch(setToast(true));
      dispatch(setToastMsg(AUTH_MESSAGES.LOUGOUT_FAIL));
    }
  };

  const logoutCallback = (status: boolean) => {
    if (status) {
      dispatch(setIsLoading(false));
      localStorage.removeItem("sop-" + USER_DETAILS.USER_KEY);
      dispatch(setIsError(false));
      dispatch(setToast(true));
      dispatch(setToastMsg(AUTH_MESSAGES.LOGOUT_SUCCESS));
    } else {
      dispatch(setIsLoading(false));
      dispatch(setIsError(true));
      dispatch(setToast(true));
      dispatch(setToastMsg(AUTH_MESSAGES.LOUGOUT_FAIL));
    }
  };

  const storeCurrentPosinLocalStorage = (currentPos: string) => {
    localStorage.setItem(SESSION_DETAILS.POS_KEY, currentPos);
  };

  const clearStore = () => {
    //clear device
    let emptyDevice: IDevice = {
      id: 0,
      name: "",
      imei: 0,
      deviceType: {
        id: 0,
        name: "",
        description: "",
      },
      sensorProtocol: 0,
      sensorIP: "",
      sensorPort: "",
      isCameraAdded: false,
      cameraIP: "",
      cameraPort: "",
      cameraProtocol: 0,
      cameraFrameRate: "",
      cameraResolution: "",
      images: [],
    };
    dispatch(setDevice(emptyDevice));

    //clear site
    let emptySiteDetails: ISiteRespone = {
      id: 0,
      location: "",
      area: "",
      lattitude: "",
      longitude: "",
      status: 3,
      images: [],
    };
    dispatch(setSiteDetails(emptySiteDetails));

    //clear equipment
    let emptyEquipmentDetails: IEquipment = {
      id: 0,
      name: "",
      vehicleNumber: "",
      description: "",
      operatingWeight: 0,
      grossPower: 0,
      bucketCapacity: 0,
      maxDiggingDepth: 0,
      images: [],
      deviceId: 0,
      status: 3,
    };
    dispatch(setEquipmentDetails(emptyEquipmentDetails));

    //clear operator
    let emptyOperator: IOperator = {
      id: 0,
      name: "",
      mobile: "",
      location: "",
      address: "",
      images: [],
    };
    dispatch(setOperator(emptyOperator));

    //clear customer

    let emptyCustomer: ICustomer = {
      id: 0,
      name: "",
      mobileNumber: "",
      gstNumber: "",
      address: "",
      images: [],
    };
    dispatch(setCustomerDetails(emptyCustomer));

    let emptyContractDetails: IContractResponse = {
      customer: {
        address: "",
        gstNumber: "",
        id: 0,
        mobileNumber: "",
        name: "",
      },
      duration: 0,
      durationPeriod: "",
      endDate: null,
      equipments: [],
      id: 0,
      name: "",
      operatorIds: [],
      site: {
        area: "",
        id: 0,
        images: [],
        lattitude: "",
        location: "",
        longitude: "",
        status: false,
      },
      startDate: "",
    };
    dispatch(setContractdetails(emptyContractDetails));
  };
  interface IApplinksInterface {
    name: string;
    icon: any;
    url: string | undefined;
    scope: keyof IUserAuthorizations;
  }

  const appLinks: IApplinksInterface[] = [
    {
      name: "Home",
      icon: HomeIcon,
      url: homeUrl,
      scope: "asset_suite",
    },
    {
      name: "Operations Dashboard",
      icon: operationalDashboardIcon,
      url: ASSET_URLS.OPERATIONAL_DASHBOARD_URL,
      scope: "operational_dashboard",
    },
    {
      name: "Management Dashboard",
      icon: managementDashboardIcon,
      url: ASSET_URLS.MANAGEMENT_DASHBOARD_URL,
      scope: "management_dashboard",
    },
    {
      name: "Site Management",
      icon: siteManagement,
      url: ASSET_URLS.CONTRACT_URL,
      scope: "asset_contract",
    },
    {
      name: "Monitoring Dashboard",
      icon: serverRoomMonitoringDashboardIcon,
      url: ASSET_URLS.MONITORING_DASHBOARD_URL,
      scope: "monitoring_dashboard",
    },
    {
      name: "Asset Models",
      icon: seqropsModelsIcon,
      url: ASSET_URLS.ASSET_MODELS_URL,
      scope: "asset_models",
    },
    {
      name: "Asset Management",
      icon: seqropsAssetsIcon,
      url: ASSET_URLS.ASSET_MANAGEMENT_URL,
      scope: "asset_management",
    },
    {
      name: "Device Management",
      icon: seqropsDeviceManagementIcon,
      url: ASSET_URLS.DEVICE_MANAGEMENT_URL,
      scope: "device_management",
    },
    {
      name: "Field Service Management",
      icon: seqropsFsmIcon,
      url: ASSET_URLS.FIELD_SERVICE_MANAGEMENT_URL,
      scope: "field_service_management",
    },
  ];
  const itemsPerRow = 1;
  const rows: any = [];
  let rowIndex = 3;

  for (const item of appLinks) {
    rows[rowIndex] = rows[rowIndex] || [];

    const scopeValue = item.scope;
    if (userScopesFromLocalStorage?.[scopeValue]) {
      rows[rowIndex].push(item);
    }

    if (rows[rowIndex].length === itemsPerRow) {
      rowIndex++;
    }
  }

  rows.forEach((row: any) => {
    row.push(
      ...Array.from({ length: Math.max(0, itemsPerRow - row.length) }, () => ({
        name: "",
        icon: "",
        url: "",
        scope: "",
      }))
    );
  });

  useEffect(() => {
    let currentPosition = localStorage.getItem(SESSION_DETAILS.POS_KEY);
    dispatch(setCurrentPos(CURRENT_POSITIONS.DASHBOARD));
    storeCurrentPosinLocalStorage(CURRENT_POSITIONS.DASHBOARD);
  }, []);

  const handleDashboardClick = (domain: string) => {
    let dashboardURL = ASSET_URLS.OPERATIONAL_DASHBOARD_URL;

    if (domain === KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD) {
      dashboardURL = ASSET_URLS.MANAGEMENT_DASHBOARD_URL;
    }

    const queryParams = new URLSearchParams({ dashboardType: domain });
    const urlWithParams = `${dashboardURL}?${queryParams.toString()}`;
    window.open(urlWithParams, "_blank");
  };

  useEffect(() => {
    let userTokenDetails: IUserTokenDetails =
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY)?.token;
    setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
    console.log(
      "userTokenDetails?.userAuthorizations ::: ",
      userTokenDetails?.userAuthorizations
    );
    let cookiedata = cookieUtils.getCookie(COOKIE_DETAILS.COOKIE_NAME)!;
    let decryptedSessionThemeType = encryptUtils.decryptURL(cookiedata);

    if (
      decryptedSessionThemeType?.themeType === "LIGHT" ||
      userTokenDetails?.themeType === "LIGHT"
    ) {
      document.documentElement.classList.remove("dark");
      dispatch(setTheme(false));
    } else if (
      decryptedSessionThemeType?.themeType === "DARK" ||
      userTokenDetails?.themeType === "DARK"
    ) {
      document.documentElement.classList.add("dark");
      dispatch(setTheme(true));
    }
  }, []);

  useEffect(() => {
    let userTokenDetails: IUserTokenDetails =
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY)?.token;
    setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
    setSession_id(userTokenDetails?.session_id);
  }, []);

  const toggleTheme = async (isDark: any, event: any) => {
    const response = await assetKeycloakActions.SetThemes(isDark, setReady);
  };

  const setReady = (status: boolean, isDark: boolean) => {
    if (status) {
      try {
        dispatch(setTheme(isDark));
        if (isDark) {
          document.documentElement.classList.add("dark");
        } else {
          document.documentElement.classList.remove("dark");
        }

        let cookieName = COOKIE_DETAILS.COOKIE_NAME;
        let domainName = COOKIE_DETAILS.COOKIE_DOMAIN;
        let sessionId = session_id;

        let sessionThemeType = {
          sessionId: sessionId,
          themeType: isDark ? "DARK" : "LIGHT",
        };
        let encryptedSessionThemeType =
          encryptUtils.encryptURL(sessionThemeType);
        cookieUtils.setCookie(cookieName, encryptedSessionThemeType, {
          domain: domainName,
        });
      } catch {}

      //store
      //cookie
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectFrom = urlParams.get("redirectFrom");
    // if (redirectFrom != null) {
    //   setHomesmUrl(
    //     ASSET_URLS.APP_SUITE_URL +
    //       "/?" +
    //       DASHBOARD_TYPE.REDIRECT +
    //       "=" +
    //       redirectFrom
    //   );
    // } else {
    setHomesmUrl(ASSET_URLS.APP_SUITE_URL);
    // }
  }, []);

  return (
    <div className="">
      <div
        className="bg-white flex justify-between top-nav pr-4 dark:dark-navbar"
        style={{ width: "100%" }}
        onMouseLeave={() => {
          closeDropdownPopover();
        }}
      >
        <div className="logo-row flex  seqrops-logo-section ">
          {/* <img src={volvoImage} className="volvo-logo" alt="logo" style={{}} /> */}
          <img
            src={seqropsLogo}
            className="seqrops-logo ml-5"
            alt="logo"
            style={{}}
          />
          <span className="mx-5 w-44 ">Site Management</span>
        </div>

        <div className=" flex align-middle  items-center justify-center h-full">
          <ReactSwitch
            className="switchButton"
            onChange={toggleTheme}
            checked={theme}
            onColor="#02457a"
            onHandleColor="#2693e6"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={40}
            checkedHandleIcon={<MoonToggleIcon />}
            uncheckedHandleIcon={<SunToggleIcon />}
          />
          <div className="seqrops-username-box">
            <div className="bg-blue-950 rounded-full text-md text-blue-400 p-2 px-[14px] font-extrabold">
              {userName ? userName.toUpperCase().charAt(0) : null}
            </div>
            <div className="seqrops-username  dark:seqrops-shade">
              {userName}
            </div>
          </div>
          <Tooltip title="Menu">
            <button className="pl-5">
              <img
                src={MenuIcon}
                className="w-6 hover:scale-125 ease-in-out duration-200"
                onClick={(e) => op.current && op.current?.toggle(e)}
              />
            </button>
          </Tooltip>
        </div>
        <OverlayPanel ref={op}>
          <div className="rounded-lg shadow-2xl mt-4 bg-white p-5 header-sidebar w-[35rem] dark:dark-bg-color">
            <div className="flex justify-between items-center border-slate-200 border-b-2">
              <h4 className="seqrops seqrops-const-font-clr dark:seqrops-shade">
                {userName}
              </h4>
              <button
                className="flex  justify-between items-center"
                onClick={handleLogout}
              >
                <span className="seqrops-const-font-clr dark:seqrops-shade">
                  Logout{" "}
                </span>
                <img
                  src={seqropsLogoutBtn}
                  alt="Log out"
                  className=" h-5 w-5"
                />
              </button>
            </div>
            <div>
              <div className="seqrops-asset-apps grid grid-cols-3">
                {rows.map((row: any, rowIndex: any) => (
                  <div
                    key={rowIndex}
                    className="row seqrops-asset-apps-row br-bottom"
                  >
                    {row.map((link: IApplinksInterface, linkIndex: any) => {
                      const scopeValue = link.scope;
                      return (
                        <div
                          key={linkIndex}
                          className="col target-app-container"
                        >
                          {userScopesFromLocalStorage !== undefined &&
                            userScopesFromLocalStorage[scopeValue] && (
                              <AppClick
                                dashboardType={scopeValue}
                                icon={link.icon}
                                label={link.name}
                                url={link.url}
                              />
                            )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </OverlayPanel>

        {/* <div className="flex items-center">
          <div ref={btnDropdownRef}>
            <img src={NotificationIcon} className="mr-5"></img>
          </div>
          <div className="flex">
            <div className="float-left flex items-center">
              <p className="bg-blue-100 rounded-full p-2 mr-2 text-xs text-black profile">
              {userName?userName.toUpperCase().charAt(0):null} 
              </p>
            </div>
  
            <button
              className="bg-white flex items-center"
              style={{ transition: "all .15s ease" }}
              type="button"
              onClick={() => {
                dropdownPopoverShow
                  ? closeDropdownPopover()
                  : openDropdownPopover();
              }}
            >
              <p className="text-black">{userName}</p>
              <img src={Dropdownopen} height={30} width={30} />
            </button>
          </div>
          <div
            ref={popoverDropdownRef}
            className={
              (dropdownPopoverShow ? "flex " : "hidden ") +
              "bg-white " +
              "text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
            }
            style={{
              minWidth: "9rem",
              cursor: "pointer",
            }}
            onClick={handleLogout}
            onMouseLeave={() => {
              closeDropdownPopover();
            }}
          >
            <div className="h-0 my-2 border border-solid border-t-0 border-gray-900 opacity-25" />
            <span
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent " +
                " text-gray-800"
              }
            >
              Logout
            </span>
          </div>
        </div> */}
      </div>
      <div></div>
      <div className="flex flex-row">
        <div className="sidebar-row">
          <nav>
            <div className="bg-white shadow-lg sidebar-nav-inner dark:dark-navbar">
              <ul className="pt-10 px-3 sidebar-p-tab">
                <li
                  onClick={() => {
                    linkClick(
                      NAVIGATIONS.DASHBOARD,
                      CURRENT_POSITIONS.DASHBOARD
                    );
                  }}
                >
                  <div>
                    <div className="bg-white flex justify-center pb-1  dark:dark-navbar">
                      <img
                        src={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.DASHBOARD
                            ? DashboaardIconActive
                            : DashboaardIcon
                        }
                        alt="logo"
                        className="text-blue-500"
                      ></img>
                    </div>
                    <div className="bg-white flex justify-center  dark:dark-navbar">
                      <p
                        className={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.DASHBOARD
                            ? "text-blue-300 text-xs text-center"
                            : "text-xs text-center"
                        }
                      >
                        Dashboard
                      </p>
                    </div>
                  </div>
                </li>
                <li
                  className="bg-white"
                  onClick={() => {
                    linkClick(
                      NAVIGATIONS.EQUIPMENT_LIST,
                      CURRENT_POSITIONS.EQUIPMENTS
                    );
                  }}
                >
                  <div
                    className="bg-white"
                    style={{ backgroundColor: "aliceblue", marginTop: 20 }}
                  >
                    <div className="bg-white flex justify-center pb-1  dark:dark-navbar">
                      <img
                        src={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.EQUIPMENTS
                            ? EquipmentIconActive
                            : EquipmentIcon
                        }
                        alt="logo"
                        className="fill-blue-500"
                      ></img>
                    </div>
                    <div className="bg-white flex justify-center  dark:dark-navbar">
                      <p
                        className={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.EQUIPMENTS
                            ? "text-blue-300 text-xs text-center"
                            : "text-xs text-center"
                        }
                      >
                        Equipments
                      </p>
                    </div>
                  </div>
                </li>

                <li
                  className="pt-50 mt-50 bg-white"
                  onClick={() => {
                    linkClick(
                      NAVIGATIONS.OPERATOR_LIST,
                      CURRENT_POSITIONS.OPERATORS
                    );
                  }}
                >
                  <div
                    className="pt-50 bg-white"
                    style={{ backgroundColor: "aliceblue", marginTop: 20 }}
                  >
                    <div className="bg-white flex justify-center pb-1  dark:dark-navbar">
                      <img
                        src={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.OPERATORS
                            ? OperatorIconActive
                            : OperatorIcon
                        }
                        alt="logo"
                      ></img>
                    </div>
                    <div className="bg-white flex justify-center  dark:dark-navbar">
                      <p
                        className={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.OPERATORS
                            ? "text-blue-300 text-xs text-center"
                            : "text-xs text-center"
                        }
                      >
                        Operators
                      </p>
                    </div>
                  </div>
                </li>
                <li
                  className="pt-50 mt-50 bg-white"
                  onClick={() => {
                    linkClick(NAVIGATIONS.SITE_LIST, CURRENT_POSITIONS.SITES);
                  }}
                >
                  <div
                    className="pt-50 bg-white"
                    style={{ backgroundColor: "aliceblue", marginTop: 20 }}
                  >
                    <div className="bg-white flex justify-center pb-1  dark:dark-navbar">
                      <img
                        src={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.SITES
                            ? SitesIconActive
                            : SitesIcon
                        }
                        alt="logo"
                      ></img>
                    </div>
                    <div className="bg-white flex justify-center  dark:dark-navbar">
                      <p
                        className={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.SITES
                            ? "text-blue-300 text-xs text-center"
                            : "text-xs text-center"
                        }
                      >
                        Sites
                      </p>
                    </div>
                  </div>
                </li>
                <li
                  className="pt-50 mt-50 bg-white"
                  onClick={() => {
                    linkClick(
                      NAVIGATIONS.CUSTOMER_LIST,
                      CURRENT_POSITIONS.CUSTOMERS
                    );
                  }}
                >
                  <div
                    className="pt-50 bg-white"
                    style={{ backgroundColor: "aliceblue", marginTop: 20 }}
                  >
                    <div className="bg-white flex justify-center pb-1  dark:dark-navbar">
                      <img
                        src={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.CUSTOMERS
                            ? CustomersIconActive
                            : CustomersIcon
                        }
                        alt="logo"
                      ></img>
                    </div>
                    <div className="bg-white flex justify-center  dark:dark-navbar">
                      <p
                        className={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.CUSTOMERS
                            ? "text-blue-300 text-xs text-center"
                            : "text-xs text-center"
                        }
                      >
                        Customers
                      </p>
                    </div>
                  </div>
                </li>
                <li
                  className="pt-50 mt-50 bg-white"
                  onClick={() => {
                    linkClick(
                      NAVIGATIONS.CONTRACT_LIST,
                      CURRENT_POSITIONS.CONTRACT
                    );
                  }}
                >
                  <div
                    className="pt-50 bg-white"
                    style={{ backgroundColor: "aliceblue", marginTop: 20 }}
                  >
                    <div className="bg-white flex justify-center pb-1  dark:dark-navbar">
                      <img
                        src={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.CONTRACT
                            ? ContractIconActive
                            : ContractIcon
                        }
                        alt="logo"
                      ></img>
                    </div>
                    <div className="bg-white flex justify-center  dark:dark-navbar">
                      <p
                        className={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.CONTRACT
                            ? "text-blue-300 text-xs text-center"
                            : "text-xs text-center"
                        }
                      >
                        Contract
                      </p>
                    </div>
                  </div>
                </li>
                <li
                  className="pt-50 mt-50 bg-white"
                  onClick={() => {
                    linkClick(
                      NAVIGATIONS.OPERATOR_HISTORY,
                      CURRENT_POSITIONS.OPERATOR_HISTORY
                    );
                  }}
                >
                  <div
                    className="pt-50 bg-white"
                    style={{ backgroundColor: "aliceblue", marginTop: 20 }}
                  >
                    <div className="bg-white flex justify-center pb-1  dark:dark-navbar">
                      <img
                        src={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.OPERATOR_HISTORY
                            ? OperatorHistoryIconActive
                            : OperatorHistoryIcon
                        }
                        alt="logo"
                      ></img>
                    </div>
                    <div className="bg-white flex justify-center  dark:dark-navbar">
                      <p
                        className={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.OPERATOR_HISTORY
                            ? "text-blue-300 text-xs text-center"
                            : "text-xs text-center"
                        }
                      >
                        Operator<br></br>History
                      </p>
                    </div>
                  </div>
                </li>
                <li
                  className="pt-50 mt-50 bg-white"
                  onClick={() => {
                    linkClick(NAVIGATIONS.REPORTS, CURRENT_POSITIONS.REPORT);
                  }}
                >
                  <div
                    className="pt-50 bg-white"
                    style={{ backgroundColor: "aliceblue", marginTop: 20 }}
                  >
                    <div className="bg-white flex justify-center pb-1  dark:dark-navbar">
                      <img
                        src={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.REPORT
                            ? ReportsIconActive
                            : ReportsIcon
                        }
                        alt="logo"
                      ></img>
                    </div>
                    <div className="bg-white flex justify-center  dark:dark-navbar">
                      <p
                        className={
                          (currentPos || localStorage.getItem("pos")) ===
                          CURRENT_POSITIONS.REPORT
                            ? "text-blue-300 text-xs text-center"
                            : "text-xs text-center"
                        }
                      >
                        Report
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div className="h-screen overflow-auto">
        <div style={{ width: "100%", height: "100%" }}>{children}</div>
      </div>
    </div>
  );
}

const SunIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#FFD700"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <circle cx="10" cy="10" r="10" />
    <line x1="12" y1="2" x2="12" y2="8" />
    <line x1="4.22" y1="10.22" x2="5.64" y2="11.64" />
    <line x1="1" y1="12" x2="7" y2="12" />
    <line x1="4.22" y1="13.78" x2="5.64" y2="12.36" />
    <line x1="12" y1="20" x2="12" y2="14" />
    <line x1="18.36" y1="11.64" x2="19.78" y2="10.22" />
    <line x1="23" y1="12" x2="17" y2="12" />
    <line x1="18.36" y1="12.36" x2="19.78" y2="13.78" />
  </svg>
);

const MoonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#FFFFFF"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm0 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
  </svg>
);

const SunToggleIcon = () => (
  <svg
    x="0"
    y="0"
    width="20"
    height="20"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="51" cy="51" r="51" fill="#F2DB10" />
    <path
      d="M34.6428 52.5109C34.575 58.7768 35.602 62.6298 37.9348 64.9626C40.2708 67.2986 44.1618 68.3572 50.5 68.3572C56.8378 68.3572 60.6929 67.2986 63.0116 64.9649C65.3317 62.6296 66.3571 58.7727 66.3571 52.5C66.3571 46.2273 65.3317 42.3705 63.0116 40.0352C60.6929 37.7014 56.8378 36.6429 50.5 36.6429C44.1626 36.6429 40.3433 37.7013 38.0421 40.0329C35.7346 42.3707 34.7108 46.2314 34.6428 52.5109Z"
      stroke="#05314D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.5 25.1071V29.3214"
      stroke="#05314D"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M50.5 75.6786V79.8929"
      stroke="#05314D"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M77.8931 52.5L73.6788 52.5"
      stroke="#05314D"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M27.3213 52.5L23.107 52.5"
      stroke="#05314D"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M29.4287 31.4286L32.4087 34.4085"
      stroke="#05314D"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M29.4287 74.444L32.4087 71.464"
      stroke="#05314D"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M72.4438 31.4286L69.4639 34.4085"
      stroke="#05314D"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M72.4438 74.444L69.4639 71.464"
      stroke="#05314D"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);
const MoonToggleIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="51" cy="51" r="51" fill="#05314D" />
    <path
      d="M75.875 61.7234L76.7995 62.1046C76.9585 61.719 76.8615 61.2753 76.5561 60.9913C76.2508 60.7072 75.8013 60.6425 75.4282 60.8288L75.875 61.7234ZM48.2829 28.12L49.0892 28.7115C49.3352 28.3761 49.3479 27.9234 49.1211 27.5747C48.8943 27.2261 48.4754 27.0542 48.0691 27.1431L48.2829 28.12ZM66.0893 63.0255C54.4056 63.0255 44.9101 53.416 44.9101 41.5325L42.9101 41.5325C42.9101 54.494 53.2746 65.0255 66.0893 65.0255L66.0893 63.0255ZM75.4282 60.8288C72.6119 62.2353 69.4428 63.0255 66.0893 63.0255L66.0893 65.0255C69.7606 65.0255 73.235 64.1597 76.3218 62.6181L75.4282 60.8288ZM74.9505 61.3423C71.4283 69.8857 63.1157 75.8749 53.4359 75.8749L53.4359 77.8749C63.9666 77.8749 72.9844 71.3585 76.7995 62.1046L74.9505 61.3423ZM53.4359 75.8749C40.5748 75.8749 30.1249 65.2975 30.125 52.22L28.125 52.22C28.1249 66.3755 39.4438 77.8749 53.4359 77.8749L53.4359 75.8749ZM30.125 52.22C30.125 40.867 38.0037 31.3935 48.4967 29.0969L48.0691 27.1431C36.6591 29.6405 28.125 39.9261 28.125 52.22L30.125 52.22ZM44.9101 41.5325C44.9101 36.7249 46.4643 32.2898 49.0892 28.7115L47.4766 27.5285C44.6072 31.44 42.9101 36.2874 42.9101 41.5325L44.9101 41.5325Z"
      fill="white"
    />
    <path
      d="M64.5937 29.8895C64.8195 29.2807 65.6805 29.2807 65.9063 29.8895L68.5817 37.1027C68.6528 37.2942 68.804 37.4452 68.9956 37.5159L76.2212 40.1833C76.8313 40.4085 76.8313 41.2714 76.2212 41.4967L68.9956 44.1641C68.804 44.2348 68.6528 44.3858 68.5817 44.5773L65.9063 51.7905C65.6805 52.3993 64.8195 52.3993 64.5937 51.7905L61.9183 44.5773C61.8472 44.3858 61.696 44.2348 61.5044 44.1641L54.2788 41.4967C53.6687 41.2714 53.6687 40.4085 54.2788 40.1833L61.5044 37.5159C61.696 37.4452 61.8472 37.2942 61.9183 37.1027L64.5937 29.8895Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);

export default LayoutComponent;
