import jwt_decode from "jwt-decode";

import {
  ITokenDetails,
  IUserAuthorizations,
  IUserTokenDetails,
} from "./asset-keycloak.interface";
import { addAuthorizations, setTokens } from "./asset-keycloak.slice";
import {
  ASSET_SCOPES,
  ASSET_SUITE_LOGIN_URL,
  CLEAR_CLIENT_SCOPES,
  COOKIE_DETAILS,
  KEYCLOAK_CLIENTS,
  KEYCLOAK_CLIENT_SECRETS,
  SESSION_KEY,
} from "../default.config";
import { store } from "../config.store";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import { cookieUtils } from "../../utils/cookie.utils";

const processToken = (
  authToken: string,
  refreshToken: string,
  sessionId: string,
  setIsLogged: any
) => {
  let decoded: any = jwt_decode(authToken);
  let resources = Object.keys(decoded.resource_access);
  let username = decoded.preferred_username;

  let cookiedata = cookieUtils.getCookie(COOKIE_DETAILS.COOKIE_NAME)!;
  let decryptedSessionThemeType = encryptUtils.decryptURL(cookiedata);

  let themeType = decryptedSessionThemeType.themeType;

  if (!resources.includes(KEYCLOAK_CLIENTS.ASSET_CONTRACT)) {
    window.location.replace(ASSET_SUITE_LOGIN_URL);
    return;
  }

  let tokenDetails: ITokenDetails = {
    auth_token: authToken,
    refresh_token: refreshToken,
    client_id: KEYCLOAK_CLIENTS.ASSET_SUITE,
    client_secret: KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
    password: "",
    username: username,
    session_id: sessionId,
  };
  store.dispatch(setTokens(tokenDetails));

  let userAuthorizations: IUserAuthorizations = {
    asset_management: false,
    asset_models: false,
    device_management: false,
    asset_suite: false,
    field_service_management: false,
    operational_dashboard: false,
    management_dashboard: false,
    asset_contract: false,
    monitoring_dashboard: false,
  };

  for (let scope of resources) {
    if (ASSET_SCOPES.includes(scope)) {
      store.dispatch(addAuthorizations(scope));

      switch (scope) {
        case KEYCLOAK_CLIENTS.ASSET_SUITE:
          userAuthorizations = { ...userAuthorizations, asset_suite: true };
          break;
        case KEYCLOAK_CLIENTS.ASSET_MANAGEMENT:
          userAuthorizations = {
            ...userAuthorizations,
            asset_management: true,
          };
          break;
        case KEYCLOAK_CLIENTS.ASSET_MODELS:
          userAuthorizations = { ...userAuthorizations, asset_models: true };
          break;
        case KEYCLOAK_CLIENTS.DEVICE_MANAGEMENT:
          userAuthorizations = {
            ...userAuthorizations,
            device_management: true,
          };
          break;
        case KEYCLOAK_CLIENTS.FIELD_SERVICE_MANAGEMENT:
          userAuthorizations = {
            ...userAuthorizations,
            field_service_management: true,
          };
          break;
        case KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD:
          userAuthorizations = {
            ...userAuthorizations,
            operational_dashboard: true,
          };
          break;
        case KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD:
          userAuthorizations = {
            ...userAuthorizations,
            management_dashboard: true,
          };
          break;
        case KEYCLOAK_CLIENTS.MONITORING_DASHBOARD:
          userAuthorizations = {
            ...userAuthorizations,
            monitoring_dashboard: true,
          };
          break;
        case KEYCLOAK_CLIENTS.ASSET_CONTRACT:
          userAuthorizations = { ...userAuthorizations, asset_contract: true };
          break;
        case CLEAR_CLIENT_SCOPES:
          let IUserAuthorizations: IUserAuthorizations = {
            asset_suite: false,
            asset_management: false,
            asset_models: false,
            device_management: false,
            field_service_management: false,
            operational_dashboard: false,
            management_dashboard: false,
            asset_contract: false,
            monitoring_dashboard: false,
          };
          userAuthorizations = IUserAuthorizations;
          break;
        default:
          // toasterUtils.showError('Unrecognized resource access : ' + scope);
          break;
      }
    }
  }

  let userTokenDetails: IUserTokenDetails = {
    auth_token: authToken,
    refresh_token: refreshToken,
    username: username,
    password: "",
    client_id: KEYCLOAK_CLIENTS.ASSET_SUITE,
    client_secret: KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
    session_id: sessionId,
    userAuthorizations: userAuthorizations,
    isLoggedIn: true,
    sourceDomain: "",
    themeType: themeType,
  };
  let cookieName = COOKIE_DETAILS.COOKIE_NAME;
  let domainName = COOKIE_DETAILS.COOKIE_DOMAIN;
  let sessionThemeType = {
    sessionId: sessionId,
    themeType: themeType,
  };

  let encryptedSessionThemeType = encryptUtils.encryptURL(sessionThemeType);
  cookieUtils.setCookie(cookieName, encryptedSessionThemeType, {
    domain: domainName,
  });

  // storing in localstorage
  const data = { token: userTokenDetails };
  sessionStorageUtils.setLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY, data);
  setIsLogged(true);
};

export const TokenServices = {
  processToken,
};
