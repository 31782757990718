import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import "./assets/styles/styles.scss"
import { MainNavigation } from './routes/routing.component';
import { useNavigate } from 'react-router-dom';
import Toaster from './modules/components/toaster';
import Loader from './modules/components/loader';
import mqtt from "precompiled-mqtt";
import { useAppDispatch, useAppSelector } from './config/store.hooks';
import { IAlert } from './common/common.interface';
import { current } from '@reduxjs/toolkit';
import { ASSET_SUITE_LOGIN_URL, COOKIE_DETAILS } from './config/default.config';
import { cookieUtils } from './utils/cookie.utils';
import { validateToken } from './config/keycloak/asset-keycloak.action';
import { encryptUtils } from './utils/encryption.utils';
import { IRecentMediaRequest } from './modules/dashboard/alarmSection/alarmSection.interface';
import { dashboardAction } from './modules/dashboard/service/dashbord.action';
import { setRecentAlarmResult, setRecentEquipmentAlarmStatus } from './common/common.reducer';
function App() {
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    (state) => state.commonReducer.isLoading
  );
  // const currentAlerts = useAppSelector(
  //   (state) => state.commonReducer.
  // );

  const theme =useAppSelector((state)=>(state.commonReducer.theme))
  const URL = "wss://172.232.67.83:30689";
  // const URL = "tcp://127.0.0.1:1883";


  const client = mqtt.connect(URL);

  client.on("connect", () => {
    client.subscribe("face-recognition-alarm", (err, data) => {
    });
  });

  client.on("message", (topic, message) => {
    const payload = message.toString();
    try {
      const parsedPayload = JSON.parse(payload);
      let recentAlarmPaginationPayload:IRecentMediaRequest={
        sortTerm: "",
        sortOrder: "DESC",
        page: 0,
        pageSize: 5
      }
      
      recentAlarm(recentAlarmPaginationPayload)
      
    } catch (error) {
      console.error("Error parsing payload:", error);
    }
  });


  client.on("connect", () => {
    client.subscribe("equipment-status", (err, data) => {
    });
  });

  client.on("message", (topic, message) => {
    const payload = message.toString();
    try {
      const parsedPayload = JSON.parse(payload);
  
      dispatch(setRecentEquipmentAlarmStatus(parsedPayload));
      
      
    } catch (error) {
      console.error("Error parsing payload:", error);
    }
  });


  const recentAlarm=async(recentAlarmPaginationPayload:IRecentMediaRequest)=>{
    const response:any=await dispatch(dashboardAction.getMediaAlaram(recentAlarmPaginationPayload));
    dispatch(setRecentAlarmResult(response.Result))
  }

  useEffect(() => {
    const validateAuth = async () => {
      let cookieName = COOKIE_DETAILS.COOKIE_NAME;      
      let encryptedCookieData = cookieUtils.getCookie(cookieName);
      let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);

      if (decryptedCookieData?.sessionId != null && decryptedCookieData?.sessionId !== undefined && decryptedCookieData?.sessionId !== "") {
        const validation = await validateToken(decryptedCookieData?.sessionId, setIsLogged);
        if (!validation) {
          setIsLogged(false);
          localStorage.clear();
          cookieUtils.deleteCookie(cookieName);
          window.location.replace(ASSET_SUITE_LOGIN_URL);
        }
      } else if (
        decryptedCookieData?.sessionId == null ||
        decryptedCookieData?.sessionId === undefined ||
        decryptedCookieData?.sessionId === ""
      ) {
        setIsLogged(false);
        localStorage.clear();
        cookieUtils.deleteCookie(cookieName);
        window.location.replace(ASSET_SUITE_LOGIN_URL);
      }
    };
    validateAuth();
  }, []);  

  // useEffect(()=>{
  //   console.log("alerts ::: ", currentAlerts);
  // },[currentAlerts])

  return (
    <div className="" id={theme ? "dark" : "light"}>
       {isLogged?<MainNavigation />:null}  
      <Toaster/>
      {isLoading && <Loader/> }
    </div>
  );
}

export default App;
