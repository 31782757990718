import React, { createContext, useState, useContext } from "react";
import { CONFIRMATION_ID } from "../../../config/default.config";
import closeIcon from "../../../assets/images/close_icon.svg";

interface IPopupContext {
  open: (
    title: string,
    message: string,
    submitBtnTitle: string,
    cancelBtnTitle: string,
    onSuccess: () => void,
    onCancel: () => void,
    messageType?:string,
   
  ) => void;
  close: () => void;
}

interface IState {
  isOpen: boolean;
  title: string;
  message: string;
  submitBtnTitle: string;
  cancelBtnTitle: string;
  onSuccess: (() => void) | null;
  onCancel: (() => void) | null;
  messageType?:string,
}

const PopupContext = createContext<IPopupContext | undefined>(undefined);

export const useConfirmationPopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error("HOOK ERROR");
  }
  return context;
};

export const PopupProvider = ({ children }: any) => {
  const [state, setState] = useState<IState>({
    isOpen: false,
    title: "",
    message: "",
    submitBtnTitle: "Ok",
    cancelBtnTitle: "Cancel",
    onSuccess: null,
    onCancel: null,
    messageType:"",
  });

  const open = (
    title: string,
    message: string,
    submitBtnTitle: string,
    cancelBtnTitle: string,
    onSuccess: () => void,
    onCancel: () => void,
    messageType?:string
  ) => {
    setState({
      isOpen: true,
      title,
      message,
      submitBtnTitle,
      cancelBtnTitle,
      onSuccess,
      onCancel,
      messageType
    });
  };

  const close = () => {
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  return (
    <PopupContext.Provider value={{ open, close }}>
      {children}
      {state.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50  popup-overlay">
          <div
            className="modal-bg"
            onClick={() => {
              if (state.onCancel) {
                state.onCancel();
              }
            }}
          ></div>
          <div className="modal p-6 bg-white rounded shadow-lg z-50 poup-confirmation-w-300 dark:status-tile-container">
            <div className="flex justify-between mb-4">
              <h2 className="text-xl font-bold dark:head-text-color">{state.title}</h2>
              <button
                className=""
                onClick={() => {
                  if (state.onCancel) {
                    state.onCancel();
                  }
                }}
              >
            <img className="w-7" src={closeIcon} alt="close" />
              </button>
            </div>
            <div className="modal-content">{state.message}</div>
            <div className="flex justify-end mt-4">
              <button
                className="btn btn-primary w-6/12"
                onClick={() => {
                  if (state.onSuccess) {
                    state.onSuccess();
                  }
                }}
              >
                {state.submitBtnTitle}
              </button>
              {
              state.messageType!==CONFIRMATION_ID.SESSION_ID?<button
                className="btn btn-secondary ml-2 w-6/12"
                onClick={() => {
                  if (state.onCancel) {
                    state.onCancel();
                  }
                }}
              >
                {state.cancelBtnTitle}
              </button>:null
              }
              
            </div>
          </div>
        </div>
      )}
    </PopupContext.Provider>
  );
};
