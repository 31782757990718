import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/Delete_icon.svg";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import noImageIcon from "../../assets/images/No-image.jpg";
import {
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_DIALOUGES,
  CONFIRMATION_TITLES,
  DEVICE_ERROR_CODES,
  DEVICE_MESSAGES,
  ERROR,
  NAVIGATIONS,
  PROTOCOLS,
  SESSION_DETAILS,
  SUBMIT_BUTTON_TEXT,
} from "../../config/default.config";
import Tooltip from "@mui/material/Tooltip";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import { deviceActions } from "./services/device.actions";
import { useParams } from "react-router-dom";
import { IDevice } from "./device.interface";
import { setDevice } from "./store/device.reducer";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";
import {
  setIsError,
  setIsLoading,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { encryptUtils } from "../../utils/encryption.utils";

function ViewDevice() {
  const [ready, setReady] = useState<boolean>(false);
  const navigate = useNavigate();
  const { open, close } = useConfirmationPopup();
  const dispatch = useAppDispatch();
  const deviceFromStore = useAppSelector(
    (state) => state.deviceReducer.deviceDetails
  );
  const { id } = useParams();
  const handleNavigateBack = () => {
    navigate(NAVIGATIONS.DEVICE_LIST);
    let emptyDevice: IDevice = {
      id: 0,
      name: "",
      imei: 0,
      deviceType: {
        id: 0,
        name: "",
        description: "",
      },
      sensorProtocol: 0,
      sensorIP: "",
      sensorPort: "",
      isCameraAdded: false,
      cameraIP: "",
      cameraPort: "",
      cameraProtocol: 0,
      cameraFrameRate: "",
      cameraResolution: "",
      images: [],
    };

    dispatch(setDevice(emptyDevice));
  };

  const [deviceDetails, setDeviceDetails] = useState<IDevice>();

  const setSuccessForDeviceFetching = (state: boolean) => {
    dispatch(setIsLoading(false));
    if (state) {
      setReady(true);
    }
  };

  const setSuccessForDeviceDelete = (state: boolean, errorCode: any) => {
    dispatch(setIsLoading(false));
    if (state && errorCode === null) {
      dispatch(setToastMsg(DEVICE_MESSAGES.DEVICE_DELETE_SUCCESS));
      dispatch(setToast(true));
      handleNavigateBack();
      handleClosePopup();
    } else {
      if (
        !state &&
        errorCode != null &&
        errorCode === DEVICE_ERROR_CODES.DEVICE_DELETE_ERROR_CODE
      ) {
        dispatch(setToastMsg(DEVICE_MESSAGES.DEVICE_UNABLE_DELETE));
        dispatch(setIsError(true));
        dispatch(setToast(true));
      } else {
        dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
        dispatch(setIsError(true));
        dispatch(setToast(true));
      }

      handleClosePopup();
    }
  };

  const getDeviceDetails = async () => {
    dispatch(setIsLoading(true));
    dispatch(
      deviceActions.getDeviceById(
        Number(encryptUtils.decryptURL(id)),
        setSuccessForDeviceFetching
      )
    );
  };

  const handleEditClick = () => {
    navigate(
      NAVIGATIONS.DEVICE_UPDATE.replace(
        ":id",
        encryptUtils.encryptURL(encryptUtils.decryptURL(id))
      )
    );
  };

  const handleDeleteConfirmation = (confirm: boolean) => {
    if (confirm) {
      dispatch(setIsLoading(true));
      deviceActions.deleteDeviceById(
        deviceFromStore?.id,
        setSuccessForDeviceDelete
      );
    } else {
      handleClosePopup();
    }
  };

  const handleClosePopup = () => {
    close();
  };

  const handleOpenPopup = (confirmation: any) => {
    open(
      CONFIRMATION_TITLES.CONFIRM_DELETE,
      CONFIRMATION_DIALOUGES.DEVICE_DELETE_CONFIRMATION.replace(
        ":deviceName",
        deviceFromStore?.name
      ),
      SUBMIT_BUTTON_TEXT.YES,
      CANCEL_BUTTON_TEXT.NO,
      () => {
        confirmation(true);
      },
      () => {
        confirmation(false);
      }
    );
  };

  useEffect(() => {
    getDeviceDetails();
  }, []);

  useEffect(() => {
    setDeviceDetails(deviceFromStore);
  }, [deviceFromStore]);

  if (!ready) {
    return null;
  }

  return (
    <div className="inner-page-row">
      <div className="grid md:grid-cols-3 gap-28 pt-3">
        <div className="col-span-2 box-border">
          <div className="flex justify-between">
            <div className=" flex text-lg">
              <button
                type="button"
                className="mr-3"
                onClick={handleNavigateBack}
              >
                <img src={backBtnIcon} alt="" className="p-1" />
              </button>
              <div className=" font-bold p-1 text-gray-600 mt-1">Device</div>
              <div className="p-1 text-gray-500 mt-1">Status</div>
            </div>
            {/* <div
              className=" text-blue-600 p-2 text-sm cursor-pointer mt-1"
              onClick={() => handleNavigateBack()}
            >
              View More
            </div> */}
          </div>
          <div className=" bg-white rounded-3xl shadow-md mt-4">
            <div className="p-10 ">
              <div className=" flex justify-between">
                <div className="flex">
                  <div className=" inline p-2 text-gray-500">
                    <b>Device</b>
                  </div>
                  <Tooltip title="Edit" placement="top">
                    <div
                      className=" inline p-2 cursor-pointer"
                      onClick={() => {
                        handleEditClick();
                      }}
                    >
                      <img className="w-7" src={editIcon} alt="edit" />
                    </div>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <div
                      className=" inline p-2 cursor-pointer"
                      onClick={() => {
                        handleOpenPopup(handleDeleteConfirmation);
                      }}
                    >
                      <img className="w-7" src={deleteIcon} alt="delete" />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="sm:block md:flex py-3">
                <div className="rounded-xl">
                  <img
                    style={{ width: 100, height: 100 }}
                    src={
                      deviceFromStore?.images[0]
                        ? `${deviceFromStore?.images[0]?.resource}`
                        : noImageIcon
                    }
                    alt="noImage icon"
                    className="border border-gray h-32 rounded-2xl"
                  />
                </div>
                <div className="px-8">
                  <div className="font-bold">{deviceDetails?.name}</div>
                  <div className="flex pt-3">
                    <div className=" pr-3  text-gray-400">
                      IMEI : <b>{deviceDetails?.imei}</b>
                    </div>
                  </div>
                  <div className="text-gray-500">
                    Device Type : <b>{deviceDetails?.deviceType?.name}</b>
                  </div>
                </div>
              </div>
              <hr className="flex-grow" />
              <div className="text-gray-500 text-md font-bold mt-2">Sensor</div>
              <div className="flex pt-3">
                <div className=" pr-3  text-gray-400">
                  Protocol :{" "}
                  <b>
                    {deviceDetails?.sensorProtocol === PROTOCOLS.TCP.id
                      ? PROTOCOLS.TCP.name
                      : deviceDetails?.sensorProtocol === PROTOCOLS.UDP.id
                      ? PROTOCOLS.UDP.name
                      : ""}
                  </b>
                </div>
              </div>
              <div className="flex">
                <div className=" pr-3  text-gray-400">
                  IP Address / Hostname : <b>{deviceDetails?.sensorIP}</b>
                </div>
              </div>
              <div className="flex pb-8">
                <div className=" pr-3  text-gray-400">
                  Port :<b> {deviceDetails?.sensorPort}</b>
                </div>
              </div>
              <hr className="flex-grow" />
              <div className="text-gray-500 text-md font-bold mt-2">Camera</div>
              <div className="flex pt-3">
                <div className=" pr-3  text-gray-400">
                  Protocol :{" "}
                  <b>
                    {deviceDetails?.cameraProtocol === PROTOCOLS.TCP.id
                      ? PROTOCOLS.TCP.name
                      : deviceDetails?.cameraProtocol === PROTOCOLS.UDP.id
                      ? PROTOCOLS.UDP.name
                      : ""}
                  </b>
                </div>
              </div>
              <div className="flex">
                <div className=" pr-3  text-gray-400">
                  IP Address / Hostname : <b>{deviceDetails?.cameraIP}</b>
                </div>
              </div>
              <div className=" pr-3  text-gray-400">
                Port : <b>{deviceDetails?.cameraPort}</b>
              </div>
              <div className=" pr-3  text-gray-400">
                Frame Rate: <b>{deviceDetails?.cameraFrameRate}</b>
              </div>
              <div className=" pr-3  text-gray-400">
                Resolution :<b> {deviceDetails?.cameraResolution}</b>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          Operation Hours
          <div className=" bg-white rounded-3xl shadow-md mt-8 h-24"></div>
        </div> */}
      </div>
    </div>
  );
}

export default ViewDevice;
