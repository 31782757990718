import { Tooltip } from "@mui/material";
import PaginatedItems from "../components/pagination.component";
import SortingIcon from "../../assets/images/sorting_icon.svg";
import { useNavigate } from "react-router-dom";
import {
  NAVIGATIONS,
  CONTRACT_MESSAGE,
  ERROR,
} from "../../config/default.config";
import { useEffect, useState } from "react";
import {
  IContractItem,
  IContractListRequest,
  IPaginationPayload,
} from "./contract.interface";
import { contractAction } from "./service/contract.action";
import noImageIcon from "../../assets/images/No-image.jpg";
import SortingIconDesc from "../../assets/images/sorting_icon_down.svg";
import moment from "moment";
import { encryptUtils } from "../../utils/encryption.utils";
import { useAppDispatch } from "../../config/store.hooks";
import {
  setIsError,
  setIsLoading,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import NoDataFound from "../components/noDataFound";

export function ContractList() {
  const navigate = useNavigate();
  const [formattedContractList, setFormattedContractList] = useState<
    IContractItem[]
  >([]);
  const [paginationPayload, setPaginationPayload] =
    useState<IPaginationPayload>({
      page: 1,
      pageSize: 16,
      orderByDesc: true,
      orderBy: "Name",
      searchKey: "",
    });
  const handleAddContractClick = () => {
    navigate(NAVIGATIONS.CONTRACT_ADD);
  };
  const [totalRecords, setTotalRecords] = useState();
  const dispatch = useAppDispatch();
  const getContract = async (ContractListAllRequest: IContractListRequest) => {
    dispatch(setIsLoading(true));
    const response = await contractAction.getAllContract(
      ContractListAllRequest,
      setSuccess
    );
    setTotalRecords(response?.totalItems);

    let tempContractItemList: IContractItem[] = [];
    response?.data?.map((contracts: any) => {
      let ContractItem: IContractItem = {
        customerId: contracts?.customerId,
        duration: contracts?.duration,
        durationPeriod: contracts?.durationPeriod,
        endDate: contracts?.endDate,
        equipmentIds: contracts?.equipmentIds,
        id: contracts?.id,
        name: contracts?.name,
        operatorIds: contracts?.operatorIds,
        siteId: contracts?.siteId,
        startDate: contracts.startDate,
      };

      tempContractItemList.push(ContractItem);
    });
    setFormattedContractList(tempContractItemList);
  };

  const setSuccess = (status: boolean) => {
    dispatch(setIsLoading(false));
    if (!status) {
      dispatch(setToast(true));
      dispatch(setIsError(true));
      dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
    }
  };

  const getContractList = (pagenumber: number) => {
    setPaginationPayload({
      ...paginationPayload,
      page: pagenumber,
    });

    let ContractListAllRequest: IContractListRequest = {
      Page: paginationPayload.page,
      PageSize: paginationPayload.pageSize,
      OrderByDesc: paginationPayload.orderByDesc,
      OrderBy: paginationPayload.orderBy,
      SearchKey: paginationPayload.searchKey,
    };
    getContract(ContractListAllRequest);
  };

  useEffect(() => {
    let ContractListAllRequest: IContractListRequest = {
      Page: paginationPayload.page,
      PageSize: paginationPayload.pageSize,
      OrderByDesc: paginationPayload.orderByDesc,
      OrderBy: paginationPayload.orderBy,
      SearchKey: paginationPayload.searchKey,
    };
    getContract(ContractListAllRequest);
  }, [
    paginationPayload.page,
    paginationPayload.pageSize,
    paginationPayload.orderByDesc,
    paginationPayload.orderBy,
    paginationPayload.searchKey,
  ]);

  const onPaginationChange = (
    curretdata: any,
    itemOffset: any,
    endOffset: any
  ) => {
    setFormattedContractList(curretdata);
  };

  const handleOrderBy = () => {
    setPaginationPayload({
      ...paginationPayload,
      orderByDesc: !paginationPayload.orderByDesc,
    });
  };

  const handleSearchKey = (event: any) => {
    setPaginationPayload({
      ...paginationPayload,
      searchKey: event.target.value,
    });
  };

  const navigateToContractById = (id: any) => {
    navigate(
      NAVIGATIONS.CONTRACT_VIEW.replace(":id", encryptUtils.encryptURL(id))
    );
  };
  return (
    <div className="p-10 inner-page-row">
      <div className="inline-block w-[100%] mt-5">
        <div className="float-left">
          <h2 className="text-xl">
            <b className="dark:head-text-color">Contract</b>
          </h2>
        </div>
      </div>
      <div className="top-bar-sticky dark:search-bar-bg-color">
        <div className="flex flex-wrap items-center">
          <div className="flex-1 w-100">
            <input
              type="text"
              name="searchKey"
              id="searchKey"
              className="border rounded-lg border-gray-300 p-2 placeholder-gray-200  text-slate-500 w-[100%] cursor-pointer dark:normal-text-color"
              placeholder="Search Contract"
              onChange={handleSearchKey}
            />
          </div>
          <div className="flex-2 w-100">
            <Tooltip title="Filter" placement="top">
              <select
                id="sortKey"
                name="sortKey"
                className=" dark:panel-bg-color dark:normal-text-color border rounded-lg border-gray-300 p-2 placeholder-gray-200  text-slate-500  h-10 w-40 mx-6 bg-white pl-4 pr-4 text-sm font-thin focus:outline-none focus:border-red-500 cursor-pointer"
                onChange={(e) => {}}
              >
                <option value="Name">Name</option>
              </select>
            </Tooltip>
          </div>
          <div className="flex-none  cursor-pointer">
            <Tooltip
              title={
                paginationPayload.orderByDesc ? "Sort by asc" : "Sort by desc"
              }
              placement="top"
            >
              <img
                src={
                  paginationPayload.orderByDesc ? SortingIcon : SortingIconDesc
                }
                alt="logo"
                onClick={handleOrderBy}
              ></img>
            </Tooltip>
          </div>
          <div className="flex-none ml-2 cursor-pointer">
            <button
              className=" py-2 btn btn-primary"
              onClick={() => handleAddContractClick()}
            >
              <div>Add</div>
            </button>
          </div>
        </div>
      </div>
      {formattedContractList && formattedContractList.length > 0 ? (
        <div>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 content-start mb-5 inner-page-list">
            {formattedContractList?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="transform cursor-pointer hover:scale-105 transition duration-75 active:bg-blue-100 rounded-xl place-content-center bg-white xs:w-full shadow-1xl mt-5 mr-5 border-dashed border-1 border-gray-500 dark:tile-bg-color"
                  onClick={() => {
                    navigateToContractById(item.id);
                  }}
                >
                  <div className=" flex justify-center flex-col p-[10px] sm:p-[20px] md:p-[30px]">
                    <div className="flex flex-wrap content-start ">
                      <img
                        style={{ width: 60, height: 60 }}
                        src={noImageIcon}
                        alt="logo"
                      ></img>
                      <div className="ml-5 cursor-pointer">
                        <b className="dark:head-text-color">{item?.name}</b>
                        {item?.duration !== 0 ? (
                          <p className="text-xs cursor-pointer pt-1">
                            Duration: {item?.duration} {item?.durationPeriod}
                          </p>
                        ) : (
                          <div>
                            <p className="text-xs cursor-pointer pt-1">
                              Start Date:{" "}
                              {moment(item?.startDate).format("YYYY-MM-DD")}
                            </p>
                            <p className="text-xs cursor-pointer pt-1">
                              End Date:{" "}
                              {moment(item?.endDate).format("YYYY-MM-DD")}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <PaginatedItems
            itemsPerPage={paginationPayload.pageSize}
            totalRecords={totalRecords}
            onPagination={onPaginationChange}
            reRenderDevices={getContractList}
          />
        </div>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
}
