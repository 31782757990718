import React, { useEffect, useState } from "react";
import {
  IEquipmentListerItem,
  IListerDetails,
  IListerItem,
  IListerPopupState,
} from "./lister.component-interface";
import editIcon from "../../../assets/images/edit.svg";
import { LISTER_HEADINGS } from "../../../config/default.config";
import ListerPopup from "./lister.popup";

function ListerComponentContext({
  listerDetails,
  datas,
  setDatas,
  equipmentModel,
  equipmentListData,
  setEquipmentListData
}: {
  listerDetails: IListerDetails;
  datas: IListerItem[];
  setDatas: any;
  equipmentListData:IEquipmentListerItem[],
  setEquipmentListData:any
  equipmentModel?:string
}) {
  const [popUpPayload, setPopUpPayload] = useState<IListerPopupState>({
    isOpen: false,
    isEquipmentList: false,
    isOperatorList: false,
  });
  const [renderData, setRenderData] = useState<IListerItem[]>([]);

  const handleEditClick = (listData: string) => {
    let payload: IListerPopupState = {
      isOpen: true,
      isEquipmentList: listerDetails.isEquipmentList,
      isOperatorList: listerDetails.isOperatorList,
    };
    setPopUpPayload({ ...popUpPayload, ...payload });
  };

  const handleDeselectItem = (item: IListerItem) => {
    setDatas((prevDatas: IListerItem[]) =>
      prevDatas.filter((dataItem: IListerItem) => dataItem !== item)
    );
  };

  useEffect(() => {
    if (listerDetails.isEquipmentList) {
      const equipmentData: any = equipmentListData.filter(
        (dataItem: any) => dataItem.isEquipmentList === true
      );
      setRenderData(equipmentData);
    } else if (listerDetails.isOperatorList) {
      const operatorData: IListerItem[] = datas.filter(
        (dataItem: IListerItem) => dataItem.isOperatorList === true
      );
      setRenderData(operatorData);
    }
  }, [datas,equipmentListData]);

  return (
    <div className="pl-10 pb-6 grid grid-cols-1">
      <div className="ml-12 grid grid-cols-1 gap-x-6">
        <div className="">
          <div className="flex justify-between items-center bg-gray-3 border rounded-md p-3 dark:bg-[#474b52]">
            <h2 className="font-bold p-2 pl-5 truncate text-gray dark:head-text-color">
              {listerDetails.isEquipmentList
                ? LISTER_HEADINGS.EQUIPMENT_LIST
                : LISTER_HEADINGS.OPERATOR_LIST}
            </h2>
            <div
              className="flex items-center"
              onClick={() => {
                handleEditClick(
                  listerDetails.isEquipmentList
                    ? LISTER_HEADINGS.EQUIPMENT_LIST
                    : LISTER_HEADINGS.OPERATOR_LIST
                );
              }}
            >
         <button type="button" className="px-3  text-sm font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            <img
                src={editIcon}
                alt="Edit icon"
                className="w-6 h-10 rounded-full mr-2"
              />
          Edit
          </button>
            </div>
            
          </div>
           <div className=" bg-gray-100 p-8 h-[25vh]  dark:bg-[#3e424a]">

            {
              listerDetails.isOperatorList?   <div className="overflow-scroll text-gray space-y-2 h-36">
              {renderData.length > 0 ? (
                <>
                  {renderData.map((item: IListerItem, index) => (
                    <label className="flex items-center space-x-2" key={item.id}>
                      {/* <input
                        type="checkbox"
                        className="form-checkbox"
                        defaultChecked={true}
                        onClick={() => {
                          handleDeselectItem(item);
                        }}
                      /> */}
                      <span><li  className="dark:normal-text-color">{item.name}</li></span>
                    </label>
                  ))}
                </>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p className="text-center">
                    {`Select your ${
                      listerDetails.isEquipmentList ? "Equipment" : "Operator"
                    }`}
                  </p>
                </div>
              )}
            </div>:
            <div className="overflow-scroll text-gray space-y-2 h-36">
            {renderData.length > 0 ? (
              <>
                {renderData.map((item: any, index) => (
                  <label className="flex items-center space-x-2" key={item?.insatnceId}>
                    <span><li  className="dark:normal-text-color">{item?.instanceName?item.instanceName:item?.insatnceId}</li></span>
                  </label>
                ))}
              </>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-center">
                  {`Select your ${
                    listerDetails.isEquipmentList ? "Equipment" : "Operator"
                  }`}
                </p>
              </div>
            )}
          </div>
            }
       
          </div>   

        </div>
      </div>
      <ListerPopup
        listerPopupState={popUpPayload}
        setPopUpPayload={setPopUpPayload}
        datas={datas}
        setDatas={setDatas}
        listerDetails={listerDetails}
        equipmentModel={equipmentModel} 
        equipmentListData={equipmentListData} 
        setEquipmentListData={setEquipmentListData}/>
    </div>
  );
}

export default ListerComponentContext;
