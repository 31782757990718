import { ACTIONS } from "../../../config/default.config";
import { AppThunk } from "../../../config/store.hooks";
import {
  IContractListRequest,
  IContractParameter,
} from "../contract.interface";
import { setContractdetails } from "../store/contract.reducer";
import { contractService } from "./contract.service";

const addContract = (
  paramData: IContractParameter,
  setSuccess: any
): AppThunk => {
  return async () => {
    try {
      const response = await contractService.addContract(paramData);
      if (response?.status === 200 || response?.status === 201) {
        setSuccess(true, ACTIONS.CREATE);
      } else {
        setSuccess(false, ACTIONS.CREATE);
      }
    } catch (error) {
      setSuccess(false, ACTIONS.CREATE);
    }
  };
};

const getSiteContracts = async () => {
  try {
    const response = await contractService.getSiteContract();
    return response?.data;
  } catch (error) {
    return null;
  }
};

const getCustomerContract = async () => {
  try {
    const response = await contractService.getCustomerContract();
    return response?.data;
  } catch (error) {
    return null;
  }
};

const getAllContract = async (
  ContractListAllRequest: IContractListRequest,
  setSuccess: any
) => {
  try {
    const response = await contractService.getAllContract(
      ContractListAllRequest
    );
    if (response?.status === 200) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }

    return response?.data;
  } catch (error) {
    setSuccess(false);
    return null;
  }
};
const getContractByid = (id: number, setSuccess: any): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await contractService.contractById(id);

      dispatch(setContractdetails({ ...response?.data }));
      setSuccess(true);
      return response?.data;
    } catch (error) {
      setSuccess(false);
      return null;
    }
  };
};

export const deleteContractById = async (
  contractId: number,
  setSuccess: any
) => {
  try {
    const response = await contractService.deleteContractById(contractId);

    if (response?.success) {
      setSuccess(true, null);
    } else {
      setSuccess(false, null);
    }
  } catch (err: any) {
    setSuccess(false, err?.response?.data?.errorCode);
  }
};

export const updateContract = (paramData: any, setSuccess: any) => {
  return async () => {
    try {
      const response = await contractService.updateContract(paramData);
      if (response?.success) {
        setSuccess(true, ACTIONS.UPDATE);
      } else {
        setSuccess(false, ACTIONS.UPDATE);
      }
      return response;
    } catch (error) {
      setSuccess(false, ACTIONS.UPDATE);
      return null;
    }
  };
};
export const contractAction = {
  addContract,
  getSiteContracts,
  getCustomerContract,
  getAllContract,
  getContractByid,
  deleteContractById,
  updateContract,
};
