
import React, { useEffect, useState } from "react";
import HistoryTable from "./historyTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { HISTORY_TYPE, NAVIGATIONS } from "../../../config/default.config";
import backBtnIcon from "../../../assets/images/Back_icon.svg";
import { encryptUtils } from "../../../utils/encryption.utils";

function HistoryLayout() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [historyType, setHistoryType] = useState<string>("");
  const [tableTitle, setTableTitle] = useState<string>("");

  const handleNavigateBack = () => {
         
    if (location.pathname.includes(HISTORY_TYPE.EQUIPMENTS)) {
      navigate(
        NAVIGATIONS.EQUIPMENT_VIEW.replace(
          ":id",
          encryptUtils.encryptURL(encryptUtils.decryptURL(id))
        )
      );
    }else if (location.pathname.includes(HISTORY_TYPE.CUSTOMERS)){
      navigate(
        NAVIGATIONS.CUSTOMER_VIEW.replace(
          ":id",
          encryptUtils.encryptURL(encryptUtils.decryptURL(id))
        )
      );
    }else if (location.pathname.includes(HISTORY_TYPE.OPERATORS)){
      navigate(
        NAVIGATIONS.OPERATOR_VIEW.replace(
          ":id",
          encryptUtils.encryptURL(encryptUtils.decryptURL(id))
        )
      );
    }else if (location.pathname.includes(HISTORY_TYPE.SITES)){
      navigate(
        NAVIGATIONS.SITE_VIEW.replace(
          ":id",
          encryptUtils.encryptURL(encryptUtils.decryptURL(id))
        )
      );
    }
  };

  useEffect(() => {
    if (location.pathname.includes(HISTORY_TYPE.EQUIPMENTS)) {
      setHistoryType(HISTORY_TYPE.EQUIPMENTS);
      setTableTitle("Equipment History");
    } else if (location.pathname.includes(HISTORY_TYPE.CUSTOMERS)) {
      setHistoryType(HISTORY_TYPE.CUSTOMERS);
      setTableTitle("Customer History");
    } else if (location.pathname.includes(HISTORY_TYPE.OPERATORS)) {
      setTableTitle("Operators History");
      setHistoryType(HISTORY_TYPE.OPERATORS);
    }else if (location.pathname.includes(HISTORY_TYPE.SITES)) {
      setTableTitle("Sites History");
      setHistoryType(HISTORY_TYPE.SITES);
    }
  }, [location]);

  return (
    <>
      <div className="inner-page-row">
        <div className="col-span-2 box-border">
          <div className="flex justify-between">
            <div className=" flex text-lg">
              <button type="button" className="" onClick={handleNavigateBack}>
                <img src={backBtnIcon} alt="" className="p-1" />
              </button>
              <div className="mt-2">
                <h2 className="text-xl">
                  <b className="ml-5 dark:head-text-color">{tableTitle}</b>
                </h2>
              </div>
            </div>
          </div>

          <HistoryTable historyType = {historyType} />
        </div>
      </div>
    </>
  );
}

export default HistoryLayout;
