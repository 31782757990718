import { SITE_API } from "../../../config/default.config";
import { commonUtils } from "../../../utils/common.utils";
import axiosInstance from "../../../utils/intercepter.util";
import { IHistoryRequest } from "../../components/history.component/history.component.interface";
import { ISiteListRequest } from "../site.interface";

const listAllSite = async (SiteListAllRequest: ISiteListRequest) => {
  const response = await axiosInstance.get(
    SITE_API.GET_ALL_SITE.replace(":page", `${SiteListAllRequest.Page}`)
      .replace(":pageSize", `${SiteListAllRequest.PageSize}`)
      .replace(":orderByDesc", `${SiteListAllRequest.OrderByDesc}`)
      .replace(":orderBy", SiteListAllRequest.OrderBy)
      .replace(":searchKey",SiteListAllRequest.SearchKey)
  );
  return response.data;
};

const listSiteById = async (id: number) => {
  const response = await axiosInstance.get(
    commonUtils.formatString(SITE_API.GET_SITE_BY_ID, id)
  );
  return response;
};

const addSite = async (paramData: any) => {
  const response = await axiosInstance.post(SITE_API.ADD_SITE_API, paramData);
  return response;
};


const updateSite=async(paramData:any)=>{

  const response =await axiosInstance.put(SITE_API.UPDATE_SITE_API,paramData)
  return response;

}

const deleteSite=async(siteId:number)=>{

  const response=await axiosInstance.delete(SITE_API.DELETE_SITE_API.replace(":id",`${siteId}`))
  return response.data

}

const getSitesHistory = async (
  historyRequest: IHistoryRequest,
) => {
  const response = await axiosInstance.get(
    SITE_API.GET_SITES_HISTORY.replace(
      ":page",
      `${historyRequest.page}`
    )
      .replace(":pageSize", `${historyRequest.pageSize}`)
      .replace(":orderByDesc", `${historyRequest.orderByDesc}`)
      .replace(":orderBy", historyRequest.orderBy)
      .replace(":searchKey", historyRequest.searchKey)
      .replace(":id", historyRequest.id)
  );
  console.log("HEREE ::: ", response);
  return response;
};



export const siteService = {
  listAllSite,
  listSiteById,
  addSite,
  updateSite,
  deleteSite,
  getSitesHistory,
};
