import React from "react";
import { IConfirmationDialougeReq } from "../config/commons.interface";

function ConfirmationDialouge({
  isOpen,
  onClose,
  onConfirm,
  dialouge,
}: IConfirmationDialougeReq) {
  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 bg-white p-6 pt-12 w-[30%] h-[20%] rounded-lg shadow-md">
            <p>{dialouge}</p>
            <div className="mt-4 flex justify-end">
              <button
                className="px-4 py-2 mr-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold rounded"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded"
                onClick={onConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfirmationDialouge;
