import React from "react";
import {
  DEVICE_API_ENDPOINTS,
  EQUIPMENT_API,
  NAVIGATIONS,
  SEQROPS_INSTANCE_API,
} from "../../../config/default.config";
import { commonUtils } from "../../../utils/common.utils";
import axiosInstance from "../../../utils/intercepter.util";
import { async } from "q";
import { IEquipmentListRequest } from "../equipment.interface";
import { IHistoryRequest } from "../../components/history.component/history.component.interface";

const addEquipment = async (formData: FormData) => {
  const response = await axiosInstance.post(
    EQUIPMENT_API.ADD_EQUIPMENT,
    formData
  );
  return response;
};
const updateEquipment = async (formData: FormData) => {
  const response = await axiosInstance.put(
    EQUIPMENT_API.UPDATE_EQUIPMENT,
    formData
  );
  return response;
};
const deleteEquipment = async (id: number) => {
  const response = await axiosInstance.delete(
    EQUIPMENT_API.DELETE_EQUIPMENT.replace(":id", `${id}`)
  );
  return response;
};

const listAllEquipments = async (
  EquipmentListAllRequest: IEquipmentListRequest
) => {
  const response = await axiosInstance.get(
    EQUIPMENT_API.GET_ALL_EQUIPMENT.replace(
      ":page",
      `${EquipmentListAllRequest.Page}`
    )
      .replace(":pageSize", `${EquipmentListAllRequest.PageSize}`)
      .replace(":orderByDesc", `${EquipmentListAllRequest.OrderByDesc}`)
      .replace(":orderBy", EquipmentListAllRequest.OrderBy)
      .replace(":searchKey", EquipmentListAllRequest.SearchKey)
  );

  return response;
};

const getAllModels = async () => {
  const response = await axiosInstance.get(
    SEQROPS_INSTANCE_API.GET_ALL_EQUIPMENT
  );
  return response;
};

const getAllInstance=async(InstanceRequsetList:any)=>{
  const response =await axiosInstance.post(SEQROPS_INSTANCE_API.GET_ALL_INSTANCE,InstanceRequsetList)
return response
}

const listEquipmentById = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      commonUtils.formatString(EQUIPMENT_API.GET_EQUIPMENT_BY_ID, id)
    );
    return response;
  } catch (error) {
    console.error(
      "ERROR_IN_FETCHING_EQUIPMENT_DATA_BY_ID_IN_EQUIPMENT_SERVICE :::",
      error
    );
    throw error;
  }
};

const equipmentDevices = async () => {
  const response = await axiosInstance.get(
    DEVICE_API_ENDPOINTS.EQUIPMENT_DEVICE
  );
  return response?.data;
};

const getEquipmentHistory = async (
  historyRequest: IHistoryRequest
) => {
  const response = await axiosInstance.get(
    EQUIPMENT_API.GET_EQUIPMENT_HISTORY.replace(
      ":page",
      `${historyRequest.page}`
    )
      .replace(":pageSize", `${historyRequest.pageSize}`)
      .replace(":orderByDesc", `${historyRequest.orderByDesc}`)
      .replace(":orderBy", historyRequest.orderBy)
      .replace(":searchKey", historyRequest.searchKey)
      .replace(":id", historyRequest.id)
  );
  // return response.data;
  return response;
};

export const equipmentServices = {
  addEquipment,
  updateEquipment,
  deleteEquipment,
  listAllEquipments,
  listEquipmentById,
  equipmentDevices,
  getEquipmentHistory,
  getAllModels,
  getAllInstance
};
