import { AppThunk } from "../../../config/store.hooks";
import { processFiles } from "../../../utils/file-processor";
import {
  IAddOrUpdateOperatorRequest,
  IOperatorListRequest,
  OPERATOR_OPERATIONS,
} from "../operator.interface";
import { setOperator } from "../store/operator.reducer";
import { operartorService } from "./operator.services";

export const addOperator = (
  IAddOrUpdateOperatorRequest: IAddOrUpdateOperatorRequest,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    const formData = new FormData();
    formData.append("id", String(IAddOrUpdateOperatorRequest.id));
    formData.append("name", IAddOrUpdateOperatorRequest.name);
    formData.append("mobile", String(IAddOrUpdateOperatorRequest.mobile));
    formData.append("location", IAddOrUpdateOperatorRequest.location);
    formData.append("address", IAddOrUpdateOperatorRequest.address);

    IAddOrUpdateOperatorRequest.images.forEach((item, index) => {
      for (let key in item) {
        formData.append(`images[${index}].${key}`, item[key]);
      }
    });

    try {
      const response = await operartorService.addOperator(formData);
      if (response?.status === 200 || response?.status === 201) {
        setSuccess(true, OPERATOR_OPERATIONS.CREATE, null);
      } else {
        setSuccess(false, null);
      }
    } catch (err: any) {
      let errorCode = err?.response?.data?.errorCode;
      setSuccess(false, OPERATOR_OPERATIONS.CREATE, errorCode);
      return null;
    }
  };
};

export const updateOperator = (
  addOrUpdateOperatorRequest: IAddOrUpdateOperatorRequest,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    const formData = new FormData();
    formData.append("id", String(addOrUpdateOperatorRequest.id));
    formData.append("name", addOrUpdateOperatorRequest.name);
    formData.append("mobile", String(addOrUpdateOperatorRequest.mobile));
    formData.append("location", addOrUpdateOperatorRequest.location);
    formData.append("address", addOrUpdateOperatorRequest.address);
    addOrUpdateOperatorRequest.images.forEach((item, index) => {
      for (let key in item) {
        formData.append(`images[${index}].${key}`, item[key]);
      }
    });

    try {
      const response = await operartorService.updateOperator(formData);
      if (response?.status === 200 || response?.status === 201) {
        setSuccess(true, OPERATOR_OPERATIONS.UPDATE, null);
      } else {
        setSuccess(false, OPERATOR_OPERATIONS.UPDATE, null);
      }
    } catch (err: any) {
      let errorCode = err?.response?.data?.errorCode;
      setSuccess(false, OPERATOR_OPERATIONS.UPDATE, errorCode);
      return null;
    }
  };
};

export const getOperatorList = async (
  operatorAddRequest: IOperatorListRequest,
  setSuccess: any
) => {
  try {
    const response = await operartorService.getOperatorList(operatorAddRequest);

    if (response?.status) {
      setSuccess(true);
      return response?.data;
    } else {
      setSuccess(false);
      //hanlde error case herw
    }
  } catch (err) {
    setSuccess(false);
    //handle error herw
  }
};

export const getOperatorById = (
  operatorId: number,
  setSuccess: any
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await operartorService.getOperatorById(operatorId);
      if (response?.status === 200 || response?.status === 201) {
        const processedFiles = processFiles(response?.data?.images);

        dispatch(setOperator({ ...response?.data, images: processedFiles }));
        setSuccess(true);
        return response;
      } else {
        setSuccess(false);
        //hanlde error case herw
      }
    } catch (err) {
      setSuccess(false);
    }
  };
};

export const deleteOperatorById = async (
  operatorId: number,
  setSuccess: any
) => {
  try {
    const response = await operartorService.deleteOperatorById(operatorId);
    if (response?.success) {
      setSuccess(true, null);
    } else {
      setSuccess(false, null);
    }
  } catch (err: any) {
    setSuccess(false, err?.response?.data?.errorCode);
  }
};

export const operatorActions = {
  addOperator,
  getOperatorList,
  getOperatorById,
  updateOperator,
  deleteOperatorById,
};
