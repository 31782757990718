import { CONTRACT_API, DEVICE_API_ENDPOINTS } from "../../../config/default.config";
import { commonUtils } from "../../../utils/common.utils";
import axiosInstance from "../../../utils/intercepter.util";
import { IContractListRequest } from "../contract.interface";

const addContract = async (paramData: any) => {
  const response = await axiosInstance.post(
    CONTRACT_API.ADD_CONTRACT_API,
    paramData
  );
  return response;
};
const getSiteContract = async () => {
  const response = await axiosInstance.get(CONTRACT_API.SITE_CONTRACT);
  return response.data;
};

const getCustomerContract = async () => {
  const response = await axiosInstance.get(CONTRACT_API.CUSTOMER_CONTRACT);
  return response.data;
};

const getAllContract = async (ContractListAllRequest: IContractListRequest) => {
  const response = await axiosInstance.get(
    CONTRACT_API.GET_ALL_CONTRACT.replace(
      ":page",
      `${ContractListAllRequest.Page}`
    )
      .replace(":pageSize", `${ContractListAllRequest.PageSize}`)
      .replace(":orderByDesc", `${ContractListAllRequest.OrderByDesc}`)
      .replace(":orderBy", ContractListAllRequest.OrderBy)
      .replace(":searchKey", ContractListAllRequest.SearchKey)
  );
  return response;
};

const contractById = async (id: number) => {
  const response = await axiosInstance.get(
    commonUtils.formatString(CONTRACT_API.GET_CONTRACT_BY_ID, id)
  );
  return response;
};

const deleteContractById = async (contractId: number) => {
  const response = await axiosInstance.delete(
    CONTRACT_API.DELETE_CONTRACT_BY_ID.replace(":id", `${contractId}`)
  );
  return response?.data;
};


const updateContract=async(paramData:any)=>{
  const response= await axiosInstance.put(CONTRACT_API.UPDATE_CONTRACT,paramData)
  return response.data
}



export const contractService = {
  addContract,
  getSiteContract,
  getCustomerContract,
  getAllContract,
  contractById,
  deleteContractById,
  updateContract
};
