import { Field, reduxForm } from "redux-form";
import {
  GSTNumberValidation,
  addressValidation,
  alphaNumericValidation,
  minMaxValidation,
  minMaxValidationForAddress,
  nameValidation,
  requiredValidation,
} from "../../validations/validations";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import ConfirmationDialouge from "../../utils/confirmation.dialouge";
import {
  ACTIONS,
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_DIALOUGES,
  CONFIRMATION_TITLES,
  CUSTOMER_ERROR_CODES,
  CUSTOMER_MESSAGE,
  ERROR,
  NAVIGATIONS,
  SESSION_DETAILS,
  SUBMIT_BUTTON_TEXT,
  VALIDATION_MESSAGES,
} from "../../config/default.config";
import {
  IAddCustomerRequest,
  ICustomer,
  IUpdateCustomerRequest,
} from "./customer.interface";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import { customerAction } from "./services/customer.action";
import {
  setIsError,
  setIsLoading,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import { useNavigate, useParams } from "react-router";
import { setCustomerDetails } from "./store/customer.reducer";
import { useEffect, useState } from "react";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import PhoneInput from "react-phone-number-input";
import {
  isValidPhoneNumber,
  formatPhoneNumber,
} from "react-phone-number-input";
import { encryptUtils } from "../../utils/encryption.utils";
import ImageUploadComponent, { IImageInfo } from "../components/image-upload/image-upload.component";

const renderInputName = (props: any) => {
  return (
    <div className="col-span-3">
      <label
        htmlFor="name"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Name <span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <input
          rows={5}
          {...props?.input}
          type="text"
          name="name"
          id="name"
          maxLength={20}
          className=" dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color block w-full rounded-md h-10 p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderMobileNumber = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="mobileNumber"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Mob Number <span className="text-red-500">*</span>
      </label>

      <div className="mt-2">
        <PhoneInput
          name="mobileNumber"
          id="mobileNumber"
          className=" dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color block w-full p-1 h-10 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Enter phone number"
          value={props?.phoneNumber}
          onChange={(value: string) => {
            props?.setPhoneNumberError(false);
            props?.setPhoneNumber(value);
            props?.validatePhoneNumber(String(value));
          }}
          onCountryChange={(country: any) => {
            props?.setPhoneNumberError(false);
            props?.setSelectedCountryCode(country);
          }}
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
        {props?.phoneNumberError && VALIDATION_MESSAGES.INVALID_PHONE_NUMBER}
      </div>
    </div>
  );
};

const renderGstNumber = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="gstNumber"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        GST Number <span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <input
          {...props?.input}
          type="text"
          name="gstNumber"
          id="gstNumber"
          maxLength={20}
          className=" dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color block w-full h-10 rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderAddress = (props: any) => {
  return (
    <div className="col-span-6 ">
      <label
        htmlFor="address"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Address <span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <textarea
          rows={4}
          {...props?.input}
          type="text"
          name="address"
          id="address"
          maxLength={60}
          className=" dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color resize-none block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

export function CustomerCreate(props: any) {
  const { handleSubmit, valid, rest, dirty } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { open, close } = useConfirmationPopup();
  const [ready, setReady] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [images, setImages] = useState<IImageInfo[]>([]);
  const [deletedImageCount, setDeletedImageCount] = useState<number>(0);

  const customerDetailsRespo = useAppSelector(
    (state) => state.customerReducer.customerDetails
  );
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  function validatePhoneNumber(phoneNumber: string) {
    if (!isValidPhoneNumber(phoneNumber, selectedCountryCode)) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
    return undefined;
  }

  const updateDeletedImageCount = (images: IImageInfo[]) => {
    const count = images.filter((image) => !image.deleted).length;
    setDeletedImageCount(count);
  };

  const handleNavigateBack = (confirm: boolean) => {
    handleClosePopup();
    if (confirm) {
      if (customerDetailsRespo.id !== 0) {
        navigate(
          NAVIGATIONS.CUSTOMER_VIEW.replace(
            ":id",
            `${encryptUtils.encryptURL(customerDetailsRespo.id)}`
          )
        );
      } else {
        navigate(NAVIGATIONS.CUSTOMER_LIST);
      }
    }
  };
  const handleCloseDialog = () => {
    setShowConfirmationDialog(false);
  };
  const handleConfirmDelete = () => {
    setShowConfirmationDialog(false);
  };

  const handleSave = (values: any) => {
    
    if (customerDetailsRespo?.name.length === 0) {
      let IAddCustomerRequest: IAddCustomerRequest = {
        id: 0,
        name: values.name,
        mobileNumber: phoneNumber,
        gstNumber: values.gstNumber,
        address: values.address,
        images:images
      };
      dispatch(customerAction.addCustomer(IAddCustomerRequest, setSuccess));
    } else {
      let IAddCustomerRequest: IUpdateCustomerRequest = {
        id: customerDetailsRespo.id,
        name: values.name,
        mobileNumber: phoneNumber,
        gstNumber: values.gstNumber,
        address: values.address,
        images:images
      };
      dispatch(customerAction.updateCustomer(IAddCustomerRequest, setSuccess));
    }
  };
  const handleOpenPopup = (confirmation: any) => {
    if (dirty || images.length > 0) {
      open(
        CONFIRMATION_TITLES.CONFIRM_EXIT,
        CONFIRMATION_DIALOUGES.UNSAVED_CHANGES,
        SUBMIT_BUTTON_TEXT.YES,
        CANCEL_BUTTON_TEXT.NO,
        () => {
          confirmation(true);
        },
        () => {
          confirmation(false);
        }
      );
    } else {
      confirmation(true);
    }
  };

  const handleClosePopup = () => {
    close();
  };

  const setSuccess = (status: boolean, type: string, errorCode: string) => {
    if (status) {
      if (type === ACTIONS.CREATE) {
        dispatch(setIsError(false));
        dispatch(setToast(true));
        dispatch(setToastMsg(CUSTOMER_MESSAGE.CUSTOMER_CREATED));
        navigate(NAVIGATIONS.CUSTOMER_LIST);
      } else if (type === ACTIONS.UPDATE) {
        dispatch(setIsError(false));
        dispatch(setToast(true));
        dispatch(setToastMsg(CUSTOMER_MESSAGE.CUSTOMER_UPDATED));
        navigate(
          NAVIGATIONS.CUSTOMER_LIST.replace(
            ":id",
            `${encryptUtils.encryptURL(customerDetailsRespo.id)}`
          )
        );
      } else {
        dispatch(setIsError(true));
        dispatch(setToast(true));
        dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
      }
    } else {
      if (errorCode === CUSTOMER_ERROR_CODES.INVALID_GST_NUMBER_ERROR_CODE) {
        dispatch(setIsError(true));
        dispatch(setToast(true));
        dispatch(setToastMsg(VALIDATION_MESSAGES.GST_NUMBER_EXISTS));
      } else {
        dispatch(setIsError(true));
        dispatch(setToast(true));
        dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
      }
    }
  };

  const setSuccessForCustomerFetching = (state: boolean) => {
    if (state) {
      setReady(true);
      dispatch(setIsLoading(false));
    } else {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setIsLoading(true));
      let customerIdForUpdate = encryptUtils.decryptURL(id);
      if (customerIdForUpdate !== null) {
        dispatch(
          customerAction.listCustomerById(
            customerIdForUpdate,
            setSuccessForCustomerFetching
          )
        );
      } else if (customerIdForUpdate === null) {
        dispatch(setIsLoading(false));
        setReady(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
     setImages(customerDetailsRespo.images)
        setPhoneNumber(customerDetailsRespo.mobileNumber);
        props.initialize({
          name: customerDetailsRespo.name,
          mobileNumber: customerDetailsRespo.mobileNumber,
          gstNumber: customerDetailsRespo.gstNumber,
          address: customerDetailsRespo.address,
        });
        setReady(true);
  }, [customerDetailsRespo]);

  if (!ready) {
    return null;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="inner-page-row">
          <div className="inline-block w-[100%] top-bar-sticky flex justify-between dark:top-bar-bg-color">
            <div className="float-left flex items-center">
              <button
                type="button"
                className="mr-3"
                onClick={() => handleOpenPopup(handleNavigateBack)}
              >
                <img src={backBtnIcon} alt="" className="p-1" />
              </button>

              <h2 className="text-xl">
                <b className="ml-5 dark:head-text-color">
                  {customerDetailsRespo?.name.length > 0
                    ? "Update Customer"
                    : "Add Customer"}
                </b>
              </h2>
            </div>

            <div className="float-right">
              <button
                className={`text-[14px] text-white rounded-md p-2 px-8 py-2 ${
                  !valid ||
                  phoneNumberError ||
                  phoneNumber == "" ||
                  selectedCountryCode == ""|| deletedImageCount === 0
                    ? "btn bg-slate-300"
                    : "btn btn-primary"
                } `}
                disabled={
                  !valid ||
                  phoneNumberError ||
                  phoneNumber == "" ||
                  selectedCountryCode == ""||deletedImageCount === 0
                }
              >
                <div>
                  {customerDetailsRespo?.name?.length > 0 ? "Update" : "Save"}
                </div>
              </button>
            </div>
          </div>

          <div className=" bg-white mt-6 rounded-md flex p-10 flex-wrap dark:panel-bg-color">
            <div className="w-1/2 pr-4">
              <h3 className="text-blue-500 font-bold inline-block">
                <span className="underline mb-3 px-2 py-1 dark:head-text-color">Basic</span>
              </h3>
              <div className="pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  <Field
                    type="text"
                    name="name"
                    component={renderInputName}
                    validate={[requiredValidation,nameValidation]}
                  />
                  <Field
                    type="text"
                    name="mobileNumber"
                    component={renderMobileNumber}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    validatePhoneNumber={validatePhoneNumber}
                    setSelectedCountryCode={setSelectedCountryCode}
                    phoneNumberError={phoneNumberError}
                    setPhoneNumberError={setPhoneNumberError}
                  />
                  <Field
                    type="text"
                    name="gstNumber"
                    component={renderGstNumber}
                    validate={[GSTNumberValidation]}
                  />
                  <Field
                    type="text"
                    name="address"
                    component={renderAddress}
                    validate={[minMaxValidationForAddress,addressValidation]}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 pl-4 p-20">
            <label
            htmlFor="grossPower"
             className="block text-sm font-medium leading-6 text-gray-900 ml-9 dark:normal-text-color"
              >
              Images <span className="text-red-500">*</span>
             </label>
              <ImageUploadComponent
                images={images}
                onImageUpload={(_images) => {
                  setImages(_images);
                  updateDeletedImageCount(_images);
                }}
              />
                 {valid && deletedImageCount === 0 ? (
                <span className=" ml-10 text-red-400 ">Image is required</span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default reduxForm({
  form: "create-customer-form",
})(CustomerCreate);
