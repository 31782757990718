import { ACTIONS } from "../../../config/default.config";
import { AppThunk } from "../../../config/store.hooks";
import { processFiles } from "../../../utils/file-processor";
import { IHistoryRequest } from "../../components/history.component/history.component.interface";
import {
  ISiteAddParam,
  ISiteListRequest,
  ISiteUpdateParam,
} from "../site.interface";
import { setSiteDetails } from "../store/site.reducer";
import { siteService } from "./site.service";

const getAllSites = async (
  SiteListAllRequest: ISiteListRequest,
  setSuccess: any
) => {
  try {
    const response = await siteService.listAllSite(SiteListAllRequest);
    setSuccess(true);
    return response?.data;
  } catch (error) {
    setSuccess(false);
    return null;
  }
};

const getSiteById = (id: number, setSuccess: any): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await siteService.listSiteById(id);
      const processedFiles = processFiles(response?.data?.data?.images);
      dispatch(
        setSiteDetails({ ...response?.data?.data, images: processedFiles })
      );
      setSuccess(true);
      return response?.data?.data;
    } catch (error) {
      setSuccess(false);
      return null;
    }
  };
};

const addSite = (paramData: ISiteAddParam, setSuccess: any): AppThunk => {
  return async () => {
    const formData = new FormData();
    formData.append("Id", String(paramData.Id));
    formData.append("Location", paramData.Location);
    formData.append("Area", paramData.Area);
    formData.append("Lattitude", paramData.Latitude);
    formData.append("Longitude", paramData.Longitude);
    // formData.append("siteImages",paramData.SiteImages)
    formData.append("StorageType", String(paramData.StorageType));
    formData.append("Status", String(paramData.Status));

    paramData.SiteImages.forEach((item: any, index: any) => {
      for (let key in item) {
        formData.append(`images[${index}].${key}`, item[key]);
      }
    });

    try {
      const response = await siteService.addSite(formData);
      setSuccess(true, ACTIONS.CREATE);
      return response;
    } catch (error) {
      setSuccess(false);
      return null;
    }
  };
};

const updateSite = (paramData: ISiteUpdateParam, setSuccess: any): AppThunk => {
  return async () => {
    const formData = new FormData();
    formData.append("Id", String(paramData.Id));
    formData.append("Location", paramData.Location);
    formData.append("Area", paramData.Area);
    formData.append("Lattitude", paramData.Latitude);
    formData.append("Longitude", paramData.Longitude);
    formData.append("IsDeleted", String(paramData.IsDeleted));
    formData.append("Status", String(paramData.Status));
    paramData.SiteImages.forEach((item: any, index: any) => {
      for (let key in item) {
        formData.append(`images[${index}].${key}`, item[key]);
      }
    });

    try {
      const response = await siteService.updateSite(formData);
      setSuccess(true, ACTIONS.UPDATE);
      return response;
    } catch (error) {
      setSuccess(false);
      return null;
    }
  };
};

const deleteSite = async (siteId: number, setSuccess: any) => {
  try {
    const response = await siteService.deleteSite(siteId);
    if (response?.success) {
      setSuccess(true, null);
    } else {
      setSuccess(false, null);
    }
  } catch (error: any) {
    setSuccess(false, error?.response?.data?.errorCode);
  }
};

const getSitesHistory = async (historyRequest: IHistoryRequest) => {
  try {
    const response = await siteService.getSitesHistory(historyRequest);
    return response?.data;
  } catch (error: any) {
    return 0;
  }
};

export const siteActions = {
  getAllSites,
  getSiteById,
  addSite,
  updateSite,
  deleteSite,
  getSitesHistory,
};
