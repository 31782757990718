import { ICustomer } from "./customer.interface";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/Delete_icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_TITLES,
  CUSTOMER_ERROR_CODES,
  CUSTOMER_MESSAGE,
  ERROR,
  NAVIGATIONS,
  SESSION_DETAILS,
  SUBMIT_BUTTON_TEXT,
} from "../../config/default.config";
import { setCustomerDetails } from "./store/customer.reducer";
import { Tooltip } from "@mui/material";
import { customerAction } from "./services/customer.action";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";
import {
  setIsError,
  setIsLoading,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import { useEffect, useState } from "react";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import { encryptUtils } from "../../utils/encryption.utils";
import noImageIcon from "../../assets/images/No-image.jpg";
export function CustomerView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { open, close } = useConfirmationPopup();
  const [ready, setReady] = useState<boolean>(false);

  const CustomerDetailsRespo = useAppSelector(
    (state) => state.customerReducer.customerDetails
  );

  useEffect(() => {
    fetchData();
  }, []);

  const setSuccessForCustomerFetching = (state: boolean) => {
    if (state) {
      setReady(true);
      dispatch(setIsLoading(false));
    } else {
      dispatch(setIsLoading(false));
    }
  };

  const fetchData = async () => {
    dispatch(
      customerAction.listCustomerById(
        Number(encryptUtils.decryptURL(id)),
        setSuccessForCustomerFetching
      )
    );
  };
  const handleViewClick = () => {
    navigate(NAVIGATIONS.CUSTOMER_LIST);
    let emptyCustomertDetails: ICustomer = {
      id: 0,
      name: "",
      mobileNumber: "",
      gstNumber: "",
      address: "",
      images: [],
    };
    dispatch(setCustomerDetails(emptyCustomertDetails));
  };

  const handleEditClick = () => {
    navigate(
      NAVIGATIONS.CUSTOMER_UPDATE.replace(
        ":id",
        encryptUtils.encryptURL(encryptUtils.decryptURL(id))
      )
    );
  };

  const handleDeleteConfirmation = (confirm: boolean) => {
    if (confirm) {
      customerAction.deletecustomer(
        CustomerDetailsRespo.id,
        setSuccessOfDelete
      );
    } else {
      handleClosePopup();
    }
  };

  const setSuccessOfDelete = (status: boolean, errorCode: any) => {
    if (status && errorCode == null) {
      dispatch(setToastMsg(CUSTOMER_MESSAGE.CUSTOMER_DELETED));
      dispatch(setToast(true));
      handleViewClick();
      handleClosePopup();
    } else {
      if (
        !status &&
        errorCode != null &&
        errorCode === CUSTOMER_ERROR_CODES.CUSTOMER_DELETE_ERROR_CODE
      ) {
        dispatch(setToastMsg(CUSTOMER_MESSAGE.CUSTOMER_UNABLE_DELETE));
        dispatch(setIsError(true));
        dispatch(setToast(true));
      } else {
        dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
        dispatch(setIsError(true));
        dispatch(setToast(true));
      }

      handleClosePopup();
    }
  };
  const handleClosePopup = () => {
    close();
  };
  const handleDeletePopup = (confirmation: any) => {
    open(
      CONFIRMATION_TITLES.CONFIRM_DELETE,
      CUSTOMER_MESSAGE.CUSTOMER_DELETE_CONFIRMATION.replace(
        ":name",
        CustomerDetailsRespo?.name
      ),
      SUBMIT_BUTTON_TEXT.YES,
      CANCEL_BUTTON_TEXT.NO,
      () => {
        confirmation(true);
      },
      () => {
        confirmation(false);
      }
    );
  };

  const handleNavigateBack = () => {
    let emptyCustomerDetails: ICustomer = {
      id: 0,
      name: "",
      mobileNumber: "",
      gstNumber: "",
      address: "",
      images: [],
    };
    dispatch(setCustomerDetails(emptyCustomerDetails));
    navigate(NAVIGATIONS.CUSTOMER_LIST);
  };

  const handleNavigateHistory = () => {
    let emptyCustomerDetails: ICustomer = {
      id: 0,
      name: "",
      mobileNumber: "",
      gstNumber: "",
      address: "",
      images: [],
    };
    navigate(
      NAVIGATIONS.CUSTOMER_HISTORY.replace(
        ":id",
        encryptUtils.encryptURL(encryptUtils.decryptURL(id))
      )
    );
    dispatch(setCustomerDetails(emptyCustomerDetails));
  };

  if (!ready) {
    return null;
  }

  return (
    <div className="inner-page-row">
      <div className="grid md:grid-cols-3 gap-28 pt-3">
        <div className="col-span-2 box-border">
          <div className="flex justify-between">
            <div className=" flex text-lg">
              <button
                type="button"
                className="mr-3"
                onClick={handleNavigateBack}
              >
                <img src={backBtnIcon} alt="" className="p-1" />
              </button>
              <div className=" font-bold p-1 text-gray-600 mt-1 dark:head-text-color">
                Customer
              </div>
              <div className="p-1 text-gray-500 mt-1 dark:head-text-color">
                Status
              </div>
            </div>
            <div
              className=" text-blue-600 p-2 text-sm mt-1 cursor-pointer"
              onClick={() => handleNavigateHistory()}
            >
              History
            </div>
          </div>

          <div className=" bg-white rounded-3xl shadow-md mt-4 dark:tile-bg-color">
            <div className="p-10 ">
              <div className=" flex justify-between">
                <div className="flex">
                  <div className=" inline p-2 text-gray-500">
                    <b className="dark:head-text-color">Customer</b>
                  </div>

                  <Tooltip title="Edit" placement="top">
                    <div
                      className=" inline p-2 cursor-pointer"
                      onClick={() => {
                        handleEditClick();
                      }}
                    >
                      <img className="w-7" src={editIcon} alt="edit" />
                    </div>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <div
                      className=" inline p-2 cursor-pointer"
                      onClick={() => {
                        handleDeletePopup(handleDeleteConfirmation);
                      }}
                    >
                      <img className="w-7" src={deleteIcon} alt="delete" />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="sm:block md:flex py-3">
                <div>
                  <img
                    style={{ width: 100, height: 100 }}
                    src={
                      CustomerDetailsRespo?.images[0]
                        ? `${CustomerDetailsRespo?.images[0]?.resource}`
                        : noImageIcon
                    }
                    className="border border-gray h-32 rounded-2xl"
                  />
                </div>
                <div className="px-8">
                  <div className="font-bold dark:head-text-color">
                    {CustomerDetailsRespo.name}{" "}
                  </div>
                  <div className="flex pt-3">
                    <div className=" pr-3  text-gray-400  dark:normal-text-color">
                      Address :
                    </div>
                    <span className="font-bold text-gray-500 dark:head-text-color">
                      {CustomerDetailsRespo.address}
                    </span>
                  </div>
                  <div className="flex">
                    <div className=" pr-3  text-gray-500  dark:normal-text-color">
                      Mobile Number :
                    </div>
                    <span className="font-bold text-gray-500 dark:head-text-color">
                      {CustomerDetailsRespo.mobileNumber}
                    </span>
                  </div>
                  <div className="flex">
                    <div className=" pr-3  text-gray-500  dark:normal-text-color">
                      GST Number :
                    </div>
                    <span className="font-bold text-gray-500 dark:head-text-color">
                      {CustomerDetailsRespo.gstNumber}
                    </span>
                  </div>
                </div>
              </div>
              {/* <hr className="flex-grow" /> */}
              {/* <div className="text-slate-500 font-medium mt-2 text-[18px]">
                Contract Information
              </div> */}
            </div>
            {/* <CustomerContractHistory historyType = {"customer"}/> */}
          </div>
        </div>
      </div>
    </div>
  );
}
