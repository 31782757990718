import { IImageInfo } from "../components/image-upload/image-upload.component";

export interface IAddOrUpdateOperatorRequest {
    id: number;
    name: string;
    mobile: string;
    location: string;
    address: string;
    images: any[];
  }

  export interface IOperator {
    id: number;
    name: string;
    mobile: string;
    location: string;
    address: string;
    images: IImageInfo[];
  }

  export interface IOperatorListRequest {
    Page: number;
    PageSize: number;
    OrderByDesc: boolean;
    OrderBy: string;
    SearchKey: string;
  }

  export type IOperatorItem = {
    id: number;
    name: string;
    mobile: string;
    location: string;
    image: string;
  };

  export interface IOperatorReduxState {
    operator: IOperator;
  }

  export const OPERATOR_OPERATIONS = {
    UPDATE: "UPDATE",
    CREATE: "CREATE",
  };
  