import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISiteRespone } from "../site.interface";

interface ISiteDetails {
  siteDetails: ISiteRespone;
}

const initialState: ISiteDetails = {
  siteDetails: {
    id: 0,
    location: "",
    area: "",
    lattitude: "",
    longitude: "",
    status: 3,
    images: [],
  },
};
const siteSlice = createSlice({
  name: "siteReducer",
  initialState,
  reducers: {
    setSiteDetails(state, actions: PayloadAction<ISiteRespone>) {
      state.siteDetails = actions.payload;
      return state;
    },
  },
});

export const { setSiteDetails } = siteSlice.actions;
export default siteSlice.reducer;
