import SecuredWebStorage from "secured-web-storage";

const config = {
  name: "sop",
  type: "localStorage",
  secretKey: "seqr-ops-c0nw0rx",
  secure: true,
};
const localStorage = new SecuredWebStorage(config);

const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

const getLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

const clearLocalStorage = () => {
  localStorage.clear();
};

export const sessionStorageUtils = {
  setLocalStorage,
  getLocalStorage,
  clearLocalStorage,
};
