import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ASSET_AUTH_API, AUTH_API, SESSION_DETAILS, SESSION_KEY } from "../config/default.config";
import { sessionStorageUtils } from "./session-storage.utils";
import { authActions } from "../modules/login/service/login.action";
import { ITokenResponse } from "../modules/login/types/login.types";
import { store } from "../config/config.store";
import { clearToken, setToken } from "../modules/login/store/login.reducer";

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const axiosInstance: AxiosInstance = axios.create();

// Request interceptor to add authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const tokenData = sessionStorageUtils.getLocalStorage(
      SESSION_KEY.LOCAL_STORAGE_KEY
    ).token;
    const isAuthApis =
      config.url?.endsWith(ASSET_AUTH_API.GET_LOGIN_TOKEN) ||
      config.url?.endsWith(ASSET_AUTH_API.LOGOUT_TOKEN);
    console.log(isAuthApis);
    if (!isAuthApis) {
      if (tokenData.auth_token) {
        config.headers.Authorization = `Bearer ${tokenData.auth_token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

function subscribeTokenRefresh(cb: (token: string) => void) {
  refreshSubscribers.push(cb);
}

function onRefreshed(token: string) {
  refreshSubscribers.map((cb) => cb(token));
  refreshSubscribers = [];
}

// Response interceptor to handle 401
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const originalRequest = error.config;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const tokenData: ITokenResponse = sessionStorageUtils.getLocalStorage(
            SESSION_KEY.LOCAL_STORAGE_KEY
          );
          console.log("tokenData", tokenData);
          const newToken: ITokenResponse = await authActions.getRefreshToken(
            tokenData.sessionState,
            tokenData.refreshToken
          );
          store.dispatch(setToken(newToken));
          onRefreshed(newToken.accessToken);
          isRefreshing = false;
        } catch (error) {
          isRefreshing = false;
          store.dispatch(clearToken());
          window.location.href = "/";
        }
      }

      const retryOriginalRequest = new Promise((resolve) => {
        subscribeTokenRefresh((token: string) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });

      return retryOriginalRequest;
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
