import VlvLoader from "../../assets/images/volvo-loader-low-quality.gif"

const Loader=()=>{
    return(
        <div className=" fixed top-0 h-full w-full bg-white opacity-[0.9] ">
            <img src={VlvLoader} className="h-32 absolute top-[45%] left-1/2 "/>
        </div>
    )

}
export default Loader