import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

function PaginatedItems({ itemsPerPage, totalRecords, items, onPagination, reRenderDevices }: any) {
  const [itemOffset, setItemOffset] = useState(0);
  const [endOff, setEndoff] = useState(0);
  const pageCount = Math.ceil(totalRecords / itemsPerPage);
  const [pageNo, setPage] = useState<number>(1);

  // useEffect(() => {
  //   const endOffset = itemOffset + itemsPerPage;
  //   const currentItems = items.slice(itemOffset, endOffset);
  //   onPagination(currentItems, itemOffset, itemOffset + currentItems?.length);
  //   setEndoff(itemOffset + currentItems?.length);
  // }, [itemOffset]);

  const handlePageClick = (event: any) => {
    console.log("hei");
    const newOffset = (event.selected * itemsPerPage) % totalRecords;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
    setPage(event.selected+1);
    reRenderDevices(event.selected+1);
  };

  return (
    <>
      <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6 rounded-lg">
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700 dark:paginationText">
              Showing
              <span className="font-medium"> {itemOffset + 1} </span>
              to
              <span className="font-medium"> { itemsPerPage > totalRecords ? totalRecords : itemOffset+itemsPerPage } </span>
              of
              <span className="font-medium"> {totalRecords} </span>
              results
            </p>
          </div>
          <div className="w-6/12">
            <nav
              className="isolate inline-flex -space-x-px rounded-md shadow-sm pagination-custom float-right cursor-pointer dark:paginationCount"
              aria-label="Pagination"
            >
              <ReactPaginate
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item relative inline-flex items-center text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0"
                pageLinkClassName="page-link  mx-5 my-2"
                previousClassName="page-item page-item-np relative inline-flex items-center rounded-l-md  text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                previousLinkClassName="page-link"
                nextClassName="page-item page-item-np relative inline-flex items-center rounded-r-md  text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item page-item relative inline-flex items-center text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0"
                breakLinkClassName="page-link mx-5 my-2"
                containerClassName="pagination"
                activeClassName="bg-slate-200"
                activeLinkClassName="text-blue-800 mx-6 my-3"
                renderOnZeroPageCount={null}
              />
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaginatedItems;
