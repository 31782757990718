import { useEffect, useState } from "react";
import NoDataFound from "../../components/noDataFound";
import PaginatedItems from "../../components/pagination.component";
import {
  IRecentAlarmResponse,
  IRecentMediaRequest,
  IRecentVideoResponse,
} from "./alarmSection.interface";
import moment from "moment";
import backBtnIcon from "../../../assets/images/Back_icon.svg";
import {
  NAVIGATIONS,
  SEQROPS_INSTANCE_API,
} from "../../../config/default.config";
import { useNavigate } from "react-router-dom";
import { dashboardAction } from "../service/dashbord.action";
import { useAppDispatch, useAppSelector } from "../../../config/store.hooks";
import VideoPreviewBox from "../../components/preview-box/video.preview.box";

function RecentVideoList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [recentAlarmVideo, setRecentAlarmVideoData] = useState<
    IRecentVideoResponse[]
  >([]);
  const [paginationPayload, setPaginationPayload] =
    useState<IRecentMediaRequest>({
      sortTerm: "",
      sortOrder: "DESC",
      page: 0,
      pageSize: 13,
    });

  let th1 = "VIDEO",
    th2 = "VEHICLE NUMBER",
    th3 = "DATE & TIME";

  const onPagination = (pagenumber: number) => {};
  const handleNavigateBack = () => {
    navigate(NAVIGATIONS.DASHBOARD);
  };

  const [totalRecords, setTotalRecords] = useState();
  const [showVideo, setShowVideo] = useState(false);
  const [operatorVideo, setOperatorVideo] = useState("");
  const recentVideoEquipmentDetails = useAppSelector(
    (state) => state.commonReducer.recentVideoEquipmentData
  );

  useEffect(() => {
    let recentAlarmPaginationPayload: IRecentMediaRequest = {
      sortTerm: "",
      sortOrder: "DESC",
      page: 0,
      pageSize: 13,
    };

    recentVideoAlarm(recentAlarmPaginationPayload);
  }, []);

  const recentVideoAlarm = async (
    recentAlarmPaginationPayload: IRecentMediaRequest
  ) => {
    const response: any = await dispatch(
      dashboardAction.getRecentVideoAlarms(recentAlarmPaginationPayload)
    );

    setTotalRecords(response?.Total)
    // setRecentAlarmVideoData(response?.Result);

    setRecentAlarmVideoData([
      {
        id: 817,
        name: "863540061974276__2023-11-16100419.mp4",
        imei: "863540061974276",
        fileType: "REAR VIDEO",
        trigger: "DIN1 (1)",
        time: "1700128971000",
        longitude: "0",
        latitude: "0",
        type: "VIDEO",
        operator_recognition: null,
        dateOfIdentification: null,
        operatorId: null,
      },
      {
        id: 817,
        name: "863540061974276__2023-11-16100419.mp4",
        imei: "863540061974276",
        fileType: "REAR VIDEO",
        trigger: "DIN1 (1)",
        time: "1700128971000",
        longitude: "0",
        latitude: "0",
        type: "VIDEO",
        operator_recognition: null,
        dateOfIdentification: null,
        operatorId: null,
      },
      {
        id: 817,
        name: "863540061974276__2023-11-16100419.mp4",
        imei: "863540061974276",
        fileType: "REAR VIDEO",
        trigger: "DIN1 (1)",
        time: "1700128971000",
        longitude: "0",
        latitude: "0",
        type: "VIDEO",
        operator_recognition: null,
        dateOfIdentification: null,
        operatorId: null,
      },
      {
        id: 817,
        name: "863540061974276__2023-11-16100419.mp4",
        imei: "863540061974276",
        fileType: "REAR VIDEO",
        trigger: "DIN1 (1)",
        time: "1700128971000",
        longitude: "0",
        latitude: "0",
        type: "VIDEO",
        operator_recognition: null,
        dateOfIdentification: null,
        operatorId: null,
      },
    ]);
  };

  
  const handlePreviewImage = (showStatus: boolean, image: string) => {
    setShowVideo(showStatus);
    setOperatorVideo(image);
  };

  return (
    <div className="inner-page-row">
      <VideoPreviewBox
        showVideo={showVideo}
        operatorVideo={operatorVideo}
        onClose={() => {
          handlePreviewImage(false, "");
        }}
      />
      <div className="flex justify-between mt-5">
        <div className=" flex text-lg">
          <button type="button" className="" onClick={handleNavigateBack}>
            <img src={backBtnIcon} alt="" className="p-1" />
          </button>
          <div className="mt-2">
            <h2 className="text-xl">
              <b className="ml-5 dark:head-text-color">{"Recent Videos"}</b>
            </h2>
          </div>
        </div>
      </div>

       {recentVideoEquipmentDetails && recentVideoEquipmentDetails.length > 0 ? (  
      <div>
        <div className="">
          <div className="relative overflow-x-auto  sm:rounded-lg mt-4 video-list-table ">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50   dark:table-head-bg-color dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 dark:tableTh">
                    {th1}
                  </th>
                  <th scope="col" className="px-6 py-3 dark:tableTh">
                    {th2}
                  </th>
                  <th scope="col" className="px-6 py-3 dark:tableTh">
                    {th3}
                  </th>
                </tr>
              </thead>
              <tbody>
                {recentAlarmVideo?.map((item: IRecentVideoResponse, index) => {
                  // let videoUrl = SEQROPS_INSTANCE_API.GET_OPERATORVIDEO.replace(
                  //   ":video",
                  //   item.name
                  // );

                  let videoUrl="https://seqrops-assets.ap-south-1.linodeobjects.com/dashboard/1.mp4"
                  const timestamp = Number(item?.time);
                  const date = new Date(timestamp);
                  return (
                    <tr className="bg-white dark:table-bg-color " key={item.id}>
                      <td
                        className="px-6 py-4 dark:tableTd "
                      >
                        <div className="relative video-box cursor-pointer " onClick={() => {
                          handlePreviewImage(true, videoUrl);
                        }}>
                          <video width="200" height="100">
                            <source src={videoUrl} type="video/mp4" />
                            Your browser does not support Video.
                          </video>
                          <span>
                            <svg
                              className="w-6 h-6 text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 10 16"
                            >
                              <path d="M3.414 1A2 2 0 0 0 0 2.414v11.172A2 2 0 0 0 3.414 15L9 9.414a2 2 0 0 0 0-2.828L3.414 1Z" />
                            </svg>
                          </span>
                        </div>
                      </td>
                      {/* <td className="px-6 py-4 dark:tableTd">{recentVideoEquipmentDetails[index]?.vehicleNumber}</td> */}
                      <td className="px-6 py-4 dark:tableTd">KL 32 A 6788</td>
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:tableTh"
                      >
                        {moment.utc(date).local().format("DD-MM-YYYY HH:mm:ss")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-">
          <PaginatedItems
            itemsPerPage={paginationPayload.pageSize}
            totalRecords={totalRecords}
            onPagination={onPagination}
            reRenderDevices={() => {}}
          />
        </div>
      </div>
    ) : (
        <div>
          <NoDataFound />
        </div>
      )} 
    </div>
  );
}
export default RecentVideoList;
