import { CUSTOMER_API } from "../../../config/default.config";
import { commonUtils } from "../../../utils/common.utils";
import axiosInstance from "../../../utils/intercepter.util";
import { IHistoryRequest } from "../../components/history.component/history.component.interface";
import {
  ICustomerHistoryRequest,
  ICustomerListRequest,
} from "../customer.interface";

const addCustomer = async (formData: FormData) => {
  const response = await axiosInstance.post(
    CUSTOMER_API.ADD_CUSTOMER,
    formData
  );
  return response;
};

const updateCustomer = async (formData: FormData) => {
  const response = await axiosInstance.put(
    CUSTOMER_API.UPDATE_CUSTOMER,
    formData
  );
  return response;
};

const deleteCustomer = async (id: number) => {
  const response = await axiosInstance.delete(
    CUSTOMER_API.DELETE_CUSTOMER.replace(":id", `${id}`)
  );
  return response;
};

const listAllCustomer = async (
  CustomerListAllRequest: ICustomerListRequest
) => {
  const response = await axiosInstance.get(
    CUSTOMER_API.GET_ALL_CUSTOMER.replace(
      ":page",
      `${CustomerListAllRequest.Page}`
    )
      .replace(":pageSize", `${CustomerListAllRequest.PageSize}`)
      .replace(":orderByDesc", `${CustomerListAllRequest.OrderByDesc}`)
      .replace(":orderBy", CustomerListAllRequest.OrderBy)
      .replace(":searchKey", CustomerListAllRequest.SearchKey)
  );
  return response;
};

const customerHistory = async (
  historyRequest: IHistoryRequest
) => {
  const response = await axiosInstance.get(
    CUSTOMER_API.GET_CUSTOMER_HISTORY.replace(
      ":page",
      `${historyRequest.page}`
    )
      .replace(":pageSize", `${historyRequest.pageSize}`)
      .replace(":orderByDesc", `${historyRequest.orderByDesc}`)
      .replace(":orderBy", historyRequest.orderBy)
      .replace(":searchKey", historyRequest.searchKey)
      .replace(":id", historyRequest.id)
  );
  return response;
};

const listCustomerById = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      commonUtils.formatString(CUSTOMER_API.GET_CUSTOMER_BY_ID, id)
    );
    return response;
  } catch (error) {
    console.error(
      "ERROR_IN_FETCHING_CUSTOMER_DATA_BY_ID_IN_EQUIPMENT_SERVICE :::",
      error
    );
    throw error;
  }
};

export const customerServices = {
  addCustomer,
  updateCustomer,
  deleteCustomer,
  listAllCustomer,
  listCustomerById,
  customerHistory,
};
