import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IEquipment } from "../equipment.interface";

interface IEquipmentDetails {
  equipmentDetails: IEquipment;
}

const initialState: IEquipmentDetails = {
  equipmentDetails: {
    id: 0,
    name: "",
    vehicleNumber: "",
    description: "",
    operatingWeight: 0,
    grossPower: 0,
    bucketCapacity: 0,
    maxDiggingDepth: 0,
    images: [],
    deviceId: 0,
    status: 3,
  },
};

const equipmentSlice = createSlice({
  name: "equipmentReducer",
  initialState,
  reducers: {
    setEquipmentDetails(state, actions: PayloadAction<any>) {
      state.equipmentDetails = actions.payload;
      return state;
    },
  },
});

export const { setEquipmentDetails } = equipmentSlice.actions;
export default equipmentSlice.reducer;
