import React from "react";
import { equipmentServices } from "./equipment.services";
import {
  EQUIPMENT_OPERATIONS,
  IAddEquipmentRequest,
  IEquipmentListRequest,
  
  IGetAllInstanceListRequest,
  
  IUpdateEquipment,
} from "../equipment.interface";
import { AppThunk } from "../../../config/store.hooks";
import { setEquipmentDetails } from "../store/equipment.reducer";
import { processFiles } from "../../../utils/file-processor";
import { IHistoryRequest } from "../../components/history.component/history.component.interface";

const addEquipment = (
  IAddEquipmentRequest: IAddEquipmentRequest,
  setSuccess: any
): AppThunk => {
  return async (dispatch: any, getState) => {
    const formData = new FormData();
    formData.append("id", String(IAddEquipmentRequest.id));
    formData.append("name", IAddEquipmentRequest.name);
    formData.append("vehicleNumber", IAddEquipmentRequest.vehicleNumber);
    formData.append("description", IAddEquipmentRequest.description);
    formData.append(
      "operatingWeight",
      String(IAddEquipmentRequest.operatingWeight)
    );
    formData.append("grossPower", String(IAddEquipmentRequest.grossPower));
    formData.append(
      "bucketCapacity",
      String(IAddEquipmentRequest.bucketCapacity)
    );
    formData.append(
      "maxDiggingDepth",
      String(IAddEquipmentRequest.maxDiggingDepth)
    );
    formData.append("deviceId", String(IAddEquipmentRequest.deviceId));
    formData.append("status", String(IAddEquipmentRequest.status));
    IAddEquipmentRequest.images.forEach((item :any, index : any) => {
      for (let key in item) {
        formData.append(`images[${index}].${key}`, item[key]);
      }
    });

    try {
      const response = await equipmentServices.addEquipment(formData);
      if (response?.status === 200 || response?.status === 201) {
        setSuccess(true, EQUIPMENT_OPERATIONS.CREATE);
      } else {
        setSuccess(false, EQUIPMENT_OPERATIONS.CREATE);
      }
    } catch (err) {
      setSuccess(false, EQUIPMENT_OPERATIONS.CREATE);
      return null;
    }
  };
};

const updateEquipment = (
  IUpdateEquipment: IUpdateEquipment,
  setSuccess: any
): AppThunk => {
  return async (dispatch: any, getState) => {
    const formData = new FormData();
    formData.append("id", String(IUpdateEquipment.id));
    formData.append("name", IUpdateEquipment.name);
    formData.append("vehicleNumber", IUpdateEquipment.vehicleNumber);
    formData.append("description", IUpdateEquipment.description);
    formData.append(
      "operatingWeight",
      String(IUpdateEquipment.operatingWeight)
    );
    formData.append("grossPower", String(IUpdateEquipment.grossPower));
    formData.append("bucketCapacity", String(IUpdateEquipment.bucketCapacity));
    formData.append(
      "maxDiggingDepth",
      String(IUpdateEquipment.maxDiggingDepth)
    );
    formData.append("deviceId", String(IUpdateEquipment.deviceId));
    formData.append("status", String(IUpdateEquipment.status));
    IUpdateEquipment.images.forEach((item :any, index : any) => {
      for (let key in item) {
        formData.append(`images[${index}].${key}`, item[key]);
      }
    });
    try {
      const response = await equipmentServices.updateEquipment(formData);
      if (response?.status === 200 || response?.status === 201) {
        setSuccess(true, EQUIPMENT_OPERATIONS.UPDATE);
      } else {
        setSuccess(false, EQUIPMENT_OPERATIONS.UPDATE);
      }
    } catch (err) {
      setSuccess(false, EQUIPMENT_OPERATIONS.UPDATE);
      return null;
    }
  };
};
const deleteEquipment = async (id: number, setSuccess: any) => {
  try {
    const response = await equipmentServices.deleteEquipment(id);
    if (response?.data?.success) {
      setSuccess(true, null);
    } else {
      setSuccess(false, null);
    }
  } catch (error:any) {     
    setSuccess(false,error?.response?.data?.errorCode);
  }
};

const listAllEquipments = async (
  EquipmentListAllRequest: IEquipmentListRequest
) => {
  try {
    const response = await equipmentServices.listAllEquipments(
      EquipmentListAllRequest
    );
    return response?.data;
  } catch (error: any) {
    console.log("ERROR_IN_FETCHING_EQUIPMENT_DATA_IN_EQUIPMENT_ACTION", error);
    return 0;
  }
};

const getAllModels = async () => {
  try {
    const response = await equipmentServices.getAllModels();
    if (response.status) {
      return response.data;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getAllInstance=async(InstanceRequsetList:IGetAllInstanceListRequest)=>{

  try {
    const response = await equipmentServices.getAllInstance(InstanceRequsetList)
    return response.data
  } catch (error) {
    console.log("FETCHING....ERROR...",error);
    return null
  }

}

const listEquipmentById = (id: number, setSuccess: any): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await equipmentServices.listEquipmentById(id);
      const processedFiles = processFiles(response?.data?.images);
            dispatch(
        setEquipmentDetails({ ...response?.data, images: processedFiles })
      );
      setSuccess(true);
      return response?.data;
    } catch (error) {
      setSuccess(false);
      return null;
    }
  };
};
const equipmentDevice = async () => {
  try {
    const response = await equipmentServices.equipmentDevices();
    return response?.data;
  } catch (error) {
    console.log("errorrr");
    
  }
 };

 const getEquipmentHistory = async (
  historyRequest: IHistoryRequest
) => {
  try {
    const response = await equipmentServices.getEquipmentHistory(
      historyRequest
    );
    return response?.data;
  } catch (error: any) {
    return 0;
  }
};
export const equipmentAction = {
  addEquipment,
  updateEquipment,
  deleteEquipment,
  listAllEquipments,
  listEquipmentById,
  equipmentDevice,
  getEquipmentHistory,
  getAllModels,
  getAllInstance
};
