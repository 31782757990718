import { Field, reduxForm } from "redux-form";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ConfirmationDialouge from "../../utils/confirmation.dialouge";

import {
  ACTIONS,
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_DIALOUGES,
  CONFIRMATION_TITLES,
  NAVIGATIONS,
  SESSION_DETAILS,
  EQUIPMENT_MESSAGE,
  SUBMIT_BUTTON_TEXT,
  ERROR,
} from "../../config/default.config";
import {
  alphaNumericValidation,
  bucketCapacityValidation,
  descriptionValidation,
  deviceValidation,
  grossPowerValidation,
  isNumberValidation,
  maxDiggingDepthValidation,
  minMaxValidation,
  nameValidation,
  operatingWeightValidation,
  requiredValidation,
  statusValidation,
  vehicleNumberValidation,
} from "../../validations/validations";
import {
  IAddEquipmentRequest,
  IEquipment,
  IUpdateEquipment,
} from "./equipment.interface";
import { equipmentAction } from "./services/equipment.actions";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import ImageUploadComponent, {
  IImageInfo,
} from "../components/image-upload/image-upload.component";
import { setEquipmentDetails } from "./store/equipment.reducer";
import {
  setIsError,
  setIsLoading,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";
import { encryptUtils } from "../../utils/encryption.utils";

const renderInputName = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="name"
        className="block text-sm font-medium leading-6 text-gray-900 dark:normal-text-color"
      >
        Name <span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <input
          {...props?.input}
          type="text"
          name="name"
          id="name"
          maxLength={20}
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};
const renderVehicleNumber = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="vehicleNumber"
        className="block text-sm font-medium leading-6 text-gray-900 dark:normal-text-color"
      >
        Vehicle Number<span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <input
          {...props?.input}
          type="text"
          name="vehicleNumber"
          id="vehicleNumber"
          maxLength={20}
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};
const renderDescription = (props: any) => {
  return (
    <div className="col-span-6 ">
      <label
        htmlFor="description"
        className="block text-sm font-medium leading-6 text-gray-900 dark:normal-text-color"
      >
        Description<span className="text-red-500">*</span>
      </label>
      <div className="mt-2 ">
        <textarea
          rows={3}
          {...props?.input}
          type="text"
          name="description"
          id="description"
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color resize-none p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};
const renderOperatingWeight = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="operatingWeight"
        className="block text-sm font-medium leading-6 text-gray-900 dark:normal-text-color"
      >
        Operating Weight<span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <input
          {...props?.input}
          type="text"
          name="operatingWeight"
          id="operatingWeight"
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};
const renderGrossPower = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="grossPower"
        className="block text-sm font-medium leading-6 text-gray-900 dark:normal-text-color"
      >
        Gross Power <span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <input
          {...props?.input}
          type="text"
          name="grossPower"
          id="grossPower"
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:normal-text-color p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};
const renderBucketCapacity = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="bucketCapacity"
        className="block text-sm font-medium leading-6 text-gray-900 dark:normal-text-color"
      >
        Bucket Capacity <span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <input
          {...props?.input}
          type="text"
          name="bucketCapacity"
          id="bucketCapacity"
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};
const renderMaxDiggingDepth = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="maxDiggingDepth"
        className="block text-sm font-medium leading-6 text-gray-900 dark:normal-text-color"
      >
        Max. Digging Depth <span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <input
          {...props?.input}
          type="text"
          name="maxDiggingDepth"
          id="maxDiggingDepth"
          className=" dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};
const renderSelectDevice = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="deviceId"
        className="block text-sm font-medium leading-6 text-gray-900 dark:normal-text-color"
      >
        Select Device <span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <select
          {...props?.input}
          name="deviceId"
          id="deviceId"
          placeholder="Select Device"
          className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select Device</option>

          {props.devices?.map((device: any) => (
            <option key={device.id} value={device.id}>
              {device.name}
            </option>
          ))}
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};
const renderStatus = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="status"
        className="block text-sm font-medium leading-6 text-gray-900 dark:normal-text-color"
      >
        Status <span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <select
          {...props?.input}
          name="status"
          id="status"
          placeholder="Select Status"
          className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select Status</option>
          {props.status.map((item: any, index: number) => (
            <option key={index} value={item?.value}>
              {item?.label}
            </option>
          ))}
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

export function EquipmentCreate(props: any) {
  const { id } = useParams();
  const { handleSubmit, valid, dirty } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [setEquipmentImages]: any[] = useState([]);
  const [imgtoRemove] = useState<any>();
  const [images, setImages] = useState<IImageInfo[]>([]);
  const { open, close } = useConfirmationPopup();
  const [ready, setReady] = useState<boolean>(false);
  const [equipmentDeviceData, setequipmentDeviceData]: any[] = useState([]);
  const [deletedImageCount, setDeletedImageCount] = useState<number>(0);

  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  const equipmentDetailsRespo = useAppSelector(
    (state) => state.equipmentReducer.equipmentDetails
  );

  let statusData = [
    { label: "Active", value: 0 },
    { label: "StandBy", value: 2 },
    { label: "Inactive", value: 1 },
  ];

  const updateDeletedImageCount = (images: IImageInfo[]) => {
    const count = images.filter((image) => !image.deleted).length;
    setDeletedImageCount(count);
  };
  useEffect(() => {
    const equipmentDevice = async () => {
      const equipmentDevice = await equipmentAction.equipmentDevice();
      setequipmentDeviceData(equipmentDevice);
    };
    equipmentDevice();
  }, []);

  const handleNavigateBack = (confirm: boolean) => {
    handleClosePopup();
    if (confirm) {
      if (equipmentDetailsRespo.id !== 0) {
        navigate(
          NAVIGATIONS.EQUIPMENT_VIEW.replace(
            ":id",
            `${encryptUtils.encryptURL(equipmentDetailsRespo?.id)}`
          )
        );
      } else {
        navigate(NAVIGATIONS.EQUIPMENT_LIST);
      }
    }
  };

  const handleCloseDialog = () => {
    setShowConfirmationDialog(false);
  };

  const handleConfirmDelete = () => {
    let srcToRemove = imgtoRemove?.src;
    setEquipmentImages((prevImages: any) =>
      prevImages.filter((image: any) => image.src !== srcToRemove)
    );
    setShowConfirmationDialog(false);
  };

  const handleSave = (values: any) => {
    if (equipmentDetailsRespo?.name.length === 0) {
      let IAddEquipmentRequest: IAddEquipmentRequest = {
        id: 0,
        name: values.name,
        vehicleNumber: values.vehicleNumber,
        description: values.description,
        operatingWeight: values.operatingWeight,
        bucketCapacity: values.bucketCapacity,
        grossPower: values.grossPower,
        maxDiggingDepth: values.maxDiggingDepth,
        images: images,
        deviceId: values.deviceId,
        status: values.status,
      };
      dispatch(equipmentAction.addEquipment(IAddEquipmentRequest, setSuccess));
    } else {
      let IAddEquipmentRequest: IUpdateEquipment = {
        id: equipmentDetailsRespo.id,
        name: values.name,
        vehicleNumber: values.vehicleNumber,
        description: values.description,
        operatingWeight: values.operatingWeight,
        grossPower: values.grossPower,
        bucketCapacity: values.bucketCapacity,
        maxDiggingDepth: values.maxDiggingDepth,
        images: images,
        deviceId: values.deviceId,
        status: values.status,
      };
      dispatch(
        equipmentAction.updateEquipment(IAddEquipmentRequest, setSuccess)
      );
    }
  };
  const handleOpenPopup = (confirmation: any) => {
    if (dirty || images.length > 0) {
      open(
        CONFIRMATION_TITLES.CONFIRM_EXIT,
        CONFIRMATION_DIALOUGES.UNSAVED_CHANGES,
        SUBMIT_BUTTON_TEXT.YES,
        CANCEL_BUTTON_TEXT.NO,
        () => {
          confirmation(true);
        },
        () => {
          confirmation(false);
        }
      );
    } else {
      confirmation(true);
    }
  };

  const handleClosePopup = () => {
    close();
  };

  const setSuccess = (status: boolean, type: string) => {
    if (status) {
      if (type === ACTIONS.CREATE) {
        dispatch(setIsError(false));
        dispatch(setToast(true));
        dispatch(setToastMsg(EQUIPMENT_MESSAGE.EQUIPMENT_CREATED));
        navigate(NAVIGATIONS.EQUIPMENT_LIST);
      } else if (type === ACTIONS.UPDATE) {
        let emptyEquipmentDetails: IEquipment = {
          id: 0,
          name: "",
          vehicleNumber: "",
          description: "",
          operatingWeight: 0,
          grossPower: 0,
          bucketCapacity: 0,
          maxDiggingDepth: 0,
          images: [],
          deviceId: 0,
          status: 1,
        };
        dispatch(setEquipmentDetails(emptyEquipmentDetails));
        dispatch(setIsError(false));
        dispatch(setToast(true));
        dispatch(setToastMsg(EQUIPMENT_MESSAGE.EQUIPMENT_UPDATED));
        navigate(
          NAVIGATIONS.EQUIPMENT_VIEW.replace(
            ":id",
            `${encryptUtils.encryptURL(equipmentDetailsRespo?.id)}`
          )
        );
      } else {
        dispatch(setIsError(true));
        dispatch(setToast(true));
        dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
      }
    }
  };

  const setSuccessForEquipmentFetching = (state: boolean) => {
    if (state) {
      setReady(true);
      dispatch(setIsLoading(false));
    } else {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setIsLoading(true));
      let equipmentIdForUpdate = encryptUtils.decryptURL(id);
      if (equipmentIdForUpdate !== null) {
        dispatch(
          equipmentAction.listEquipmentById(
            equipmentIdForUpdate,
            setSuccessForEquipmentFetching
          )
        );
      } else if (equipmentIdForUpdate === null) {
        dispatch(setIsLoading(false));
        setReady(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (equipmentDetailsRespo.id !== 0) {
      setImages(equipmentDetailsRespo.images);
      props.initialize({
        name: equipmentDetailsRespo.name,
        vehicleNumber: equipmentDetailsRespo.vehicleNumber,
        description: equipmentDetailsRespo.description,
        operatingWeight: equipmentDetailsRespo.operatingWeight,
        grossPower: equipmentDetailsRespo.grossPower,
        bucketCapacity: equipmentDetailsRespo.bucketCapacity,
        maxDiggingDepth: equipmentDetailsRespo.maxDiggingDepth,
        images: equipmentDetailsRespo.images,
        deviceId: equipmentDetailsRespo.deviceId,
        status: equipmentDetailsRespo.status,
      });
    }
    setReady(true);
  }, [equipmentDetailsRespo]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="inner-page-row">
          <div className="inline-block w-[100%] top-bar-sticky flex justify-between  dark:top-bar-bg-color">
            <div className="float-left flex items-center">
              <button
                type="button"
                className="mr-3"
                onClick={() => handleOpenPopup(handleNavigateBack)}
              >
                <img src={backBtnIcon} alt="" className="p-1" />
              </button>

              <h2 className="text-xl">
                <b className="ml-5 dark:head-text-color ">
                  {equipmentDetailsRespo?.name.length > 0
                    ? "Update Equipment"
                    : "Add Equipment"}
                </b>
              </h2>
            </div>

            <div className="float-right">
              <button
                className={`text-[14px] text-white rounded-md p-2 px-8 py-2 ${
                  !valid || images.length === 0 || deletedImageCount === 0
                    ? "btn bg-slate-300"
                    : "btn btn-primary"
                } `}
                disabled={
                  !valid || images.length === 0 || deletedImageCount === 0
                }
              >
                <div>
                  {equipmentDetailsRespo?.name?.length > 0 ? "Update" : "Save"}
                </div>
              </button>
            </div>
          </div>

          <div className=" bg-white mt-6 rounded-md flex p-10 flex-wrap dark:panel-bg-color">
            <div className="w-1/2 pr-4">
              <h3 className="text-blue-500 font-bold inline-block">
                <span className="underline mb-3 px-2 py-1 dark:head-text-color">
                  Basic
                </span>
              </h3>
              <div className="pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  <Field
                    type="text"
                    name="name"
                    component={renderInputName}
                    validate={[requiredValidation, nameValidation]}
                  />
                  <Field
                    type="text"
                    name="vehicleNumber"
                    component={renderVehicleNumber}
                    validate={[vehicleNumberValidation, alphaNumericValidation]}
                  />
                  <Field
                    type="text"
                    name="description"
                    component={renderDescription}
                    validate={[descriptionValidation]}
                  />

                  <Field
                    type="text"
                    name="operatingWeight"
                    component={renderOperatingWeight}
                    validate={[operatingWeightValidation]}
                  />
                  <Field
                    type="text"
                    name="grossPower"
                    component={renderGrossPower}
                    validate={[grossPowerValidation]}
                  />
                  <Field
                    type="text"
                    name="bucketCapacity"
                    component={renderBucketCapacity}
                    validate={[bucketCapacityValidation]}
                  />
                  <Field
                    type="text"
                    name="maxDiggingDepth"
                    component={renderMaxDiggingDepth}
                    validate={[maxDiggingDepthValidation]}
                  />
                  <Field
                    name="deviceId"
                    component={renderSelectDevice}
                    devices={equipmentDeviceData}
                    validate={[deviceValidation]}
                  />
                  <Field
                    name="status"
                    component={renderStatus}
                    status={statusData}
                    validate={[statusValidation]}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 pl-4 p-20">
              <label
                htmlFor="grossPower"
                className="block text-sm font-medium leading-6 text-gray-900 ml-9 dark:normal-text-color"
              >
                Images <span className="text-red-500">*</span>
              </label>
              <ImageUploadComponent
                images={images}
                onImageUpload={(_images) => {
                  setImages(_images);
                  updateDeletedImageCount(_images);
                }}
              />
              {valid && deletedImageCount === 0 ? (
                <span className=" ml-10 text-red-400 ">Image is required</span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </form>
      <ConfirmationDialouge
        isOpen={showConfirmationDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        dialouge={CONFIRMATION_DIALOUGES.IMAGE_DELETE_CONFIRMATION}
      />
    </>
  );
}

export default reduxForm({
  form: "create-equipment-form",
})(EquipmentCreate);
