import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDevice, IDeviceReduxState, IDeviceType, IDeviceTypeRequest } from "../device.interface";

const initialState: IDeviceReduxState = {
  name: "",
  deviceTypes: [],
  deviceDetails: {
    id: 0,
    name: "",
    imei: 0,
    deviceType: {
      id: 0,
      name: "",
      description: "",
    },
    sensorProtocol: 0,
    sensorIP: "",
    sensorPort: "",
    isCameraAdded: false,
    cameraIP: "",
    cameraPort: "",
    cameraProtocol: 0,
    cameraFrameRate: "",
    cameraResolution: "",
    images: [],
  }
};

const deviceSlice = createSlice({
  name: "deviceReducer",
  initialState,
  reducers: {
    setName(state, actions: PayloadAction<IDeviceReduxState>) {
      state.name = actions.payload.name;
      return state;
    },
    setDeviceType(state, actions: PayloadAction<IDeviceType[]>) {
      state.deviceTypes = actions.payload;
      return state;
    },
    setDevice(state, actions: PayloadAction<IDevice>) {
      console.log("actions.payload ::: ", actions.payload);
        state.deviceDetails = actions.payload;
        return state;
      },
  },
});

export const { setName, setDeviceType, setDevice } = deviceSlice.actions;
export default deviceSlice.reducer;
