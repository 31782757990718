import React from "react";

function NoDataFound() {
  return (
    <div className="flex justify-center items-center h-96 ">
      <div className="text-center">
        {/* <img
              style={{ width: 60, height: 60 }}
              src={noImageIcon}
              alt="noSearchResult"
            /> */}
        <h2>
          {" "}
          <b className="dark:head-text-color">No Data Found</b>{" "}
        </h2>
      </div>
    </div>
  );
}

export default NoDataFound;
