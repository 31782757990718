import React from "react";
import {
  IAddDeviceRequest,
  IDeviceListRequest,
  IDeviceTypeRequest,
} from "../device.interface";
import { DEVICE_API_ENDPOINTS } from "../../../config/default.config";
import axiosInstance from "../../../utils/intercepter.util";

const addDevice = async (formData: FormData) => {
  const response = await axiosInstance.post(
    DEVICE_API_ENDPOINTS.ADD_DEVICE,
    formData
  );
  return response;
};

const updateDevice = async (formData: FormData) => {
  const response = await axiosInstance.put(
    DEVICE_API_ENDPOINTS.UPDATE_DEVICE,
    formData
  );
  return response;
};

const getDeviceTypes = async () => {
  const response = await axiosInstance.get(
    DEVICE_API_ENDPOINTS.GET_DEVICE_TYPES
  );
  return response;
};

const getDeviceById = async (deviceId: number) => {
  const response = await axiosInstance.get(DEVICE_API_ENDPOINTS.GET_DEVICE_BY_ID.replace(':id', `${deviceId}`));
  return response;
};

const getDeviceList = async (IDeviceListRequest: IDeviceListRequest) => {
  const response = await axiosInstance.get(
    DEVICE_API_ENDPOINTS.GET_DEVICE_LIST.replace(
      ":page",
      `${IDeviceListRequest.Page}`
    )
      .replace(":pageSize", `${IDeviceListRequest.PageSize}`)
      .replace(":orderByDesc", `${IDeviceListRequest.OrderByDesc}`)
      .replace(":orderBy", IDeviceListRequest.OrderBy)
      .replace(":searchKey", IDeviceListRequest.SearchKey)
  );
  return response?.data;
};

const deleteDeviceById = async (deviceId: number) => {
  const response = await axiosInstance.delete(
    DEVICE_API_ENDPOINTS.DELETE_DEVICE.replace(":id", `${deviceId}`)
  );
  return response?.data;
};

export const deviceService = {
  addDevice,
  getDeviceTypes,
  getDeviceById,
  updateDevice,
  getDeviceList,
  deleteDeviceById,
};
