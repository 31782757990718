import { configureStore } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";
import loginReducer from "../modules/login/store/login.reducer";
import commonReducer from "../common/common.reducer";
import siteReducer from "../modules/site/store/site.reducer";
import equipmentReducer from "../modules/equipment/store/equipment.reducer";
import operatorReducer from "../modules/operator/store/operator.reducer";
import deviceReducer from "../modules/devices/store/device.reducer";
import customerReducer from "../modules/customers/store/customer.reducer";
import contractReducer from "../modules/contract/store/contract.reducer";
import assetKeycloakSlice from "./keycloak/asset-keycloak.slice";
export const store = configureStore({
    reducer: {
        form: formReducer,
        commonReducer:commonReducer,
        loginReducer:loginReducer,
        equipmentReducer:equipmentReducer,
        siteReducer:siteReducer,
        deviceReducer:deviceReducer,
        operatorReducer:operatorReducer,
        customerReducer : customerReducer,
        contractReducer:contractReducer,
        assetKeycloak: assetKeycloakSlice,
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
