import React, { useEffect, useState } from "react";
import closeIcon from "../../../assets/images/close_icon.svg";
import {
  IEquipmentListerItem,
  IInstance,
  IListerDetails,
  IListerItem,
  IListerPopupState,
  ISeletedInstance,
} from "./lister.component-interface";
import searchIcon from "../../../assets/images/search.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  IEquipmentListRequest,
  IGetAllInstanceListRequest,
} from "../../equipment/equipment.interface";
import { equipmentAction } from "../../equipment/services/equipment.actions";
import { IOperatorListRequest } from "../../operator/operator.interface";
import { operatorActions } from "../../operator/services/operator.actions";
import VlvLoader from "../../../assets/images/volvo-loader-low-quality.gif";
import { LISTER_HEADINGS } from "../../../config/default.config";
import { Panel } from "primereact/panel";

import deleteicon from "../../../assets/images/Delete_icon.svg";
import { Accordion, AccordionTab } from "primereact/accordion";

function ListerPopup({
  listerPopupState,
  setPopUpPayload,
  datas,
  setDatas,
  listerDetails,
  equipmentModel,
  equipmentListData,
  setEquipmentListData,
}: {
  listerPopupState: IListerPopupState;
  setPopUpPayload: any;
  datas: IListerItem[];
  setDatas: any;
  listerDetails: IListerDetails;
  equipmentListData: IEquipmentListerItem[];
  setEquipmentListData: any;
  equipmentModel?: string;
}) {
  const [page, setPage] = useState<number>(0);
  const [searchKey, setSearchKey] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [renderData, setRenderData] = useState<IListerItem[]>([]);
  const [fetchedData, setFetchedData] = useState<IListerItem[]>([]);
  const [selectedDatas, setSelectedDatas] = useState<IListerItem[]>([]);

  const [equipmentRenderData, setEquipmentRenderData] = useState<any>([]);
  const [equipmentModelData, setEquipmentModelData] = useState<any>([]);
  const [selectedEquipmentModel, setSelectedEquipmentModel] = useState<any>([]);
  const [equipmentDataIntance, setEquipmentDataInstance] = useState<any>({});
  const [selectedEquipments, setSelectedEquipments] = useState<any>([]);
  const [selectedModelList, setSelectedModelList] = useState<any>([]);
  const [lastAddedModel, setLastAddedModel] = useState("");

  useEffect(() => {
    getEquimentModel();
  }, []);

  const getEquimentModel = async () => {
    const responseData = await equipmentAction.getAllModels();
    let tmpEquipmentModel: any = [];
    responseData?.data?.map((item: string) => {
      let equipmentmodels = {
        label: item,
        value: item,
      };
      tmpEquipmentModel.push(equipmentmodels);
    });
    setEquipmentModelData(tmpEquipmentModel);

    let equipmentmodelList: { [key: string]: [any] } = {};
    let modelCollection: any = [];
    responseData?.data?.map((models: string) => {
      equipmentmodelList[models] = modelCollection;
    });
    setEquipmentDataInstance(equipmentmodelList);
  };

  //get Equipments
  const getEquipments = async (
    eqipmentListAllRequest: IEquipmentListRequest,
    isSearch: boolean
  ) => {
    const response = await equipmentAction.listAllEquipments(
      eqipmentListAllRequest
    );

    let tempLists: IListerItem[] = [];
    setTotalRecords(response?.totalItems);
    response?.data?.map((equipments: any) => {
      let tempListerItems: IListerItem = {
        id: equipments?.id,
        name: equipments?.name,
        isEquipmentList: true,
        isOperatorList: false,
      };
      tempLists.push(tempListerItems);
    });
    if (isSearch) {
      setFetchedData(tempLists);
    } else {
      setFetchedData([...fetchedData, ...tempLists]);
    }
  };

  // get all EquipmentInstance
  const getInstance = async (
    instanceListAllRequest: IGetAllInstanceListRequest,
    isSearch: boolean
  ) => {
    const response = await equipmentAction.getAllInstance(
      instanceListAllRequest
    );

    let tempinstance: any = [];
    const tempEquipmentDataInstance = { ...equipmentDataIntance };
    let tempLists: IListerItem[] = [];
    response?.data?.data.map((equipments: any) => {
      let instances: IInstance = {
        id: equipments?.id,
        name: equipments?.instanceName,
      };

      tempinstance.push(instances);
    });
    if (instanceListAllRequest?.modelName !== undefined) {
      tempEquipmentDataInstance[instanceListAllRequest.modelName] = [
        ...tempinstance,
      ];
    }

    setEquipmentDataInstance(tempEquipmentDataInstance);
  };

  const setSuccess = (state: boolean) => {};

  //get Operators
  const getOperatorList = async (
    operatorListRequest: IOperatorListRequest,
    isSearch: boolean
  ) => {
    const response = await operatorActions.getOperatorList(
      operatorListRequest,
      setSuccess
    );
    setTotalRecords(response?.totalItems);
    let tempLists: IListerItem[] = [];
    response?.data.map((operator: any) => {
      let tempListerItems: IListerItem = {
        id: operator?.id,
        name: operator?.name,
        isEquipmentList: false,
        isOperatorList: true,
      };
      tempLists.push(tempListerItems);
    });
    if (isSearch) {
      setFetchedData(tempLists);
    } else {
      setFetchedData([...fetchedData, ...tempLists]);
    }
  };

  const OnExpand = (modelName: string) => {
    let instanceListAllRequest: IGetAllInstanceListRequest = {
      getAll: true,
      modelName: modelName,
      pageNo: 1,
      pageSize: 10,
      searchKey: searchKey,
      sortKey: "name",
      sortOrder: "asc",
    };
    getInstance(instanceListAllRequest, false);
  };

  const fetchMoreData = (isSearch: boolean) => {
    if (listerPopupState.isEquipmentList) {
      let instanceListAllRequest: IGetAllInstanceListRequest = {
        getAll: true,
        modelName: equipmentModel,
        pageNo: isSearch ? 1 : page + 1,
        pageSize: 10,
        searchKey: searchKey,
        sortKey: "name",
        sortOrder: "asc",
      };
    }
    if (listerPopupState.isOperatorList) {
      let operatorListRequest: IOperatorListRequest = {
        Page: isSearch ? 1 : page + 1,
        PageSize: 10,
        OrderBy: "Name",
        OrderByDesc: true,
        SearchKey: searchKey,
      };
      setPage(operatorListRequest.Page);
      getOperatorList(operatorListRequest, isSearch);
    }
  };

  const handlleSelectedInstance = (instanceItem: ISeletedInstance) => {
    const isItemSelected = selectedEquipments.some(
      (selectedItem: any) => selectedItem.insatnceId === instanceItem.insatnceId
    );
    if (isItemSelected) {
      const updatedSelectedDatas = selectedEquipments.filter(
        (selectedItem: any) =>
          selectedItem.insatnceId !== instanceItem.insatnceId
      );
      setSelectedEquipments(updatedSelectedDatas);
    } else {
      setSelectedEquipments([...selectedEquipments, instanceItem]);
    }
  };

  const handleSelectItem = (item: IListerItem) => {
    const isItemSelected = selectedDatas.some(
      (selectedItem) => selectedItem.id === item.id
    );

    if (isItemSelected) {
      const updatedSelectedDatas = selectedDatas.filter(
        (selectedItem) => selectedItem.id !== item.id
      );
      setSelectedDatas(updatedSelectedDatas);
    } else {
      setSelectedDatas([...selectedDatas, item]);
    }
  };

  const handleRemoveItem = (item: IListerItem) => {
    const updatedSelectedDatas = selectedDatas.filter(
      (selectedItem) => selectedItem !== item
    );
    setSelectedDatas(updatedSelectedDatas);
  };

  const moveSelectedDatas = () => {
    if (listerPopupState.isEquipmentList) {
      const otherDatas: IEquipmentListerItem[] = equipmentListData.filter(
        (item: IEquipmentListerItem) => item.isEquipmentList === false
      );
      selectedEquipments.forEach((item: IEquipmentListerItem) => {
        otherDatas.push(item);
      });
      setEquipmentListData(otherDatas);
    } else if (listerPopupState.isOperatorList) {
      const otherDatas: IListerItem[] = datas.filter(
        (item: IListerItem) => item.isOperatorList === false
      );
      selectedDatas.forEach((item: IListerItem) => {
        otherDatas.push(item);
      });
      setDatas(otherDatas);
    }
    setPage(0);
    setSearchKey("");
    setSelectedDatas([]);
    setFetchedData([]);
    setPopUpPayload({ ...listerPopupState, isOpen: false });
  };

  const handleClosePopUp = () => {
    setPage(0);
    setSearchKey("");
    setFetchedData([]);
    setPopUpPayload({ ...listerPopupState, isOpen: false });
  };

  const handleSearchKey = (searchKey: string) => {
    setSearchKey(searchKey);
  };

  useEffect(() => {
    if (listerPopupState.isOpen) {
      fetchMoreData(false);
    }
    if (listerPopupState.isEquipmentList) {
      const equipmentData: any = equipmentListData.filter(
        (dataItem: any) => dataItem.isEquipmentList === true
      );

      const uniqueObjects = Array.from(
        new Set(
          equipmentData?.map(
            (object: { modelName: string }) => object?.modelName
          )
        )
      ).map((modelName) => ({ modelName }));

      let tempSelectedEquipmentMode: { label: string; value: string }[] = [];
      uniqueObjects.forEach((equipmentDetails: any) => {
        let selectedModelList = {
          label: equipmentDetails?.modelName,
          value: equipmentDetails?.modelName,
        };
        tempSelectedEquipmentMode.push(selectedModelList);
      });
      setSelectedEquipmentModel(tempSelectedEquipmentMode);
      setEquipmentRenderData(equipmentData);
      setSelectedEquipments(equipmentData);
    } else if (listerPopupState.isOperatorList) {
      const operatorData: IListerItem[] = datas.filter(
        (dataItem: IListerItem) => dataItem.isOperatorList === true
      );
      setSelectedDatas(operatorData);
      setRenderData(operatorData);
    }
  }, [listerPopupState]);

  useEffect(() => {
    setFetchedData([]);
    fetchMoreData(true);
  }, [searchKey]);

  const handleSelectModel = (selectedList: any, selectedItem: any) => {
    setSelectedEquipmentModel(selectedList);
  };

  if (!listerPopupState.isOpen) {
    return null;
  }

  const handleModelFromList = (e: any) => {
    let selectedModelList = {
      label: e.target.value,
      value: e.target.value,
    };
    setSelectedModelList([...selectedEquipmentModel, selectedModelList]);
    setLastAddedModel(e.target.value);
  };
  const handleAddedModel = () => {
    //remove item in dropdown
    let removeedArray = equipmentModelData.filter(
      (model: any) => lastAddedModel !== model.label
    );
    setEquipmentModelData(removeedArray);

    setSelectedEquipmentModel(selectedModelList);
  };
  const handleRemoveSelectedList = (item: any) => {
    //remove model in panel
    let removeedArray = selectedEquipmentModel.filter(
      (models: any) => models.label !== item.label
    );
    setSelectedEquipmentModel(removeedArray);

    //add to dropdown removed item
    let equipmentmodels = {
      label: item.label,
      value: item.label,
    };

    setEquipmentModelData([...equipmentModelData, equipmentmodels]);

    // const updatedSelectedDatas = selectedEquipments.filter(
    //   (selectedItem:any) => selectedItem.modelName!==item.label
    // );

    // console.log("selectedEquipmentsselectedEquipmentsselectedEquipmentsselectedEquipments",updatedSelectedDatas);

    // setEquipmentListData([...equipmentListData,updatedSelectedDatas])
    // setEquipmentListData([])
  };

  return (
    <div className="fixed inset-0 flex flex-col items-center align-middle justify-center z-50  popup-overlay">
      <div className="modal p-6 bg-white rounded shadow-lg  w-[50%] h-540 overflow-y-hidden dark:panel-bg-color">
        <div className="flex justify-between mb-4">
          <div className="mt-1 font-semibold text-lg">
            {listerDetails.isEquipmentList
              ? LISTER_HEADINGS.EQUIPMENT_LIST
              : LISTER_HEADINGS.OPERATOR_LIST}
          </div>
          <button className="" onClick={handleClosePopUp}>
            <img className="w-7" src={closeIcon} alt="close" />
          </button>
        </div>

        {listerDetails.isOperatorList ? (
          <div>
            <form>
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>

                <input
                  type="text"
                  name="searchKey"
                  id="searchKey"
                  className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder={`Search ${
                    listerPopupState.isEquipmentList
                      ? " Equipments"
                      : " Operators"
                  }`}
                  onChange={(e) => handleSearchKey(e?.target?.value)}
                />
              </div>
            </form>
            <div
              className="modal-content py-1 overflow-auto flex"
              style={{ height: "50px" }}
            >
              {selectedDatas.map((item: IListerItem, index) => (
                <div
                  className="flex p-1 rounded-lg mr-2 border border-slate-300 text-sm font-medium align-center px-3 w-32"
                  key={item.id}
                >
                  <span
                    className="flex-1 leading-8"
                    style={{
                      maxWidth: "80%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.name}
                  </span>
                  <img
                    src={closeIcon}
                    alt="close icon"
                    className="ml-4 w-6  rounded-full"
                    onClick={() => handleRemoveItem(item)}
                  />
                </div>
              ))}
            </div>
            <div className="modal-content p-2  overflow-auto">
              <InfiniteScroll
                className=""
                dataLength={fetchedData.length}
                next={() => fetchMoreData(false)}
                hasMore={fetchedData.length === totalRecords ? false : true}
                loader={
                  <div className="bg-white opacity-[0.9]">
                    {
                      <img
                        src={VlvLoader}
                        className="h-32 absolute top-[45%] left-1/2"
                      />
                    }
                  </div>
                }
              >
                {fetchedData.map((item: IListerItem, index) => (
                  <>
                    <label
                      className="flex items-center space-x-2 p-2 text-black "
                      key={item.id}
                    >
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        onChange={(e) => handleSelectItem(item)}
                        disabled={renderData.some(
                          (yourItem: IListerItem) => yourItem.id === item.id
                        )}
                        checked={selectedDatas.some(
                          (selectedItem) => selectedItem.id === item.id
                        )}
                      />
                      <span
                        // className={
                        //   renderData.some(
                        //     (yourItem: IListerItem) => yourItem.id === item.id
                        //   )
                        //     ? "text-gray-500"
                        //     : ""
                        // }
                        className="dark:text-white"
                      >
                        {item.name}
                      </span>
                    </label>
                    <hr className="flex-grow" />
                  </>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        ) : (
          <div>
            {/* <Select options={equipmentModelData} isMulti onChange={handleSelectModel} /> */}

            <div className="flex">
              <select
                id="equipmentModel"
                name="equipmentModel"
                onChange={handleModelFromList}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   dark:panel-bg-color dark:normal-text-color"
              >
                <option value="">Select Equipment Model</option>
                {equipmentModelData?.map(
                  (models: { label: string; value: string }) => (
                    <option value={models?.value}>{models?.label}</option>
                  )
                )}
              </select>
              <button
                type="button"
                onClick={handleAddedModel}
                className=" bg-blue-700 text-white ml-5 p-2 px-4 rounded-md"
              >
                Add
              </button>
            </div>
            <div className="mt-7 pt-2 rounded-md">
              {selectedEquipmentModel?.map(
                (item: { id: number; label: string }) => {
                  return (
                    <div className="flex" key={item?.label}>
                      {/* <Accordion  >
                  <AccordionTab header="Header I">
                      Content I
                  </AccordionTab>
                  <AccordionTab header="Header II">
                      Content II
                  </AccordionTab>
                  <AccordionTab header="Header III">
                      Content III
                  </AccordionTab>
              </Accordion> */}

                      <Panel
                        header={item?.label}
                        toggleable
                        className="mb-4 px-2 text-slate-800  text-lg w-11/12 bg-slate-100 rounded-md p-2 dark:bg-gray-700 dark:text-white"
                        collapsed={true}
                        onExpand={() => OnExpand(item?.label)}
                      >
                        <div className=" h-48 overflow-y-auto mt-5">
                          {equipmentDataIntance[item?.label]?.map(
                            (instance: { id: string; name: string }) => (
                              <>
                                <label
                                  className="flex items-center space-x-5 pt-5 text-black"
                                  key={item?.id}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-checkbox"
                                    onChange={(e) =>
                                      handlleSelectedInstance({
                                        modelName: item?.label,
                                        insatnceId: instance?.id,
                                        instanceName: instance?.name,
                                        isEquipmentList: true,
                                        isOperatorList: false,
                                      })
                                    }
                                    // disabled={equipmentRenderData.some(
                                    //   (yourItem: any) => yourItem.insatnceId===instance.id

                                    //  )}
                                    checked={selectedEquipments.some(
                                      (selectedItem: any) =>
                                        selectedItem.insatnceId === instance.id
                                    )}
                                  />
                                  <span className="dark:text-white">
                                    {instance?.name}
                                  </span>
                                </label>
                                <hr className="flex-grow" />
                              </>
                            )
                          )}
                        </div>
                      </Panel>
                      <button
                        type="button"
                        onClick={() => handleRemoveSelectedList(item)}
                        className="  text-slate-600 ml-5 h-4"
                      >
                        <img src={deleteicon} className="h-10" />
                      </button>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
      <div className="p-4 flex justify-end bg-white w-[50%] dark:panel-bg-color">
        <button
          type="button"
          className={`px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${
            listerDetails.isOperatorList
              ? selectedDatas.length === 0
              : selectedEquipments.length === 0
              ? " bg-blue-800"
              : "bg-slate-300"
          }`}
          onClick={moveSelectedDatas}
          disabled={
            listerDetails.isOperatorList
              ? selectedDatas.length === 0
              : selectedEquipments.length === 0
          }
        >
          Add +
        </button>
      </div>
    </div>
  );
}

export default ListerPopup;
