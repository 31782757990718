import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { siteActions } from "./service/site.action";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/Delete_icon.svg";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import noImageIcon from "../../assets/images/No-image.jpg";
import {
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_TITLES,
  ERROR,
  NAVIGATIONS,
  SESSION_DETAILS,
  SITE_ERROR_MESSAGE,
  SITE_MESSAGES,
  SUBMIT_BUTTON_TEXT,
} from "../../config/default.config";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";

import { setSiteDetails } from "./store/site.reducer";
import { ISiteRespone } from "./site.interface";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";
import {
  setIsError,
  setIsLoading,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import { Tooltip } from "@mui/material";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { encryptUtils } from "../../utils/encryption.utils";

export type IPagination = {
  area: string;
  id: string;
  lattitude: string;
  location: string;
  longitude: string;
  status: string;
};

export function SiteView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { open, close } = useConfirmationPopup();
  const [ready, setReady] = useState<boolean>(false);

  const siteDetailsRespo = useAppSelector(
    (state) => state.siteReducer.siteDetails
  );

  const setSuccessForSiteFetching = (state: boolean) => {
    if (state) {
      setReady(true);
      dispatch(setIsLoading(false));
    } else {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(
      siteActions.getSiteById(
        Number(encryptUtils.decryptURL(id)),
        setSuccessForSiteFetching
      )
    );
  };

  const handleViewClick = () => {
    navigate(NAVIGATIONS.SITE_LIST);
    let emptySiteDetails: ISiteRespone = {
      id: 0,
      location: "",
      area: "",
      lattitude: "",
      longitude: "",
      status: 3,
      images: [],
    };
    dispatch(setSiteDetails(emptySiteDetails));
  };
  const handleNavigateBack = () => {
    navigate(NAVIGATIONS.SITE_LIST);
    let emptySiteDetails: ISiteRespone = {
      id: 0,
      location: "",
      area: "",
      lattitude: "",
      longitude: "",
      status: 3,
      images: [],
    };
    dispatch(setSiteDetails(emptySiteDetails));
  };
  const handleEditClick = () => {
    navigate(
      NAVIGATIONS.SITE_UPDATE.replace(
        ":id",
        encryptUtils.encryptURL(encryptUtils.decryptURL(id))
      )
    );
  };

  const handleDeleteConfirmation = (confirm: boolean) => {
    if (confirm) {
      siteActions.deleteSite(siteDetailsRespo.id, setSuccessOfDelete);
    } else {
      handleClosePopup();
    }
  };
  const setSuccessOfDelete = (status: boolean, errorCode: any) => {
    if (status && errorCode === null) {
      dispatch(setToastMsg(SITE_MESSAGES.SITE_DELETED));
      dispatch(setToast(true));
      handleViewClick();
      handleClosePopup();
    } else {
      if (
        !status &&
        errorCode != null &&
        errorCode === SITE_ERROR_MESSAGE.SITE_DELETE_ERROR_CODE
      ) {
        dispatch(setToastMsg(SITE_MESSAGES.SITE_UNABLE_DELETE));
        dispatch(setIsError(true));
        dispatch(setToast(true));
      } else {
        dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
        dispatch(setIsError(true));
        dispatch(setToast(true));
      }

      handleClosePopup();
    }
  };

  const handleClosePopup = () => {
    close();
  };

  const handleNavigateHistory = () => {
    navigate(
      NAVIGATIONS.SITE_HISTORY.replace(
        ":id",
        encryptUtils.encryptURL(encryptUtils.decryptURL(id))
      )
    );
  };

  const handleDeletePopup = (confirmation: any) => {
    open(
      CONFIRMATION_TITLES.CONFIRM_DELETE,
      SITE_MESSAGES.DEVICE_DELETE_CONFIRMATION.replace(
        ":locationName",
        siteDetailsRespo?.location
      ),
      SUBMIT_BUTTON_TEXT.YES,
      CANCEL_BUTTON_TEXT.NO,
      () => {
        confirmation(true);
      },
      () => {
        confirmation(false);
      }
    );
  };

  if (!ready) {
    return null;
  }

  return (
    <div className="inner-page-row grid md:grid-cols-3 gap-28 mt-6">
      <div className="col-span-2 box-border">
        <div className="flex justify-between">
          <div className=" flex text-lg">
            <button type="button" className="mr-3" onClick={handleNavigateBack}>
              <img src={backBtnIcon} alt="" className="p-1" />
            </button>
            <div className=" font-bold p-1 text-gray-600 mt-1 dark:head-text-color">Site</div>
            <div className="p-1 text-gray-500 mt-1 dark:head-text-color">Status:</div>
          </div>
          {/* <div
              className=" text-blue-600 p-2 text-sm mt-1 cursor-pointer"
              onClick={() => handleNavigateHistory()}
            >
              History
            </div> */}
        </div>

        <div className=" bg-white rounded-3xl shadow-md mt-4 dark:tile-bg-color">
          <div className="p-10 ">
            <div className=" flex justify-between">
              <div>
                <div className=" inline p-2 text-gray-500 text-xl ">
                  <b className="dark:head-text-color">Site</b>
                  <Tooltip title="Update" placement="top">
                    <img
                      className=" inline p-2 ml-5 cursor-pointer"
                      src={editIcon}
                      alt="edit"
                      onClick={handleEditClick}
                    />
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <img
                      className=" inline p-2 cursor-pointer"
                      src={deleteIcon}
                      alt="delete"
                      onClick={() => {
                        handleDeletePopup(handleDeleteConfirmation);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
              <div className=" text-gray-400 text-md  dark:normal-text-color">
                Status:
                <span
                  className={`${
                    siteDetailsRespo?.status === 0
                      ? "text-green-600"
                      : siteDetailsRespo?.status === 1
                      ? "text-red-400"
                      : "text-yellow-400"
                  } text-md`}
                >
                  {siteDetailsRespo?.status === 0
                    ? " Active"
                    : siteDetailsRespo?.status === 1
                    ? " Inactive"
                    : " StandBy"}
                </span>
              </div>
            </div>
            <div className="sm:block md:flex md:p-5">
              <div className="rounded-xl">
                <img
                  style={{ width: 100, height: 100 }}
                  src={
                    siteDetailsRespo?.images[0]
                      ? `${siteDetailsRespo?.images[0]?.resource}`
                      : noImageIcon
                  }
                  className="border border-gray h-32 rounded-2xl"
                />
              </div>
              <div className="px-8">
                <div className="font-bold dark:head-text-color">{siteDetailsRespo?.location}</div>
                <div className="flex pt-2">
                  <div className=" pr-3  text-gray-400  dark:normal-text-color">Area :</div>
                  <div className=" font-bold text-gray-500 dark:head-text-color">
                    {siteDetailsRespo?.area}
                  </div>
                </div>
                <div className="flex">
                  <div className=" pr-3  text-gray-400  dark:normal-text-color">Latitude :</div>
                  <div className=" font-bold text-gray-500 dark:head-text-color">
                    {siteDetailsRespo?.lattitude}
                  </div>
                </div>
                <div className="flex">
                  <div className=" pr-3  text-gray-400  dark:normal-text-color">Longitude :</div>
                  <div className=" font-bold text-gray-500 dark:head-text-color">
                    {siteDetailsRespo?.longitude}
                  </div>
                </div>
              </div>
            </div>
            {/* <hr className="flex-grow" />
            <div className="text-gray-400 text-md font-medium mt-2">
              More about site
            </div> */}
          </div>
        </div>
      </div>
      {/* <div>
        Operation Hours
        <div className=" bg-white rounded-3xl shadow-md mt-8 h-24"></div>
      </div> */}
    </div>
  );
}
