import { SEQROPS_INSTANCE_API } from "../../../config/default.config";
import { IRecentAlarmPopParam } from "./previewbox.interface";
import closeIcon from "../../../assets/images/close_icon.svg";
import { useEffect, useState } from "react";
import {
  IOperator,
  IOperatorListRequest,
} from "../../operator/operator.interface";
import { operatorActions } from "../../operator/services/operator.actions";
import { IOperatorStatusUpdateRequest } from "../../dashboard/alarmSection/alarmSection.interface";
import { dashboardAction } from "../../dashboard/service/dashbord.action";
import { useAppDispatch } from "../../../config/store.hooks";

interface IRecentAlarmPopParams {
  operatorInfo: IRecentAlarmPopParam;
  onClose: () => void;
}

function RecentAlarmPopup({ operatorInfo, onClose }: IRecentAlarmPopParams) {
  const [operatorDetails, setOperatordetails] = useState<IOperator[]>([]);
  const [selectedOperator, setSelectedOperator] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    let operatorListRequest: IOperatorListRequest = {
      Page: 1,
      PageSize: 100,
      OrderBy: "Name",
      OrderByDesc: true,
      SearchKey: "",
    };
    getOperatorList(operatorListRequest);
  }, []);

  const setSuccess = (state: boolean) => {};

  const getOperatorList = async (operatorListRequest: IOperatorListRequest) => {
    const response = await operatorActions.getOperatorList(
      operatorListRequest,
      setSuccess
    );
    setOperatordetails(response?.data);
  };

  const handleConfirm = () => {
    let operatorStatusUpdateRequest: IOperatorStatusUpdateRequest = {
      operatorId: selectedOperator,
      dashboardImage: operatorInfo?.image,
      dateOfIdentification: operatorInfo?.dateOfIdentification,
    };

    OperatorStatusUpdate(operatorStatusUpdateRequest);
    onClose();
  };

  const OperatorStatusUpdate = async (
    operatorStatusUpdateRequest: IOperatorStatusUpdateRequest
  ) => {
    const response = await dispatch(
      dashboardAction.updateOperatorStatus(operatorStatusUpdateRequest)
    );
  };

  const handleOnSelect = (events: any) => {
    setSelectedOperator(events.target.value);
  };

  return operatorInfo.showPopup ? (
    <div className="fixed inset-0 flex items-center justify-center z-50  popup-overlay ">
      <div className=" bg-white flex flex-col dark:panel-bg-color rounded-xl">
        <div className=" px-6 py-6 flex w-full">
          <img
            src={SEQROPS_INSTANCE_API.GET_OPERATORIMAGE.replace(
              ":image",
              operatorInfo?.image
            )}
            className="h-72 w-72 rounded-xl"
          />
          <div className=" pl-14 pt-5 w-[60vh]">
            <div className=" flex justify-end  items-center p-">
              <button className="" onClick={onClose}>
                <img className="" src={closeIcon} alt="close" />
              </button>
            </div>
            <div className="dark:head-text-color dark:normal-text-color text-xl font-bold">
              Operator not recognized
            </div>
            <div className="dark:head-text-color font-light pt-5">
              Plaese confirm this operator
            </div>

            <select
              className=" w-60 dark:bg-black dark:text-white mt-3"
              onChange={handleOnSelect}
            >
              <option>Select Operator</option>
              {operatorDetails?.map((item: IOperator) => (
                <option value={item?.id}>{item?.name}</option>
              ))}
            </select>

            <div className=" flex flex-row  mt-16">
              <button
                className=" rounded-lg border-[1px] border-zinc-700 p-3 m-1 w-36"
                onClick={onClose}
              >
                Ignore
              </button>
              <button
                className=" rounded-lg border-[1px] border-zinc-700 p-3 m-1 w-36"
                onClick={onClose}
              >
                No
              </button>
              <button
                className=" rounded-lg border-[1px] bg-blue-500 p-3 m-1 text-white w-36"
                onClick={handleConfirm}
              >
                Yes confim
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
export default RecentAlarmPopup;
