import { IImageInfo } from "../components/image-upload/image-upload.component";

export interface IAddDeviceRequest {
  id: number;
  name: string;
  imei: number;
  deviceType: string;
  sensorProtocol: string;
  sensorIP: string;
  sensorPort: string;
  isCameraAdded: boolean;
  cameraIP: string;
  cameraPort: string;
  cameraProtocol: string;
  cameraFrameRate: string;
  cameraResolution: string;
  images: any[];
  isDeleted: boolean;
}

export interface IUpdateDeviceRequest {
  id: number;
  name: string;
  imei: number;
  deviceType: number;
  sensorProtocol: string;
  sensorIP: string;
  sensorPort: string;
  isCameraAdded: boolean;
  cameraIP: string;
  cameraPort: string;
  cameraProtocol: string;
  cameraFrameRate: string;
  cameraResolution: string;
  storageType: number;
  isDeleted: boolean;
  images: any[];
}

export interface IDeviceTypeRequest {
  Page: number;
  PageSize: number;
  OrderByDesc:  boolean;
  OrderBy: string;
  SearchKey: string;
}

export interface IDeviceType {
  id: number;
  name: string;
  description: string;
}

export interface IDeviceReduxState {
  name: string;
  deviceTypes: IDeviceType[];
  deviceDetails: IDevice;
}

export interface IDevice {
  id: number;
  name: string;
  imei: number;
  deviceType: IDeviceType;
  sensorProtocol: number;
  sensorIP: string;
  sensorPort: string;
  isCameraAdded: boolean;
  cameraIP: string;
  cameraPort: string;
  cameraProtocol: number;
  cameraFrameRate: string;
  cameraResolution: string;
  images: IImageInfo[];
}

export interface IDeviceListRequest {
  Page: number;
  PageSize: number;
  OrderByDesc: boolean;
  OrderBy: string;
  SearchKey: string;
}

export type IDeviceItem = {
  id: number;
  name: string;
  imei: number
  image: string;
};

export interface IPaginationPayload{
  page: number;
  pageSize: number;
  orderByDesc: boolean;
  orderBy: string;
  searchKey: string;
}

export interface IFileInfo {
  id: number;
  deviceId: number;
  storageType: number;
  reference: string;
  url: string;
  extention: string;
}

export const DEVICE_OPERATIONS = {
  UPDATE: "UPDATE",
  CREATE: "CREATE",
};

export interface IDeviceErrors{
  name : string;
  imei: string;
} 
