import React from "react";

const UnderDevelopment = () => {
  return (
    <div className="under-development-container dark:head-text-color">
      <h1 className="under-development-heading">Under Development</h1>
      <p className="under-development-message">
        This feature is currently under development.
      </p>
    </div>
  );
};

export default UnderDevelopment;
