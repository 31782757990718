import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Login from "../modules/login/login";
import DashboardComponent from "../modules/dashboard/dashboard";
import { DeviceList } from "../modules/devices/device.list";
import DeviceCreate from "../modules/devices/device.create";
import ViewDevice from "../modules/devices/device.view";
import { SiteView } from "../modules/site/site.view";
import ForgotVerifyEmail from "../modules/login/forgot-password";
import ForgotPassword from "../modules/login/reset-password";
import { EquipmentList } from "../modules/equipment/equipment.list";
import EquipmentCreate from "../modules/equipment/equipment.create";
import { EquipmentView } from "../modules/equipment/equipment.view";
import { NAVIGATIONS } from "../config/default.config";
import { useAppSelector } from "../config/store.hooks";
import LayoutComponent from "../modules/components/layout.component";
import UnderDevelopment from "../modules/components/under-development.component";
import { SiteList } from "../modules/site/site.list";
import SiteCreate from "../modules/site/site.create";
import { CustomerList } from "../modules/customers/customer.list";
import { CustomerView } from "../modules/customers/customer.view";
import CustomerCreate from "../modules/customers/customer.create";
import { OperatorList } from "../modules/operator/operator.list";
import CreateOperator, {
  OperatorCreate,
} from "../modules/operator/operator.create";
import { OperatorView } from "../modules/operator/operator.view";
import { ContractList } from "../modules/contract/contract.list";
import CreateContract from "../modules/contract/contract.create";
import { ContractView } from "../modules/contract/contract.view";
import HistoryLayout from "../modules/components/history.component/historyLayout";
import { AlarmSectionList } from "../modules/dashboard/alarmSection/alarmSection.list";
import { AlarmSectionView } from "../modules/dashboard/alarmSection/alarmSection.view";
import OperatorStatusView from "../modules/dashboard/operator.status.view";
import RecentVideoList from "../modules/dashboard/alarmSection/alarmSection.recent.video.list";


export function MainNavigation() {
  const token = useAppSelector((state) => state.loginReducer.accessToken);

  return (
    <Routes>
      {/* {token ? ( */}
        <Route
          path="/"
          element={
            <LayoutComponent>
              <Outlet />
            </LayoutComponent>
          }
        >
          <Route path="" element={<Outlet />}>
            <Route index element={<DashboardComponent />} />
           <Route path={NAVIGATIONS.ALARM_SECTION_LIST} element={<AlarmSectionList/>}/>
           <Route path={NAVIGATIONS.ALARM_SECTION_VIEW} element={<AlarmSectionView/>}/>
           <Route path={NAVIGATIONS.ALARM_SECTION_VIDEO_LIST} element={<RecentVideoList/>}/>
           <Route path={NAVIGATIONS.OPERATOR_STATUS_VIEW} element={<OperatorStatusView/>}/>
          </Route>
          <Route path={NAVIGATIONS.EQUIPMENT_LIST} element={<Outlet />}>
            <Route index element={<EquipmentList />} />
            <Route
              path={NAVIGATIONS.EQUIPMENT_ADD}
              element={<EquipmentCreate />}
            />
            <Route
              path={NAVIGATIONS.EQUIPMENT_UPDATE}
              element={<EquipmentCreate />}
            />
            <Route
              path={NAVIGATIONS.EQUIPMENT_VIEW}
              element={<EquipmentView />}
            />
            <Route
              path={NAVIGATIONS.EQUIPMENT_HISTORY}
              element={<HistoryLayout />}
            />
          </Route>
          <Route path={NAVIGATIONS.OPERATOR_LIST} element={<Outlet />}>
            <Route index element={<OperatorList />} />
            <Route
              path={NAVIGATIONS.OPERATOR_ADD}
              element={<OperatorCreate />}
            />
            <Route
              path={NAVIGATIONS.OPERATOR_UPDATE}
              element={<OperatorCreate />}
            />
            <Route
              path={NAVIGATIONS.OPERATOR_VIEW}
              element={<OperatorView />}
            />
            <Route
              path={NAVIGATIONS.OPERATOR_HISTORY}
              element={<HistoryLayout />}
            />
          </Route>
          <Route path={NAVIGATIONS.SITE_LIST} element={<Outlet />}>
            <Route index element={<SiteList />} />
            <Route path={NAVIGATIONS.SITE_VIEW} element={<SiteView />} />
            <Route path={NAVIGATIONS.SITE_ADD} element={<SiteCreate />} />
            <Route path={NAVIGATIONS.SITE_UPDATE} element={<SiteCreate />} />
            <Route
              path={NAVIGATIONS.SITE_HISTORY}
              element={<HistoryLayout />}
            />
          </Route>
          <Route path={NAVIGATIONS.CUSTOMER_LIST} element={<Outlet />}>
            <Route index element={<CustomerList />} />
            <Route
              path={NAVIGATIONS.CUSTOMER_VIEW}
              element={<CustomerView />}
            />
            <Route
              path={NAVIGATIONS.CUSTOMER_ADD}
              element={<CustomerCreate />}
            />
            <Route
              path={NAVIGATIONS.CUSTOMER_UPDATE}
              element={<CustomerCreate />}
            />
            <Route
              path={NAVIGATIONS.CUSTOMER_HISTORY}
              element={<HistoryLayout />}
            />
          </Route>
          <Route path={NAVIGATIONS.DEVICE_LIST} element={<Outlet />}>
            <Route index element={<DeviceList />} />
            <Route path={NAVIGATIONS.DEVICE_ADD} element={<DeviceCreate />} />
            <Route path={NAVIGATIONS.DEVICE_VIEW} element={<ViewDevice />} />
            <Route
              path={NAVIGATIONS.DEVICE_UPDATE}
              element={<DeviceCreate />}
            />
          </Route>
          <Route path={NAVIGATIONS.CONTRACT_LIST} element={<Outlet />}>
            <Route index element={<ContractList />} />
            <Route
              path={NAVIGATIONS.CONTRACT_ADD}
              element={<CreateContract />}
            />
            <Route
              path={NAVIGATIONS.CONTRACT_VIEW}
              element={<ContractView />}
            />
            <Route
              path={NAVIGATIONS.CONTRACT_UPDATE}
              element={<CreateContract />}
            />
          </Route>
          <Route
            path={NAVIGATIONS.OPERATOR_HISTORY}
            element={<UnderDevelopment />}
          />
          <Route path={NAVIGATIONS.REPORTS} element={<UnderDevelopment />} />
        </Route>
      {/* ) : (
        <Route path="/" element={<Outlet />}>
          <Route index element={<Login />} />
          <Route
            path={NAVIGATIONS.FORGOT_PASSWORD}
            element={<ForgotVerifyEmail />}
          />
          <Route
            path={NAVIGATIONS.RESET_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route path="/" element={<Navigate to={"/"} />} />
        </Route>
      )} */}
      <Route path="/*" element={<Navigate to={NAVIGATIONS.DASHBOARD} />} />
    </Routes>
  );
}
