import React, { useEffect, useState } from "react";
import PaginatedItems from "../../components/pagination.component";
import NoDataFound from "../../components/noDataFound";

import backBtnIcon from "../../../assets/images/Back_icon.svg";
import { useNavigate } from "react-router-dom";
import { NAVIGATIONS, OPERATOR_STATUS } from "../../../config/default.config";
import {
  IRecentAlarmResponse,
  IRecentMediaRequest,
} from "./alarmSection.interface";
import { IEquipmentDetailsResponse } from "../../components/status.component/status.table.interface";
import { useAppDispatch } from "../../../config/store.hooks";
import { dashboardAction } from "../service/dashbord.action";
import right from "../../../assets/images/right_black.svg";
import moment from "moment";
import RecentAlarmPopup from "../../components/preview-box/recent.alarm.popup";

export function AlarmSectionList() {
  let th1 = "Date And Time",
    th2 = "Vehicle Number",
    th3 = "Status";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [recentAlarmData, setRecentAlarmData] = useState<
    IRecentAlarmResponse[]
  >([]);
  const [equipmentDetailsData, setEquipmentDetailsData] = useState<
    IEquipmentDetailsResponse[]
  >([]);
  const [paginationPayload, setPaginationPayload] =
  useState<IRecentMediaRequest>({
    sortTerm: "",
    sortOrder: "DESC",
    page: 0,
    pageSize: 13
  });

  const [totalRecords, setTotalRecords] = useState();
  const [operatorImage,setOperatorImage]=useState({image:"",showPopup:false,dateOfIdentification:""})

 
  useEffect(() => {
    let recentAlarmPaginationPayload: IRecentMediaRequest = {
      sortTerm: "",
      sortOrder: "DESC",
      page: 0,
      pageSize: 13,
    };
    recentAlarm(recentAlarmPaginationPayload);
  }, []);


  const onPagination=(pagenumber: number)=>{
    setPaginationPayload({
      ...paginationPayload,
      page: pagenumber,
    });

    let recentAlarmPaginationPayload: IRecentMediaRequest = {
      sortTerm: paginationPayload.sortTerm,
      sortOrder:paginationPayload.sortOrder,
      page: pagenumber,
      pageSize: paginationPayload.pageSize,
    };
    recentAlarm(recentAlarmPaginationPayload);
  }


  const recentAlarm = async (
    recentAlarmPaginationPayload: IRecentMediaRequest
  ) => {
    const response: any = await dispatch(
      dashboardAction.getMediaAlaram(recentAlarmPaginationPayload)
    );

    setTotalRecords(response?.Total);
    setRecentAlarmData(response?.Result);
    getAllOperatorStatus(response?.EquipmentDetails);
  };

  const getAllOperatorStatus = async (responseOperatorLog: any) => {
    const response: any = await dispatch(
      dashboardAction.getEquipmentDetails(responseOperatorLog)
    );
    setEquipmentDetailsData(response);
  };

  const handleNavigateBack = () => {
    navigate(NAVIGATIONS.DASHBOARD);
  };

  const handleOperatorStatus = (message: string) => {
    let status = "";
    if(message===OPERATOR_STATUS.OPERATOR_RECOGNISED)
    {
      status=OPERATOR_STATUS.OPERATOR_PRESENT_STATUS
    }
    else if(message==OPERATOR_STATUS.NO_FACE){
      status=OPERATOR_STATUS.ALARM_NO_FACE
    }
    else if(message===OPERATOR_STATUS.FACE_NOT_RECOGNISED){
      status=OPERATOR_STATUS.ALARM_UNRECOGNISED
    }
    return status
  };
  const handleOperatorRecognition=(item:IRecentAlarmResponse,showPopup:boolean)=>{
    setOperatorImage({image:item.name,dateOfIdentification:item.dateTime,showPopup:showPopup})
  }

  return (
    <div className="inner-page-row">
      <RecentAlarmPopup
        operatorInfo={operatorImage}
        onClose={() => {
          handleOperatorRecognition(
            {
              id: 0,
              name: "",
              equipmentId: "",
              imei: "",
              acknowledgement: "",
              acknowledgedAt: "",
              acknowledgedMessage: "",
              recognitionStatus: "",
              dateTime: "",
            },
            false
          );
        }}
      />
      <div className="flex justify-between mt-5">
        <div className=" flex text-lg">
          <button type="button" className="" onClick={handleNavigateBack}>
            <img src={backBtnIcon} alt="" className="p-1" />
          </button>
          <div className="mt-2">
            <h2 className="text-xl">
              <b className="ml-5 dark:head-text-color">{"Recent Alarms"}</b>
            </h2>
          </div>
        </div>
      </div>

      {equipmentDetailsData && equipmentDetailsData.length > 0 ? (
        <div>
          <div className="">
            <div className="relative overflow-x-auto sm:rounded-lg mt-4 video-list-table">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50   dark:table-head-bg-color dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 dark:tableTh">
                      {th1}
                    </th>
                    <th scope="col" className="px-6 py-3 dark:tableTh">
                      {th2}
                    </th>
                    <th scope="col" className="px-6 py-3 dark:tableTh">
                      {th3}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {recentAlarmData?.map((item: IRecentAlarmResponse, index) => {
                    let isFaceNotRecognized=item?.recognitionStatus===OPERATOR_STATUS.FACE_NOT_RECOGNISED
                    return (
                      <tr
                        className={`bg-white dark:table-bg-color cursor-pointer ${isFaceNotRecognized?"text-orange-500":""}`}
                        key={item.equipmentId}
                        onClick={()=>{isFaceNotRecognized&&handleOperatorRecognition(item,true)}}
                      >
                        <td
                          scope="row"
                          className="px-6 py-4 font-medium  whitespace-nowrap dark:tableTh"
                        >
                          {moment
                            .utc(item?.dateTime)
                            .local()
                            .format("DD-MM-YYYY HH:mm:ss")}
                        </td>
                        <td className="px-6 py-4 dark:tableTd">
                          {equipmentDetailsData[index]?.vehicleNumber}
                        </td>

                        {isFaceNotRecognized ? (
                          <td className=" py-4 dark:tableTd flex relative items-center ">
                            {handleOperatorStatus(item?.recognitionStatus)}
                            <img
                              className="h-3 pl-11"
                              src={right}
                              alt="right"
                            />
                          </td>
                        ) : (
                          <td className=" py-4 dark:tableTd flex relative items-center">
                            {handleOperatorStatus(item?.recognitionStatus)}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-">
            <PaginatedItems
              itemsPerPage={paginationPayload.pageSize}
              totalRecords={totalRecords}
              onPagination={onPagination}
              reRenderDevices={() => {}}
            />
          </div>
        </div>
      ) : (
        <div>
          <NoDataFound />
        </div>
      )}
    </div>
  );
}
