import closeIcon from "../../../assets/images/close_icon.svg";

export interface IVideoPreviewBox {
  showVideo: boolean;
  operatorVideo: string;
  onClose: () => void;
}

function VideoPreviewBox({
  showVideo,
  operatorVideo,
  onClose,
}: IVideoPreviewBox) {

  return showVideo ? (
    <div className="fixed inset-0 flex items-center justify-center z-50  popup-overlay ">
      <div className="relative z-10 bg-white p-4 rounded-lg dark:panel-bg-color">
        <div className="flex justify-end mb-4">
          <button className="" onClick={onClose}>
            <img className="w-6" src={closeIcon} alt="close" />
          </button>
        </div>
        <video width="1000" height="360" controls >
          <source src={operatorVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  ) : null;
}
export default VideoPreviewBox;
