import { setRecentEquipmentData, setRecentVideoEquipmentData } from "../../../common/common.reducer";
import { AppThunk } from "../../../config/store.hooks";
import {
  IInstanceProperties,
  IOperatorLogsRequest,
  IOperatorStatusRequest,
  IStatusEquipmentReq,
} from "../../components/status.component/status.table.interface";
import { IOperatorStatusUpdateRequest, IRecentMediaRequest } from "../alarmSection/alarmSection.interface";
import { dashboardService } from "./dashboard.service";

const getEquipmentDetails = (
  OperatorStatusReq: IOperatorStatusRequest[]
): AppThunk => {
  return async (dispatch: any, state: any) => {
    try {
      const response = await dashboardService.getEquipmentDetails(
        OperatorStatusReq
      );
      return response?.data;
    } catch (error) {}
  };
};

const getOperatorLogs = (IOperatorLogsReq: IOperatorLogsRequest): AppThunk => {
  return async (dispatch: any, state: any) => {
    try {
      const response = await dashboardService.getOperatorLog(IOperatorLogsReq);
      return response;
    } catch (error) {}
  };
};

const getMediaAlaram = (
  recentMediaAlarmRequest: IRecentMediaRequest
): AppThunk => {
  return async (dispatch: any, state: any) => {
    try {
      const response = await dashboardService.getMediaAlarm(
        recentMediaAlarmRequest
      );

      if (response.status === 200) {
        const equipmentResponse: any = await dispatch(
          dashboardAction.getEquipmentDetails(response?.data?.EquipmentDetails)
        );
        dispatch(setRecentEquipmentData(equipmentResponse))
      
        return response.data;
      } else {
        return null;
      }
    } catch (error) {}
  };
};

const getSiteStatus = (): AppThunk => {
  return async (dispatch: any, state: any) => {
    try {
      const response = await dashboardService.getSiteStatus();

      if(response.status===200)
      {
        return response.data;
      }
      else{
        return null
      }

    } catch (error) {}
  };
};


const getRecentVideoAlarms = (
  recentMediaAlarmRequest: IRecentMediaRequest
): AppThunk => {
  return async (dispatch: any, state: any) => {
    try {
      const response =await dashboardService.getRecentVideoAlarms(recentMediaAlarmRequest);
      if(response.status===200)
      {
        const equipmentResponse: any = await dispatch(
          dashboardAction.getEquipmentDetails(response?.data?.EquipmentDetails)
        );
          dispatch(setRecentVideoEquipmentData(equipmentResponse))
        return response?.data
      }
      else{
        return null
      }
    
    } catch (error) {
      
    }
   
  };
};

const updateOperatorStatus = (
  updateOperatorStatus: IOperatorStatusUpdateRequest
): AppThunk => {
  return async (dispatch: any, state: any) => {
    try {
      const response =await dashboardService.updateOperatorStatus(updateOperatorStatus);
      return response 
    } catch (error) {
      
    }
   
  };
};

const getEquipmentStatus=():AppThunk=>{
  return async (dispatch: any, state: any) => {
    try {
      const response =await dashboardService.getEquipmentStatus();
      if(response.status===200)
      {
        return response.data
      }
      else{
        return null
      }
    } catch (error) {
      
    }
   
  };
}

const getAllInstanceProperties=(instancePropertiesRequest:IInstanceProperties):AppThunk=>{
  return async (dispatch: any, state: any) => {
    try {
      const response =await dashboardService.getAllInstanceProperties(instancePropertiesRequest);
    
      if(response.status===200)
      {
        return response?.data
      }
      else{
        return null
      }
     
    } catch (error) {
      
    }
   
  };
}

const getOperatorDetails=(operatorDetails:IStatusEquipmentReq[]):AppThunk=>{
  return async()=>{

    try {
      const response =await dashboardService.getOperatorDetails(operatorDetails);
      if(response?.status===200)
      {
        return response?.data
      }
      else{
        return null
      }
    } catch (error) {
      return null
    }

  }
}

export const dashboardAction = {
  getEquipmentDetails,
  getOperatorLogs,
  getMediaAlaram,
  getSiteStatus,
  getRecentVideoAlarms,
  updateOperatorStatus,
  getEquipmentStatus,
  getAllInstanceProperties,
  getOperatorDetails
};
