import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITokenResponse } from "../types/login.types";
import { sessionStorageUtils } from "../../../utils/session-storage.utils";
import { SESSION_DETAILS } from "../../../config/default.config";

const initialState: ITokenResponse = sessionStorageUtils.getLocalStorage(
  SESSION_DETAILS.AUTH_TOKEN
)
  ? sessionStorageUtils.getLocalStorage(SESSION_DETAILS.AUTH_TOKEN)
  : {
      accessToken: "",
      tokenType: "",
      expiresIn: 0,
      refreshExpiresIn: 0,
      refreshToken: "",
      sessionState: "",
      scope: "",
      active: false,
      sessionId: "",
    };

const loginSlice = createSlice({
  name: "loginReducer",
  initialState,
  reducers: {
    setToken(state, actions: PayloadAction<ITokenResponse>) {
      state = { ...actions.payload };
      sessionStorageUtils.setLocalStorage(
        SESSION_DETAILS.AUTH_TOKEN,
        actions.payload
      );
      return state;
    },
    clearToken(state, actions: PayloadAction) {
      state = {
        accessToken: "",
        tokenType: "",
        expiresIn: 0,
        refreshExpiresIn: 0,
        refreshToken: "",
        sessionState: "",
        scope: "",
        active: false,
        sessionId: "",
      };
      sessionStorageUtils.setLocalStorage(SESSION_DETAILS.AUTH_TOKEN, state);
      return state;
    },
  },
});

export const { setToken, clearToken } = loginSlice.actions;
export default loginSlice.reducer;
