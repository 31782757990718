import SortingIcon from "../../assets/images/sorting_icon.svg";
import PaginatedItems from "../components/pagination.component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { equipmentAction } from "./services/equipment.actions";
import { NAVIGATIONS } from "../../config/default.config";
import SortingIconDesc from "../../assets/images/sorting_icon_down.svg";
import noImageIcon from "../../assets/images/No-image.jpg";
import noSearchResult from "../../assets/images/no-search-results.svg";
import {
  IEquipmentItem,
  IEquipmentListRequest,
  IPaginationPayload,
} from "./equipment.interface";
import { Tooltip } from "@mui/material";
import { encryptUtils } from "../../utils/encryption.utils";
import NoDataFound from "../components/noDataFound";

export type Paginationn = {
  id: number;
  name: string;
  companyName: string;
  place: string;
};

export type IPagination = {
  id: number;
  name: string;
  vehicleNumber: string;
  description: string;
  operatingWeight: number;
  grossPower: number;
  bucketCapacity: number;
  maxDiggingDepth: number;
  deviceId: number;
  status: boolean;
};

export function EquipmentList() {
  const [equipmentList, setequipmentList] = useState<IPagination[]>([]);
  const navigate = useNavigate();
  const [formattedEquipmentList, setFormattedEquipmentList] = useState<
    IEquipmentItem[]
  >([]);
  const [paginationPayload, setPaginationPayload] =
    useState<IPaginationPayload>({
      page: 1,
      pageSize: 16,
      orderByDesc: true,
      orderBy: "Name",
      searchKey: "",
    });
  const [totalRecords, setTotalRecords] = useState();

  const getEquipment = async (
    EquipmentListAllRequest: IEquipmentListRequest
  ) => {
    const response = await equipmentAction.listAllEquipments(
      EquipmentListAllRequest
    );
    setTotalRecords(response?.totalItems);
    let tempEquipmentItemList: IEquipmentItem[] = [];
    response?.data?.map((equipments: any) => {
      let IEquipmentItem: IEquipmentItem = {
        id: equipments?.id,
        name: equipments?.name,
        vehicleNumber: equipments?.vehicleNumber,
        image: equipments?.images[0]?.url,
        status: equipments?.status,
      };
      tempEquipmentItemList.push(IEquipmentItem);
    });
    setFormattedEquipmentList(tempEquipmentItemList);
  };

  const getEquipmentList = (pagenumber: number) => {
    setPaginationPayload({
      ...paginationPayload,
      page: pagenumber,
    });
    let equipmentListRequest: IEquipmentListRequest = {
      Page: pagenumber,
      PageSize: paginationPayload.pageSize,
      OrderByDesc: paginationPayload.orderByDesc,
      OrderBy: paginationPayload.orderBy,
      SearchKey: paginationPayload.searchKey,
    };
    getEquipment(equipmentListRequest);
  };

  useEffect(() => {
    let equipmentListRequest: IEquipmentListRequest = {
      Page: paginationPayload.page,
      PageSize: paginationPayload.pageSize,
      OrderByDesc: paginationPayload.orderByDesc,
      OrderBy: paginationPayload.orderBy,
      SearchKey: paginationPayload.searchKey,
    };
    getEquipment(equipmentListRequest);
  }, [
    paginationPayload.page,
    paginationPayload.pageSize,
    paginationPayload.orderByDesc,
    paginationPayload.orderBy,
    paginationPayload.searchKey,
  ]);

  const onPaginationChange = (
    curretdata: any,
    itemOffset: any,
    endOffset: any
  ) => {
    setFormattedEquipmentList(curretdata);
  };

  const handleSortKeyChange = (orderBy: string) => {
    setPaginationPayload({
      ...paginationPayload,
      orderBy: orderBy,
    });
  };

  const handleOrderBy = () => {
    setPaginationPayload({
      ...paginationPayload,
      orderByDesc: !paginationPayload.orderByDesc,
    });
  };
  const handleAddEquipment = () => {
    navigate(NAVIGATIONS.EQUIPMENT_ADD);
  };

  const navigateToEquipmentById = (id: any) => {
    navigate(
      NAVIGATIONS.EQUIPMENT_VIEW.replace(":id", encryptUtils.encryptURL(id))
    );
  };

  const handleSearchKey = (event: any) => {
    setPaginationPayload({
      ...paginationPayload,
      searchKey: event.target.value,
    });
  };

  return (
    <div className="p-10 inner-page-row">
      <div className="inline-block container mt-5">
        <div className="float-left">
          <h2 className="text-xl">
            <b className="dark:head-text-color">Equipments</b>
          </h2>
        </div>
      </div>
      <div className="top-bar-sticky dark:search-bar-bg-color">
        <div className="flex flex-wrap items-center">
          <div className="flex-1 w-100">
            <input
              type="text"
              name="searchKey"
              id="searchKey"
              className="border rounded-lg border-gray-300 p-2 placeholder-gray-300  text-slate-500 w-[100%] cursor-pointer dark:normal-text-color"
              placeholder="Search Equipments"
              onChange={handleSearchKey}
            />
          </div>
          <div className="flex-2 w-100 cursor-pointer">
            <Tooltip title="filter" placement="top">
              <select
                id="sortKey"
                name="sortKey"
                className=" dark:panel-bg-color dark:normal-text-color border rounded-lg border-gray-300 p-2 placeholder-white  text-slate-500  h-10 w-40 mx-6 bg-white pl-4 pr-4 text-sm font-thin focus:outline-none focus:border-red-500 cursor-pointer "
                onChange={(e) => {
                  handleSortKeyChange(e?.target?.value);
                }}
              >
                <option value="name">Name</option>
                <option value="vehicleNumber">Vechicle Number</option>
                <option value="status">Status</option>
              </select>
            </Tooltip>
          </div>
          <div className="flex-none ml-2 active:bg-slate-300 cursor-pointer">
            <Tooltip
              title={
                paginationPayload.orderByDesc ? "Sort by asc" : "Sort by desc"
              }
              placement="top"
            >
              <img
                width={26}
                height={26}
                style={{ width: 26, height: 26 }}
                src={
                  paginationPayload.orderByDesc ? SortingIcon : SortingIconDesc
                }
                alt="logo"
                onClick={() => {
                  setPaginationPayload({
                    ...paginationPayload,
                    orderByDesc: !paginationPayload.orderByDesc,
                  });
                }}
              ></img>
            </Tooltip>
          </div>
          <div className="flex-none ml-2 cursor-pointer ">
            <button
              className=" py-2 btn btn-primary"
              onClick={handleAddEquipment}
            >
              <div>Add</div>
            </button>
          </div>
        </div>
      </div>
      {formattedEquipmentList && formattedEquipmentList.length > 0 ? (
        <div>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 content-start mb-5 inner-page-list">
            {formattedEquipmentList?.map((item: IEquipmentItem, index) => {
              return (
                <div
                  key={index}
                  className="transform cursor-pointer hover:scale-105 transition duration-75 active:bg-blue-100 rounded-xl place-content-center bg-white xs:w-full shadow-1xl mt-5 mr-5 border-dashed border-1 border-gray-500"
                  onClick={() => {
                    navigateToEquipmentById(item.id);
                  }}
                >
                  <div className=" flex justify-center flex-col p-[10px] sm:p-[20px] md:p-[30px]">
                    <div className="flex flex-wrap content-start ">
                      <img
                        className="border border-gray h-32 rounded mr-5"
                        style={{ width: 60, height: 60 }}
                        src={`${item?.image ? item?.image : noImageIcon}`}
                        alt="logo"
                      ></img>
                      <div className="cursor-pointer box-row">
                        <p>
                          <b>{item?.vehicleNumber}</b>
                        </p>
                        <p className="text-xs cursor-pointer">{item?.name}</p>
                        <p className="text-xs cursor-pointer">
                          Status:
                          <span
                            className={`${
                              item?.status === 0
                                ? "text-green-600"
                                : item?.status === 1
                                ? "text-red-400"
                                : "text-yellow-400"
                            } text-md`}
                          >
                            {item?.status === 0
                              ? " Active"
                              : item?.status === 1
                              ? " Inactive"
                              : " StandBy"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <PaginatedItems
            itemsPerPage={paginationPayload.pageSize}
            totalRecords={totalRecords}
            onPagination={onPaginationChange}
            reRenderDevices={getEquipmentList}
          />
        </div>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
}
