import { IImageInfo } from "../components/image-upload/image-upload.component";

export interface IPaginationPayload {
  page: number;
  pageSize: number;
  orderByDesc: boolean;
  orderBy: string;
  searchKey: string;
}
export interface ICustomerListRequest {
  Page: number;
  PageSize: number;
  OrderByDesc: boolean;
  OrderBy: string;
  SearchKey: string;
}

export interface ICustomerHistoryRequest {
  Page: number;
  PageSize: number;
  OrderByDesc: boolean;
  OrderBy: string;
  SearchKey: string;
}

export type ICustomerItem = {
  id: number;
  name: string;
  mobileNumber: string;
  address: string;
  images:IImageInfo[]
};

export type IHistoryItem = {
  col1: string;
  col2: string;
  col3: string;
  col4: string;
  col5: string
};

export interface IAddCustomerRequest {
  id: number;
  name: string;
  mobileNumber: string;
  gstNumber: string;
  address: string;
  images: IImageInfo[];
}

export interface IUpdateCustomerRequest {
  id: number;
  name: string;
  mobileNumber: string;
  gstNumber: string;
  address: string;
  images: IImageInfo[];
}

export interface ICustomer {
  id: number;
  name: string;
  mobileNumber: string;
  gstNumber: string;
  address: string;
  images:IImageInfo[]
}

export const CUSTOMER_OPERATIONS = {
  UPDATE: "UPDATE",
  CREATE: "CREATE",
};
