import React, { useEffect, useState } from "react";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/Delete_icon.svg";
import noImageIcon from "../../assets/images/No-image.jpg";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { equipmentAction } from "./services/equipment.actions";
import {
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_TITLES,
  EQUIPMENT_ERROR_CODES,
  EQUIPMENT_MESSAGE,
  ERROR,
  NAVIGATIONS,
  SESSION_DETAILS,
  SUBMIT_BUTTON_TEXT,
} from "../../config/default.config";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";
import { IEquipment } from "./equipment.interface";
import { setEquipmentDetails } from "./store/equipment.reducer";
import { siteActions } from "../site/service/site.action";
import { stat } from "fs/promises";
import {
  setIsError,
  setIsLoading,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import { Tooltip } from "@mui/material";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { encryptUtils } from "../../utils/encryption.utils";

export function EquipmentView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { open, close } = useConfirmationPopup();
  const [ready, setReady] = useState<boolean>(false);

  const equipmentDetailsRespo = useAppSelector(
    (state) => state.equipmentReducer.equipmentDetails
  );

  const setSuccessForEquipmentFetching = (state: boolean) => {
    if (state) {
      setReady(true);
      dispatch(setIsLoading(false));
    } else {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    dispatch(
      equipmentAction.listEquipmentById(
        Number(encryptUtils.decryptURL(id)),
        setSuccessForEquipmentFetching
      )
    );
  };

  const handleViewClick = () => {
    navigate(NAVIGATIONS.EQUIPMENT_LIST);
    let emptyEquipmentDetails: IEquipment = {
      id: 0,
      name: "",
      vehicleNumber: "",
      description: "",
      operatingWeight: 0,
      grossPower: 0,
      bucketCapacity: 0,
      maxDiggingDepth: 0,
      images: [],
      deviceId: 0,
      status: 3,
    };
    dispatch(setEquipmentDetails(emptyEquipmentDetails));
  };

  const handleEditClick = () => {
    navigate(
      NAVIGATIONS.EQUIPMENT_UPDATE.replace(
        ":id",
        encryptUtils.encryptURL(encryptUtils.decryptURL(id))
      )
    );
  };

  const handleDeleteConfirmation = (confirm: boolean) => {
    if (confirm) {
      equipmentAction.deleteEquipment(
        equipmentDetailsRespo.id,
        setSuccessOfDelete
      );
    } else {
      handleClosePopup();
    }
  };

  const setSuccessOfDelete = (status: boolean, errorCode: any) => {
    if (status && errorCode === null) {
      dispatch(setToastMsg(EQUIPMENT_MESSAGE.EQUIPMENT_DELETED));
      dispatch(setToast(true));
      handleViewClick();
      handleClosePopup();
    } else {
      if (
        !status &&
        errorCode != null &&
        errorCode == EQUIPMENT_ERROR_CODES.EQUIPMENT_DELETE_ERROR_CODE
      ) {
        dispatch(setToastMsg(EQUIPMENT_MESSAGE.EQUIPMENT_UNABLE_DELETE));
        dispatch(setIsError(true));
        dispatch(setToast(true));
      } else {
        dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
        dispatch(setIsError(true));
        dispatch(setToast(true));
      }
      handleClosePopup();
    }
  };
  const handleClosePopup = () => {
    close();
  };

  const handleDeletePopup = (confirmation: any) => {
    open(
      CONFIRMATION_TITLES.CONFIRM_DELETE,
      EQUIPMENT_MESSAGE.EQUIPMENT_DELETE_CONFIRMATION.replace(
        ":equipmentName",
        equipmentDetailsRespo?.name
      ),
      SUBMIT_BUTTON_TEXT.YES,
      CANCEL_BUTTON_TEXT.NO,
      () => {
        confirmation(true);
      },
      () => {
        confirmation(false);
      }
    );
  };

  const handleNavigateBack = () => {
    let emptyEquipmentDetails: IEquipment = {
      id: 0,
      name: "",
      vehicleNumber: "",
      description: "",
      operatingWeight: 0,
      grossPower: 0,
      bucketCapacity: 0,
      maxDiggingDepth: 0,
      images: [],
      deviceId: 0,
      status: 3,
    };
    dispatch(setEquipmentDetails(emptyEquipmentDetails));
    navigate(NAVIGATIONS.EQUIPMENT_LIST);
  };

  const handleNavigateHistory = () => {
    navigate(
      NAVIGATIONS.EQUIPMENT_HISTORY.replace(
        ":id",
        encryptUtils.encryptURL(encryptUtils.decryptURL(id))
      )
    );
  };

  if (!ready) {
    return null;
  }

  return (
    <div className="inner-page-row">
      <div className="grid md:grid-cols-3 gap-28 pt-3">
        <div className="col-span-2 box-border">
          <div className="flex justify-between">
            <div className=" flex text-lg">
              <button
                type="button"
                className="mr-3"
                onClick={handleNavigateBack}
              >
                <img src={backBtnIcon} alt="" className="p-1" />
              </button>
              <div className=" font-bold p-1 text-gray-600 mt-1">Equipment</div>
              <div className="p-1 text-gray-500 mt-1">Status</div>
            </div>
            <div
              className=" text-blue-600 p-2 text-sm mt-1 cursor-pointer"
              onClick={() => handleNavigateHistory()}
            >
              History
            </div>
          </div>

          <div className=" bg-white rounded-3xl shadow-md mt-4">
            <div className="p-10 ">
              <div className=" flex justify-between">
                <div className="flex">
                  <div className=" inline p-2 text-gray-500">
                    <b>Equipment</b>
                  </div>

                  <Tooltip title="Edit" placement="top">
                    <div
                      className=" inline p-2 cursor-pointer"
                      onClick={() => {
                        handleEditClick();
                      }}
                    >
                      <img className="w-7" src={editIcon} alt="edit" />
                    </div>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <div
                      className=" inline p-2 cursor-pointer"
                      onClick={() => {
                        handleDeletePopup(handleDeleteConfirmation);
                      }}
                    >
                      <img className="w-7" src={deleteIcon} alt="delete" />
                    </div>
                  </Tooltip>
                </div>

                <div className=" text-gray-400 text-md">
                  Status:
                  <span
                    className={`${
                      equipmentDetailsRespo?.status === 0
                        ? "text-green-600"
                        : equipmentDetailsRespo?.status === 1
                        ? "text-red-400"
                        : "text-yellow-400"
                    } text-md`}
                  >
                    {equipmentDetailsRespo?.status === 0
                      ? " Active"
                      : equipmentDetailsRespo?.status === 1
                      ? " Inactive"
                      : " StandBy"}
                  </span>
                </div>
              </div>
              <div className="sm:block md:flex py-3">
                <div>
                  <img
                    style={{ width: 100, height: 100 }}
                    src={
                      equipmentDetailsRespo?.images[0]
                        ? `${equipmentDetailsRespo?.images[0]?.resource}`
                        : noImageIcon
                    }
                    className="border border-gray h-32 rounded-2xl"
                  />
                </div>
                <div className="px-8">
                  <div className="font-bold">{equipmentDetailsRespo.name} </div>
                  <div className="flex pt-3">
                    <div className=" pr-3  text-gray-400">Vehicle Number :</div>
                    <span className="font-bond  text-gray-500">
                      {equipmentDetailsRespo.vehicleNumber}
                    </span>
                  </div>
                  <div className="flex">
                    <div className=" pr-3  text-gray-500">Gross power :</div>
                    <span className="font-bold text-gray-500">
                      {equipmentDetailsRespo.grossPower}
                    </span>
                  </div>
                </div>
              </div>
              <hr className="flex-grow" />
              <div className="text-slate-500 font-bold  text-[17px] my-4">
                More about <span> {equipmentDetailsRespo.name}</span>
              </div>
              <div>
                <h2 className="text-slate-500 font-normal  mt-2 text-[15px]">
                  Description
                </h2>
                <h2 className="text-slate-400 font-bold text-[15px]">
                  {equipmentDetailsRespo.description}
                </h2>
              </div>
              <div className="flex pt-3">
                <div>
                  <h2 className="text-slate-500 font-normal mt-2 text-[15px]">
                    Operating weight
                  </h2>
                  <h2 className="text-slate-400 font-bold text-[15px]">
                    {equipmentDetailsRespo.operatingWeight}
                  </h2>
                </div>
              </div>
              <div>
                <h2 className="text-slate-500 font-normal mt-2 text-[15px]">
                  Bucket capacity
                </h2>
                <h2 className="text-slate-400 font-bold text-[15px]">
                  {equipmentDetailsRespo.bucketCapacity}
                </h2>
              </div>
              <div>
                <h2 className="text-slate-500 font-normal mt-2 text-[15px]">
                  Max digging depth
                </h2>
                <h2 className="text-slate-400 font-bold text-[15px]">
                  {equipmentDetailsRespo.maxDiggingDepth}
                </h2>
              </div>
            </div>
          </div>
          {/* <div>
          Operation Hours
          <div className=" bg-white rounded-3xl shadow-md mt-8 h-24"></div>
        </div> */}
        </div>
      </div>
    </div>
  );
}
