import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAlert, ICommonStore } from "./common.interface";

const initialState: ICommonStore = {
  toastState: false,
  toastMsg: "",
  isError: false,
  currentPos: "",
  isLoading: false,
  theme: false,
  recentAlarmRecent: [],
  recentEquipmentData: [],
  recentVideoEquipmentData: [],
  recentEquipmentAlarmStatus:{
    dashboard_image: "",
    dateOfIdentification: null,
    equipmentId: "",
    operator_recognition: "",
    videoName: ""
  }
};

const commonsListSlice = createSlice({
  name: "commonsListReducer",
  initialState,
  reducers: {
    setToast(state, actions: PayloadAction<any>) {
      state.toastState = actions.payload;
      return state;
    },
    setToastMsg(state, actions: PayloadAction<any>) {
      state.toastMsg = actions.payload;
      return state;
    },
    setIsError(state, actions: PayloadAction<any>) {
      state.isError = actions.payload;
      return state;
    },
    setCurrentPos(state, actions: PayloadAction<any>) {
      state.currentPos = actions.payload;
      return state;
    },
    setIsLoading(state, actions: PayloadAction<any>) {
      state.isLoading = actions.payload;
      return state;
    },
    // setAlerts(state, actions: PayloadAction<IAlert>) {
    //   state.alerts = [...state.alerts, actions.payload]; 
    //   return state;
    // },
    setRecentAlarmResult(state, actions: PayloadAction<any>){
      state.recentAlarmRecent = actions.payload;
        return state;
    },
    setRecentEquipmentData(state, actions: PayloadAction<any>){
      state.recentEquipmentData = actions.payload;
        return state;
    },
    setRecentVideoEquipmentData(state, actions: PayloadAction<any>){
      state.recentVideoEquipmentData = actions.payload;
        return state;
    },
    setRecentEquipmentAlarmStatus(state, actions: PayloadAction<any>){
      state.recentEquipmentAlarmStatus = actions.payload;
        return state;
    },
    clearCurrentPos(state) {
      state.currentPos = "";
      return state;
    },
    setTheme: (state, action: PayloadAction<boolean>) => {
      state.theme = action.payload
    },
  },
});

export const {
  setToast,
  setToastMsg,
  setIsError,
  setCurrentPos,
  clearCurrentPos,
  setIsLoading,
  setRecentAlarmResult,
  setRecentEquipmentData,
  setRecentVideoEquipmentData,
  setRecentEquipmentAlarmStatus,
  setTheme
} = commonsListSlice.actions;
export default commonsListSlice.reducer;
