import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import { setToast } from "../../common/common.reducer";


const Toaster = () => {
  const dispatch = useAppDispatch();

  const visibility = useAppSelector((state) => state.commonReducer.toastState);

  const toastMsg = useAppSelector((state) => state.commonReducer.toastMsg);

  const isError = useAppSelector((state) => state.commonReducer.isError);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setToast(false));
    }, 4000);
  }, [visibility]);

  const handleOnclose=()=>{
    dispatch(setToast(false));
  }

  return visibility ? (
    <div className="fixed bottom-3 right-3 z-10">
      <div
        id="toast-default"
        className="flex items-center w-full p-6  text-gray-500 shadow dark:text-gray-400 dark:bg-gray-800 rounded"
        role="alert"
        style={{ backgroundColor: isError ? "#ff8f00" : "green" }}
      >
        {isError ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="white"
            viewBox="0 0 24 24"
          >
            <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" />
          </svg>
        ) : null}

        <div className="ml-2 text-sm font-semibold  text-white rounded">{toastMsg}</div>
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 text-gray-400 rounded-lg inline-flex items-center justify-center h-8 w-8"
          data-dismiss-target="#toast-default"
          aria-label="Close"
          onClick={handleOnclose}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            viewBox="0 0 14 14"
          >
            <path
              stroke="white"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
    </div>
  ) : null;
};
export default Toaster;

