import StatusTable from "../components/status.component/status.table";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import { useNavigate } from "react-router-dom";
import { NAVIGATIONS } from "../../config/default.config";

function OperatorStatusView() {
  const navigate = useNavigate();
  const handleNavigateBack = () => {
    navigate(NAVIGATIONS.DASHBOARD);
  };
  return (
    <div className=" pl-24 pt-16 pb-2 h-full">
      <div className="flex  justify-between px-8">
        <div className=" flex ">
          <button type="button" className="mr-3" onClick={handleNavigateBack}>
            <img src={backBtnIcon} alt="" className="p-1" />
          </button>
          <div className="flex text-lg p-4">
            <div className="font-bold  text-gray-500 dark:head-text-color">Equipment</div>
            <div className=" text-gray-400 pl-1 dark:head-text-color">Status</div>
          </div>
        </div>
      </div>
      <div className="viewMoreTab">
        <StatusTable />
      </div>
    </div>
  );
}
export default OperatorStatusView;
