import { Field, change, reduxForm } from "redux-form";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import {
  alphaNumericValidation,
  customerListValidation,
  dateValidation,
  deviceTypeValidation,
  durationValidation,
  startDateValidation,
  minMaxValidation,
  requiredValidation,
  siteListValidation,
  endDateValidation,
  nameValidation,
} from "../../validations/validations";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ACTIONS, CANCEL_BUTTON_TEXT, CONFIRMATION_DIALOUGES, CONFIRMATION_TITLES, CONTRACT_MESSAGE, ERROR, NAVIGATIONS, SESSION_DETAILS, SUBMIT_BUTTON_TEXT } from "../../config/default.config";
import ListerComponentContext from "../components/lister.component/lister.component-context";
import { contractAction } from "./service/contract.action";
import { IContractParameter, IContractUpdateParameter, IEquipmentInstance } from "./contract.interface";
import moment from "moment";
import { setIsError, setIsLoading, setToast, setToastMsg } from "../../common/common.reducer";
import { IEquipmentListerItem, IListerItem, ISeletedInstance } from "../components/lister.component/lister.component-interface";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import { equipmentAction } from "../equipment/services/equipment.actions";


const renderInputName = (props: any,startDate:string) => {
  return (
    <div>
      <label
        htmlFor="name"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Name<span className="text-red-500">*</span>
      </label>
  
      <div className="">
        <input
          {...props?.input}
          type="text"
          name="name"
          id="name"
          className=" dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          maxLength={20}
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
        {props?.error?.name}
      </div>
    </div>
  );
};

const renderSiteList = (props: any) => {
  return (
    <div>
      <label
        htmlFor="sitelist"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Site<span className="text-red-500">*</span>
      </label>
      <div className="">
        <select
          {...props?.input}
          id="siteList"
          name="siteList"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select Site</option>
          {props.siteData?.map((sites: any) => (
            <option key={sites.id} value={sites.id}>
              {sites.location}
            </option>
          ))}
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderCustomerList = (props: any) => {
  return (
    <div>
      <label
        htmlFor="customerlist"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Customer<span className="text-red-500">*</span>
      </label>
      <div className="">
        <select
          {...props?.input}
          id="customerList"
          name="customerList"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select CustomerList</option>
          {props.customerData?.map((customer: any) => (
            <option key={customer.id} value={customer.id}>
              {customer.name}
            </option>
          ))}
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderStartDate = (props: any) => {
  props.setStartDate(props.input.value);

  return (
    <div>
      <label
        htmlFor=""
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Start Date<span className="text-red-500">*</span>
      </label>
      <div className="">
        <input
          {...props?.input}
          type="date"
          name="date"
          id="date"
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 cursor-pointer"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderPeriod = (props: any) => {
  return (
    <div className="p-4 flex ">
      <div className="flex items-center">
        <div className=" pr-4">
          <input
            {...props?.input}
            type="radio"
            name="period"
            id="endDateOption"
            className="h-5   w-5 cursor-pointer"
            value={"endDate"}
            checked={props.input.value==="endDate"}
          />
        </div>
        <label htmlFor="" className="block text-sm font-medium  text-gray-900  pb-1  dark:normal-text-color">
          End Date
        </label>
      </div>
      <div className="flex items-center">
        <div className=" px-4">
          <input
            {...props?.input}
            type="radio"
            name="period"
            id="durationOption"
            className="h-5 w-5 cursor-pointer"
            value={"duration"}
            checked={props.input.value==="duration"}
          />
        </div>
        <label htmlFor="" className="block text-sm font-medium  text-gray-900 pb-1  dark:normal-text-color">
          Duration
        </label>
      </div>
    </div>
  );
};

const renderEndDate = (props: any) => {
  return (
    <div>
      <label
        htmlFor=""
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        End Date<span className="text-red-500">*</span>
      </label>
      <div className="">
        <input
          {...props?.input}
          type="date"
          name="endDate"
          id="endDate"
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 cursor-pointer"
          min={props.startDate}
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderDuration = (props: any) => {
  const onHandleDurationType = (e: any) => {
    props.setDuration(e.target.value);
  };
  return (
    <div>
      <label
        htmlFor="duartion"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Duration<span className="text-red-500">*</span>
      </label>
      <div className="relative">
        <input
          {...props?.input}
          type="text"
          name="duartion"
          id="duartion"
          className=" dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          maxLength={5}
        />
        <select
          id="duartionType"
          name="duartionType"
          className="absolute bg-transparent text-sm"
          style={{
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          onChange={onHandleDurationType}
          defaultValue={props.duration}
        >
          <option value="month">Month</option>
          <option value="year">Year</option>
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};



const renderEquipmentModel = (props: any) => {
  props.setEquipmentModel(props.input.value);
  return (
    <div>
      <label
        htmlFor="sitelist"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Equipment Model<span className="text-red-500">*</span>
      </label>
      <div className="">
        <select
          {...props?.input}
          id="equipmentModel"
          name="equipmentModel"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select Equipment Model</option>
          {props?.equipmentModelData?.map((model: any) => (
            <option key={model} value={model}>
              {model}
            </option>
          ))}
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
}

export function CreateContract(props: any) {
  const { handleSubmit, valid, reset, dirty } = props;
  const [visible, setVisible] = useState(true);
  const [duration, setDuration] = useState("month");
  const [startDate, setStartDate] = useState("");
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [siteData, setSiteData] = useState();
  const [customerData, setCustomerData] = useState();
  const [equipmentModelData,setEquipmentModelData]=useState()
  const [datas, setDatas] = useState<IListerItem[]>([]);
  const { open, close } = useConfirmationPopup();
  const [equipmentData,setEqipmentData]= useState<IListerItem[]>([]);
  const [operatorData,setOperatorData] =useState<IListerItem[]>([]);
  const [ready, setReady] = useState<boolean>(false);
  const [equipmentModel,setEquipmentModel]=useState("");

  const [equipmentInstance, setEquipmentInstance] = useState<[]>([]); 

  const contractDetailsResponse = useAppSelector(
    (state) => state.contractReducer.contractDetails
  );
  useEffect(() => {
    getSiteData();
    getCustomerData();
    getEquimentModel()
    props.initialize({
      period:"endDate"
    })
  }, []);

  useEffect(()=>{
    let equipments;
    equipments = datas.filter(
      (dataItem: IListerItem) => dataItem.isEquipmentList === true
    );
  setEqipmentData(equipments)

  let operator;
      operator = datas.filter(
      (dataItem: IListerItem) => dataItem.isOperatorList === true
    );
    setOperatorData(operator)
    
  },[datas]);


  const getSiteData = async () => {
    const responseData = await contractAction.getSiteContracts();
    setSiteData(responseData);
  };

  const getCustomerData = async () => {
    const responseData = await contractAction.getCustomerContract();
    setCustomerData(responseData);
  };

  const getEquimentModel =async()=>{
    const responseData=await equipmentAction.getAllModels()

    setEquipmentModelData(responseData.data)
    
  }

  const handleOpenPopup = (confirmation: any) => {

    if (dirty || equipmentData.length > 0||operatorData.length>0) {
      open(
        CONFIRMATION_TITLES.CONFIRM_EXIT,
        CONFIRMATION_DIALOUGES.UNSAVED_CHANGES,
        SUBMIT_BUTTON_TEXT.YES,
        CANCEL_BUTTON_TEXT.NO,
        () => {
          confirmation(true);
        },
        () => {
          confirmation(false);
        }
      );
    } else {
      confirmation(true);
    }
  };

  const handleNavigateBack = (confirm:boolean) => {
    handleClosePopup();
    if(confirm)
    {
      if (contractDetailsResponse.id !== 0) {
        navigate(
          NAVIGATIONS.CONTRACT_VIEW.replace(
            ":id",
            `${encryptUtils.encryptURL(contractDetailsResponse.id)}`
          )
        );
      } else {
        navigate(NAVIGATIONS.CONTRACT_LIST);
      };
    }
  };

  const handleClosePopup = () => {
    close();
  };

  const handleVisibility = (e: any) => {
    if (e.target.value === "endDate") {
      setVisible(true);
      handleValidateFields()
    } else {
      setVisible(false);
      handleValidateFields()
    }
  };


  useEffect(()=>{
    console.log("equipmentInstance",equipmentInstance);
  },[equipmentInstance])
  
  
  const handleSave = async(value: any) => {
    let equipmentIdList: any[] = [];
    let operatorIdList: number[] = [];
    equipmentInstance.forEach((item:ISeletedInstance)=>{
      let equipmentInstanceRequest={
        equipmentId:item.insatnceId,
        modelName:item.modelName
      }
      equipmentIdList.push(equipmentInstanceRequest)
    })

    datas.forEach((item: IListerItem) => {
       if (item.isOperatorList) {
        operatorIdList.push(item.id);
      }
    });
    const currentTime = moment();
    const formattedCurrentTime = currentTime.format(' HH:mm:ss.SSSS');
  
    const formattedStartDate = value.startDate+formattedCurrentTime;
    const formattedEndDate = value.endDate+formattedCurrentTime;

    dispatch(setIsLoading(true))
    if(contractDetailsResponse.id===0)
    {
      let AddContractParmeter: IContractParameter = {
        Name: value?.name,
        SiteId: Number(value?.sieList),
        CustomerId: Number(value?.customerList),
        Equipments: equipmentIdList,
        OperatorIds: operatorIdList,
        Duration:  !visible ? (Number(value?.duration)) : 0,
        DurationPeriod: duration,
        StartDate: formattedStartDate,
        EndDate: visible ? formattedEndDate : null,
      };
      dispatch(contractAction.addContract(AddContractParmeter, setSuccess));
    }
    else{
      let AddContractParmeter: IContractUpdateParameter = {
        Id:contractDetailsResponse.id,
        Name: value?.name,
        SiteId: Number(value?.sieList),
        CustomerId: Number(value?.customerList),
        Equipments: equipmentIdList,
        OperatorIds: operatorIdList,
        Duration:  !visible ? (Number(value?.duration)) : 0,
        DurationPeriod: duration,
        StartDate: formattedStartDate,
        EndDate: visible ? formattedEndDate : null,
      };
      dispatch(contractAction.updateContract(AddContractParmeter,setSuccess));
    }
  };

  const setSuccess = (status: boolean,type:string) => {
    dispatch(setIsLoading(false))
    if (status) {
      if(type===ACTIONS.CREATE)
      {
        dispatch(setToast(true));
        dispatch(setToastMsg(CONTRACT_MESSAGE.CONTRACT_CREATED));
        navigate(NAVIGATIONS.CONTRACT_LIST);
        dispatch(setIsError(false));
      }
      else if(type===ACTIONS.UPDATE)
      {
        dispatch(setToast(true));
        dispatch(setToastMsg(CONTRACT_MESSAGE.CONTRACT_UPDATE));
       navigate(NAVIGATIONS.CONTRACT_VIEW.replace(
        ":id",
        `${encryptUtils.encryptURL(contractDetailsResponse.id)}`
      )) 
        dispatch(setIsError(false));
      }
    
    } else {
      dispatch(setToast(true));
      dispatch(setIsError(true));
      dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
    }
  };
  const handleValidateFields = () => {
    let formName: string = "create-contract-form";
    if (!visible) {
      dispatch(change(formName, "endDate", startDate));
    } else {
      dispatch(change(formName, "duration", "1"));
    }
  };

  const setSuccessForContractFetching = (state: boolean) => {
    if (state) {
      setReady(true);
      dispatch(setIsLoading(false));
    } else {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setIsLoading(true));
      let contractIdForUpdate = encryptUtils.decryptURL(id);
      if (contractIdForUpdate !== null) {
        dispatch(
          contractAction.getContractByid(contractIdForUpdate, setSuccessForContractFetching)
        );
      } else if (contractIdForUpdate === null) {
        dispatch(setIsLoading(false));
        setReady(true);
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    //INITIALIZE FIELDS HERE 
    let formName: string = "create-contract-form";
    if(contractDetailsResponse.id!==0)
    {
      props.initialize({
        name:contractDetailsResponse.name,
        customerList:contractDetailsResponse.customer.id,
        sieList:contractDetailsResponse.site.id,
        startDate:moment(contractDetailsResponse?.startDate).format('YYYY-MM-DD'),
        endDate:contractDetailsResponse?.endDate===null?null:moment(contractDetailsResponse?.endDate).format('YYYY-MM-DD'),
        duration:contractDetailsResponse?.duration,
        period:contractDetailsResponse?.endDate===null?"duration":"endDate"
      })
      if(contractDetailsResponse?.endDate===null)
      {
        dispatch(change(formName, "endDate", "0"));
        setVisible(false)
        setDuration(contractDetailsResponse.durationPeriod)
      }
      
      let tempLists: IListerItem[] = [];
          // contractDetailsResponse?.equipmentIds?.forEach((item:any)=>{
          //   let listItem: IListerItem = {
          //   id: item?.id,
          //     name: item?.name,
          //     isEquipmentList: true,
          //     isOperatorList:false
          //   };
          //   tempLists.push(listItem);
          // })
          let equipmentTempList:any=[]
          contractDetailsResponse.equipments.forEach((item:any)=>{
            let equipmentReponseitem={
              insatnceId:item.equipmentId,
              modelName:item.modelName,
              isEquipmentList:true,
              isOperatorList:false

            }
            equipmentTempList.push(equipmentReponseitem)
          })
           setEquipmentInstance(equipmentTempList)
          
          contractDetailsResponse.operatorIds.forEach((item:any)=>{
            let listItem: IListerItem = {
              id: item?.id,
                name: item?.name,
                isEquipmentList: false,
                isOperatorList:true
              };
              tempLists.push(listItem);
          })
          setDatas(tempLists);

    }
   
    
  }, [contractDetailsResponse]);

  

  return (
    <div>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="p-10 inner-page-row">
          <div className="inline-block  w-[100%] top-bar-sticky dark:top-bar-bg-color">
            <div className="float-left flex items-center">
              <button
                type="button"
                className="mr-3"
                onClick={() =>{handleOpenPopup(handleNavigateBack)}}
              >
                <img src={backBtnIcon} alt="" className="p-1" />
              </button>

              <h2 className="text-xl ">
                <b className="ml-5 dark:head-text-color">{contractDetailsResponse.id?"Update":"Add"} Contract</b>
              </h2>
            </div>
            <div className="float-right">
              <button
                className={`text-[14px] text-white rounded-md p-2 px-8 py-2 ${
                  valid && equipmentInstance.length!==0 &&operatorData.length!==0? "bg-blue-800" : "bg-slate-300"
                }`}
                disabled={!valid || equipmentInstance.length===0 || operatorData.length===0}
              >

                {contractDetailsResponse.id?<div>Update</div>:<div>Save</div>}
              </button>
            </div>
          </div>
          <div className=" bg-white mt-6 rounded-md flex h-[80%] p-4 pb-0 w-full dark:panel-bg-color">
            <div className=" w-full">
              <h3 className="text-blue-700 font-bold text-lg inline-block">
                <span className=" underline underline-offset-8 decoration-4 mb-3 py-1 dark:head-text-color">
                  Basic
                </span>
              </h3>
              <div className="pb-12 grid grid-cols-2">
                <div className="mt-10 grid grid-cols-2 gap-x-6 gap-y-4 ">
                  <Field
                    type="text"
                    name="name"
                    component={renderInputName}
                    validate={[requiredValidation,nameValidation]}
                  />

                  <Field
                    name="customerList"
                    component={renderCustomerList}
                    validate={[customerListValidation]}
                    customerData={customerData}
                  />
                  <Field
                    name="sieList"
                    component={renderSiteList}
                    validate={[siteListValidation]}
                    siteData={siteData}
                  />
                  <Field
                    name="startDate"
                    component={renderStartDate}
                    validate={[dateValidation,startDateValidation]}
                    setStartDate={setStartDate}
                  />
                  <Field
                    name="period"
                    component={renderPeriod}
                    onChange={handleVisibility}
                  />

                  {visible ? (
                    <Field
                      name="endDate"
                      component={renderEndDate}
                      validate={[dateValidation,endDateValidation]}
                      startDate={startDate}
                      onChange={handleValidateFields}
                    />
                  ) : (
                    <Field
                      name="duration"
                      component={renderDuration}
                      setDuration={setDuration}
                      duration={duration}
                      validate={visible ? durationValidation : undefined}
                      onChange={handleValidateFields}
                    />
                  )}
                  {/* <Field
                    name="equipmentModel"
                    component={renderEquipmentModel}
                    equipmentModelData={equipmentModelData}
                    setEquipmentModel={setEquipmentModel}
                    onChange={handleEquipmentModelChange}
                  /> */}

             
                </div>
                <div className="row-span-2">
                  <ListerComponentContext
                    listerDetails={{
                      isEquipmentList: true,
                      isOperatorList: false,
                    }}
                    datas={datas}
                    setDatas={setDatas}
                    equipmentModel={equipmentModel}
                    equipmentListData={equipmentInstance} 
                   setEquipmentListData={setEquipmentInstance} />
                    <ListerComponentContext
                    listerDetails={{
                      isEquipmentList: false,
                      isOperatorList: true,
                    }}
                    datas={datas}
                    setDatas={setDatas} 
                    equipmentListData={[]}
                    setEquipmentListData={setEquipmentInstance} />
                </div>
              </div>
              {/* <div className="bg-white mt-6 rounded-md flex p-10 flex-wrap">
              <ListerComponent listerDetails = {{isEquipmentList : true,isOperatorList : false}}/>
                <ListerComponent listerDetails = {{isEquipmentList : false,isOperatorList : true}}/>
              </div> */}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default reduxForm({
  form: "create-contract-form",
})(CreateContract);
