import React, { useState, ChangeEvent, useEffect } from "react";
import addIcon from "../../../assets/images/add_icon.svg";
import noImageIcon from "../../../assets/images/No-image.jpg";
import deleteIcon from "../../../assets/images/Delete_icon.svg";

interface ImageUploadComponentProps {
  images: IImageInfo[];
  onImageUpload: (images: IImageInfo[]) => void;
}

export interface IImageInfo {
  resource: File | string;
  reference: string;
  deleted: boolean;
}

const ImageUploadComponent: React.FC<ImageUploadComponentProps> = ({
  images,
  onImageUpload,
}) => {
  const [selectedImages, setSelectedImages] = useState<IImageInfo[]>([]);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [activeImagesCount, setActiveImageesCount] = useState<number>(0);
  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newImages = Array.from(files);
      if (newImages[0]) {
        const newInfo: IImageInfo = {
          resource: newImages[0],
          reference: "",
          deleted: false,
        };
        const images = [newInfo, ...selectedImages];
        handleActiveImagesCount(images);
      }
    }
  };

  useEffect(() => {
    setSelectedImages(images);
    handleActiveImagesCount(images);
  }, [images]);

  const handleActiveImagesCount = (images: IImageInfo[]) => {
    setSelectedImages(images);
    const activeImages = images.filter((x) => x.deleted !== true);
    if (activeImages[0]) {
      if (typeof activeImages[0].resource === "string") {
        setPreviewImage(activeImages[0].resource);
      } else {
        setPreviewImage(URL.createObjectURL(activeImages[0].resource as any));
      }
    } else {
      setPreviewImage(null);
    }
    setActiveImageesCount(activeImages.length);
    onImageUpload(images);
  };

  const handleThumbnailClick = (image: string) => {
    setPreviewImage(image);
  };

  const handleDeleteClick = (index: number) => {
    let newImages = [...selectedImages];
    newImages[index] = { ...newImages[index], deleted: true };

    handleActiveImagesCount(newImages);
  };

  const handleAddClick = () => {
    const inputElement = document.getElementById("fileInput");
    if (inputElement) {
      inputElement.click();
    }
  };

  const renderThumbnails = () => {
    return selectedImages.map((info: IImageInfo, index) =>
      info.deleted ? null : (
        <section className="flex-shrink-0  border-2 cursor-pointer relative rounded-lg">
          <img
            className="bg-purple-200 h-20 w-20  border-2 rounded-lg"
            src={
              typeof info.resource === "string"
                ? info.resource
                : `${URL.createObjectURL(info.resource)}`
            }
            alt={`Thumbnail ${index}`}
            onClick={() =>
              handleThumbnailClick(
                typeof info.resource === "string"
                  ? info.resource
                  : `${URL.createObjectURL(info.resource)}`
              )
            }
          />
          <div className="delete-icon-image" onClick={() => handleDeleteClick(index)}>
          <img src={deleteIcon} alt="no image" className="w-8" />
          </div>
        </section>
      )
    );
  };

  return (
    <div className="relative">
      <div className="col-span-ful">
        <div
          className="mt-3 m-10 mb-2 flex justify-center rounded-lg bg-gray-100 border border-dashed border-gray-500 cursor-pointer"
          onClick={() => {
            if (activeImagesCount === 0) handleAddClick();
          }}
        >
          <div
            className="absolute right-0 p-2"
            style={{
              top: "-1rem",
              right: "1.5rem",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleAddClick();
            }}
          >
            <img src={addIcon} alt="Add Image" className="w-9 rounded-lg" />
          </div>
          <div
            className="flex"
            style={{
              height: "20rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {activeImagesCount > 0 && previewImage ? (
              <div className="w-[100%] h-[100%] p-3">
                <img
                  src={previewImage}
                  alt="Preview"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ) : activeImagesCount === 0 ? (
              <div className="text-center">
                  <img src={noImageIcon} alt="no image" className="w-40" />
                </div>
            ) : null}
          </div>
        </div>
      </div>
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        style={{ display: "none" }}
      />
      <div className="flex overflow-x-auto space-x-12 px-8">
        {renderThumbnails()}
      </div>
    </div>
  );
};

export default ImageUploadComponent;
