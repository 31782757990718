import { Field, reduxForm } from "redux-form";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ConfirmationDialouge from "../../utils/confirmation.dialouge";
import {
  ACTIONS,
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_DIALOUGES,
  CONFIRMATION_TITLES,
  ERROR,
  NAVIGATIONS,
  SESSION_DETAILS,
  SITE_MESSAGES,
  SUBMIT_BUTTON_TEXT,
} from "../../config/default.config";
import {
  areaValidation,
  latitudeValidation,
  locationValidation,
  longitudeValidation,
  statusValidation,
} from "../../validations/validations";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";

import { siteActions } from "./service/site.action";
import {
  ISiteAddParam,
  ISiteRespone,
  ISiteUpdateParam,
} from "./site.interface";
import { useNavigate } from "react-router-dom";
import {
  setIsError,
  setIsLoading,
  setToast,
  setToastMsg,
} from "../../common/common.reducer";
import ImageUploadComponent, {
  IImageInfo,
} from "../components/image-upload/image-upload.component";
import { setSiteDetails } from "./store/site.reducer";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";
import { encryptUtils } from "../../utils/encryption.utils";

const renderLocationInput = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="Location"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Location<span className="required_symbol">*</span>
      </label>
      <div className="mt-2">
        <input
          {...props?.input}
          type="text"
          name="Location"
          id="Location"
          maxLength={20}
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2  validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};
const renderAreaInput = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="Area"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Area<span className="required_symbol">*</span>
      </label>
      <div className="mt-2">
        <input
          {...props?.input}
          type="text"
          name="Area"
          id="Area"
          maxLength={20}
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderLatitude = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="Latitude"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Latitude<span className="required_symbol">*</span>
      </label>
      <div className="mt-2">
        <input
          {...props?.input}
          type="text"
          name="Latitude"
          id="Latitude"
          maxLength={60}
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderLangitude = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="Longitude"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Longitude<span className="required_symbol">*</span>
      </label>
      <div className="mt-2">
        <input
          {...props?.input}
          type="text"
          name="Longitude"
          id="Longitude"
          maxLength={60}
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderStatus = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="Status"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Status<span className="required_symbol">*</span>
      </label>
      <div className="mt-2">
        <select
          {...props?.input}
          id="Status"
          name="Status"
          placeholder="Select Status"
          className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="select">Select Status</option>
          {props.status.map((item: any, index: number) => (
            <option key={index} value={item?.value}>
              {item?.label}
            </option>
          ))}
        </select>
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

export function CreateSite(props: any) {
  const { handleSubmit, valid, dirty } = props;

  const navigate = useNavigate();
  const [setSiteImages]: any[] = useState([]);
  const dispatch = useAppDispatch();
  const { open, close } = useConfirmationPopup();
  const [imgtoRemove] = useState<any>();
  const [deletedImageCount, setDeletedImageCount] = useState<number>(0);
  const [images, setImages] = useState<IImageInfo[]>([]);
  const [ready, setReady] = useState<boolean>(false);
  const { id } = useParams();
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  const siteDetailsRespo = useAppSelector(
    (state) => state.siteReducer.siteDetails
  );

  let statusData = [
    { label: "Active", value: 0 },
    { label: "StandBy", value: 2 },
    { label: "Inactive", value: 1 },
  ];

  const updateDeletedImageCount = (images: IImageInfo[]) => {
    const count = images.filter((image) => !image.deleted).length;
    setDeletedImageCount(count);
  };

  const handleNavigateBack = (confirm: boolean) => {
    handleClosePopup();
    if (confirm) {
      if (siteDetailsRespo.id !== 0) {
        navigate(
          NAVIGATIONS.SITE_VIEW.replace(
            ":id",
            `${encryptUtils.encryptURL(siteDetailsRespo.id)}`
          )
        );
      } else {
        navigate(NAVIGATIONS.SITE_LIST);
      }
    }
  };

  const handleCloseDialog = () => {
    setShowConfirmationDialog(false);
  };
  const handleConfirmDelete = () => {
    let srcToRemove = imgtoRemove?.src;
    setSiteImages((prevImages: any) =>
      prevImages.filter((image: any) => image.src !== srcToRemove)
    );
    setShowConfirmationDialog(false);
  };

  const handleSave = (values: any) => {
    dispatch(setIsLoading(true));
    if (siteDetailsRespo.location.length === 0) {
      let AddSiteParamater: ISiteAddParam = {
        Id: 0,
        Location: values.Location,
        Area: values.Area,
        Latitude: values.Latitude,
        Longitude: values.Longitude,
        SiteImages: images,
        StorageType: 0,
        Status: values.Status,
      };

      dispatch(siteActions.addSite(AddSiteParamater, setSuccess));
    } else {
      let AddSiteParamater: ISiteUpdateParam = {
        Id: siteDetailsRespo.id,
        Location: values.Location,
        Area: values.Area,
        Latitude: values.Latitude,
        Longitude: values.Longitude,
        SiteImages: images,
        IsDeleted: false,
        Status: values.Status,
      };
      dispatch(siteActions.updateSite(AddSiteParamater, setSuccess));
    }
  };

  const handleOpenPopup = (confirmation: any) => {
    if (dirty || images.length > 0) {
      open(
        CONFIRMATION_TITLES.CONFIRM_EXIT,
        CONFIRMATION_DIALOUGES.UNSAVED_CHANGES,
        SUBMIT_BUTTON_TEXT.YES,
        CANCEL_BUTTON_TEXT.NO,
        () => {
          confirmation(true);
        },
        () => {
          confirmation(false);
        }
      );
    } else {
      confirmation(true);
    }
  };

  const handleClosePopup = () => {
    close();
  };

  const setSuccess = (status: boolean, type: string) => {
    if (status) {
      if (type === ACTIONS.CREATE) {
        dispatch(setIsError(false));
        dispatch(setToast(true));
        dispatch(setToastMsg(SITE_MESSAGES.SITE_CREATED));
        navigate(NAVIGATIONS.SITE_LIST);
        dispatch(setIsLoading(false));
      } else if (type === ACTIONS.UPDATE) {
        dispatch(setIsError(false));
        dispatch(setToast(true));
        dispatch(setToastMsg(SITE_MESSAGES.SITE_UPDATED));
        navigate(
          NAVIGATIONS.SITE_VIEW.replace(
            ":id",
            `${encryptUtils.encryptURL(siteDetailsRespo.id)}`
          )
        );
        dispatch(setIsLoading(false));
      }
    } else {
      dispatch(setIsError(true));
      dispatch(setToast(true));
      dispatch(setToastMsg(ERROR.SOMETHING_WENT_TO_WRONG));
      dispatch(setIsLoading(false));
    }
  };

  const setSuccessForSiteFetching = (state: boolean) => {
    if (state) {
      setReady(true);
      dispatch(setIsLoading(false));
    } else {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setIsLoading(true));
      let siteIdForUpdate = encryptUtils.decryptURL(id);
      if (siteIdForUpdate !== null) {
        dispatch(
          siteActions.getSiteById(siteIdForUpdate, setSuccessForSiteFetching)
        );
      } else if (siteIdForUpdate === null) {
        dispatch(setIsLoading(false));
        setReady(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setImages(siteDetailsRespo.images);
    props.initialize({
      Location: siteDetailsRespo.location,
      Area: siteDetailsRespo.area,
      Latitude: siteDetailsRespo.lattitude,
      Longitude: siteDetailsRespo.longitude,
      Status: siteDetailsRespo.status,
      images: siteDetailsRespo.images,
    });
  }, [siteDetailsRespo]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="inner-page-row">
          <div className="inline-block  w-[100%] top-bar-sticky dark:top-bar-bg-color">
            <div className="float-left flex items-center">
              <button
                type="button"
                className="mr-3"
                onClick={() => {
                  handleOpenPopup(handleNavigateBack);
                }}
              >
                <img src={backBtnIcon} alt="" className="p-1" />
              </button>

              <h2 className="text-xl ">
                <b className="ml-5 dark:head-text-color">
                  {siteDetailsRespo.id ? "Update Site" : "Add site"}
                </b>
              </h2>
            </div>
            <div className="float-right">
              <button
                className={`text-[14px] text-white rounded-md p-2 px-8 py-2 ${
                  !valid || images.length === 0 || deletedImageCount === 0
                    ? " bg-slate-300"
                    : "bg-blue-800"
                } `}
                disabled={
                  !valid || images.length === 0 || deletedImageCount === 0
                }
              >
                {siteDetailsRespo.id ? <div>Update</div> : <div>Save</div>}
              </button>
            </div>
          </div>
          <div className=" bg-white mt-6 rounded-md flex h-[80%] p-10 dark:panel-bg-color">
            <div className="w-1/2 pr-4">
              <h3 className="text-blue-700 font-bold text-lg inline-block">
                <span className=" underline underline-offset-8 decoration-4 mb-3 px-2 py-1 dark:head-text-color">
                  Basic
                </span>
              </h3>

              <div className="pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  <Field
                    type="text"
                    name="Location"
                    component={renderLocationInput}
                    validate={[locationValidation]}
                  />
                  <Field
                    type="text"
                    name="Area"
                    component={renderAreaInput}
                    validate={[areaValidation]}
                  />
                </div>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  <Field
                    type="text"
                    name="Latitude"
                    component={renderLatitude}
                    validate={[latitudeValidation]}
                  />
                  <Field
                    type="text"
                    name="Longitude"
                    component={renderLangitude}
                    validate={[longitudeValidation]}
                  />
                </div>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  <Field
                    name="Status"
                    component={renderStatus}
                    status={statusData}
                    validate={[statusValidation]}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 pl-4 p-20">
              <label
                htmlFor="grossPower"
                className="block text-sm font-medium leading-6 text-gray-900 ml-9  dark:normal-text-color"
              >
                Images <span className="text-red-500">*</span>
              </label>
              <ImageUploadComponent
                images={images}
                onImageUpload={(_images) => {
                  setImages(_images);
                  updateDeletedImageCount(_images);
                }}
              />
              {valid && deletedImageCount === 0 ? (
                <span className=" ml-10 text-red-400 ">Image is required</span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </form>
      <ConfirmationDialouge
        isOpen={showConfirmationDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        dialouge={CONFIRMATION_DIALOUGES.IMAGE_DELETE_CONFIRMATION}
      />
    </>
  );
}

export default reduxForm({
  form: "create-site-form",
})(CreateSite);
