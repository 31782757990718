import React, { useEffect, useState } from "react";
import exclamation from "../../../assets/images/exclamation-circle.svg";
import right from "../../../assets/images/right_black.svg";
import { useNavigate } from "react-router-dom";
import { NAVIGATIONS, OPERATOR_STATUS, SEQROPS_INSTANCE_API } from "../../../config/default.config";
import { dashboardAction } from "../service/dashbord.action";
import {
  IRecentAlarmResponse,
  IRecentMediaRequest,
  IRecentVideoResponse,
} from "./alarmSection.interface";
import { useAppDispatch, useAppSelector } from "../../../config/store.hooks";
import {
  IEquipmentDetailsResponse,
  IOperatorStatusRequest,
} from "../../components/status.component/status.table.interface";
import moment from "moment";
import RecentAlarmPopup from "../../components/preview-box/recent.alarm.popup";
import VideoPreviewBox from "../../components/preview-box/video.preview.box";

export function RecentAlarmLayout() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [operatorImage,setOperatorImage]=useState({image:"",showPopup:false,dateOfIdentification:""})

  const [recentAlarmData, setRecentAlarmData] = useState<
    IRecentAlarmResponse[]
  >([]);

  const [recentAlarmVideo, setRecentAlarmVideoData] = useState<
  IRecentVideoResponse[]
>([]);

// const [recentVideoEquipmentDetails,setRecentVideoEquipmentDetails]=useState<IEquipmentDetailsResponse[]>([])

  // const [equipmentDetailsData, setEquipmentDetailsData] = useState<
  //   IEquipmentDetailsResponse[]
  // >([]);


  const equipmentDetailsData = useAppSelector(
    (state) => state.commonReducer.recentEquipmentData
  );
  const recentVideoEquipmentDetails = useAppSelector(
    (state) => state.commonReducer.recentVideoEquipmentData
  );

  const mqttRecentAlarmData = useAppSelector(
    (state) => state.commonReducer.recentAlarmRecent
  );

  const [showVideo, setShowVideo] = useState(false);
  const [operatorVideo, setOperatorVideo] = useState("");

  const handleNavigateList = () => {
    navigate(NAVIGATIONS.ALARM_SECTION_LIST);
  };

  const handleRecentVideoNavigation=()=>{

      navigate(NAVIGATIONS.ALARM_SECTION_VIDEO_LIST)
  }

  const handleOperatorRecognition=(item:IRecentAlarmResponse,showPopup:boolean)=>{
    setOperatorImage({image:item.name,dateOfIdentification:item.dateTime,showPopup:showPopup})
  }

  useEffect(() => {
    let recentAlarmPaginationPayload: IRecentMediaRequest = {
      sortTerm: "",
      sortOrder: "DESC",
      page: 0,
      pageSize: 5,
    };
    recentAlarm(recentAlarmPaginationPayload);

    let recentVideoAlarmPaginationPayload: IRecentMediaRequest = {
      sortTerm: "",
      sortOrder: "DESC",
      page: 0,
      pageSize: 4,
    };
    recentVideoAlarm(recentVideoAlarmPaginationPayload);
  }, []);


  const recentVideoAlarm = async (
    recentAlarmPaginationPayload: IRecentMediaRequest
  ) => {
    const response: any = await dispatch(
      dashboardAction.getRecentVideoAlarms(recentAlarmPaginationPayload)
    );
  
    // setRecentAlarmVideoData(response?.Result)

    setRecentAlarmVideoData([
      {
        id: 817,
        name: "863540061974276__2023-11-16100419.mp4",
        imei: "863540061974276",
        fileType: "REAR VIDEO",
        trigger: "DIN1 (1)",
        time: "1700128971000",
        longitude: "0",
        latitude: "0",
        type: "VIDEO",
        operator_recognition: null,
        dateOfIdentification: null,
        operatorId: null,
      },
      {
        id: 817,
        name: "863540061974276__2023-11-16100419.mp4",
        imei: "863540061974276",
        fileType: "REAR VIDEO",
        trigger: "DIN1 (1)",
        time: "1700128971000",
        longitude: "0",
        latitude: "0",
        type: "VIDEO",
        operator_recognition: null,
        dateOfIdentification: null,
        operatorId: null,
      },
      {
        id: 817,
        name: "863540061974276__2023-11-16100419.mp4",
        imei: "863540061974276",
        fileType: "REAR VIDEO",
        trigger: "DIN1 (1)",
        time: "1700128971000",
        longitude: "0",
        latitude: "0",
        type: "VIDEO",
        operator_recognition: null,
        dateOfIdentification: null,
        operatorId: null,
      },
      {
        id: 817,
        name: "863540061974276__2023-11-16100419.mp4",
        imei: "863540061974276",
        fileType: "REAR VIDEO",
        trigger: "DIN1 (1)",
        time: "1700128971000",
        longitude: "0",
        latitude: "0",
        type: "VIDEO",
        operator_recognition: null,
        dateOfIdentification: null,
        operatorId: null,
      },
    ]);
  };

  const recentAlarm = async (
    recentAlarmPaginationPayload: IRecentMediaRequest
  ) => {
    const response: any = await dispatch(
      dashboardAction.getMediaAlaram(recentAlarmPaginationPayload)
    );
    setRecentAlarmData(response?.Result);
    // getAllOperatorStatus(response?.EquipmentDetails)
  };

  useEffect(() => {
    setRecentAlarmData(mqttRecentAlarmData);
  }, [mqttRecentAlarmData]);


  //api calling.. equipment details for vehicle number 
  // const getAllOperatorStatus = async (responseOperatorLog: any) => {
  //   const response: any = await dispatch(
  //     dashboardAction.getEquipmentDetails(responseOperatorLog)
  //   );
  //   // setEquipmentDetailsData(response);
  // };

  const handleOperatorStatus = (message: string) => {
    let status = "";
    if (message === OPERATOR_STATUS.OPERATOR_RECOGNISED) {
      status = OPERATOR_STATUS.OPERATOR_PRESENT_STATUS;
    } else if (message == OPERATOR_STATUS.NO_FACE) {
      status = OPERATOR_STATUS.ALARM_NO_FACE;
    } else if (message === OPERATOR_STATUS.FACE_NOT_RECOGNISED) {
      status = OPERATOR_STATUS.ALARM_UNRECOGNISED;
    }
    return status;
  };

  const handlePreviewImage = (showStatus: boolean, image: string) => {
    setShowVideo(showStatus);
    setOperatorVideo(image);
  };

  return (
    <div className="recentAlarmDesign rounded-lg  shadow-[0px_0px_11px_0px_#00000024] bg-white m-5 mt-16 mr-10 h-[85vh] dark:status-tile-container">
      <RecentAlarmPopup
        operatorInfo={operatorImage}
        onClose={() => {
          handleOperatorRecognition(
            {
              id: 0,
              name: "",
              equipmentId: "",
              imei: "",
              acknowledgement: "",
              acknowledgedAt: "",
              acknowledgedMessage: "",
              recognitionStatus: "",
              dateTime: "",
            },
            false
          );
        }}
      />


      <VideoPreviewBox
        showVideo={showVideo}
        operatorVideo={"https://seqrops-assets.ap-south-1.linodeobjects.com/dashboard/1.mp4"}
        onClose={() => {
          handlePreviewImage(false, "");
        }}
      />
    <div className="">
      <div className="flex  justify-between w-full mb-4 align-middle">
        <div className="font-semibold text-slate-500 text-xl dark:head-text-color title-tab">
          Recent Alarms
        </div>
        <div
          className="text-blue-600 cursor-pointer a-tab"
          onClick={handleNavigateList}
        >
          View More
        </div>
      </div>
      <div className="h-custom overflow-auto">
      {recentAlarmData?.map((item: IRecentAlarmResponse, index) => {
        
        return (
          <div className="flex w-full" key={item?.equipmentId + index}>
            <div className="pr-2 pt-3">
              <img src={exclamation} alt="exclamation" />
            </div>
            <div className="relative group w-full tab-text-sm">
              <div className="flex flex-col justify-between w-full rounded-md ">
                {item?.recognitionStatus ===
                OPERATOR_STATUS.FACE_NOT_RECOGNISED ? (
                  <div 
                   onClick={() => {
                    handleOperatorRecognition(item, true);
                  }}>
                    <div
                      className="pt-2 pl-2 text-black  text-xs dark:status-tile-container cursor-pointer "
                    >
                      {moment
                        .utc(item?.dateTime)
                        .local()
                        .format("DD-MM-YYYY HH:mm:ss")}{" "}
                      -{equipmentDetailsData[index]?.vehicleNumber}
                    </div>
                    <div className="text-orange-500 pl-2 cursor-pointer ">
                      {handleOperatorStatus(item?.recognitionStatus)}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="pt-2 pl-2 text-black text-xs dark:status-tile-container ">
                      {moment
                        .utc(item?.dateTime)
                        .local()
                        .format("DD-MM-YYYY HH:mm:ss")}{" "}
                      -{equipmentDetailsData[index]?.vehicleNumber}
                    </div>
                    <div className="text-yellow-500 pl-2 ">
                      {handleOperatorStatus(item?.recognitionStatus)}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`absolute hidden ${
                  item?.recognitionStatus ===
                  OPERATOR_STATUS.FACE_NOT_RECOGNISED
                    ? "group-hover:flex"
                    : ""
                } right-0 top-0 pr-4 pt-6 `}
              >
                <img className="h-3" src={right} alt="right" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
      </div>
      <div className="">
      <div className="flex  justify-between w-full align-middle">
        <div className="font-semibold text-slate-500 text-xl dark:head-text-color title-tab">
          Recent Videos
        </div>
        <div
          className="text-blue-600 cursor-pointer a-tab"
          onClick={handleRecentVideoNavigation}
        >
          View More
        </div>
      </div>
      <div className="video-height overflow-auto">

      <div className="grid grid-cols-2">
        {recentAlarmVideo?.map((item: IRecentVideoResponse,index) => {
          let videoUrl = SEQROPS_INSTANCE_API.GET_OPERATORVIDEO.replace(
            ":video",
            item.name
          );
          let timestamp =0
  
              if(item?.time)
              {
                timestamp = Number(item?.time);

              }       
               const date = new Date(timestamp);
          return (
            <div key={item.id} className=" cursor-pointer flex flex-col items-center" onClick={()=>{handlePreviewImage(true,videoUrl)}}>
              <div className="relative video-box mt-5 mb-2 mx-3">
                <video width="200" height="100">
                  <source src={"https://seqrops-assets.ap-south-1.linodeobjects.com/dashboard/1.mp4"} type="video/mp4" />
                  Your browser does not support Video.
                </video>
                <span> 
                  <svg className ="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 10 16">
                    <path d="M3.414 1A2 2 0 0 0 0 2.414v11.172A2 2 0 0 0 3.414 15L9 9.414a2 2 0 0 0 0-2.828L3.414 1Z"/>
                 </svg>
                </span>
              </div>
              <div className=" flex flex-col items-center text-xs">
                <div>{moment.utc(date).local().format("DD-MM-YYYY HH:mm:ss")} -{recentVideoEquipmentDetails[index]?.vehicleNumber}</div>
              </div>
            </div>
          );
        })}
        
      </div>
      </div>
      </div>
    </div>
  );
}
