const DEVICE_API_BASE_URL = process.env.REACT_APP_DEVICE_API_BASE_URL;
const OPERATOR_API_BASE_URL = process.env.REACT_APP_OPERATOR_API_BASE_URL;
const SITE_API_BASE_URL = process.env.REACT_APP_SITE_API_BASE_URL;
const UMS_BASE_URL = process.env.REACT_APP_UMS_API_BASE_URL;
const EQUIPMENT_URL = process.env.REACT_APP_EQUIPMENT_API_BASE_URL;
const CUSTOMER_URL = process.env.REACT_APP_CUSTOMER_API_BASE_URL;
const CONTRACT_API_BASE_URL = process.env.REACT_APP_CONTRACT_API_BASE_URL;

//seqrops env urls
export const AUTH_BASE_URL = `${process.env.REACT_APP_AUTH_API_URL}`;
export const ASSET_SUITE_LOGIN_URL = `${process.env.REACT_APP_SUITE_URL}`;
export const ASSET_MANAGEMENT_URL = `${process.env.REACT_APP_ASSETS_API_URL}`;
export const COMPUTATION_URL = `${process.env.REACT_APP_COMPUTATION_API_URL}`;

export const AUTH_CREDENTIALS = {
  KEY: "3d7b7a48fb2d4b5481f2688d8e9a27b3",
  IV: "3fd7aaf3s4b0438d",
  CLIENT_SECRET: `${process.env.REACT_APP_CONWORX_CLIENT_SECRET}`,
  CLIENT_ID: `${process.env.REACT_APP_CONWORX_CLIENT_ID}`,
};

export const DASHBOARD_TYPE = {
  REDIRECT: "redirectFrom",
};
export const THEME_CHANGE_URL = {
  // THEME_CHANGE :AUTH_BASE_URL+"api/v1/attributes/set-theme"
  THEME_CHANGE: "https://demo-api.seqrops.in/oauth/api/v1/attributes/set-theme",
};

//seqrops cookie env
export const COOKIE_DETAILS = {
  COOKIE_NAME: `${process.env.REACT_APP_COOKIE_PREFIX}_user_session_id`,
  COOKIE_DOMAIN: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
};

export const KEYCLOAK_GRANT_TYPES = {
  PASSWORD: "password",
  CLIENT_CREDENTIALS: "client_credentials",
  REFRESH_TOKENS: "refresh_token",
};

export const KEYCLOAK_CLIENTS = {
  ASSET_SUITE: "asset-suite",
  ASSET_MANAGEMENT: "asset-management",
  ASSET_MODELS: "asset-models",
  DEVICE_MANAGEMENT: "device-management",
  FIELD_SERVICE_MANAGEMENT: "field-service-management",
  OPERATIONAL_DASHBOARD: "operational-dashboard",
  MANAGEMENT_DASHBOARD: "management-dashboard",
  ASSET_CONTRACT: "asset-contract",
  MONITORING_DASHBOARD: "monitoring-dashboard",
};

export const ASSET_SCOPES = [
  "asset-management",
  "asset-models",
  "device-management",
  "asset-suite",
  "field-service-management",
  "operational-dashboard",
  "management-dashboard",
  "asset-contract",
  "monitoring-dashboard",
];

export const CLEAR_CLIENT_SCOPES = "clear-scopes";

export const KEYCLOAK_CLIENT_SECRETS = {
  ASSET_SUITE: `${process.env.REACT_APP_SUITE_CLIENT_SECRET}`,
  ASSET_MANAGEMENT: "",
};

//auth api of seqrops
export const ASSET_AUTH_API = {
  GET_LOGIN_TOKEN: AUTH_BASE_URL + "api/v1/auth/token",
  VERIFY_LOGIN_TOKEN: AUTH_BASE_URL + "api/v1/auth/token/introspect",
  LOGOUT_TOKEN: AUTH_BASE_URL + "api/v1/auth/logout",
};

export const ASSET_URLS = {
  APP_SUITE_URL: `${process.env.REACT_APP_SUITE_URL}/dashboard`,
  ASSET_MANAGEMENT_URL: `${process.env.REACT_APP_ASSETS_URL}`,
  ASSET_MODELS_URL: `${process.env.REACT_APP_MODELS_URL}`,
  DEVICE_MANAGEMENT_URL: `${process.env.REACT_APP_DEVICE_MANAGEMENT_URL}/#/devices`,
  FIELD_SERVICE_MANAGEMENT_URL: `${process.env.REACT_APP_FSM_URL}`,
  OPERATIONAL_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  MANAGEMENT_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  CONTRACT_URL: `${process.env.REACT_APP_CONTRACT_URL}`,
  MONITORING_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
};

//end

export const NAVIGATIONS = {
  DASHBOARD: "/",
  EQUIPMENT_LIST: "/equipments",
  EQUIPMENT_ADD: "/equipments/add",
  EQUIPMENT_VIEW: "/equipments/:id",
  EQUIPMENT_UPDATE: "/equipments/update/:id",
  EQUIPMENT_HISTORY: "/equipments/history/:id",
  OPERATOR_LIST: "/operators",
  OPERATOR_ADD: "/operators/add",
  OPERATOR_VIEW: "/operators/:id",
  OPERATOR_UPDATE: "/operators/update/:id",
  OPERATOR_HISTORY: "/operators/history/:id",
  SITE_LIST: "/sites",
  SITE_ADD: "/sites/add",
  SITE_VIEW: "/sites/:id",
  SITE_UPDATE: "/sites/update/:id",
  SITE_HISTORY: "/sites/history/:id",
  CUSTOMER_LIST: "/customers",
  CUSTOMER_ADD: "/customers/add",
  CUSTOMER_VIEW: "/customers/:id",
  CUSTOMER_UPDATE: "/customers/update/:id",
  CUSTOMER_HISTORY: "/customers/history/:id",
  DEVICE_LIST: "/devices",
  DEVICE_ADD: "/devices/add",
  DEVICE_VIEW: "/devices/:id",
  DEVICE_UPDATE: "/devices/update/:id",
  CONTRACT_LIST: "/contracts",
  CONTRACT_ADD: "/contracts/add",
  CONTRACT_VIEW: "/contracts/:id",
  CONTRACT_UPDATE: "/contracts/update/:id",
  REPORTS: "/reports",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:code",
  ALARM_SECTION_LIST: "/alarmsection",
  ALARM_SECTION_VIEW: "/alarmsection/:id",
  OPERATOR_STATUS_VIEW: "/operatorstatusview",
  ALARM_SECTION_VIDEO_LIST: "/alarmsectionvideo",
};
export const AUTH_API = {
  TOKEN: `${UMS_BASE_URL}/token`,
  INTROSPECT: `${UMS_BASE_URL}/token/introspect`,
  LOGOUT: `${UMS_BASE_URL}/logout`,
  FORGOT_PASSWORD: `${UMS_BASE_URL}/api/v1/user/forgot-password`,
  VERIFY_RESET_CODE: `${UMS_BASE_URL}/api/v1/user/verify-reset-code/{0}`,
  RESET_PASSWORD: `${UMS_BASE_URL}/api/v1/user/reset-password`,
};

export const EQUIPMENT_API = {
  ADD_EQUIPMENT: `${EQUIPMENT_URL}/api/v1/Equipment/save`,
  UPDATE_EQUIPMENT: `${EQUIPMENT_URL}/api/v1/Equipment/update`,
  DELETE_EQUIPMENT: `${EQUIPMENT_URL}/api/v1/Equipment/delete?id=:id`,
  GET_ALL_EQUIPMENT: `${EQUIPMENT_URL}/api/v1/Equipment/getAll/pagination?Page=:page&PageSize=:pageSize&OrderByDesc=:orderByDesc&OrderBy=:orderBy&SearchKey=:searchKey`,
  GET_EQUIPMENT_BY_ID: `${EQUIPMENT_URL}/api/v1/Equipment/getByid?id={0}`,
  GET_EQUIPMENT_HISTORY: `${CONTRACT_API_BASE_URL}/api/v1/Equipment/History/getAll/history?Page=:page&PageSize=:pageSize&OrderByDesc=:orderByDesc&OrderBy=:orderBy&SearchKey=:searchKey&Id=:id`,
  GET_EQUIPMENT_STATUS: `${ASSET_MANAGEMENT_URL}/api/v1/instance/get-equipments-status/EXCAVATOR`,
};

export const CUSTOMER_API = {
  ADD_CUSTOMER: `${CUSTOMER_URL}/api/v1/Customer/save`,
  UPDATE_CUSTOMER: `${CUSTOMER_URL}/api/v1/Customer/update`,
  DELETE_CUSTOMER: `${CUSTOMER_URL}/api/v1/Customer/delete?id=:id`,
  GET_ALL_CUSTOMER: `${CUSTOMER_URL}/api/v1/Customer/getAll/pagination?Page=:page&PageSize=:pageSize&OrderByDesc=:orderByDesc&OrderBy=:orderBy&SearchKey=:searchKey`,
  GET_CUSTOMER_BY_ID: `${CUSTOMER_URL}/api/v1/Customer/getByid?id={0}`,
  GET_CUSTOMER_HISTORY: `${CONTRACT_API_BASE_URL}/api/v1/Customer/History/getAll/history?Page=:page&PageSize=:pageSize&OrderByDesc=:orderByDesc&OrderBy=:orderBy&SearchKey=:searchKey&Id=:id`,
};

export const DEVICE_API_ENDPOINTS = {
  GET_DEVICE_LIST: `${DEVICE_API_BASE_URL}/api/v1/Device/getAll/pagination?Page=:page&PageSize=:pageSize&OrderByDesc=:orderByDesc&OrderBy=:orderBy&SearchKey=:searchKey`,
  ADD_DEVICE: `${DEVICE_API_BASE_URL}/api/v1/Device/save`,
  GET_DEVICE_TYPES: `${DEVICE_API_BASE_URL}/api/v1/DeviceType/getAll`,
  GET_DEVICE_BY_ID: `${DEVICE_API_BASE_URL}/api/v1/Device/getById?id=:id`,
  UPDATE_DEVICE: `${DEVICE_API_BASE_URL}/api/v1/Device/update`,
  DELETE_DEVICE: `${DEVICE_API_BASE_URL}/api/v1/Device/delete?id=:id`,
  EQUIPMENT_DEVICE: `${DEVICE_API_BASE_URL}/api/v1/Device/getAll/equipmentDevices`,
};

export const OPERATOR_API_ENDPOINTS = {
  GET_OPERATOR_LIST: `${OPERATOR_API_BASE_URL}/api/v1/Operator/getAll/pagination?Page=:page&PageSize=:pageSize&OrderByDesc=:orderByDesc&OrderBy=:orderBy&SearchKey=:searchKey`,
  ADD_OPERATOR: `${OPERATOR_API_BASE_URL}/api/v1/Operator/save`,
  GET_OPERATOR_BY_ID: `${OPERATOR_API_BASE_URL}/api/v1/Operator/getById?id=:id`,
  UPDATE_OPERATOR: `${OPERATOR_API_BASE_URL}/api/v1/Operator/update`,
  DELETE_OPERATOR: `${OPERATOR_API_BASE_URL}/api/v1/Operator/delete?id=:id`,
};

export const SITE_API = {
  GET_ALL_SITE: `${SITE_API_BASE_URL}/api/v1/Site/getAll/pagination?Page=:page&PageSize=:pageSize&OrderByDesc=:orderByDesc&OrderBy=:orderBy&SearchKey=:searchKey`,
  GET_SITE_BY_ID: `${SITE_API_BASE_URL}/api/v1/Site/getById?id={0}`,
  ADD_SITE_API: `${SITE_API_BASE_URL}/api/v1/Site/save`,
  UPDATE_SITE_API: `${SITE_API_BASE_URL}/api/v1/Site/update`,
  DELETE_SITE_API: `${SITE_API_BASE_URL}/api/v1/Site/delete?id=:id`,
  GET_SITES_HISTORY: `${CONTRACT_API_BASE_URL}/api/v1/Site/History/getAll/history?Page=:page&PageSize=:pageSize&OrderByDesc=:orderByDesc&OrderBy=:orderBy&SearchKey=:searchKey&Id=:id`,
  SITE_UNABLE_DELETE: "Site cannot be deleted as it is used in contracts.",
  SITE_STATUS: `${SITE_API_BASE_URL}/api/v1/Site/getAll/siteStatus`,
};

export const CONTRACT_API = {
  ADD_CONTRACT_API: `${CONTRACT_API_BASE_URL}/api/v1/Contract/save`,
  SITE_CONTRACT: `${SITE_API_BASE_URL}/api/v1/Site/getAll/siteContracts`,
  CUSTOMER_CONTRACT: `${CUSTOMER_URL}/api/v1/Customer/getAll/customerContracts`,
  GET_ALL_CONTRACT: `${CONTRACT_API_BASE_URL}/api/v1/Contract/getAll/pagination?Page=:page&PageSize=:pageSize&OrderByDesc=:orderByDesc&OrderBy=:orderBy&SearchKey=:searchKey`,
  GET_CONTRACT_BY_ID: `${CONTRACT_API_BASE_URL}/api/v1/Contract/getById?id={0}`,
  GET_EQUIPMENT_BY_IDS: `${EQUIPMENT_URL}/api/v1/Equipment/getByIds`,
  DELETE_CONTRACT_BY_ID: `${CONTRACT_API_BASE_URL}/api/v1/Contract/delete?id=:id`,
  UPDATE_CONTRACT: `${CONTRACT_API_BASE_URL}/api/v1/Contract/update`,
};

export const SEQROPS_INSTANCE_API = {
  // GET_ALL_EQUIPMENT:"https://demo-api.seqrops.in/assets/api/v1/instance/get-all-equipment-models",
  // GET_ALL_INSTANCE:"https://demo-api.seqrops.in/assets//api/v1/instance/getAllInstances"
  GET_ALL_EQUIPMENT: `${ASSET_MANAGEMENT_URL}api/v1/instance/get-all-equipment-models`,
  GET_ALL_INSTANCE: `${ASSET_MANAGEMENT_URL}/api/v1/instance/getAllInstances`,
  GET_ALL_EQUIPMENT_DETAILS: `${ASSET_MANAGEMENT_URL}api/v1/instance/get-equipments-details`,
  GET_ALL_OPERATOR_LOGS: `${COMPUTATION_URL}api/v1/computation/get-operator-logs`,
  GET_OPERATORIMAGE: `${COMPUTATION_URL}api/v1/computation/get-media-file/:image/IMAGE`,
  GET_MEDIA_ALARM: `${COMPUTATION_URL}api/v1/computation/get-media-alarm`,
  GET_RECENT_VIDEO: `${COMPUTATION_URL}api/v1/computation/get-media-logs/`,
  GET_OPERATORVIDEO: `${COMPUTATION_URL}api/v1/computation/get-media-file/:video/VIDEO`,
  UPDATE_OPERATOR_STATUS: `${COMPUTATION_URL}api/v1/computation/update-operator-status`,
  GET_ALL_INSTANCE_PROPERTIES: `${ASSET_MANAGEMENT_URL}api/v1/instance/getAllInstances-properties`,
  GET_OPERATOR_DETAILS: `${COMPUTATION_URL}api/v1/simulate/get-all-operators`,
};

export const VALIDATION_MESSAGES = {
  NAME_REQUIRED: "Name is required",
  NAME_MIN_LENGTH: "Value must be between 3 to 20 characters",
  ADDRESS_MIN_LENGTH: "Value must be between 3 to 100 characters",
  DEVICE_TYPE_REQUIRED: "Device type is required",
  INVALID_PHONE_NUMBER: "Invalid phone number",
  IMEI_REQUIRED: "IMEI is required",
  PROTOCOL_REQUIRED: "Protocol is required",
  PORT_REQUIRED: "Port is required",
  FRAME_RATE_REQUIRED: "Frame rate is required",
  RESOLUTION_REQUIRED: "Resolution is required",
  PORT_NUMBER_MAX_LIMIT: "Max limit is 6 digit",
  PORT_NUMBER_MIN_LIMIT: "Min limit is 3 digit",
  IPADDRESS_REQUIRED: "IPAddress is required",
  ADDRESS_REQUIRED: "Address is required",
  REFRESH_RATE_REQUIRED: "Refresh rate is required",
  ALPHANUMERIC_VALIDATION: "Only alphanumeric characters allowed",
  INVALID_IMEI: "Invalid IMEI format (max 15 digit)",
  INVALID_IPADDRESS_OR_HOSTNAME: "Invalid IP address or hostname",
  ONLY_NUMBER_ALLOWED: "Only numbers allowed",
  LOCATION_REQUIRED: "Location is required",
  AREA_REQUIRED: "Area is required",
  LATITUDE_REQUIRED: "Latitude is required",
  LONGITUDE_REQUIRED: "Longitude is required",
  VALID_LATITUDE: "Enter valid latitude",
  VALID_LONGITUDE: "Enter valid longitude",
  STATUS_REQUIRED: "Status required",
  DEVICE_REQUIRED: "Device Required",
  VEHICLE_NUMBER_REQUIRED: "Vehicle Number is required",
  DESCRIPTION_REQUIRED: "Description  is required",
  BUCKET_CAPACITY_REQUIRED: "Bucket Capacity is required",
  MAX_DIGGING_DEPTH_REQUIRED: "Max. Digging Depth is required",
  OPERATING_WEIGHT_REQUIRED: "Operating Weight is required",
  GROSS_POWER_REQUIRED: "Gross Power is required",
  INVALID_GST_NUMBER: " Invalid GST Number",
  GST_NUMBER_EXISTS: "GST number already exists",
  GST_NUMBER_REQUIRED: "GST Number is required",
  CUSTOMER_LIST: "Customer List is required",
  SITE_LIST: "Site List is required",
  DATE_REQUIRED: "Date is required",
  DURATION_REQUIRED: "Duration is required",
  DURATION_MINIMUM: "Max limit 5 digit",
  STARTDATE_LIMIT: "Date must be :endDate or before",
  ENDATE_LIMIT: "Date must be :startDate or later",
  INVALID_INPUT: "Invalid input.This field only allow :expression",
};

export const AUTH_MESSAGES = {
  USER_NOT_FOUND: "Invalid Credentials ",
  SERVER_ERROR:
    "Server not found!, please check your internet connection and try again",
  LOGIN_SUCCESS: "Login Successfully",
  LOGOUT_SUCCESS: "Logout Sucessfully",
  LOUGOUT_FAIL: "Logout fail.Please try again",
  LOGOUT_TITLE: "Confirm Logout",
  LOGOUT_CONFIRMATION: "Are you sure do you want to logout?",
  FORGOT_VERIFYCODE_SEND_SUCCESS: "Verification code send sucessfully",
  RESET_SUCCESS: "Success! Your password has been reset successfully.",
  SESSION_EXPIRED: "Session Expired !",
  SESSION_EXPIRED_MSG: "Your session is expired.Please try again",
};

export const SITE_MESSAGES = {
  SITE_CREATED: "Site has been successfully added.",
  SITE_UPDATED: "Site has been successfully updated ",
  SITE_DELETED: "Site has been successfully deleted",
  SITE_DELETE_FAIL: "Site deletion was not successful",
  DEVICE_DELETE_CONFIRMATION: `Do you want to delete the site ":locationName" ?`,
  SITE_UNABLE_DELETE: "Site cannot be deleted as it is used in contracts.",
};

export const EQUIPMENT_MESSAGE = {
  EQUIPMENT_CREATED: "Equipment has been successfully added.",
  EQUIPMENT_UPDATED: "Equipment has been successfully updated ",
  EQUIPMENT_DELETED: "Equipment has been successfully deleted",
  EQUIPMENT_DELETE_FAIL: "Equipment deletion was not successful",
  EQUIPMENT_DELETE_CONFIRMATION: `Do you want to delete the Equipment ":equipmentName" ?`,
  EQUIPMENT_UNABLE_DELETE:
    "Equipment cannot be deleted as it is used in contracts.",
};

export const CUSTOMER_MESSAGE = {
  CUSTOMER_CREATED: "Customer has been successfully added.",
  CUSTOMER_UPDATED: "Customer has been successfully updated ",
  CUSTOMER_DELETED: "Customer has been successfully deleted",
  CUSTOMER_DELETE_FAIL: "Customer deletion was not successful",
  CUSTOMER_DELETE_CONFIRMATION: `Do you want to delete the customer ":name" ?`,
  CUSTOMER_UNABLE_DELETE:
    "Customer cannot be deleted as it is used in contracts.",
};

export const CONTRACT_MESSAGE = {
  CONTRACT_CREATED: "Contract has been successfully added.",
  CONTRACT_DELETE_SUCCESS: "Contract has been successfully deleted",
  CONTRACT_UPDATE: "Contract has been successfully updated",
  CONTARCT_DELETE_FAILED: "Contract deletion was not successful",
};
export const DEVICE_MESSAGES = {
  DEVICE_ADD_SUCCESS: "Device has been successfully added.",
  DEVICE_UPDATE_SUCCESS: "Device has been successfully updated",
  DEVICE_DELETE_SUCCESS: "Device has been successfully deleted",
  DEVICE_ADD_FAILED: "Device addition was not successful.",
  DEVICE_UPDATE_FAILED: "Device update was not successful",
  DEVICE_DELETE_FAILED: "Device deletion was not successful",
  DEVICE_NAME_ALREADY_EXIST: "Device name already in use",
  DEVICE_IMEI_ALREADY_EXIST: "Device IMEI already exist",
  DEVICE_UNABLE_DELETE: "Device cannot be deleted as it is used in equipments.",
};

export const OPERATOR_MESSAGES = {
  OPERATOR_ADD_SUCCESS: "Operator has been successfully added.",
  OPERATOR_UPDATE_SUCCESS: "Operator has been successfully updated",
  OPERATOR_DELETE_SUCCESS: "Operator has been successfully deleted",
  OPERATOR_ADD_FAILED: "Operator addition was not successful.",
  OPERATOR_UPDATE_FAILED: "Operator update was not successful",
  OPERATOR_DELETE_FAILED: "Operator deletion was not successful",
  OPERATOR_UNABLE_DELETE:
    "Operator cannot be deleted as it is used in contracts.",
};
export const ERROR = {
  SOMETHING_WENT_TO_WRONG: "Something went to wrong",
};

export const OPERATOR_ERROR_MESSAGE = {
  OPERATOR_DELETE_ERROR_CODE: "IS_USED_IN_CONTRACTS",
};
export const SITE_ERROR_MESSAGE = {
  SITE_DELETE_ERROR_CODE: "IS_USED_IN_CONTRACTS",
};

export const DEVICE_ERROR_CODES = {
  DEVICE_NAME_ALREADY_EXIST: "NAME_ALREADY_EXISTS",
  DEVICE_IMEI_ALREADY_EXIST: "IMEI_ALREADY_EXISTS",
  DEVICE_DELETE_ERROR_CODE: "IS_USED_IN_EQUIPMENTS",
  IMEI_ALREADY_EXISTS_ERROR_CODE: "IMEI_ALREADY_EXISTS",
};
export const EQUIPMENT_ERROR_CODES = {
  EQUIPMENT_DELETE_ERROR_CODE: "IS_USED_IN_CONTRACTS",
};
export const CUSTOMER_ERROR_CODES = {
  CUSTOMER_DELETE_ERROR_CODE: "IS_USED_IN_CONTRACTS",
  INVALID_GST_NUMBER_ERROR_CODE: "INVALID_GST_NUMBER",
};

export const CONFIRMATION_TITLES = {
  CONFIRM_EXIT: "Confirm Exit",
  CONFIRM_DELETE: "Confirm Delete",
};

export const CONFIRMATION_DIALOUGES = {
  IMAGE_DELETE_CONFIRMATION: "Do you want to delete the selected image?",
  DEVICE_DELETE_CONFIRMATION: "Do you want to delete the device :deviceName ?",
  UNSAVED_CHANGES:
    "You have unsaved changes. Are you sure you want to continue without saving these changes?",
  EQUIPMENT_DELETE_CONFIRMATION:
    "Do you want to delete the equipment :equipmentName ?",
  OPERATOR_DELETE_CONFIRMATION:
    "Do you want to delete the operator :operatorName ?",
  CONTRACT_DELETE_CONFIRMATION:
    "Do you want to delete the contract :contractName ?",
};
export const CONFIRMATION_ID = {
  SESSION_ID: "SESSION_EXPIRE",
};

export const SUBMIT_BUTTON_TEXT = {
  CONFIRM: "Confirm",
  SUBMIT: "Submit",
  OK: "Ok",
  YES: "Yes",
};

export const CANCEL_BUTTON_TEXT = {
  CANCEL: "Cancel",
  DECLINE: "Decline",
  NO: "No",
};

export const SESSION_DETAILS = {
  AUTH_TOKEN: "auth_details",
  SESSION_KEY: "session_id",
  POS_KEY: "pos",
};

export const SESSION_KEY = {
  NAVIGATION_STATE: "vdfwfznVgu",
  LOCAL_STORAGE_KEY: "ut",
  REDIRECT_LOCATION_KEY: "rl",
  CLEAR_ALL_TOKEN_DATA_KEY: "cl",
  DOMAIN_KEY: "dmn",
  SUITE_KEY: "su1te",
  MODELS_KEY: "mod3ls",
  ASSETS_KEY: "ass3ts",
  DEVICE_MANAGEMENET_KEY: "d3vice",
  FSM_KEY: "f2m",
  OPERATIONAL_DASHBOARD_KEY: "0pd",
  MANAGEMENT_DASHBOARD_KEY: "Wgmt",
};

export const USER_DETAILS = {
  USER_KEY: "U53rNa33",
};

export const AUTH_GRANT_TYPES = {
  PASSWORD: "password",
  REFRESH_TOKEN: "refresh_token",
};

export const ACTIONS = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

export const CURRENT_POSITIONS = {
  DASHBOARD: "dashboard",
  EQUIPMENTS: "equipments",
  OPERATORS: "operators",
  SITES: "sites",
  CUSTOMERS: "customers",
  DEVICES: "devices",
  CONTRACT: "contract",
  OPERATOR_HISTORY: "operator_history",
  REPORT: "report",
};

export const PROTOCOLS = {
  TCP: { id: 1, name: "TCP" },
  UDP: { id: 2, name: "UDP" },
};

export const LISTER_HEADINGS = {
  EQUIPMENT_LIST: "Equipment List",
  YOUR_EQUIPMENT: "Your Equipment",
  OPERATOR_LIST: "Operator List",
  YOUR_OPERATOR: "Your Operator",
};

export const HISTORY_TYPE = {
  EQUIPMENTS: "equipments",
  OPERATORS: "operators",
  SITES: "sites",
  CUSTOMERS: "customers",
  CONTRACTS: "contracts",
};

export const OPERATOR_STATUS = {
  OPERATOR_RECOGNISED: "OPERATOR_RECOGNISED",
  FACE_NOT_RECOGNISED: "FACE_NOT_RECOGNISED",
  NO_FACE: "NO_FACE",
  OPERATOR_PRESENT_STATUS: "Operator Present",
  OPERATOR_NOT_PRESENT: "Operator Not Present",
  UNKNOWN_OPERATOR_STATUS: "Unknown Operator",
  NO_FACE_STATUS: "NO FACE",
  ALARM_NO_FACE: "Operator not present in the image",
  ALARM_UNRECOGNISED: "Operator unrecognised in the image",
  OPERATOR_NOT_ACTIVE_STATUS: "Inactive",
  OPERATOR_STANDBY: "StandBy",
};

export const ASSET_REDIRECT_SCOPES = {
  ASSET_SUITE: "asset_suite",
  ASSET_MANAGEMENT: "asset_management",
  ASSET_MODELS: "asset_models",
  DEVICE_MANAGEMENT: "device_management",
  FIELD_SERVICE_MANAGEMENT: "field_service_management",
  OPERATIONAL_DASHBOARD: "operational_dashboard",
  MANAGEMENT_DASHBOARD: "management_dashboard",
  MONITORING_DASHBOARD: "monitoring_dashboard",
};
