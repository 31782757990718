import { ILoginFormValues, ITokenResponse } from "../types/login.types";
import { AESUtils } from "../../../utils/aes.utils";
import { authService } from "./login.services";
import { Action, Dispatch } from "@reduxjs/toolkit";
import { IApiResponse } from "../../../config/commons.interface";
import { clearToken, setToken } from "../store/login.reducer";
import { sessionStorageUtils } from "../../../utils/session-storage.utils";
import {
  AUTH_CREDENTIALS,
  AUTH_GRANT_TYPES,
  SESSION_DETAILS,
  USER_DETAILS,
} from "../../../config/default.config";
import { clearCurrentPos } from "../../../common/common.reducer";
import jwt_decode from "jwt-decode";

export type IApiParams = {
  emailAddress: string;
  password: string;
};

const login = (
  param: ILoginFormValues,
  callback: (errorCode: string | null) => void
) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const data = {
        username: param.emailAddress,
        password: AESUtils.encrypt(param.password),
        clientId: AUTH_CREDENTIALS.CLIENT_ID,
        clientSecret: AUTH_CREDENTIALS.CLIENT_SECRET,
        grantType: "password",
        refreshToken: "",
        sessionId: "",
      };
      const response = await authService.getToken(data);
      if (response.statusCode === 200) {
        dispatch(setToken(response.data));
        let decoded : any = jwt_decode(response.data?.accessToken);
        sessionStorageUtils.setLocalStorage(USER_DETAILS.USER_KEY,decoded)
        callback(null);
      }
    } catch (error: any) {
      callback(error.code);
    }
  };
};

const getRefreshToken = async (
  sessionState: string,
  refreshToken: string
): Promise<ITokenResponse> => {
  const data = {
    username: AESUtils.encrypt("USER"),
    password: AESUtils.encrypt("PASS"),
    clientId: AUTH_CREDENTIALS.CLIENT_ID,
    clientSecret: AUTH_CREDENTIALS.CLIENT_SECRET,
    grantType: AUTH_GRANT_TYPES.REFRESH_TOKEN,
    refreshToken: refreshToken,
    sessionId: AESUtils.encrypt(sessionState),
  };

  const response: IApiResponse<ITokenResponse> = await authService.verifyToken(
    AESUtils.encrypt(sessionState) || ""
  );

  if (`${response.data.active}` === "false") {
    return {
      accessToken: "",
      tokenType: "",
      expiresIn: 0,
      refreshExpiresIn: 0,
      refreshToken: "",
      sessionState: "",
      scope: "",
      active: false,
      sessionId: "",
    };
  } else {
    const refreshResponse = await authService.getToken(data);
    if (refreshResponse.statusCode === 200) {
      return refreshResponse.data;
    } else {
      return {
        accessToken: "",
        tokenType: "",
        expiresIn: 0,
        refreshExpiresIn: 0,
        refreshToken: "",
        sessionState: "",
        scope: "",
        active: false,
        sessionId: "",
      };
    }
  }
};

const logout = (callback: (status: boolean) => void) => {
  return async (dispatch: Dispatch<Action>) => {
    const tokenDetails: ITokenResponse = sessionStorageUtils.getLocalStorage(
      SESSION_DETAILS.AUTH_TOKEN
    );
    const requestParam = {
      refreshToken: tokenDetails.refreshToken,
      sessionId: AESUtils.encrypt(tokenDetails.sessionState),
    };
    const response: IApiResponse<null> = await authService.logout(requestParam);
    if (response.statusCode === 200) {
      callback(true);
      dispatch(clearToken());
      dispatch(clearCurrentPos())
      localStorage.removeItem(SESSION_DETAILS.POS_KEY);
    } else {
      callback(false);
      dispatch(clearToken());
    }
  };
};

const forgotPassword = async (email: string) => {
  const response: IApiResponse<ITokenResponse | null> =
    await authService.forgotPassword(email);
  return response;
};

const verifyCode = async (code: string) => {
  const response: IApiResponse<ITokenResponse | null> =
    await authService.verifyCode(code);
  return response;
};

const resetPassword = async (
  code: string,
  newPassword: string,
  confirmPassword: string
) => {
  const response: IApiResponse<ITokenResponse | null> =
    await authService.resetPassword(code, newPassword, confirmPassword);
  return response;
};
export const authActions = {
  login,
  getRefreshToken,
  logout,
  forgotPassword,
  verifyCode,
  resetPassword,
};
