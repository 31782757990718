interface ITileInput {
  title: string;
  active: number
  standby: number
  finished: number
}

function StatusTile({ title, active, standby, finished }: ITileInput) {
  let titleText = title.split(" ");
  return (
    <div className="tab-pad bg-white shadow-[0px_0px_11px_0px_#00000024] md:w-[48%] h-55 my-8 p-5 overflow-y-auto rounded-2xl dark:status-tile-container">
      <div className=" flex text-lg title-tab">
        <div className=" font-[550] text-gray-500 mt-1 dark:head-text-color title-tab">{titleText[0]}</div>
        <div className=" text-gray-500 mt-1 pl-1 dark:head-text-color title-tab">{titleText[1]}</div>
        <div className=" text-gray-500 mt-1 pl-1 dark:head-text-color title-tab">{titleText[2]}</div>
      </div>
      {/* <div className=" grid grid-cols-3 pt-5 w-full">
        <div className=" flex relative">
          <div>
            <div className=" bg-green-600 h-10 w-10 rounded-full m-1 ">
              <div className="h-full text-center pt-2 text-white">{active}</div>
            </div>
            <div>Active</div>
          </div>
          <div className=" absolute left-11 top-6 bg-slate-600 w-full">
            <hr className=" border-slate-400" />
          </div>
        </div>
        <div className=" flex  relative">
          <div>
            <div className=" bg-orange-400 h-10 w-10 rounded-full m-1">
              <div className="h-full text-center pt-2 text-white">{standby}</div>
            </div>
            <div>StandBy</div>
          </div>
          <div className=" absolute left-11 top-6  w-full">
            <hr className=" border-slate-400" />
          </div>
        </div>
        <div className=" flex  relative">
          <div>
            <div className=" bg-gray-600 h-10 w-10 rounded-full m-1">
              <div className="h-full text-center pt-2 text-white">{finished}</div>
            </div>
            <div>InActive</div>
          </div>
        </div>
      </div> */}
      <div className="flex justify-around pt-3 ">
        <div className="status-tile">
        <div className="text-gray-500 dark:text-color ">Active</div>
          
          <div className="round-box bg-green-600">{active}</div>
        </div>
        <div className=" status-tile">
         <div className="text-gray-500 dark:text-color">StandBy</div> <div className="round-box bg-orange-400 ">{standby}</div>
        </div>
        <div className=" status-tile">
        <div className="text-gray-500 dark:text-color">Inactive</div> <div className="round-box bg-gray-600">{finished}</div>
        </div>
      </div>
    </div>
  );
}

export default StatusTile;
