import { useNavigate } from "react-router-dom";
import SortingIcon from "../../assets/images/sorting_icon.svg";
import SortingIconDesc from "../../assets/images/sorting_icon_down.svg";
import { useEffect, useState } from "react";
import PaginatedItems from "../components/pagination.component";
import { NAVIGATIONS } from "../../config/default.config";
import noImageIcon from "../../assets/images/No-image.jpg";
import { deviceActions } from "./services/device.actions";
import {
  IDeviceItem,
  IDeviceListRequest,
  IPaginationPayload,
} from "./device.interface";
import { Tooltip } from "@mui/material";
import { encryptUtils } from "../../utils/encryption.utils";
import NoDataFound from "../components/noDataFound";

export function DeviceList() {
  const navigate = useNavigate();
  const handleAddDeviceItemClick = (id: number) => {
    navigate(
      NAVIGATIONS.DEVICE_VIEW.replace(":id", `${encryptUtils.encryptURL(id)}`)
    );
  };
  const [formattedDeviceList, setFormattedDeviceList] = useState<IDeviceItem[]>(
    []
  );
  const [totalRecords, setTotalRecords] = useState();
  const [paginationPayload, setPaginationPayload] =
    useState<IPaginationPayload>({
      page: 1,
      pageSize: 16,
      orderByDesc: true,
      orderBy: "Name",
      searchKey: "",
    });

  const onPaginationChange = (
    curretdata: any,
    itemOffset: any,
    endOffset: any
  ) => {
    setFormattedDeviceList(curretdata);
  };

  const handleAddDeviceClick = () => {
    navigate(NAVIGATIONS.DEVICE_ADD);
  };

  const getDeviceList = async (IDeviceListRequest: IDeviceListRequest) => {
    const response = await deviceActions.getDeviceList(IDeviceListRequest);
    setTotalRecords(response?.totalItems);
    let tempDeviceItemList: IDeviceItem[] = [];
    response?.data.map((device: any) => {
      let IDeviceItem: IDeviceItem = {
        id: device?.id,
        name: device?.name,
        imei: device?.imei,
        image: device?.images[0]?.url,
      };
      tempDeviceItemList.push(IDeviceItem);
    });
    setFormattedDeviceList(tempDeviceItemList);
  };

  const reRenderDevices = (page: number) => {
    setPaginationPayload({
      ...paginationPayload,
      page: page,
    });

    let deviceListRequest: IDeviceListRequest = {
      Page: page,
      PageSize: paginationPayload.pageSize,
      OrderByDesc: paginationPayload.orderByDesc,
      OrderBy: paginationPayload.orderBy,
      SearchKey: paginationPayload.searchKey,
    };
    getDeviceList(deviceListRequest);
  };

  useEffect(() => {
    const deviceListRequest: IDeviceListRequest = {
      Page: paginationPayload.page,
      PageSize: paginationPayload.pageSize,
      OrderByDesc: paginationPayload.orderByDesc,
      OrderBy: paginationPayload.orderBy,
      SearchKey: paginationPayload.searchKey,
    };
    getDeviceList(deviceListRequest);
  }, [
    paginationPayload.page,
    paginationPayload.pageSize,
    paginationPayload.orderByDesc,
    paginationPayload.orderBy,
    paginationPayload.searchKey,
  ]);

  return (
    <div className="inner-page-row">
      <div className="inline-block w-[100%] mt-5">
        <div className="float-left">
          <h2 className="text-xl">
            <b>Devices</b>
          </h2>
        </div>
      </div>
      <div className="top-bar-sticky">
        <div className="flex flex-wrap items-center">
          <div className="flex-1 w-100">
            <input
              type="text"
              name="searchKey"
              id="searchKey"
              className="border rounded-lg border-gray-300 p-2 placeholder-gray-300  text-slate-500     w-[100%] cursor-pointer"
              placeholder="Search Devices"
              onChange={(e) => {
                setPaginationPayload({
                  ...paginationPayload,
                  searchKey: e?.target?.value,
                });
              }}
            />
          </div>
          <div className="flex-2 w-100">
            <Tooltip title="filter" placement="top">
              <select
                id="sortKey"
                name="sortKey"
                className="border rounded-lg border-gray-300 p-2 placeholder-gray-200  text-slate-500 font-thin  cursor-pointer h-10 w-40 mx-6 bg-white pl-4 pr-4 text-sm font-thin focus:outline-none focus:border-red-500 cursor-pointer"
                onChange={(e) => {
                  setPaginationPayload({
                    ...paginationPayload,
                    orderBy: e?.target?.value,
                  });
                }}
              >
                <option value="Name">Name</option>
                <option value="IMEI">IMEI</option>
              </select>
            </Tooltip>
          </div>
          <div className="flex-none ml-2 cursor-pointer">
            <Tooltip
              title={
                paginationPayload.orderByDesc ? "sort by asc" : "sort by desc"
              }
              placement="top"
            >
              <img
                width={26}
                height={26}
                style={{ width: 26, height: 26 }}
                src={
                  paginationPayload.orderByDesc ? SortingIcon : SortingIconDesc
                }
                alt="logo"
                onClick={() => {
                  setPaginationPayload({
                    ...paginationPayload,
                    orderByDesc: !paginationPayload.orderByDesc,
                  });
                }}
              ></img>
            </Tooltip>
          </div>
          <div className="flex-none ml-2 cursor-pointer">
            <button
              className=" py-2 btn btn-primary"
              onClick={() => handleAddDeviceClick()}
            >
              <div>Add</div>
            </button>
          </div>
        </div>
      </div>
      {formattedDeviceList && formattedDeviceList.length > 0 ? (
        <div>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 content-start mb-5 inner-page-list">
            {formattedDeviceList?.map((item: IDeviceItem, index) => {
              return (
                <div
                  key={item?.id}
                  className="transform cursor-pointer hover:scale-105 transition duration-75 active:bg-blue-100 rounded-xl place-content-center bg-white xs:w-full shadow-1xl mt-5 mr-5 border-dashed border-1 border-gray-500"
                  onClick={() => {
                    handleAddDeviceItemClick(item?.id);
                  }}
                >
                  <div className=" flex justify-center flex-col p-[10px] sm:p-[20px] md:p-[30px]">
                    <div className="flex flex-wrap content-start ">
                      <img
                        style={{ width: 60, height: 60 }}
                        src={item.image ? item.image : noImageIcon}
                        alt="logo"
                        className="border border-gray h-32 rounded mr-5 "
                      ></img>
                      <div className="cursor-pointer box-row">
                        <p>
                          <b>{item?.name}</b>
                        </p>
                        <p className="text-xs cursor-pointer">{item?.imei}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <PaginatedItems
            itemsPerPage={paginationPayload.pageSize}
            totalRecords={totalRecords}
            onPagination={onPaginationChange}
            reRenderDevices={reRenderDevices}
          />
        </div>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
}
