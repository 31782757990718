import { IImageInfo } from "../modules/components/image-upload/image-upload.component";

export const processFiles = (files: any[]): IImageInfo[] => {
  const images: IImageInfo[] = [];

  for (let file of files) {
    images.push({
      reference: file?.reference,
      resource: file?.url,
      deleted: false,
    });
  }

  return images;
};
