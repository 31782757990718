import React, { useEffect, useState } from "react";
import backBtnIcon from "../../assets/images/Back_icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  isValidPhoneNumber,
} from "react-phone-number-input";
import { useConfirmationPopup } from "../components/confirmation.component/confirmation-popup-context";
import {
  Field,
  reduxForm,
} from "redux-form";
import {
  addressValidation,
  alphaNumericValidation,
  locationValidation,
  minMaxValidation,
  minMaxValidationForAddress,
  nameValidation,
  requiredValidation,
} from "../../validations/validations";
import {
  CANCEL_BUTTON_TEXT,
  CONFIRMATION_DIALOUGES,
  CONFIRMATION_TITLES,
  NAVIGATIONS,
  OPERATOR_MESSAGES,
  SUBMIT_BUTTON_TEXT,
  VALIDATION_MESSAGES,
} from "../../config/default.config";
import { useAppDispatch, useAppSelector } from "../../config/store.hooks";
import ImageUploadComponent, {
  IImageInfo,
} from "../components/image-upload/image-upload.component";
import { setIsError, setIsLoading, setToast, setToastMsg } from "../../common/common.reducer";
import {
  IAddOrUpdateOperatorRequest,
  OPERATOR_OPERATIONS,
} from "./operator.interface";
import { operatorActions } from "./services/operator.actions";
import { encryptUtils } from "../../utils/encryption.utils";


const renderInputName = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="name"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Name<span className="text-red-500">*</span>
      </label>
      <div className="">
        <input
          {...props?.input}
          type="text"
          name="name"
          id="name"
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderMobile = (props: any) => {
  return (
    <div className="sm:col-span-3">
      <label
        htmlFor="mobile"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Mobile<span className="text-red-500">*</span>
      </label>
      <div className="">
        <PhoneInput
          name="mobile"
          id="mobile"
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color  block h-10 w-full p-3 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Enter phone number"
          value={props?.phoneNumber}
          onChange={(value: string) => {
            props?.setPhoneNumberError(false);
            props?.setPhoneNumber(value);
            props?.validatePhoneNumber(String(value));
          }}
          onCountryChange={(country: any) => {
            props?.setPhoneNumberError(false);
            props?.setSelectedCountryCode(country);
          }}
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
        {props?.phoneNumberError && VALIDATION_MESSAGES.INVALID_PHONE_NUMBER}
      </div>
    </div>
  );
};

const renderLocation = (props: any) => {
  return (
    <div className="sm:col-span-6">
      <label
        htmlFor="location"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Location<span className="text-red-500">*</span>
      </label>
      <div className="">
        <input
          {...props?.input}
          type="text"
          name="location"
          id="location"
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color  block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm validation-error">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

const renderAddress = (props: any) => {
  return (
    <div className="col-span-12 ">
      <label
        htmlFor="address"
        className="block text-sm font-medium leading-6 text-gray-900  dark:normal-text-color"
      >
        Address <span className="text-red-500">*</span>
      </label>
      <div className="mt-2">
        <textarea
          rows={4}
          {...props?.input}
          type="text"
          name="address"
          id="address"
          maxLength={60}
          className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:normal-text-color  resize-none block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className=" text-red-400 pt-2 text-sm">
        {props.meta.touched ? props.meta.error : ""}
      </div>
    </div>
  );
};

export let OperatorCreate: any = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleSubmit, valid, reset, dirty } = props;
  const { open, close } = useConfirmationPopup();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [deletedImageCount, setDeletedImageCount] = useState<number>(0);
  const { id } = useParams();
  const [selectedCountryCode, setSelectedCountryCode] = useState<any>();
  const [ready, setReady] = useState<boolean>(false);
  const operatorFromStore = useAppSelector(
    (state) => state.operatorReducer.operator
  );
  const [images, setImages] = useState<IImageInfo[]>([]);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);

  function validatePhoneNumber(phoneNumber: string) {
    console.log(typeof phoneNumber, selectedCountryCode);
    if (!isValidPhoneNumber(phoneNumber, selectedCountryCode)) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
    return undefined;
  }

  const handleSave = async (values: any) => {
    dispatch(setIsLoading(true));
    if (operatorFromStore.name.length !== 0) {
      let updateOperatorRequest: IAddOrUpdateOperatorRequest = {
        id: operatorFromStore.id,
        name: values.name,
        mobile: phoneNumber,
        location: values.location,
        address: values.address,
        images: images,
      };
      dispatch(
        await operatorActions.updateOperator(updateOperatorRequest, setSuccess)
      );
    } else {
      let addOperatorRequest: IAddOrUpdateOperatorRequest = {
        id: 0,
        name: values.name,
        mobile: phoneNumber,
        location: values.location,
        address: values.address,
        images: images,
      };
      dispatch(operatorActions.addOperator(addOperatorRequest, setSuccess));
    }
  };

  const setSuccess = (
    state: boolean,
    type: string,
    errorCode: string | null
  ) => {
    dispatch(setIsLoading(false));
    if (state) {
      if (type === OPERATOR_OPERATIONS.CREATE) {
        dispatch(setIsError(false));
        dispatch(setToastMsg(OPERATOR_MESSAGES.OPERATOR_ADD_SUCCESS));
        dispatch(setToast(true));
        reset();
        navigate(NAVIGATIONS.OPERATOR_LIST);
      } else if (type === OPERATOR_OPERATIONS.UPDATE) {
        dispatch(setIsError(false));
        dispatch(setToastMsg(OPERATOR_MESSAGES.OPERATOR_UPDATE_SUCCESS));
        dispatch(setToast(true));
        navigate(
          NAVIGATIONS.OPERATOR_VIEW.replace(":id", `${encryptUtils.encryptURL(operatorFromStore.id)}`)
        );
      }
    } else {
      if (type === OPERATOR_OPERATIONS.CREATE) {
        dispatch(setIsError(true));
        dispatch(setToastMsg(OPERATOR_MESSAGES.OPERATOR_ADD_FAILED));
        dispatch(setToast(true));
      } else if (type === OPERATOR_OPERATIONS.UPDATE) {
        dispatch(setIsError(true));
        dispatch(setToastMsg(OPERATOR_MESSAGES.OPERATOR_UPDATE_FAILED));
        dispatch(setToast(true));
      }
    }
  };

  const handleOpenPopup = (confirmation: any) => {
    if (dirty || images.length > 0) {
      open(
        CONFIRMATION_TITLES.CONFIRM_EXIT,
        CONFIRMATION_DIALOUGES.UNSAVED_CHANGES,
        SUBMIT_BUTTON_TEXT.YES,
        CANCEL_BUTTON_TEXT.NO,
        () => {
          confirmation(true);
        },
        () => {
          confirmation(false);
        }
      );
    } else {
      confirmation(true);
    }
  };

  const handleClosePopup = () => {
    close();
  };

  const updateDeletedImageCount = (images: IImageInfo[]) => {
    const count = images.filter((image) => !image.deleted).length;
    setDeletedImageCount(count);
  };

  const handleNavigateBack = (confirm: boolean) => {
    handleClosePopup();
    if (confirm) {
      if (operatorFromStore.name.length > 0) {
        navigate(
          NAVIGATIONS.OPERATOR_VIEW.replace(":id", `${encryptUtils.encryptURL(operatorFromStore.id)}`)
        );
      } else {
        navigate(NAVIGATIONS.OPERATOR_LIST);
      }
    }
  };

  const setSuccessForOperatorFetching = (state: boolean) => {
    dispatch(setIsLoading(false))
    if (state) {
      setReady(true);
      dispatch(setIsLoading(false))
    }else{
      dispatch(setIsLoading(false))
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setIsLoading(true));
      let operatorIdForUpdate = encryptUtils.decryptURL(id);
      if (operatorIdForUpdate !== null) {
        dispatch(
          operatorActions.getOperatorById(operatorIdForUpdate, setSuccessForOperatorFetching)
        );
      } else if (operatorIdForUpdate === null) {
        dispatch(setIsLoading(false));
        setReady(true);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
        setImages(operatorFromStore.images);
        setPhoneNumber(operatorFromStore.mobile);
        props.initialize({
          name: operatorFromStore.name,
          mobile: operatorFromStore.mobile,
          location: operatorFromStore.location,
          address: operatorFromStore.address,
          images: operatorFromStore.images,
        });
        setReady(true);
  }, [operatorFromStore]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="inner-page-row">
          <div className="inline-block w-[100%] top-bar-sticky flex justify-between  dark:top-bar-bg-color">
            <div className="float-left flex items-center">
              <button
                type="button"
                className="mr-3"
                onClick={() => {
                  handleOpenPopup(handleNavigateBack);
                }}
              >
                <img src={backBtnIcon} alt="" className="p-1" />
              </button>
              <h2 className="text-xl">
              <b className="ml-5 dark:head-text-color ">
                  {operatorFromStore.name.length > 0
                    ? "Update Operator"
                    : "Add Operator"}
                </b>
              </h2>
            </div>
            <div className="float-right">
              <button
                className={`text-[14px] text-white rounded-md p-2 px-8 py-2 ${
                  !valid ||
                  images.length === 0 ||
                  deletedImageCount === 0 ||
                  phoneNumberError ||
                  phoneNumber == "" ||
                  selectedCountryCode == ""
                    ? "btn bg-slate-300"
                    : "btn btn-primary"
                } `}
                disabled={
                  !valid ||
                  images.length === 0 ||
                  deletedImageCount === 0 ||
                  phoneNumberError ||
                  phoneNumber == "" ||
                  selectedCountryCode == ""
                }
              >
                <div>
                  {operatorFromStore?.name?.length > 0 ? "Update" : "Save"}
                </div>
              </button>
            </div>
          </div>
          <div className=" bg-white mt-6 rounded-md flex p-10 flex-wrap   dark:panel-bg-color">
            <div className="w-1/2 pr-4">
              <h3 className="text-blue-500 font-bold inline-block">
                <span className="underline mb-3 px-2 py-1 dark:head-text-color">Basic</span>
              </h3>

              <div className="pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  <Field
                    type="text"
                    name="name"
                    component={renderInputName}
                    validate={[requiredValidation,nameValidation]}
                  />
                  <Field
                    type="text"
                    name="mobile"
                    component={renderMobile}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    validatePhoneNumber={validatePhoneNumber}
                    setSelectedCountryCode={setSelectedCountryCode}
                    phoneNumberError={phoneNumberError}
                    setPhoneNumberError={setPhoneNumberError}
                  />
                </div>
              </div>
              <div className="w-[100%] rounded-md ">
                <div className="grid grid-cols-12 gap-x-6 gap-y-2 sm:grid-cols-12">
                  <Field
                    type="text"
                    name="location"
                    component={renderLocation}
                    validate={[
                      locationValidation,
                      alphaNumericValidation,
                      minMaxValidation,
                    ]}
                  />
                  <Field
                    type="text"
                    name="address"
                    component={renderAddress}
                    validate={[
                      addressValidation,
                      minMaxValidationForAddress,
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 pl-4 p-20">
            <label
            htmlFor="grossPower"
             className="block text-sm font-medium leading-6 text-gray-900 ml-9  dark:normal-text-color"
              >
              Images <span className="text-red-500">*</span>
             </label>
              <ImageUploadComponent
                images={images}
                onImageUpload={(_images) => {
                  setImages(_images);
                  updateDeletedImageCount(_images);
                }}
              />
              {valid && deletedImageCount === 0 ? (
                <span className=" ml-10 text-red-400 ">Image is required</span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

OperatorCreate = reduxForm({
  form: "create-operator-form",
})(OperatorCreate);

export default OperatorCreate;
